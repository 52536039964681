import AgricultureIcon from '@mui/icons-material/Agriculture';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HomeIcon from '@mui/icons-material/Home';
import LightModeIcon from '@mui/icons-material/LightMode';
import MedicalServices from '@mui/icons-material/MedicalServices';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SolarPowerIcon from '@mui/icons-material/SolarPower';

export const NEEDS = [
  {
    name: 'Zdrowie i życie',
    icon: <MedicalServices fontSize="large" />,
  },
  {
    name: 'Dom i mieszkanie',
    icon: <HomeIcon fontSize="large" />,
  },
  {
    name: 'Podróż',
    icon: <LightModeIcon fontSize="large" />,
  },
  {
    name: 'Pojazd',
    icon: <DirectionsCarIcon fontSize="large" />,
  },
  {
    name: 'Firma',
    icon: <BusinessCenterIcon fontSize="large" />,
  },
  {
    name: 'Gospodarstwo rolne',
    icon: <AgricultureIcon fontSize="large" />,
  },
  {
    name: 'Panele fotowoltaiczne',
    icon: <SolarPowerIcon fontSize="large" />,
  },
  {
    name: 'Inne',
    icon: <MoreHorizIcon fontSize="large" />,
  },
];
