/* eslint-disable */

import { Box, Button, Grid, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { updateOfferStage } from 'api/agent/offer/offerApi';
import { createPolicy, patchPolicy } from 'api/agent/policy/policyApi';
import { usePolicyMutate } from 'api/agent/policy/policyQuery';
import { uploadFile } from 'api/files/filesApi';
import { FileBody } from 'api/files/types';
import { useFormFiles } from 'library/hooks/useFormFiles';
import useModal from 'library/hooks/useModal';
import AgentChat from 'pages/AgentRemoteSale/components/AgentChat';
import InsuranceBox from 'pages/AgentRemoteSale/components/InsuranceBox';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { useOffer } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import { useUploadOfferFile } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOfferFiles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormContent } from 'styles/FormContent';
import { fileResultAsFormFile } from 'utils/formFile';
import { isNotNullish } from 'utils/typeHelpers';

import { FormFile } from 'components/Attachments/types';
import ConfirmationModal from 'components/ConfirmationModal';
import { LeftContainer, MainContainer, RightContainer } from 'components/Layout/styles';
import LoadingView from 'components/LoadingView';

import PolicyFormComponent from './components/PolicyForm/PolicyForm';
import { PolicyForm } from './components/PolicyForm/type';
import { policyFormAsCreateUpdatePolicy } from './components/PolicyForm/utils';

function ClientOffer() {
  const { t } = useTranslation();
  const {
    open: confirmationModalOpen,
    handleClose: closeConfirmationModal,
    handleOpen: openConfirmationModal,
  } = useModal();
  const {
    state: { insurances, policy },
    onUpdate,
  } = useAgentForm();
  const policyMutation = usePolicyMutate(policy?.id);
  const { offer } = useOffer();
  const [formData, setFormData] = useState<PolicyForm>();
  const [notAccept, setNotAccept] = useState(false);

  const formFilesState = useFormFiles({ tempStored: !policy?.id });
  const { mutateAsync: uploadFiles, isLoading: isUploading } = useUploadOfferFile({ policy: policy?.id });
  const { mutateAsync: uploadFilesForNewPolicy, isLoading: isUploadingTempFiles } = useMutation((body: FileBody) =>
    uploadFile(body),
  );

  const selectedInsurance = insurances?.find((insurance) => insurance.chosen);
  const handlePolicySave = (formData: PolicyForm) => {
    setFormData(formData);
    openConfirmationModal();
  };

  const useUpdateOfferStageMutation = () => {
    return useMutation(updateOfferStage);
  };

  const offerPolicy: any = offer?.policy;

  const createPolicyMutation = useMutation(createPolicy);
  const updateOfferStageMutation = useUpdateOfferStageMutation();
  const patchPolicyMutation = useMutation((formData: any) => patchPolicy(formData, offerPolicy), {
    onError: () => {
      toast(t('errors.api.serverError'));
    },
  });

  const handleConfirm = async () => {
    try {
      if (!formData || !offer || notAccept) return;

      if (offer?.policy === null) {
        const form = { ...formData, offer: offer.id };

        const createdPolicy = await createPolicyMutation.mutateAsync(policyFormAsCreateUpdatePolicy(form));
        const createdPolicyId = createdPolicy.id;

        let attachments: FormFile[] = [];

        if (formData.attachments) {
          const filesResults = await Promise.all(
            formData.attachments?.map((tempFile) => {
              if (tempFile.file) return uploadFilesForNewPolicy({ file: tempFile.file, policy: createdPolicyId });
            }),
          );

          attachments = filesResults.filter(isNotNullish).map(fileResultAsFormFile);
        }

        await updateOfferStageMutation.mutateAsync(offer.id);

        const newState = { ...formData, id: createdPolicyId };
        onUpdate({ policy: newState });
      } else if (offer?.policy) {
        const form = { ...formData, offer: offer.id };
        await patchPolicyMutation.mutateAsync(policyFormAsCreateUpdatePolicy(form));
      }
      setNotAccept(true);

      window.location.reload();
    } catch (error) {
      toast(t('errors.api.serverError'));
    }
  };

  if (!selectedInsurance) return <LoadingView />;

  return (
    <MainContainer>
      <LeftContainer>
        <FormContent>
          <InsuranceBox insurance={selectedInsurance} />
          <Box marginTop={5}>
            <PolicyFormComponent
              date={offer?.startDate}
              onSubmit={handlePolicySave}
              initialValues={policy}
              onUpload={policy?.id ? uploadFiles : undefined}
              onAddFile={policy?.id ? formFilesState.onAddFile : undefined}
              onDeleteFile={policy?.id ? formFilesState.onDeleteFile : undefined}
            >
              <Grid container justifyContent="flex-end" marginTop={2}>
                <Button type="submit" variant="contained" disabled={isUploading}>
                  {t('common.next')}
                </Button>
              </Grid>
            </PolicyFormComponent>
          </Box>
        </FormContent>
      </LeftContainer>
      <RightContainer>
        <AgentChat />
      </RightContainer>
      <ConfirmationModal
        open={confirmationModalOpen}
        onReject={closeConfirmationModal}
        onAccept={handleConfirm}
        title={t('common.areYouSureYouWantToContinue')}
        acceptText={t('common.send')}
        rejectText={t('common.cancel')}
        isLoading={policyMutation.isLoading || isUploadingTempFiles}
      >
        <Typography>{t('common.makeSureProvidedDataIsCorrect')}</Typography>
        <Typography>{t('insuranceWizard.policy.policyWillBeSentToClient')}</Typography>
      </ConfirmationModal>
    </MainContainer>
  );
}
export default ClientOffer;
