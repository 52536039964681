import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton } from '@mui/material';
import InsuranceBox from 'pages/AgentRemoteSale/components/InsuranceBox';
import { InsuranceFormState } from 'pages/AgentRemoteSale/components/InsuranceBox/types';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { useState } from 'react';

import InsuranceFormModal from '../InsuranceFormModal/InsuranceFormModal';
import DeleteConfirmation from './DeleteConfirmation';
import * as S from './styles';
import { Insurance, InsuranceAction } from './types';

interface Props {
  insurances: InsuranceFormState[];
}

function InsurancesList({ insurances }: Props) {
  const { onUpdate } = useAgentForm();

  const [selectedInsurance, setSelectedInsurance] = useState<Insurance | undefined>(undefined);

  const handleDelete = () => {
    onUpdate({ insurances: insurances.filter((insurance) => insurance.id !== selectedInsurance?.insurance.id) });
  };

  const handleInsuranceAction =
    (insurance: InsuranceFormState, action: InsuranceAction) => (_: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setSelectedInsurance({ insurance, action });
    };

  const handleCancelAction = () => setSelectedInsurance(undefined);

  return (
    <>
      {insurances?.map((insurance, index) => (
        <Box key={insurance.id} marginBottom={1}>
          <InsuranceBox index={index} insurance={insurance}>
            <IconButton onClick={handleInsuranceAction(insurance, 'EDIT')} color="primary">
              <EditIcon />
            </IconButton>
            <Box>
              <S.Popup
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleCancelAction}
                open={selectedInsurance?.action === 'DELETE' && selectedInsurance.insurance.id === insurance.id}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
                title={<DeleteConfirmation id={insurance.id} onCancel={handleCancelAction} onConfirm={handleDelete} />}
              >
                <IconButton onClick={handleInsuranceAction(insurance, 'DELETE')} color="error">
                  <DeleteForeverIcon />
                </IconButton>
              </S.Popup>
            </Box>
          </InsuranceBox>
        </Box>
      ))}

      <InsuranceFormModal
        insurance={selectedInsurance?.insurance}
        onClose={handleCancelAction}
        showModal={selectedInsurance?.action === 'EDIT'}
      />
    </>
  );
}

export default InsurancesList;
