import isValidNip from 'is-valid-nip';
import isValidPesel from 'is-valid-pesel';
import * as yup from 'yup';

export const passwordValidation = yup.string().min(8, 'errors.passwordTooShort').max(256, 'errors.passwordTooLong');

export const requiredSwitchChecked = yup.boolean().oneOf([true], 'errors.hasToBeChecked');

export const phoneValidation = yup
  .string()
  .min(9, { key: 'errors.phoneMustBeAtLeastCharacters', options: { characters: 9 } })
  .max(12, { key: 'errors.phoneMustBeUpToCharacters', options: { characters: 12 } })
  .transform((value) => (value ? value : undefined));

export const peselValidation = yup
  .string()
  .transform((value) => (value ? value : undefined))
  .test('pesel', 'errors.invalidPesel', (pesel) => (pesel ? isValidPesel(pesel || '') : true));

export const nipValidation = yup
  .string()
  .test('nip', 'errors.invalidNip', (nip) => (nip ? isValidNip(nip || '') : true))
  .transform((value) => (value ? value : undefined));
