/* eslint-disable react-hooks/exhaustive-deps */
import config from 'config';
import { nanoid } from 'nanoid';
import {
  useClientOfferMessages,
  useUpdateMessages,
} from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientMessages';
import { useClientOffer } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientOffer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Chat from 'components/Chat/Chat';
import EmptyChat from 'components/Chat/components/EmptyChat';
import { useMessages } from 'components/Chat/hooks/useMessages';
import { MessageState } from 'components/Chat/types';
import LoadingView from 'components/LoadingView';

import * as S from './styles';
import { paginatedMessagesToState } from './utils';

export default function ClientChat({ setMessagesApk }: any) {
  const { t } = useTranslation();
  const { offer } = useClientOffer();
  const messageMutation = useUpdateMessages();

  const clientMessageAuthor = `${t('common.clientChat')} (${t('common.me')})`;
  const agentMessageAuthor = t('common.agentChat');

  const { isLoading, data } = useClientOfferMessages({
    refetchInterval: config.MESSAGE_REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
    onSuccess: (data) => {
      const messages = paginatedMessagesToState(data, agentMessageAuthor, clientMessageAuthor);
      setMessages(messages);
    },
  });

  const [initialMessagesState] = useState(
    data ? paginatedMessagesToState(data, agentMessageAuthor, clientMessageAuthor) : [],
  );
  const { messages, onAdd, onUpdate, setMessages } = useMessages(initialMessagesState);

  const handleSendMessage = async (messageContent: string) => {
    const newMessageObject: MessageState = {
      id: nanoid(),
      message: messageContent,
      author: clientMessageAuthor,
      sentAt: new Date(),
      variant: 'me',
      state: 'sending',
    };

    try {
      onAdd(newMessageObject);
      await messageMutation.mutateAsync({
        client: offer?.client ?? '',
        offer: offer?.id ?? '',
        text: messageContent,
        file: null,
      });

      onUpdate(newMessageObject.id, { state: 'sent' });
    } catch (error) {
      onUpdate(newMessageObject.id, { state: 'error' });
    }
  };

  useEffect(() => {
    if (setMessagesApk) {
      setMessagesApk(messages);
    }
  }, [messages]);

  if (isLoading)
    return (
      <S.ChatContent>
        <LoadingView />
      </S.ChatContent>
    );

  return (
    <S.ChatContent>
      <Chat messages={messages} onSend={handleSendMessage} emptyScreen={<EmptyChat type="client" />} />
    </S.ChatContent>
  );
}
