import { TextField, TextFieldProps } from '@mui/material';
import { errorMessage } from 'library/language/validation/errorMessage';
import { FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  inputProps?: TextFieldProps;
}

function ControlledTextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ label, inputProps, ...controllerProps }: Props<TFieldValues, TName>) {
  const { t } = useTranslation();
  const { field, fieldState } = useController(controllerProps);

  return (
    <TextField
      {...field}
      helperText={errorMessage(t, fieldState?.error?.message)}
      error={!!fieldState.error}
      fullWidth
      label={label}
      {...inputProps}
    />
  );
}

export default ControlledTextField;
