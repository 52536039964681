import { CardContent, CardContentProps } from '@mui/material';
import styled, { css } from 'styled-components';

interface Props extends CardContentProps {
  $withActions?: boolean;
}

export const ModalContainer = styled(CardContent)<Props>`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 0 !important;

  ${({ $withActions }) => {
    if ($withActions)
      return css`
        margin-bottom: 60px;
      `;
  }}
`;
