import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import InsuranceBox from 'pages/AgentRemoteSale/components/InsuranceBox';
import PolicyBox from 'pages/AgentRemoteSale/steps/Payment/components/PolicyBox';
import ClientChat from 'pages/ClientRemoteSale/components/ClientChat';
import { useClientForm } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientForm';
import { useClientOffer } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientOffer';
import { useUploadClientOfferFile } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientOfferFiles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LeftContainer, MainContainer, RightContainer } from 'components/Layout/styles';
import WizardLoadingView from 'components/WizardLoadingView';

import Sign from './components/Sign';
import { SignForm } from './components/Sign/SignMethods/types';
import * as S from './styles';

function PolicyPayment() {
  const { t } = useTranslation();
  const { offer } = useClientOffer();
  const {
    state: { clientData, selectedInsurance, policy },
    onUpdate,
  } = useClientForm();

  const [messagesApk, setMessagesApk] = useState([]);
  const { mutateAsync: uploadFiles, isLoading, isSuccess } = useUploadClientOfferFile({ policy: policy?.id });

  const handleSubmit = async (data: SignForm) => {
    onUpdate({ sign: data });

    if (data.type === 'SCAN_DOCUMENT' && policy) {
      Promise.all(
        data.documents?.map((tempFile) => {
          if (tempFile.file) return uploadFiles(tempFile.file);
        }),
      );
    }
  };

  if (!clientData || !selectedInsurance || !policy) return <WizardLoadingView height="100vh" />;

  return (
    <MainContainer>
      <LeftContainer>
        <S.FormContentWithSpacing>
          <PolicyBox client={clientData} policy={policy} insurance={selectedInsurance} clientView />
          {isSuccess && (
            <Fade in>
              <Alert severity="success">{t('insuranceWizard.paymentStep.yourSignHasBeenCompleted')}</Alert>
            </Fade>
          )}

          <S.Box>
            <Typography variant="body2" fontWeight="bold">
              {t('insuranceWizard.policy.acceptPolicyWithThisMethod')}
            </Typography>
            <Typography variant="body2" fontWeight="bold" color="gray" style={{ marginBottom: '15px' }}>
              {t(`insuranceWizard.policy.signMethods.${policy.signMethod}.label`)}
            </Typography>
            {!isSuccess && (
              <Sign onSubmit={handleSubmit} signMethod={policy.signMethod}>
                <S.SignButton variant="contained" type="submit" disabled={isLoading}>
                  {t('insuranceWizard.paymentStep.sign')}
                </S.SignButton>
              </Sign>
            )}
          </S.Box>
          <Grid marginBottom={2}>
            <Typography variant="body2" fontWeight="bold">
              Przygotowano na podstawie zaakceptowanej propozycji ubezpieczenia:
            </Typography>
          </Grid>
          <InsuranceBox
            insurance={selectedInsurance}
            acceptedDate={offer?.acceptedDate || null}
            stage={offer?.stage}
            messagesApk={messagesApk}
          />
        </S.FormContentWithSpacing>
      </LeftContainer>
      <RightContainer>
        <ClientChat setMessagesApk={setMessagesApk} />
      </RightContainer>
    </MainContainer>
  );
}

export default PolicyPayment;
