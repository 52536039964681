import { Offer } from 'api/agent/offer/types';
import { insuranceAsInsuranceForm } from 'pages/AgentRemoteSale/steps/InsurancesSuggestions/utils';
import { fileResultAsFormFile } from 'utils/formFile';

import { FormState } from './types';

export function offerAsFormState(offer: Offer, currentFormState: FormState): FormState {
  const insurances = offer.insurance.map(insuranceAsInsuranceForm);

  return {
    offer: offer.id,
    clientNeeds: {
      name: offer.needsShort,
      description: offer.needsDescription,
      startDate: new Date(offer.startDate),
      insuranceType: offer.insuranceType,
    },
    clientData: currentFormState.clientData
      ? {
          ...currentFormState.clientData,
          attachments: offer.files.map(fileResultAsFormFile),
        }
      : undefined,
    selectedInsurance: insurances.find((insurance) => insurance.chosen),
    insurances,
  };
}
