import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import config from 'config';
import useBrand from 'library/hooks/useBrand';
import { DateTime } from 'luxon';
import { InsuranceFormState } from 'pages/AgentRemoteSale/components/InsuranceBox/types';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import { PolicyForm } from 'pages/AgentRemoteSale/steps/Policy/components/PolicyForm/type';
import { useTranslation } from 'react-i18next';
import { accountNumberWithSpaces } from 'utils/accountNumber';

import DownloadList from 'components/DownloadList';
import LabelValue from 'components/LabelValue';

import * as S from './styles';

interface Props {
  policy: PolicyForm;
  client: ClientDataFormState;
  insurance: InsuranceFormState;
  clientView?: boolean;
}

function PolicyBox({ policy, insurance, client }: Props) {
  const { t } = useTranslation();
  const brand = useBrand(insurance.companyBrand);

  const total = insurance?.cost.find((item: any) => item.chosen);

  return (
    <S.Container variant="outlined">
      {policy.receivedDate && (
        <Grid container justifyContent="space-between" padding={2}>
          <Grid item>
            {policy.receivedDate && (
              <LabelValue label={t('insuranceWizard.clientOffer.seenByClient')}>
                {DateTime.fromJSDate(policy.receivedDate).toFormat(config.DATE_TIME_FORMAT_LONG)}
              </LabelValue>
            )}
          </Grid>
          <Grid item>
            {policy.confirmedDate && (
              <LabelValue label={t('insuranceWizard.clientOffer.acceptedByTheClient')}>
                {DateTime.fromJSDate(policy.confirmedDate).toFormat(config.DATE_TIME_FORMAT_LONG)}
              </LabelValue>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container gap={1} padding={2}>
        <Grid item xs={2} container flexDirection="column" justifyContent="space-between" alignItems="center" gap={5}>
          <img src={brand?.img} alt="insurance logo" />
        </Grid>
        <Grid item xs={4} paddingX={5} container flexDirection="column" justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle1" fontWeight="bold">
              {total.cost} PLN
            </Typography>
            <Typography>{policy.name}</Typography>
          </Grid>
          <Grid item>
            <Typography>{t('insuranceWizard.policy.signBy')}</Typography>
            <Typography fontWeight="bold">
              {t(`insuranceWizard.policy.signMethods.${policy.signMethod}.label`)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs container flexDirection="column" justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle1" fontWeight="bold" textAlign="right">
              {client.clientUser?.firstName} {client.clientUser?.lastName}
            </Typography>
            <Typography textAlign="right">
              {t('common.from')}:{' '}
              <b>{DateTime.fromJSDate(policy.startProtection).toFormat(config.DATE_FORMAT_SHORT)}</b>
            </Typography>
            <Typography textAlign="right">
              {t('common.to')}:{' '}
              <b>
                {policy.endProtection
                  ? DateTime.fromJSDate(policy.endProtection).toFormat(config.DATE_FORMAT_SHORT)
                  : t('insuranceWizard.policy.endless')}
              </b>
            </Typography>
          </Grid>
          <Grid item>
            {policy.attachments.length ? (
              <DownloadList justifyContent={'flex-end'} files={policy.attachments} spacing={0.5} />
            ) : (
              <S.AttachmentsText>({t('insuranceWizard.clientOffer.noAttachments')})</S.AttachmentsText>
            )}
          </Grid>
        </Grid>
      </Grid>
      {policy?.paymentDate instanceof Date &&
      policy?.paymentDate.toString() !== 'Invalid Date' &&
      policy?.accountNumber !== '' ? (
        <S.Footer>
          <Typography>
            {t('insuranceWizard.policy.paymentUntil')}:{' '}
            {DateTime.fromJSDate(policy.paymentDate).toFormat(config.DATE_FORMAT_SHORT)}
          </Typography>
          <Typography>
            {t('insuranceWizard.policy.accountNumber')}: {accountNumberWithSpaces(policy.accountNumber)}
          </Typography>
          <Typography>
            {t('insuranceWizard.policy.title')}: {policy.tuNumber}
          </Typography>
        </S.Footer>
      ) : null}
    </S.Container>
  );
}

export default PolicyBox;
