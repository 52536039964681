import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 200px;
  flex: 100%;
`;

export const Container = styled.div`
  display: flex;
  width: 600px;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`;

export const Policy = styled.div`
  display: flex;
  justify-content: center;
  width: 600px;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 20px;
`;
