import AgentAddPolicy from 'pages/AgentAddPolicy/AgentAddPolicy';
import AgentDashboard from 'pages/AgentDashboard';
import AgentExternalPolicy from 'pages/AgentExternalPolicy';
import AgentNewPassword from 'pages/AgentNewPassword';
import AgentPolicy from 'pages/AgentPolicy';
import AgentProfile from 'pages/AgentProfile';
import AgentRemindPassword from 'pages/AgentRemindPassword';
import AgentRemoteSale from 'pages/AgentRemoteSale';
import AgentSettlements from 'pages/AgentSettlements';
import AgentSignIn from 'pages/AgentSignIn';
import AgentSignUp from 'pages/AgentSignUp';
import AgentSupport from 'pages/AgentSupport';
import AgentTasksAndService from 'pages/AgentTasksAndService';
import ClientRemoteSale from 'pages/ClientRemoteSale';
import ClientSignIn from 'pages/ClientSignIn';
import ClientUnauthorized from 'pages/ClientUnauthorized';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import AgentGlobalPage from './pages/AgentGlobalPage';
import AgentPages from './pages/AgentPages';
import AgentPrivatePages from './pages/AgentPrivatePages';
import AgentPublicPages from './pages/AgentPublicPages';
import ClientPages from './pages/ClientPages';
import ClientPrivatePages from './pages/ClientPrivatePages';
import ClientPublicPages from './pages/ClientPublicPages';
import { PATHS } from './paths';

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={PATHS.agent.index} />} />

        {/* Agent routes */}
        <Route path={PATHS.agent.index} element={<AgentPages />}>
          <Route element={<AgentPublicPages />}>
            <Route path={PATHS.agent.signIn} element={<AgentSignIn />} />
            <Route path={PATHS.agent.singUp} element={<AgentSignUp />} />
            <Route path={PATHS.agent.remindPassword} element={<AgentRemindPassword />} />
            <Route path={PATHS.agent.newPassword} element={<AgentNewPassword />} />
          </Route>
          <Route element={<AgentPrivatePages />}>
            <Route index element={<AgentDashboard />} />
            <Route path={PATHS.agent.remoteSale} element={<AgentRemoteSale />} />
            <Route path={'/agent/remote-sale/client/:id'} element={<AgentRemoteSale />} />
            <Route path={PATHS.agent.support} element={<AgentSupport />} />
            <Route path={PATHS.agent.profile} element={<AgentProfile />} />
            <Route path={PATHS.agent.addPolicy} element={<AgentAddPolicy />} />
            <Route path={PATHS.agent.externalPolicy} element={<AgentExternalPolicy />} />
          </Route>
          <Route element={<AgentGlobalPage />}>
            <Route path={PATHS.agent.tasks} element={<AgentTasksAndService text="odznacz" />} />
            <Route path={PATHS.agent.service} element={<AgentTasksAndService text="zaznacz" />} />
            <Route path={PATHS.agent.clientsAndPolicies} element={<AgentPolicy />} />
            <Route path={PATHS.agent.settlements} element={<AgentSettlements />} />
          </Route>
          <Route path="*" element={<Navigate to={PATHS.agent.index} replace />} />
        </Route>

        {/* Client routes */}
        <Route path={PATHS.client.index} element={<ClientPages />}>
          <Route element={<ClientPublicPages />}>
            <Route path={PATHS.client.signIn} element={<ClientSignIn />} />
            <Route path={PATHS.client.signIn + '/:invitationId'} element={<ClientSignIn />} />
            <Route path={PATHS.client.unauthorized} element={<ClientUnauthorized />} />
          </Route>
          <Route element={<ClientPrivatePages />}>
            <Route path={PATHS.client.remoteSale} element={<ClientRemoteSale />} />
          </Route>

          <Route path="*" element={<Navigate to={PATHS.client.remoteSale} replace />} />
          <Route index element={<Navigate to={PATHS.client.remoteSale} replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
