import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosMutationOptions, AxiosQueryOptions } from 'api/types';
import { useOffer } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';

import { CreateUpdatePolicy, Policy } from '../../types/policy';
import { createPolicy, getPolicy, patchPolicy } from './policyApi';

const POLICY_QUERY = (id: string) => ['policy', id];

export function usePolicyMutate(id?: string, options?: AxiosMutationOptions<Policy, CreateUpdatePolicy>) {
  const { offer } = useOffer();

  if (!offer) throw new Error('No offer');

  return useMutation((body: Partial<CreateUpdatePolicy>) => {
    const policyBodyWithOffer = { offer: offer.id, ...body };
    if (!id) return createPolicy(policyBodyWithOffer);
    else return patchPolicy(policyBodyWithOffer, id);
  }, options);
}

export function usePolicyQuery(policyId: string, options?: AxiosQueryOptions<Policy>) {
  return useQuery(POLICY_QUERY(policyId), () => getPolicy(policyId), options);
}
