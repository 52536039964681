import { Grid } from '@mui/material';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledTextField, FormGroup } from 'components/Form';

import * as S from './styles';

function ContactInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'insuranceWizard.clientData' });
  const { control, getValues } = useFormContext<ClientDataFormState>();

  const isIndividualPerson = !getValues('company');

  return (
    <FormGroup title={t('contactData')}>
      <S.Container>
        <Grid item md={6} xs={12}>
          <ControlledTextField control={control} name="clientUser.firstName" defaultValue="" label={t('name')} />
        </Grid>
        <Grid item md={6} xs={12}>
          <ControlledTextField control={control} name="clientUser.lastName" defaultValue="" label={t('lastName')} />
        </Grid>
        <Grid item md={6} xs={12}>
          <ControlledTextField
            control={control}
            name="clientUser.email"
            defaultValue=""
            label={t('email')}
            inputProps={{
              type: 'email',
              disabled: true,
              InputLabelProps: {
                required: true,
              },
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ControlledTextField
            control={control}
            name="phoneNumber"
            defaultValue=""
            label={t('phoneNumber')}
            inputProps={{ type: 'tel' }}
          />
        </Grid>
        {isIndividualPerson && (
          <Grid item md={6} xs={12}>
            <ControlledTextField control={control} name="clientUser.pesel" defaultValue="" label={t('pesel')} />
          </Grid>
        )}
      </S.Container>
    </FormGroup>
  );
}

export default ContactInfo;
