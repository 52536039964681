import Alert from '@mui/material/Alert';
import { useClientForm } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientForm';
import { useTranslation } from 'react-i18next';

import BaseClientDataBox from 'components/RemoteSale/BaseClientDataBox';

interface Props {
  defaultOpen?: boolean;
}

function ClientDataInfoBox({ defaultOpen }: Props) {
  const { t } = useTranslation();
  const {
    state: { clientData },
  } = useClientForm();

  if (!clientData) return <Alert severity="error">{t('errors.cantDisplay')}</Alert>;

  return <BaseClientDataBox clientData={clientData} defaultOpen={defaultOpen} readonly type="client" />;
}
export default ClientDataInfoBox;
