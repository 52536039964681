import * as S from './styles';

function Bar(props: any) {
  const { stage } = props;

  const step = () => {
    switch (stage) {
      case 0:
        return <S.Bar width="0%" />;
      case 1:
        return <S.Bar width="25%" />;
      case 2:
        return <S.Bar width="50%" />;
      case 3:
        return <S.Bar width="75%" />;
      case 4:
        return <S.Bar width="100%" />;
      default:
        return null;
    }
  };

  return <S.Main>{step()}</S.Main>;
}

export default Bar;
