import { patchOfferNew, updateOfferStage } from 'api/agent/offer/offerApi';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { useTranslation } from 'react-i18next';

import NeedsForm from './components/NeedsForm/NeedsForm';
import { clientNeedsAsOfferRequest } from './components/NeedsForm/utils';
import * as S from './styles';
import { ClientNeedsFormState } from './types';

function ClientNeeds() {
  const { t } = useTranslation();
  const { goTo } = useWizard();
  const { onUpdate: onFormUpdate, state, clientForId } = useAgentForm();

  const idOffer = localStorage.getItem('selected_client_id');

  const onSubmit = async (data: ClientNeedsFormState) => {
    const body = {
      ...clientNeedsAsOfferRequest(data),
      client: clientForId,
    };

    try {
      if (idOffer !== null) {
        const offer = await patchOfferNew(idOffer, body);
        onFormUpdate({ clientNeeds: data });
        updateOfferStage(offer.id);
        goTo(2, 2);
      }
    } catch (error) {
      console.error('Błąd podczas wykonywania mutacji:', error);
    }
  };

  return (
    <S.Container>
      <NeedsForm onSubmit={onSubmit} initialState={state.clientNeeds}>
        <S.Submit>{t('common.next')}</S.Submit>
      </NeedsForm>
    </S.Container>
  );
}

export default ClientNeeds;
