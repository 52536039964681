import { Typography, dividerClasses } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';
import { Expandable } from 'styles/Expandable';

export const BoxContent = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr auto;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(1)};
  height: 80px;
  overflow: hidden;
  align-items: center;

  img {
    width: 100%;
    padding: ${theme.spacing(1)};
    height: 100%;
    object-fit: contain;
    display: block;
    overflow: hidden;
  }
`;

export const BoxInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: start;
  height: 100%;
  overflow: hidden;
  gap: ${theme.spacing(1)};

  p {
    line-height: 1.2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const Name = styled(Typography)`
  width: 80%;
  font-weight: bold;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  margin-left: ${theme.spacing(2)};
`;

export const ExpandMore = styled(Expandable)`
  height: fit-content;
`;

export const DescriptionContent = styled.div`
  padding: 0 ${theme.spacing(2)} ${theme.spacing(2)};
  text-align: left;

  .${dividerClasses.root} {
    margin-bottom: ${theme.spacing(2)};
  }
`;

export const ContributionWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
`;

export const Contribution = styled.div`
  display: flex;
  justify-content: center;
  align-itmes: center;
`;

export const Text = styled.p`
  margin: 0px;
  font-weight: 1000;
`;

export const DownloadAPKButtonContent = styled.span`
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
