import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 120px 70px 0 70px;
`;

export const Paragraph = styled.p<any>`
  font-size: 14px;
  margin: ${(props) => props.margin};
  font-weight: ${(props) => props.weight};
  @media (max-width: 1000px) {
    font-size: 11px;
  }
`;

export const Input = styled.input`
  border: 2px solid ${theme.palette.grey[300]};
  font-size: 16px;
  padding-right: 40px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  @media (max-width: 1400px) {
    margin-bottom: 30px;
  }
`;

export const Loupe = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  right: 10px;
  top: 0;
  background-color: transparent;
  border: none;
`;

export const Views = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  width: 100%;
  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  font-size: 20px;
  padding: 20px 0;
  margin-top: 25px;
  width: 100%;
  @media (max-width: 1400px) {
    padding: 0;
    justify-content: center;
  }
`;
