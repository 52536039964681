/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useState } from 'react';
import { createContext } from 'react';

interface Props {
  initialStep?: number;
  children: React.ReactNode;
}

interface WizardContextProps {
  actStep: number;
  stage: number;
  completedSteps: Record<number, boolean>;
  onNext: () => void;
  onPrev: () => void;
  goTo: (step: number, stage: number) => void;
}

export const WizardContext = createContext<WizardContextProps>({
  actStep: 0,
  stage: 0,
  completedSteps: [],
  onNext: () => {},
  onPrev: () => {},
  goTo: () => {},
});

function WizardProvider({ initialStep = 0, children }: Props) {
  const [step, setStep] = useState<number>(initialStep);
  const [completedSteps, setCompletedSteps] = useState<Record<number, boolean>>({});
  const [stage, setStage] = useState<number>(0);

  const handleNext = useCallback(() => {
    setStep((currentStep) => {
      const nextValue = currentStep + 1;
      setCompletedSteps((prevCompleted) => ({ ...prevCompleted, [currentStep]: true }));
      return nextValue;
    });
    setStage((stage) => stage + 1);
  }, []);

  const handlePrev = useCallback(
    () =>
      setStep((currentStep) => {
        const prevValue = currentStep - 1;
        if (prevValue < 0) return currentStep;
        return currentStep;
      }),
    [],
  );

  const handleGoTo = useCallback((step: number, stage: number) => {
    setStep(step);
    setStage(stage);
  }, []);

  return (
    <>
      <WizardContext.Provider
        value={{
          completedSteps: completedSteps,
          actStep: step,
          onNext: handleNext,
          onPrev: handlePrev,
          goTo: handleGoTo,
          stage: stage,
        }}
      >
        {children}
      </WizardContext.Provider>
    </>
  );
}

export default WizardProvider;
