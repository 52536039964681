import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import { Box, Grid, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
import { KeyboardEvent, ReactNode, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { ControlledTextField } from 'components/Form';

import MessagesDisplay from './components/MessagesDisplay';
import { chatSchema } from './schema';
import * as S from './styles';
import { MessageForm, MessageState } from './types';

function generateShortUniqueId(): string {
  return uuidv4().replace(/-/g, '').substring(0, 21);
}

interface Props {
  messages: MessageState[];
  emptyScreen?: ReactNode;
  onSend: (messageContent: string, attachedFile: File | null) => void;
}

function Chat({ messages, onSend, emptyScreen }: Props) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<MessageForm>({
    mode: 'onBlur',
    resolver: yupResolver(chatSchema),
  });
  const formRef = useRef<HTMLFormElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [attachedFile, setAttachedFile] = useState<any>({
    id: '',
    name: '',
    url: '',
  });

  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    setAttachedFile({
      id: generateShortUniqueId(),
      name: file?.name,
      url: URL.createObjectURL(file),
      ...file,
    });
  };

  const handleReadFile = (file: File) => {
    const reader = new FileReader();
    reader.readAsText(file);
  };

  const onSubmit: SubmitHandler<MessageForm> = (data, event) => {
    event?.stopPropagation();

    const messageContent = data.message;
    onSend(messageContent, attachedFile);
    reset({ message: '' });

    if (attachedFile.url) {
      handleReadFile(attachedFile);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  useEffect(() => {
    if (!chatContainerRef.current) return;
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [messages.length]);

  return (
    <S.Wrapper direction="column" container>
      <S.ChatContainer item xs ref={chatContainerRef}>
        {!messages.length ? emptyScreen : <MessagesDisplay messages={messages} />}
      </S.ChatContainer>
      <Grid item xs="auto">
        <Box marginTop={2}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <ControlledTextField
              control={control}
              name="message"
              defaultValue=""
              inputProps={{
                size: 'small',
                multiline: true,
                placeholder: 'Aa',
                onKeyDown: handleKeyDown,
                helperText: undefined,
                error: false,
                maxRows: 4,
                InputProps: {
                  endAdornment: (
                    <>
                      <IconButton type="button" size="small" onClick={() => fileInputRef.current?.click()}>
                        <AddIcon />
                      </IconButton>
                      <IconButton type="submit" size="small" disabled={!isValid}>
                        <SendIcon />
                      </IconButton>
                    </>
                  ),
                },
              }}
            />
            <input
              type="file"
              accept=".pdf"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </form>
        </Box>
      </Grid>
    </S.Wrapper>
  );
}

export default Chat;
