import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Link, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { email } from 'api/auth/authApi';
import { EmailRequestBody } from 'api/auth/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { PATHS } from 'routes/paths';

import { ControlledTextField } from 'components/Form';

import PasswordUpdate from '../../components/PasswordUpdate';
import { emailSchema } from './schema';
import * as S from './styles';

function AgentRemindPassword() {
  const { mutate, isLoading } = useMutation(email);
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<EmailRequestBody>({ resolver: yupResolver(emailSchema) });

  const [isReset, setIsReset] = useState<boolean>(false);

  const onSubmit = (data: EmailRequestBody) =>
    mutate(data, {
      onSuccess: () => {
        setIsReset(true);
      },
    });

  return (
    <S.RemindPasswordWrapper>
      {!isReset ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5">{t('agent.passwordReminder')}</Typography>
            </Grid>
            <Grid item container gap={2}>
              <ControlledTextField control={control} name="email" label={t('agent.email')} />
            </Grid>
            <Grid item container justifyContent="space-between" alignItems="center" marginTop={2}>
              <Trans
                i18nKey="agent.signPage"
                components={{ a: <Link href={`${PATHS.agent.index}/${PATHS.agent.signIn}`} /> }}
              />
              <Button variant="contained" type="submit" disabled={isLoading}>
                {t('agent.resetPassword')}
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <PasswordUpdate title={t('agent.checkYourMail')} />
      )}
    </S.RemindPasswordWrapper>
  );
}

export default AgentRemindPassword;
