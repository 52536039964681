import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useOffer } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';

import { AxiosMutationOptions, AxiosQueryOptions, PaginatedResponse } from '../../types';
import { createOffer, getOffers, patchOffer, sendInvitation, updateOfferStage } from './offerApi';
import { Offer, UpdateOfferBody } from './types';

const OFFER_QUERY = (clientId: string) => ['offer', clientId];
export function useOfferQuery(clientId?: string, options?: AxiosQueryOptions<PaginatedResponse<Offer>>) {
  return useQuery(OFFER_QUERY(clientId ?? ''), getOffers, options);
}

export function useOfferRefetch() {
  const { offer } = useOffer();
  if (!offer) throw new Error('No offer provided');
  const queryClient = useQueryClient();

  return () => queryClient.refetchQueries({ queryKey: OFFER_QUERY(offer.client) });
}

export function useOfferMutate(
  offerId: string | undefined,
  options?: AxiosMutationOptions<Offer, Partial<UpdateOfferBody>>,
) {
  return useMutation((body: Partial<Omit<Offer, 'id'>>) => {
    if (offerId) return patchOffer(offerId, body);
    else return createOffer(body);
  }, options);
}

export function useOfferMutateStep(offerId: string | undefined, options?: AxiosMutationOptions<Offer, undefined>) {
  return useMutation(() => {
    if (!offerId) throw new Error('No offer provided');
    return updateOfferStage(offerId);
  }, options);
}

export function useOfferMutateInvitation(
  offerId: string | undefined,
  body?: Partial<UpdateOfferBody>,
  options?: AxiosMutationOptions<any, void>,
) {
  return useMutation(() => {
    if (!offerId || !body) throw new Error('No offer provided');
    return sendInvitation(offerId, body);
  }, options);
}
