import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing(2)};
  padding-block: ${theme.spacing(2)};
`;

export const TopActions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing(2)};
  padding-block: ${theme.spacing(2)};
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
