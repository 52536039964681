import { Button, Card, CircularProgress } from '@mui/material';
import theme from 'library/theme/theme';
import styled, { css } from 'styled-components';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 650px;
  max-height: 90%;
  position: absolute;
  inset: 0;
  margin: auto;
`;

export const Header = styled.div`
  padding: ${theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.palette.grey[200]};
`;

export const Content = styled.div`
  padding: ${theme.spacing(2)} ${theme.spacing(5)};
  margin-block: ${theme.spacing(3)};
`;

export const LoadingButton = styled(Button)<{ isLoading?: boolean }>`
  position: relative;

  ${({ isLoading }) => {
    if (isLoading)
      return css`
        color: transparent !important;
      `;
  }}
`;

export const LoadingButtonIndicator = styled(CircularProgress)`
  position: absolute;
  text-align: center;
  inset: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  margin: 0 auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing(2)};
  border-top: 1px solid ${theme.palette.grey[200]};
  padding: ${theme.spacing(2)};
`;
