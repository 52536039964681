import ClientAuthContextProvider from 'contexts/ClientAuthContext';
import { Outlet } from 'react-router';

import Header from './components/Header';

function ClientPages() {
  return (
    <ClientAuthContextProvider>
      <header>
        <Header />
      </header>
      <main>
        <Outlet />
      </main>
    </ClientAuthContextProvider>
  );
}
export default ClientPages;
