import { Alert } from '@mui/material';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import { useTranslation } from 'react-i18next';

import WizardLoadingView from 'components/WizardLoadingView';

import PaleScreen from '../../AgentRemoteSale/components/PaleScreen';
import { useOffer } from '../contexts/FormContext/hooks/useOffer';
import { WizardSteps } from '../types';
import ClientData from './ClientData';
import ClientNeeds from './ClientNeeds';
import ClientOffer from './ClientOffer';
import ClientSearchStep from './ClientSearchStep';
import InsuranceAdjustment from './IncusranceAdjustment';
import InsurancesSuggestions from './InsurancesSuggestions/InsurancesSuggestions';
import Payment from './Payment';
import Policy from './Policy';

function StepsContent() {
  const { actStep, stage } = useWizard();
  const { t } = useTranslation();
  const { loading } = useOffer();

  if (loading) return <WizardLoadingView height="200px" />;

  const commonProps = stage >= actStep + 1 && actStep !== 0 ? <PaleScreen /> : null;

  const renderComponent = (component: any) => (
    <>
      {commonProps}
      {component}
    </>
  );

  switch (actStep) {
    case WizardSteps.ClientSearch:
      return renderComponent(<ClientSearchStep />);
    case WizardSteps.ClientNeeds:
      return renderComponent(<ClientNeeds />);
    case WizardSteps.ClientData:
      return renderComponent(<ClientData />);
    case WizardSteps.Insurances:
      return renderComponent(<InsurancesSuggestions />);
    case WizardSteps.InsuranceAdjustment:
      return renderComponent(<InsuranceAdjustment />);
    case WizardSteps.InsuranceAcceptance:
      return renderComponent(<ClientOffer />);
    case WizardSteps.Policy:
      return renderComponent(<Policy />);
    case WizardSteps.Payment:
      return renderComponent(<Payment />);
    default:
      return <Alert severity="error">{t('errors.cantDisplay')}</Alert>;
  }
}

export default StepsContent;
