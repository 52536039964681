import Link from '@mui/material/Link';
import { useAgent } from 'contexts/AgentAuthContext/hooks';
import { ClientDataFormStateAgreements } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import React from 'react';
import { Trans } from 'react-i18next';

import { AgentData } from './types';

export const AGREEMENTS = ({
  firstName,
  lastName,
  email,
  infoFile,
}: AgentData): Array<{
  id: keyof ClientDataFormStateAgreements;
  required?: boolean;
  content: () => React.ReactNode;
}> => {
  const agent = useAgent();

  const file = infoFile ? infoFile : agent?.infoFile;

  return [
    {
      id: 'privacyPolicy',
      required: true,
      content: () => (
        <Trans
          i18nKey="insuranceWizard.clientData.agreements.privacyPolicy"
          components={{
            a:
              file === null || file === undefined ? (
                <Link href="#"></Link>
              ) : (
                <Link
                  href="#"
                  onClick={() => {
                    window.open(file, '_blank');
                  }}
                />
              ),
          }}
        />
      ),
    },
    {
      id: 'marketingInformationThroughElectronic',
      required: true,
      content: () => <Trans i18nKey="insuranceWizard.clientData.agreements.marketingInformationThroughElectronic" />,
    },
    {
      id: 'marketingInformationThroughOFWCA',
      required: true,
      content: () => (
        <Trans
          i18nKey="insuranceWizard.clientData.agreements.marketingInformationThroughOFWCA"
          values={{ agentData: firstName && lastName ? `${firstName} ${lastName}` : email }}
        />
      ),
    },
    {
      id: 'contractConclusion',
      required: true,
      content: () => <Trans i18nKey="insuranceWizard.clientData.agreements.contractConclusion" />,
    },
  ];
};
