import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, Grid } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { deleteInsurance } from 'api/agent/insurances/insurancesApi';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

interface Props {
  id: string;
  onConfirm: () => void;
  onCancel: () => void;
}

function DeleteConfirmation({ id, onCancel, onConfirm }: Props) {
  const { t } = useTranslation();
  const { mutate, isLoading } = useMutation(deleteInsurance);

  const handleDelete = () => {
    mutate(id, { onSuccess: () => onConfirm() });
  };

  return (
    <Box padding={2}>
      <S.InfoText>
        <WarningIcon color="warning" /> {t('common.areYouSureYouWantToRemove')}
      </S.InfoText>
      <Grid container justifyContent="flex-end" spacing={1} marginTop={1}>
        <Grid item>
          <Button variant="outlined" size="small" onClick={onCancel} disabled={isLoading}>
            {t('common.no')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" size="small" onClick={handleDelete} disabled={isLoading}>
            {t('common.yes')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DeleteConfirmation;
