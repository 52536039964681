import OfferBox from 'components/OfferBox';

import * as S from './styles';

function OfferDetails() {
  return (
    <S.HeaderContainer>
      <S.BorderedBox>
        <OfferBox offer="insurance" fontSize="large" />
      </S.BorderedBox>
      <S.BorderedBox>
        <OfferBox offer="newPolicy" fontSize="large" />
      </S.BorderedBox>
    </S.HeaderContainer>
  );
}

export default OfferDetails;
