import { Grid, Link, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { PATHS } from 'routes/paths';

import { PasswordUpdateProps } from './types';

function PasswordUpdate({ title }: PasswordUpdateProps) {
  return (
    <Grid item container justifyContent="center" direction="column" alignItems="center">
      <Typography variant="h5" style={{ textAlign: 'center' }} marginBottom={10}>
        {title}
      </Typography>
      <Trans
        i18nKey="agent.signPage"
        components={{ a: <Link href={`${PATHS.agent.index}/${PATHS.agent.signIn}`} /> }}
      />
    </Grid>
  );
}

export default PasswordUpdate;
