import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Collapse, Divider, Grid, Paper, Typography } from '@mui/material';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { getClientOffer } from 'api/client/offer/offerApi';
import config from 'config';
import { useClient } from 'contexts/ClientAuthContext/hooks';
import useBrand from 'library/hooks/useBrand';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'routes/paths';

import DownloadList from 'components/DownloadList';
import LabelValue from 'components/LabelValue';

import * as S from './styles';
import { InsuranceFormState } from './types';

interface Props {
  insurance: InsuranceFormState;
  children?: React.ReactNode;
  acceptedDate?: string | null;
  insurancesState?: any;
  setInsurancesState?: any;
  index?: any;
  stage?: number;
  messagesApk?: any;
  uploaded?: boolean;
}

function InsuranceBox({ index, insurance, children, acceptedDate, insurancesState, setInsurancesState, stage }: Props) {
  const insuranceListItem = useBrand(insurance.companyBrand);
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  const client = useClient();

  const page = pathname === `${PATHS.agent.index}/${PATHS.agent.remoteSale}`;

  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => setExpanded((prev) => !prev);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>, installments: any) => {
    const updatedInsurancesState = [...insurancesState];

    if (updatedInsurancesState.length > 0) {
      const lastInsurance = updatedInsurancesState[index];

      lastInsurance.cost = lastInsurance.cost.map((costItem: any) => {
        const updatedCostItem = {
          ...costItem,
          chosen: costItem.installments.toString() === installments.toString(),
        };
        return updatedCostItem;
      });
    }

    setInsurancesState(updatedInsurancesState);
  };

  const total = insurance.cost.find((item: any) => item.chosen);

  const handleDownloadClick = async () => {
    if (client?.offerId) {
      try {
        const offer = await getClientOffer();
        const response = await fetch(offer?.apk);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = 'apk.pdf';
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error('Błąd pobierania pliku:', error);
      }
    }
  };

  return (
    <Paper variant="outlined">
      {acceptedDate && (
        <Grid container justifyContent="space-between" padding={2}>
          <Grid item>
            <LabelValue label={t('insuranceWizard.clientOffer.acceptedDate')}>
              {DateTime.fromISO(acceptedDate).toFormat(config.DATE_TIME_FORMAT_LONG)}
            </LabelValue>
          </Grid>
        </Grid>
      )}
      <S.BoxContent>
        <img src={insuranceListItem?.img} alt="insurance brand" />
        <S.BoxInfo>
          <S.Name>{insurance.name}</S.Name>
          <div>
            <Typography textAlign="right" fontWeight="bold">
              {total?.cost} PLN
            </Typography>
          </div>
        </S.BoxInfo>
        <S.Actions>
          {children}
          <S.ExpandMore size="small" expand={expanded} onClick={handleExpand}>
            <ExpandMoreIcon />
          </S.ExpandMore>
        </S.Actions>
      </S.BoxContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <S.DescriptionContent>
          <Divider />
          <Grid container justifyContent="flex-start" spacing={1}>
            <Grid item xs={12}>
              <LabelValue label={t('insuranceWizard.insurances.idOrNumber')}>{insurance.identification}</LabelValue>
            </Grid>
            <Grid item xs={12}>
              <LabelValue label={t('common.name')}>{insurance.name}</LabelValue>
            </Grid>
            {insurance.description && (
              <Grid item xs={12}>
                <LabelValue label={t('common.description')} multiline>
                  {insurance.description}
                </LabelValue>
              </Grid>
            )}
            <S.ContributionWrapper>
              <RadioGroup name="insurance-type-group" onChange={handleSelect}>
                {insurance.cost.map((item: any, index: number) => (
                  <S.Contribution key={index}>
                    <LabelValue label={t('insuranceWizard.insurances.contribution')}>
                      <S.Text>{item.cost} PLN</S.Text>
                    </LabelValue>
                    <LabelValue label={t('insuranceWizard.propositionsOfInsurances.installment')}>
                      <S.Text>{item.installments}</S.Text>
                    </LabelValue>
                    <FormControlLabel
                      key={item.installments}
                      value={item.installments}
                      control={<Radio />}
                      checked={item.chosen}
                      disabled={stage === 6 || page}
                      label={null}
                    />
                  </S.Contribution>
                ))}
              </RadioGroup>
            </S.ContributionWrapper>
            {insurance.costNotes && (
              <Grid item xs={6}>
                <LabelValue label={t('insuranceWizard.propositionsOfInsurances.installment')}>
                  {insurance.costNotes}
                </LabelValue>
              </Grid>
            )}
            <Grid item xs={10}>
              <LabelValue label={t('insuranceWizard.insurances.propositionExpiration')}>
                {DateTime.fromISO(insurance.expirationDate).isValid
                  ? insurance.expirationDate
                  : DateTime.fromJSDate(insurance.expirationDate).toFormat(config.DATE_FORMAT_SHORT)}
              </LabelValue>
            </Grid>
            {insurance?.attachments && insurance.attachments.length > 0 && (
              <Grid item xs={12}>
                <LabelValue label={t('insuranceWizard.insurances.attachments')}>
                  <DownloadList files={insurance.attachments} spacing={0.5} />
                </LabelValue>
              </Grid>
            )}
          </Grid>
        </S.DescriptionContent>
      </Collapse>
      {stage === 6 ? (
        <Button variant="text" startIcon={<DownloadIcon fontSize="large" />} onClick={handleDownloadClick}>
          <S.DownloadAPKButtonContent>POBIERZ APK ANALIZE POTRZEB KLIENTA</S.DownloadAPKButtonContent>
        </Button>
      ) : null}
    </Paper>
  );
}
export default InsuranceBox;
