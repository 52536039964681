import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useMutation } from '@tanstack/react-query';
import { clientLogin } from 'api/auth/authApi';
import { useClientActions } from 'contexts/ClientAuthContext/hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ControlledTextField } from 'components/Form';

import { signInSchema } from './schema';
import * as S from './styles';
import { ClientSignInForm } from './types';

function ClientSignIn() {
  const { invitationId } = useParams<{ invitationId: string | undefined }>();

  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<ClientSignInForm>({
    resolver: yupResolver(signInSchema),
  });
  const { signIn } = useClientActions();
  const { mutate, isLoading } = useMutation(clientLogin);

  const onSubmit = (data: ClientSignInForm) => {
    if (!invitationId) return;
    mutate(
      { invitationPassword: data.password, invitationId },
      {
        onSuccess: (result) => {
          signIn(result);
        },
      },
    );
  };

  if (!invitationId) return <Alert severity="error">{t('errors.invalidLink')}</Alert>;

  return (
    <S.SignInWrapper>
      <Typography variant="h5">{t('client.accessVerification')}</Typography>
      <Typography variant="body2">{t('client.accessVerificationDescription')}</Typography>
      <Box marginTop={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} flexDirection="column">
            <Grid item>
              <ControlledTextField
                label={t('common.password')}
                control={control}
                name="password"
                defaultValue=""
                inputProps={{ type: 'password' }}
              />
            </Grid>
            <Grid item container justifyContent="space-between" alignItems="center">
              <Button variant="contained" type="submit" disabled={isLoading}>
                {t('common.signIn')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </S.SignInWrapper>
  );
}

export default ClientSignIn;
