export const INSTRUCTIONS = {
  REMOTELY_IN_SYSTEM: {
    descriptions: [
      '1. sprawdz swoją skrzynkę e-mail',
      '2. poszukaj wiadomości od ',
      '3. postępuj zgodnie z instrukcjami Towarzystwa Ubezpieczeń',
    ],
  },
  BY_PAYMENT: {
    descriptions: ['1. opłać wniosek / polisę na wskazany numer konta'],
  },
  BY_POST_OFFIE: {
    descriptions: [
      '1. wgraj poniżej podpisane skany dokumentów (polisy itp)',
      '2. wyślij papierowe, podpisane wersje na adres towarzystwa ubezpieczeń',
    ],
  },
  SIGN_WITH_AGENT: {
    descriptions: ['1. ustal z Agentem czas i miejsce spotkania', '2. podczas spotkania podpiszesz dokumenty'],
  },
};
