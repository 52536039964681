import AddIcon from '@mui/icons-material/Add';
import LanguageIcon from '@mui/icons-material/Language';
import { Checkbox } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { INSURANCES_LIST } from 'library/insurances';
import theme from 'library/theme/theme';
import moment from 'moment';
import numeral from 'numeral';
import Bar from 'pages/AgentTasksAndService/components/Bar';
import { useNavigate } from 'react-router-dom';
import { calculation } from 'utils/calculation';

import * as S from './styles';

function Process({ data, selectedCheckboxes, setSelectedCheckboxes }: any) {
  const nav = useNavigate();

  const showProces = (item: any) => {
    localStorage.setItem('selected_client_id', item.offer);
    if (item.source === 'rs') {
      nav('/agent/remote-sale');
    } else {
      localStorage.setItem('policy_id', item.id);
      nav('/agent/external-policy');
    }
  };

  const handleCheckboxChange = (id: any) => {
    const newSelectedCheckboxes = [...selectedCheckboxes];
    const isChecked = newSelectedCheckboxes.includes(id);
    if (isChecked) {
      newSelectedCheckboxes.splice(newSelectedCheckboxes.indexOf(id), 1);
    } else {
      newSelectedCheckboxes.push(id);
    }

    setSelectedCheckboxes(newSelectedCheckboxes);
  };

  const handleCheckboxChangeAll = () => {
    const allCheckboxIds = data.map((item: any) => item.id);
    const allSelected = allCheckboxIds.every((id: any) => selectedCheckboxes.includes(id));

    if (allSelected) {
      setSelectedCheckboxes([]);
    } else {
      setSelectedCheckboxes(allCheckboxIds);
    }
  };

  const source = (source: string) => {
    if (source === 'rs') {
      return <LanguageIcon />;
    } else {
      return <AddIcon />;
    }
  };

  const filerData = data.filter((item: any) => item.commissionPaid);

  return (
    <S.Main>
      <S.Box>
        <S.Price>
          kwoty łącznie<S.Name>{numeral(calculation(filerData)).format('0,0').replace(/,/g, ' ')}</S.Name>
        </S.Price>
      </S.Box>
      <S.Views>
        <S.Wrapper>
          <S.Text>
            liczba wyświetlonych elementów {filerData.length} z {filerData.length}
          </S.Text>
        </S.Wrapper>
      </S.Views>
      <S.Container>
        <S.Wrap flex="0">
          <Checkbox
            style={{ padding: 0, marginRight: '15px' }}
            checked={selectedCheckboxes.length === filerData.length}
            onChange={handleCheckboxChangeAll}
          />
        </S.Wrap>
        <S.Wrap flex="0px" color="grey">
          źródło
        </S.Wrap>
        <S.Wrap flex="10px" color="grey">
          start ochrony
        </S.Wrap>
        <S.Wrap flex="10px" color="grey" justify="flex-end">
          ochrona do
        </S.Wrap>
        <S.Wrap flex="200px" color="grey">
          Klient
        </S.Wrap>
        <S.Wrap flex="50px" justify="center" color="grey">
          dostawca
        </S.Wrap>
        <S.Wrap flex="200px" color="grey">
          polisa
        </S.Wrap>
        <S.Wrap flex="100px" justify="flex-end" color="grey">
          data rozliczenia
        </S.Wrap>
        <S.Wrap flex="50px" justify="flex-end" color="grey">
          składka
        </S.Wrap>
        <Bar stage={0} color={theme.palette.primary.main} />
      </S.Container>

      {filerData.map((item: any, index: number) => {
        const matchingInsurances = INSURANCES_LIST.find((element) => item.companyBrand === element.brand);

        return (
          <S.ContainerTwo key={index}>
            <S.CheckBox>
              <S.Wrap flex="0">
                <Checkbox
                  style={{ padding: 0, marginRight: '15px' }}
                  checked={selectedCheckboxes.includes(item.id)}
                  onChange={() => handleCheckboxChange(item.id)}
                />
              </S.Wrap>
            </S.CheckBox>
            <S.MainWrapper onClick={() => showProces(item)}>
              <S.Wrap flex="0px" color={theme.palette.primary.main}>
                {source(item.source)}
              </S.Wrap>
              <S.Wrap flex="10px">
                <S.Paragraph>{item.startProtection}</S.Paragraph>
              </S.Wrap>
              <S.Wrap flex="10px" justify="flex-end">
                <S.Paragraph>{item.endProtection === null ? 'bezterminowa' : item.endProtection}</S.Paragraph>
              </S.Wrap>
              <S.Wrap flex="200px" color={theme.palette.primary.main}>
                <Tooltip
                  title={
                    (item.clientSnapshot.clientUser.firstName ?? '').length +
                      (item.clientSnapshot.clientUser.lastName ?? '').length >
                    20
                      ? `${item.clientSnapshot.clientUser.firstName ?? ''} ${
                          item.clientSnapshot.clientUser.lastName ?? ''
                        }`
                      : undefined
                  }
                >
                  <S.Paragraph>
                    {(item.clientSnapshot.clientUser.firstName ?? '').length +
                      (item.clientSnapshot.clientUser.lastName ?? '').length >
                    20
                      ? `${(
                          (item.clientSnapshot.clientUser.firstName ?? '') +
                          ' ' +
                          (item.clientSnapshot.clientUser.lastName ?? '')
                        ).slice(0, 20)}...`
                      : `${item.clientSnapshot.clientUser.firstName ?? ''} ${
                          item.clientSnapshot.clientUser.lastName ?? ''
                        }`}
                  </S.Paragraph>
                </Tooltip>
              </S.Wrap>

              <S.Wrap flex="50px" justify="center" color={theme.palette.primary.main}>
                <S.Image src={matchingInsurances?.img} />
              </S.Wrap>

              <S.Wrap flex="200px" color={theme.palette.primary.main}>
                <Tooltip title={item.tuNumber.length > 20 ? item.tuNumber : undefined}>
                  <S.Paragraph>
                    {item.tuNumber.length > 20 ? `${item.tuNumber.slice(0, 20)}...` : item.tuNumber}
                  </S.Paragraph>
                </Tooltip>
              </S.Wrap>
              <S.Wrap flex="100px" justify="flex-end">
                {moment(item.acceptedDate).format('DD-MM-YYYY')}
              </S.Wrap>
              <S.Wrap flex="50px" justify="flex-end">
                <S.Paragraph>{numeral(item.cost).format('0,0').replace(/,/g, ' ')}</S.Paragraph>
              </S.Wrap>
            </S.MainWrapper>
            <Bar stage={0} color={theme.palette.primary.main} />
          </S.ContainerTwo>
        );
      })}
    </S.Main>
  );
}

export default Process;
