import { FormFile } from 'components/Attachments/types';

export const signMethods = [
  'REMOTELY_IN_SYSTEM',
  'SCAN_DOCUMENT',
  'BY_PAYMENT',
  'BY_POST_OFFIE',
  'SIGN_WITH_AGENT',
  'POLICY_ALREADY_ACCEPTED',
] as const;
export type SignMethod = (typeof signMethods)[number];

export interface PolicyForm {
  offer: any;
  id?: string;
  tuNumber: string;
  name: string;
  startProtection: Date;
  endProtection: Date | null;
  receivedDate: Date | null;
  endless: boolean;
  accountNumber: string;
  paymentDate: Date;
  signMethod: SignMethod;
  attachments: FormFile[];
  received: boolean;
  confirmed: boolean;
  confirmedDate: Date | null;
  policyPaid: boolean;
  commissionPaid: boolean;
}
