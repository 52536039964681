import { Step as MUIStep, StepLabel, stepLabelClasses } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 100px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
`;

export const LineOne = styled.div`
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
  border: 1px solid ${theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    top: 28px;
    width: 90%;
    left: 10%;
  }
`;

export const LineTwo = styled.div`
  display: none;
  @media (max-width: 800px) {
    position: absolute;
    left: 0;
    top: 78px;
    width: 90%;
    border: 1px solid ${theme.palette.primary.main};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Step = styled(MUIStep)`
  display: flex;
  justify-content: center;
  align-items: center;
  & .${stepLabelClasses.iconContainer} {
    padding: 0;
  }
  @media (max-width: 800px) {
    display: flex;
    width: 25%;
    margin-top: 10px;
  }
`;

export const WizardStepLabel = styled(StepLabel)`
  & .${stepLabelClasses.label}, & .${stepLabelClasses.active} {
    color: ${theme.palette.primary.main};
    font-weight: 600;
  }
`;

export const Title = styled.div`
  margin-top: ${theme.spacing(3)};
  text-align: center;
  min-height: 32px;
`;
