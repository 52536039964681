import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Main = styled.div`
  margin: 214px 70px 0 70px;
`;

export const Content = styled.div`
  max-width: 1100px;
  margin-inline: auto;
  margin-top: ${theme.spacing(3)};
  position: relative;
  padding-bottom: 30px;
`;
