import { authorizedAxios } from 'library/axios/apiProvider';

export async function updateApk(body: any, id: any): Promise<any> {
  const { data } = await authorizedAxios().put(`/api/v1/policies-client/${id}/`, body);
  return data;
}

export async function createAPK(body: any): Promise<any> {
  const { data } = await authorizedAxios().post(`/api/v1/offer-client/apk`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
}
