import Alert from '@mui/material/Alert';
import { useClientForm } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientForm';
import { useTranslation } from 'react-i18next';

import BaseClientNeedsBox from 'components/RemoteSale/BaseClientNeedsBox';

interface Props {
  defaultOpen?: boolean;
}

function ClientNeedsInfoBox({ defaultOpen }: Props) {
  const {
    state: { clientNeeds },
  } = useClientForm();
  const { t } = useTranslation();
  if (!clientNeeds) return <Alert severity="error">{t('errors.cantDisplay')}</Alert>;

  return <BaseClientNeedsBox clientNeeds={clientNeeds} defaultOpen={defaultOpen} readonly />;
}
export default ClientNeedsInfoBox;
