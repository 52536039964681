import config from 'config';
import { t } from 'i18next';

export const MAX_FILE_SIZE_IN_MB = config.MAX_FILE_SIZE / 1_000_000;

export const getErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case 'file-too-large':
      return `${t('errors.fileTooLarge')}, ${t('common.allowedFileSize', { fileSize: `${MAX_FILE_SIZE_IN_MB}MB` })}`;
    case 'file-invalid-type':
      return `${t('errors.invalidFormat')} ${t('common.allowedFileTypes', {
        types: Object.values(config.ALLOWED_FILE_TYPES)
          .flatMap((type) => type)
          .join(', '),
      })}`;
    default:
      return t('errors.unknownError');
  }
};
