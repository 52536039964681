import { useAgent } from 'contexts/AgentAuthContext/hooks';
import { Navigate, Outlet } from 'react-router';
import { PATHS } from 'routes/paths';

function AgentPublicPages() {
  const agent = useAgent();

  if (agent) return <Navigate to={PATHS.agent.remoteSale} />;

  return <Outlet />;
}

export default AgentPublicPages;
