import { authorizedAxios, unauthorizedAxios } from 'library/axios/apiProvider';

import {
  AgentAuthResponse,
  ClientAuthResponse,
  ClientLoginRequestBody,
  NewPasswordRequest,
  RefreshResponse,
  RegistrationRequestBody,
} from './types';
import { EmailRequestBody, LoginRequestBody } from './types';

export async function email(body: EmailRequestBody): Promise<EmailRequestBody> {
  const { data } = await unauthorizedAxios.post('/auth/password/reset/', body);
  return data;
}

export async function newPasswordChange(body: NewPasswordRequest): Promise<NewPasswordRequest> {
  const { data } = await unauthorizedAxios.post('/auth/password/reset/confirm/', body);
  return data;
}

export async function login(body: LoginRequestBody): Promise<AgentAuthResponse> {
  const { data } = await unauthorizedAxios.post('/auth/login/', body);
  return data;
}

export async function register(body: RegistrationRequestBody): Promise<AgentAuthResponse> {
  const { data } = await unauthorizedAxios.post('/auth/registration/', body);
  return data;
}

export async function refreshToken(refreshToken: string): Promise<RefreshResponse> {
  const { data } = await unauthorizedAxios.post(
    `/auth/token/refresh/`,
    { refresh: refreshToken },
    { withCredentials: true },
  );
  return data;
}

export async function clientLogin(body: ClientLoginRequestBody): Promise<ClientAuthResponse> {
  const { data } = await unauthorizedAxios.post('/auth/client-login', body);
  return data;
}

export async function signOut(): Promise<void> {
  await authorizedAxios().post('/auth/logout/');
}

export async function changePassword(body: LoginRequestBody): Promise<AgentAuthResponse> {
  const { data } = await unauthorizedAxios.post('/auth/password/change/', body);
  return data;
}
