import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CheckIcon from '@mui/icons-material/Check';
import RuleIcon from '@mui/icons-material/Rule';
import TuneIcon from '@mui/icons-material/Tune';

import { StepConfig } from 'components/WizardStepper/types';

import { WizardSteps } from './types';

export const STEPS: StepConfig<WizardSteps>[] = [
  {
    icon: <RuleIcon />,
    name: 'client_data',
    step: WizardSteps.ClientData,
    label: 'insuranceWizard.dataAgreements',
    title: 'insuranceWizard.clientData.yourDataAndAgreements',
  },
  {
    icon: <TuneIcon />,
    name: 'offer_adjustment',
    step: WizardSteps.Insurances,
    label: 'insuranceWizard.offerAdjustment',
    title: 'insuranceWizard.propositionsOfInsurances.reviewAndAdjustment',
  },
  {
    icon: <CheckIcon />,
    name: 'offer',
    label: 'insuranceWizard.offerSummary',
    step: WizardSteps.Offer,
    title: 'insuranceWizard.offer.offerSummary',
  },
  {
    icon: <AccountBalanceWalletIcon />,
    name: 'payment',
    step: WizardSteps.Payment,
    label: 'insuranceWizard.payment',
    title: 'insuranceWizard.paymentStep.policyPayment',
  },
];
