/* eslint-disable @typescript-eslint/no-empty-function */
import { useClientOfferQuery } from 'api/client/offer/offerQuery';
import { ClientOffer } from 'api/client/offer/types';
import { usePolicyQuery } from 'api/client/policy/policyQuery';
import { useClient } from 'contexts/ClientAuthContext/hooks';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import { clientAsClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/utils';
import { policyAsPolicyForm } from 'pages/AgentRemoteSale/steps/Policy/components/PolicyForm/utils';
import { createContext, useCallback, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';

import { FormState } from './types';
import { offerAsFormState } from './utils';

interface FormContextProps {
  state: FormState;
  onUpdate: (newState: Partial<FormState>) => void;
  onReset: (newState: Partial<FormState>) => void;
  apk: any;
  setApk: any;
}

export const FormContext = createContext<FormContextProps>({
  state: {},
  onUpdate: () => {},
  onReset: () => {},
  apk: null,
  setApk: () => {},
});

interface OfferContextProps {
  loading: boolean;
  offer: ClientOffer | undefined;
  onUpdate: (offerState: ClientOffer) => void;
}

export const OfferContext = createContext<OfferContextProps>({
  offer: undefined,
  loading: false,
  onUpdate: () => {},
});

interface Props {
  children: React.ReactNode;
}

function FormContextProvider({ children }: Props) {
  const client = useClient();
  const wizard = useWizard();
  const [currentOffer, setCurrentOffer] = useState<ClientOffer | undefined>();
  const [state, setState] = useState<FormState>({
    clientData: client ? clientAsClientDataFormState(client) : undefined,
    offer: client?.offerId,
    agent: client?.agent,
  });
  const [apk, setApk] = useState<any>(null);

  const { isFetching: isOfferLoading } = useClientOfferQuery({
    onSuccess: (offerData) => {
      flushSync(() => {
        if (offerData.stage) wizard.goTo(offerData.stage, 0);
        setCurrentOffer(offerData);
        setState((prev) => ({
          ...prev,
          ...offerAsFormState(offerData, prev),
        }));
      });
    },
  });

  const { isFetching: isPolicyLoading } = usePolicyQuery(currentOffer?.policy || '', {
    enabled: !!currentOffer?.policy,
    onSuccess(data) {
      handleUpdate({ policy: policyAsPolicyForm(data) });
    },
  });

  const handleUpdate = useCallback((newState: Partial<FormState>) => {
    setState((prev) => ({ ...prev, ...newState }));
  }, []);

  const handleReset = useCallback((newState: Partial<FormState>) => {
    setState(newState);
  }, []);

  return (
    <FormContext.Provider
      value={useMemo(
        () => ({ state, onUpdate: handleUpdate, onReset: handleReset, apk, setApk }),
        [handleReset, handleUpdate, state, apk, setApk],
      )}
    >
      <OfferContext.Provider
        value={useMemo(
          () => ({
            offer: currentOffer,
            loading: isOfferLoading || isPolicyLoading,
            onUpdate: setCurrentOffer,
          }),
          [currentOffer, isOfferLoading, isPolicyLoading],
        )}
      >
        {children}
      </OfferContext.Provider>
    </FormContext.Provider>
  );
}
export default FormContextProvider;
