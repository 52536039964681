import styled from 'styled-components';

interface BoxProps {
  marginLeft?: string;
  marginRight?: string;
}

export const Main = styled.div`
  display: flex;
  justify-content: center;
  width: 320px;
  min-height: 100vh;
  background-color: rgb(238, 238, 238);
  right: 0;
  padding: 20px 0;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;

export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
`;

export const MultiBox = styled.div`
  display: flex;
`;

export const Label = styled.label`
  margin-bottom: 5px;
  font-size: 14px;
`;

export const Input = styled.input`
  padding: 8.5px;
  border-radius: 3.5px;
  border: 1px solid #e0e0e0;
  width: 100%;
`;

export const Submit = styled.button`
  border: none;
  height: 50px;
  width: 100%;
  padding: 8.5px;
  margin-bottom: 20px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ac242c;
  box-shadow: 2px 2px 5px 2px black;
`;
