import theme from 'library/theme/theme';
import styled from 'styled-components';

interface BarProps {
  width: string;
  color?: string;
}

export const Main = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid ${theme.palette.grey[300]};
  background-color: ${theme.palette.grey[300]};
`;

export const Bar = styled.span<BarProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: ${(props) => props.width};
  border-bottom: 1px solid ${(props) => props.color};
  background-color: ${(props) => props.color};
`;
