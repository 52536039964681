import { useContext } from 'react';

import { ClientAuthContext } from './ClientAuthContext';

export function useClient() {
  return useContext(ClientAuthContext).client;
}

export function useClientActions() {
  const { signIn, signOut } = useContext(ClientAuthContext);
  return { signIn, signOut };
}
