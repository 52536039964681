import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing(2)};
  flex-direction: column;
  text-align: center;

  > * {
    color: ${theme.palette.grey[400]};
  }
`;
