import { Link } from '@mui/material';
import { Trans } from 'react-i18next';

import { OfferData } from '../../types';

export const ADDITIONAL_AGREEMENTS: Array<{
  id: keyof OfferData['agreements'];
  required: boolean;
  content: () => React.ReactNode;
}> = [
  {
    id: 'apk',
    required: true,
    content: () => (
      <Trans components={{ a: <Link href="" /> }} i18nKey={'insuranceWizard.offer.agreements.apkCondition'} />
    ),
  },
  {
    id: 'distribution',
    required: true,
    content: () => <Trans i18nKey={'insuranceWizard.offer.agreements.distribution'} />,
  },
  {
    id: 'informed',
    required: true,
    content: () => <Trans i18nKey={'insuranceWizard.offer.agreements.informed'} />,
  },
];
