import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { patchAgentFileInfo } from 'api/agent/agentApi';
import theme from 'library/theme/theme';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as S from './styles';

function FileInfoAdministrator(agent: any) {
  const [infoFile, setInfoFile] = useState<string | null>(null);

  const mutationLogo = useMutation((dataToSave) => patchAgentFileInfo(dataToSave), {
    onSuccess: (data) => {
      setInfoFile(data.infoFile);
    },
    onError: () => {
      toast.error('Coś poszło nie tak');
    },
  });

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const formData = new FormData();

    formData.append('info_file', file);
    formData.append('id', agent.agent.agentData.id);

    await mutationLogo.mutateAsync(formData as any);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'image/webp': [],
      'application/pdf': [],
    },
  });

  useEffect(() => {
    setInfoFile(agent?.agent?.agentData?.infoFile);
  }, [agent]);

  return (
    <div>
      <S.Attachments>
        <Typography variant="body1" fontWeight="bolder" fontSize={16}>
          Informacja o administratorze danych (zajrzyj do działu{' '}
          <S.SpanText>
            <Link style={{ color: theme.palette.primary.main, textDecoration: 'none' }} to={'/agent/support'}>
              WSPARCIE
            </Link>
          </S.SpanText>
          , jeśli szukasz wzoru) [max 1 plik]
        </Typography>
        <S.DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <CloudUploadOutlinedIcon color="primary" />
          <S.DropzoneText>Upuść pliki tutaj, lub kliknij, aby wybrać z dysku (max 10MB)</S.DropzoneText>
        </S.DropzoneContainer>
      </S.Attachments>
      {infoFile !== null && infoFile !== undefined ? (
        <div style={{ marginTop: '15px' }}>
          <Link
            style={{ fontSize: '16px', color: theme.palette.primary.main }}
            to="#"
            onClick={() => {
              window.open(infoFile, '_blank');
            }}
          >
            {infoFile.substring(infoFile.lastIndexOf('/') + 1)}
          </Link>
        </div>
      ) : null}
    </div>
  );
}

export default FileInfoAdministrator;
