import theme from 'library/theme/theme';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  gap: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(2)};
`;

export const BorderedBox = styled.div`
  flex: 1;
  border: 1px solid ${theme.palette.primary.main};
  color: ${theme.palette.primary.main};
  padding: ${theme.spacing(2)};
  background: #e5f6fd;
`;
