import { Message } from 'api/messages/types';
import { PaginatedResponse } from 'api/types';
import { DateTime } from 'luxon';

import { MessageState } from 'components/Chat/types';

export function paginatedMessagesToState(
  messages: PaginatedResponse<Message>,
  agentAuthorKey: string,
  clientAuthorKey: string,
) {
  return messages.results
    .map<MessageState>((message) => messageToMessageStateForAgent(message, agentAuthorKey, clientAuthorKey))
    .reverse();
}

function messageToMessageStateForAgent(
  message: Message,
  agentAuthorKey: string,
  clientAuthorKey: string,
): MessageState {
  return {
    id: message.id,
    message: message.text,
    sentAt: DateTime.fromISO(message.created).toJSDate(),
    author: message.agent ? agentAuthorKey : clientAuthorKey,
    variant: message.agent ? 'me' : 'other',
    state: 'sent',
  };
}
