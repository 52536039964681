import Policy from './components/Policy/Policy';
import * as S from './styles';

function AgentAddPolicy() {
  return (
    <S.Main>
      <S.Content>
        <Policy />
      </S.Content>
    </S.Main>
  );
}

export default AgentAddPolicy;
