import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from 'library/theme/theme';

import * as S from './styles';

const CancellationOfProcesses = ({
  isCancellationDialogOpen,
  handleCancelCancellation,
  handleConfirmCancellation,
}: any) => {
  return (
    <Dialog
      open={isCancellationDialogOpen}
      onClose={handleCancelCancellation}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Czy na pewno chcesz anulować oferty?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Tej operacji nie można cofnąć. Czy na pewno chcesz kontynuować?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <S.ButtonWrapper>
          <S.Button color={theme.palette.primary.main} onClick={handleCancelCancellation}>
            Nie
          </S.Button>
          <S.Button color="firebrick" onClick={handleConfirmCancellation} autoFocus>
            Tak
          </S.Button>
        </S.ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default CancellationOfProcesses;
