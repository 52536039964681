import { Typography } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const AcceptanceType = styled(Typography).attrs((props) => ({
  fontWeight: 'bold',
  ...props,
}))``;

export const AcceptanceDescription = styled(Typography).attrs((props) => ({
  ...props,
}))`
  color: ${theme.palette.grey[500]};
`;
