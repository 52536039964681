import { passwordValidation } from 'library/yup/common';
import * as yup from 'yup';

export const schema = yup.object().shape({
  old_password: yup.string().required('Podaj dotychczasowe hasło'),
  new_password1: passwordValidation.required().matches(/[\W_]/, 'errors.passwordSpecialChar'),
  new_password2: yup
    .string()
    .required()
    .oneOf([yup.ref('new_password1')], 'Nowe hasła muszą być identyczne'),
});
