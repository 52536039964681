import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { insuranceSortOrder } from 'library/hooks/useSortedInsurances';
import { useTranslation } from 'react-i18next';

function InsuranceSortSelect(props: any) {
  const { t } = useTranslation();

  const label = props.label ? props.label : t('common.insuranceSortOrder.sort');

  return (
    <FormControl size="small">
      <InputLabel id="insurances-sort-order">{label}</InputLabel>
      <Select labelId="insurances-sort-order" defaultValue={'added'} input={<OutlinedInput label={label} />} {...props}>
        {insuranceSortOrder.map((sortOrder) => (
          <MenuItem key={sortOrder} value={sortOrder}>
            {t(`common.insuranceSortOrder.${sortOrder}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default InsuranceSortSelect;
