import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

interface Props {
  open: boolean;
  onReject: () => void;
  onAccept: () => void;
  children: React.ReactNode;
  title?: string;
  acceptText?: string;
  rejectText?: string;
  isLoading?: boolean;
}

function ConfirmationModal({ open, onReject, onAccept, title, children, acceptText, rejectText, isLoading }: Props) {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onReject}>
      <S.Container>
        <S.Header>
          <Typography variant="h6">{title || t('common.warning')}</Typography>
          <IconButton onClick={onReject}>
            <CloseIcon />
          </IconButton>
        </S.Header>
        <S.Content>{children}</S.Content>
        <S.Footer>
          <Button variant="outlined" onClick={onReject}>
            {rejectText || t('common.no')}
          </Button>
          <S.LoadingButton variant="contained" onClick={onAccept} disabled={isLoading} isLoading={isLoading}>
            {isLoading && <S.LoadingButtonIndicator />}
            {acceptText || t('common.yes')}
          </S.LoadingButton>
        </S.Footer>
      </S.Container>
    </Modal>
  );
}

export default ConfirmationModal;
