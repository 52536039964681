import { TextField } from '@mui/material';
import config from 'config';
import { ErrorMessage, errorMessage } from 'library/language/validation/errorMessage';
import { ChangeEvent } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

interface Props {
  onChange: (value: string) => void;
  value: string;
  fieldState: ControllerFieldState;
}

// warning findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of InputMaskChildrenWrapper which is inside StrictMode. Instead, add a ref directly to the element you want to reference.
// https://github.com/sanniassin/react-input-mask/issues/239
// not fixed from 2020
function BankAccountNumberInput({ onChange, value, fieldState }: Props) {
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    onChange(value.replaceAll('_', '').replaceAll(' ', ''));
  };

  return (
    <InputMask mask={config.BANK_ACCOUNT_NUMBER_MASK} value={value} onChange={handleChange}>
      <TextField
        fullWidth
        InputLabelProps={{ required: false }}
        label={t('insuranceWizard.policy.accountNumber')}
        error={!!fieldState?.error}
        helperText={errorMessage(t, fieldState?.error?.message as ErrorMessage)}
      />
    </InputMask>
  );
}

export default BankAccountNumberInput;
