import { useQuery } from '@tanstack/react-query';
import { AxiosQueryOptions } from 'api/types';
import { Policy } from 'api/types/policy';

import { getPolicy } from './policyApi';

const POLICY_QUERY = (id: string) => ['policy', id];

export function usePolicyQuery(id: string, options?: AxiosQueryOptions<Policy>) {
  return useQuery(POLICY_QUERY(id), () => getPolicy(id), options);
}
