import LoadingView from 'components/LoadingView';

import * as S from './styles';

function WizardLoadingView({ height }: { height: string }) {
  return (
    <S.AbsoluteContainer height={height}>
      <LoadingView />
    </S.AbsoluteContainer>
  );
}

export default WizardLoadingView;
