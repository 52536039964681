import { Alert } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const ListAlert = styled(Alert)`
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0;
  margin-top: ${theme.spacing(1)};
`;
