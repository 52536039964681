import PaymentsIcon from '@mui/icons-material/Payments';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { patchPolicies } from 'api/agent/policy/policyApi';
import theme from 'library/theme/theme';
import { useState } from 'react';

import * as S from './styles';

function MarkPaid({ data, setData, selectedCheckboxes }: any) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const customStyle = { fontSize: '25px' };

  const mutation = useMutation(() => patchPolicies({ policyPaid: true }, selectedCheckboxes), {
    onSuccess: () => {
      const updatedFilterData = [...data];

      updatedFilterData.forEach((item: any) => {
        if (selectedCheckboxes.includes(item.id)) {
          const index = item.policyStatus.findIndex((status: any) => status.key === 'policyPaid');
          if (index !== -1) {
            item.policyStatus[index].value = true;
          }
        }
      });

      setData(updatedFilterData);
      setIsOpenModal(false);
    },
  });

  const handleFormSubmit = async () => {
    setIsOpenModal(true);
  };

  const handleCancelRemove = () => {
    setIsOpenModal(false);
  };

  const handleConfirmRemove = () => {
    mutation.mutate();
  };

  return (
    <S.Wrapper>
      <Dialog
        open={isOpenModal}
        onClose={handleCancelRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Czy na pewno chcesz zmienić statusy na <S.SpanColor>opłacona</S.SpanColor> ?
        </DialogTitle>
        <DialogActions>
          <S.ButtonWrapper>
            <S.ConfirmationButton color={theme.palette.primary.main} onClick={handleCancelRemove}>
              Nie
            </S.ConfirmationButton>
            <S.ConfirmationButton color="firebrick" onClick={handleConfirmRemove} autoFocus>
              Tak
            </S.ConfirmationButton>
          </S.ButtonWrapper>
        </DialogActions>
      </Dialog>
      <S.Button color="#038B00" onClick={handleFormSubmit} disabled={selectedCheckboxes.length === 0}>
        <PaymentsIcon style={customStyle} />
        <S.Text>
          oznacz <S.Span>opłacona</S.Span>
        </S.Text>
      </S.Button>
    </S.Wrapper>
  );
}

export default MarkPaid;
