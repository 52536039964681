import { Grid } from '@mui/material';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledTextField, FormGroup } from 'components/Form';

import * as S from './styles';

function Recommender() {
  const { t } = useTranslation('translation', { keyPrefix: 'insuranceWizard.clientData' });
  const { control } = useFormContext<ClientDataFormState>();

  return (
    <FormGroup title={t('recommender')}>
      <S.Container>
        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            name="referralEmail"
            defaultValue=""
            label={t('email')}
            inputProps={{
              type: 'email',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            name="referralPhone"
            defaultValue=""
            label={t('phoneNumber')}
            inputProps={{
              type: 'tel',
            }}
          />
        </Grid>
      </S.Container>
    </FormGroup>
  );
}

export default Recommender;
