import { Typography } from '@mui/material';

interface Props {
  title: string;
  children: React.ReactNode;
}

function FormGroup({ title, children }: Props) {
  return (
    <>
      <Typography variant="body2" fontWeight="bold" marginBottom={2} textAlign="left">
        {title}
      </Typography>
      {children}
    </>
  );
}

export default FormGroup;
