import { Insurance } from 'api/agent/insurances/types';
import { DateTime } from 'luxon';
import { InsuranceFormState } from 'pages/AgentRemoteSale/components/InsuranceBox/types';
import { fileResultAsFormFile } from 'utils/formFile';

export function insuranceAsInsuranceForm(insurance: Insurance): InsuranceFormState {
  return {
    id: insurance.id,
    identification: insurance.identification,
    name: insurance.name,
    companyBrand: insurance.companyBrand,
    description: insurance.description,
    cost: insurance.cost,
    expirationDate: new Date(insurance.expirationDate),
    chosen: insurance.chosen,
    attachments: insurance.files?.map(fileResultAsFormFile),
  };
}

export function insuranceFormAsInsurance(formState: InsuranceFormState, offerId: string): Insurance {
  return {
    id: formState.id,
    offer: offerId,
    identification: formState.identification,
    name: formState.name,
    companyBrand: formState.companyBrand,
    cost: formState.cost,
    description: formState.description,
    expirationDate: DateTime.fromJSDate(formState.expirationDate).toISODate(),
  };
}
