import { useMutation } from '@tanstack/react-query';
import { deleteOfferFile, uploadFile } from 'api/files/filesApi';
import { FileBody } from 'api/files/types';

import { FormFile } from 'components/Attachments/types';

import { useOffer } from './useOffer';

type FileContext = Pick<FileBody, 'insuranceId' | 'offer' | 'policy'>;

export function useUploadOfferFile(fileContext?: FileContext) {
  const { offer } = useOffer();
  return useMutation(async (file: File) => {
    if (!offer?.id) throw new Error('No offer provided');
    const context = fileContext || { offer: offer.id };

    return uploadFile({ file: file, ...context });
  });
}

export function useDeleteOfferFiles() {
  return useMutation(async (files: FormFile[]) => {
    return Promise.all(files.map((f) => deleteOfferFile(f.id)));
  });
}
