import { DateTime } from 'luxon';
import * as yup from 'yup';

export const schema = yup.object().shape({
  identification: yup.string().required().trim().max(50),
  name: yup.string().required().trim().max(50),
  description: yup.string().required().max(2000),
  companyBrand: yup.string().required(),
  expirationDate: yup
    .date()
    .min(DateTime.now().startOf('day'))
    .typeError({ key: 'errors.dateMustBeInFormat', options: { format: 'DD.MM.YYYY' } })
    .required(),
});
