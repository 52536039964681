const LOCAL_STORAGE_KEY = 'refresh_token';

export function getStoredRefreshToken() {
  return localStorage.getItem(LOCAL_STORAGE_KEY) || '';
}

export function setStoredRefreshToken(refreshToken: string) {
  return localStorage.setItem(LOCAL_STORAGE_KEY, refreshToken);
}

export function deleteStoredRefreshToken() {
  return localStorage.removeItem(LOCAL_STORAGE_KEY);
}
