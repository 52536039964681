import { Paper, Typography } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Container = styled(Paper)`
  text-align: left;
  img {
    width: 100%;
    max-width: 80px;
  }
`;

export const AttachmentsText = styled(Typography)`
  display: block;
  text-align: right;
  color: ${theme.palette.grey[400]};
`;

export const Footer = styled.div`
  display: flex;
  gap: ${theme.spacing(2)};
  justify-content: space-between;
  margin-top: ${theme.spacing(2)};
  padding: ${theme.spacing(2)};
  background-color: ${theme.palette.grey[200]};
  border-radius: 0 0 ${theme.shape.borderRadius} ${theme.shape.borderRadius};
`;
