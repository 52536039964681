import theme from 'library/theme/theme';
import styled from 'styled-components';

interface Props {
  height: string;
}

export const AbsoluteContainer = styled.div<Props>`
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  height: ${(props) => props.height};
  background-color: ${theme.palette.common.white};
`;
