/* eslint-disable @typescript-eslint/no-unused-vars */
import { errorMessage } from 'library/language/validation/errorMessage';
import { ArrayPath, FieldValues, UseControllerProps, useController, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Attachments from 'components/Attachments';
import { FormFile } from 'components/Attachments/types';

import { fileAsFormFile } from './utils';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends ArrayPath<FormFile[]> = ArrayPath<FormFile[]>,
> extends UseControllerProps<TFieldValues, any> {
  files: FormFile[];
  onAttach?: (files: File[]) => Promise<FormFile[] | undefined>;
  onRemove?: (file: FormFile) => void;
}

function ControlledAttachments<
  TFieldValues extends FieldValues = FieldValues,
  TName extends ArrayPath<FormFile[]> = ArrayPath<FormFile[]>,
>({ control, name, files, onAttach, onRemove }: Props<TFieldValues, TName>) {
  const { t } = useTranslation();
  const { append, remove } = useFieldArray({ control, name });
  const {
    fieldState: { error },
  } = useController({ name, control });

  const handleDeleteFile = (fileName: string) => {
    const fileIndex = files.findIndex((file) => file.name === fileName);
    if (onRemove) onRemove(files[fileIndex]);
    remove(fileIndex);
  };

  const handleAddFile = async (file: File | File[]) => {
    if (onAttach) {
      const results = Array.isArray(file) ? await onAttach(file) : await onAttach([file]);
      if (results) append(results as never);
    } else {
      Array.isArray(file) ? append(file.map(fileAsFormFile) as never) : append(fileAsFormFile(file) as never);
    }
  };

  return (
    <Attachments
      files={files || []}
      onFileAdd={handleAddFile}
      onFileRemove={handleDeleteFile}
      error={!!error}
      errorText={errorMessage(t, error?.message)}
    />
  );
}

export default ControlledAttachments;
