import { useCallback, useState } from 'react';

import { FormFile } from 'components/Attachments/types';

interface Options {
  /** defines if added files not uploaded so hook has to keep track on them being deleted */
  tempStored?: boolean;
}

export function useFormFiles({ tempStored }: Options = {}) {
  const [filesToDelete, setFilesToDelete] = useState<FormFile[]>([]);
  const [addedFiles, setAddedFiles] = useState<FormFile[]>([]);

  const handleDeleteFile = useCallback(
    (file: FormFile) => {
      const addedFile = addedFiles.find((f) => f.id === file.id);
      if (addedFile) {
        setAddedFiles((prev) => prev.filter((f) => f.id !== file.id));
        // if the file is not uploaded then there is no need to store if it has to be be deleted afterwards
        if (tempStored) return;
      }

      setFilesToDelete((prev) => [...prev, file]);
    },
    [addedFiles, tempStored],
  );

  const handleAddFile = useCallback((file: FormFile) => {
    setAddedFiles((prev) => [...prev, file]);
  }, []);

  const handleClear = useCallback(() => {
    setAddedFiles([]);
    setFilesToDelete([]);
  }, []);

  return {
    addedFiles,
    onAddFile: handleAddFile,
    onDeleteFile: handleDeleteFile,
    filesToDelete,
    onClear: handleClear,
  };
}
