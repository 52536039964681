import { authorizedAxios } from 'library/axios/apiProvider';

import { Insurance, UpdateInsuranceBody } from './types';

export async function createInsurance(insurance: UpdateInsuranceBody): Promise<Insurance> {
  const { data } = await authorizedAxios().post('/api/v1/insurances/', insurance);
  return data;
}

export async function patchInsurance(id: string, insurance: Partial<UpdateInsuranceBody>): Promise<Insurance> {
  const { data } = await authorizedAxios().patch(`/api/v1/insurances/${id}/`, insurance);
  return data;
}

export async function deleteInsurance(id: string) {
  const { data } = await authorizedAxios().delete(`/api/v1/insurances/${id}/`);
  return data;
}
