import { Typography } from '@mui/material';

import * as S from './styles';

function FirstLoginInformation() {
  return (
    <S.Main>
      <Typography fontWeight="bold" variant="h6" style={{ marginBottom: '10px', fontSize: '16px' }}>
        Twój email został potwierdzony !
      </Typography>
      <Typography variant="body1" style={{ fontSize: '16px' }}>
        Teraz możesz się zalogować - skorzystaj z formularza poniżej :-)
      </Typography>
    </S.Main>
  );
}

export default FirstLoginInformation;
