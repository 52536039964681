import axios from 'axios';

import { withClientToken } from './decorators/withClientToken';
import { withCredentials } from './decorators/withCredentials';
import { withRefreshToken } from './decorators/withRefreshToken';
import { InstanceService } from './instanceService';

export const unauthorizedAxios = new InstanceService(axios.create({})).axios;
let authorizedService: InstanceService | null = null;

/**
 * method for initializing axios designed for agent which includes refresh token logic
 */
export const createAuthorizedAxiosForAgent = (deAuthorize: () => void, refreshToken: string) => {
  const inst = new InstanceService(axios.create());

  withCredentials(inst.axios);
  withRefreshToken(deAuthorize, refreshToken, inst.axios);

  authorizedService = inst;
};
export const destroyAuthorizedAxiosForAgent = () => {
  authorizedService = null;
};

/**
 * method for initializing axios designed for client
 */
export const createAuthorizedAxiosForClient = (clientToken: string) => {
  const inst = new InstanceService(axios.create());

  withCredentials(inst.axios);
  withClientToken(clientToken, inst.axios);

  authorizedService = inst;
};
export const destroyAuthorizedAxiosForClient = () => {
  authorizedService = null;
};

export const authorizedAxios = () => {
  if (!authorizedService) throw new Error('Axios not initialized');

  return authorizedService.axios;
};
