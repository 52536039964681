import theme from 'library/theme/theme';
import styled from 'styled-components';

export const PricingSummary = styled.div`
  width: 45%;
  min-width: 200px;
  border-top: 1px solid ${theme.palette.grey[900]};
  padding-top: ${theme.spacing(1)};
  margin-left: auto;
`;

export const DownloadAPKButtonContent = styled.span`
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
