import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { useTranslation } from 'react-i18next';

import Heading from '../../components/Heading';
import WizardStepIcon from '../WizardStepIcon';
import * as S from './styles';
import { StepConfig } from './types';

interface Props {
  steps: StepConfig[];
}

function WizardStepper({ steps }: Props) {
  const { actStep, goTo, stage } = useWizard();
  const { t } = useTranslation();
  const { emailClient } = useAgentForm();

  const currentStep = steps.find((s) => s.step === actStep);

  return (
    <S.Container>
      {emailClient ? (
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: '-65px',
            zIndex: 1,
            fontSize: '16px',
            background: '#EDEDED',
            borderRadius: '5px',
          }}
        >
          <p style={{ padding: '10px', margin: 0, fontWeight: 'bold' }}>{emailClient}</p>
        </div>
      ) : null}
      <S.Wrapper>
        <S.LineOne />
        <S.LineTwo />
        {steps.map((step, index: any) => (
          <S.Step active={step.step === currentStep?.step} completed={step.step < actStep} key={step.step}>
            <S.WizardStepLabel
              StepIconComponent={(props) => (
                <WizardStepIcon
                  {...props}
                  icon={step.icon}
                  label={step.label}
                  index={index}
                  goTo={() => goTo(index, stage)}
                  stage={stage}
                  actStep={actStep}
                />
              )}
            />
          </S.Step>
        ))}
      </S.Wrapper>

      <S.Title>
        <Heading>{t(currentStep?.title as never)}</Heading>
      </S.Title>
    </S.Container>
  );
}

export default WizardStepper;
