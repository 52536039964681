import theme from 'library/theme/theme';
import styled from 'styled-components';

interface ButtonProps {
  disabled?: boolean;
}

export const Button = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  color: ${theme.palette.primary.main};
  cursor: pointer;
  margin-left: 10px;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')}};
`;

export const Input = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: pointer;
  padding: 0 10px;
`;

export const Number = styled.div`
  position: absolute;
  right: 10px;
  padding-left: 7px;
  top: 30px;
`;
