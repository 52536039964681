import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress, Grid, Typography } from '@mui/material';
import config from 'config';
import { DateTime } from 'luxon';
import { memo } from 'react';

import { MessageState } from 'components/Chat/types';

import * as S from './styles';

interface Props {
  message: MessageState;
}

function SpeechBubble({ message }: Props) {
  return (
    <S.SpeechBubble key={message.id} variant={message.variant}>
      <div className="status-icon">
        {message.state === 'sending' && <CircularProgress size={14} />}
        {message.state === 'error' && <ErrorIcon fontSize="small" color="error" />}
      </div>
      <div className="sent-time">{DateTime.fromJSDate(message.sentAt).toFormat(config.DATE_TIME_FORMAT_SHORT)}</div>
      <Grid container flexDirection="column">
        <Grid>
          <Typography variant="caption">{message.author}</Typography>
        </Grid>
        {message.message}
      </Grid>
    </S.SpeechBubble>
  );
}
export default memo(SpeechBubble);
