import config from 'config';
import { nanoid } from 'nanoid';
import { useOfferMessages, useUpdateMessages } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useMessages';
import { useOffer } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Chat from 'components/Chat/Chat';
import EmptyChat from 'components/Chat/components/EmptyChat';
import { useMessages } from 'components/Chat/hooks/useMessages';
import { MessageState } from 'components/Chat/types';
import LoadingView from 'components/LoadingView';

import * as S from './styles';
import { paginatedMessagesToState } from './utils';

export default function AgentChat() {
  const { t } = useTranslation();
  const { offer } = useOffer();
  const messageMutation = useUpdateMessages();

  const agentMessageAuthor = `${t('common.agentChat')} (${t('common.me')})`;
  const clientMessageAuthor = t('common.clientChat');

  const { isLoading, data } = useOfferMessages({
    refetchInterval: config.MESSAGE_REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
    onSuccess: (data) => {
      const messages = paginatedMessagesToState(data, agentMessageAuthor, clientMessageAuthor);
      setMessages(messages);
    },
  });

  const [initialMessagesState] = useState(
    data ? paginatedMessagesToState(data, agentMessageAuthor, clientMessageAuthor) : [],
  );
  const { messages, onAdd, onUpdate, setMessages } = useMessages(initialMessagesState);

  const handleSendMessage = async (messageContent: string) => {
    const newMessageObject: MessageState = {
      id: nanoid(),
      message: messageContent,
      author: agentMessageAuthor,
      sentAt: new Date(),
      variant: 'me',
      state: 'sending',
    };

    try {
      onAdd(newMessageObject);
      await messageMutation.mutateAsync({
        client: null,
        offer: offer?.id ?? '',
        text: messageContent,
        file: null,
      });

      onUpdate(newMessageObject.id, { state: 'sent' });
    } catch (error) {
      onUpdate(newMessageObject.id, { state: 'error' });
    }
  };

  if (isLoading)
    return (
      <S.ChatContent>
        <LoadingView />
      </S.ChatContent>
    );

  return (
    <S.ChatContent>
      <Chat messages={messages} onSend={handleSendMessage} emptyScreen={<EmptyChat type="agent" />} />
    </S.ChatContent>
  );
}
