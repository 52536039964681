import { StepIconProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'routes/paths';

import * as S from './styles';

interface WizardStepIconProps extends StepIconProps {
  index: number;
  actStep: number;
  goTo: () => void;
  stage: number;
  label: any;
}

function WizardStepIcon({ active, completed, icon, index, goTo, stage, label, actStep }: WizardStepIconProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const { pathname } = location;

  const currentStage = stage + 1;
  const page = pathname === `${PATHS.agent.index}/${PATHS.agent.remoteSale}`;
  const isAgent = page ? index < currentStage : active;

  const show = (index: number) => {
    if (index < currentStage && page) {
      goTo();
    }
  };

  return (
    <S.StepIconRoot
      $active={isAgent}
      $completed={completed}
      $page={page}
      $stateClick={index === actStep}
      onClick={() => show(index)}
    >
      {icon}
      <S.InfoContent className="info-content">{t(label)}</S.InfoContent>
    </S.StepIconRoot>
  );
}

export default WizardStepIcon;
