import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Popup = styled(({ className, ...rest }: TooltipProps) => (
  <Tooltip arrow classes={{ popper: className }} {...rest} />
))`
  .${tooltipClasses.arrow} {
    color: white;
  }

  .${tooltipClasses.tooltip} {
    background-color: white;
    color: ${theme.palette.text.primary};
    box-shadow: 5px 0px 20px 1px rgba(0, 0, 0, 0.2); // need to use custom instead of mui because it needs to be a little bit higher
  }
`;
