import { InsuranceSortOrder } from './types';

const SORT_ORDER_KEY = 'insurances_sort_order';

export function getSortOrder(): InsuranceSortOrder {
  return (localStorage.getItem(SORT_ORDER_KEY)?.toString() as InsuranceSortOrder) || 'added';
}

export function saveSortOrder(sortOrder: InsuranceSortOrder) {
  return localStorage.setItem(SORT_ORDER_KEY, sortOrder);
}
