import { INSURANCES_LIST } from 'library/insurances';
import { useMemo } from 'react';

function useBrand(name: string) {
  return useMemo(
    () => INSURANCES_LIST.find((insurance) => insurance?.brand.toLowerCase() === name?.toLowerCase()),
    [name],
  );
}

export default useBrand;
