import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Link, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { newPasswordChange } from 'api/auth/authApi';
import { NewPasswordRequest } from 'api/auth/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'routes/paths';

import ControlledPassword from 'components/Form/Controls/ControlledPassword';

import PasswordUpdate from '../../components/PasswordUpdate';
import { newPasswordSchema } from './schema';
import * as S from './styles';

function AgentNewPassword() {
  const location = useLocation();
  const searchQuery = location.search.replace(/&amp;/g, '&');

  const modifiedSearchParams = new URLSearchParams(searchQuery);

  const uid = modifiedSearchParams.get('uid');
  const token = modifiedSearchParams.get('token');

  const { mutate, isLoading } = useMutation(newPasswordChange);
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<NewPasswordRequest>({
    resolver: yupResolver(newPasswordSchema),
    defaultValues: {
      uid: uid || '',
      token: token || '',
    },
  });

  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);

  const onSubmit = (data: NewPasswordRequest) => {
    mutate(data, {
      onSuccess: () => {
        setIsChangePassword(true);
      },
    });
  };

  if (!uid || !token) {
    return (
      <S.NewPasswordWrapper>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">{t('errors.permit')}</Typography>
          </Grid>
          <Grid item container gap={0.5}>
            <Trans
              i18nKey="errors.passwordReminder"
              components={{ a: <Link href={`${PATHS.agent.index}/${PATHS.agent.remindPassword}`} /> }}
            />
          </Grid>
        </Grid>
      </S.NewPasswordWrapper>
    );
  }

  return (
    <S.NewPasswordWrapper>
      {!isChangePassword ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5">{t('agent.newPassword')}</Typography>
            </Grid>
            <Grid item container gap={2}>
              <ControlledPassword
                control={control}
                name="new_password1"
                label={t('agent.newPassword')}
                useVisibilitySwitch
              />
            </Grid>
            <Grid item container gap={2}>
              <ControlledPassword
                control={control}
                name="new_password2"
                label={t('agent.confirmPassword')}
                useVisibilitySwitch
              />
            </Grid>
            <Grid item container justifyContent="flex-end" alignItems="center" marginTop={2}>
              <Button variant="contained" type="submit" disabled={isLoading}>
                {t('agent.save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <PasswordUpdate title={t('agent.passwordChangedSuccessfully')} />
      )}
    </S.NewPasswordWrapper>
  );
}

export default AgentNewPassword;
