import { useEffect } from 'react';

import LoadingView from 'components/LoadingView';

import * as S from './styles';

const Calendar = () => {
  const url = 'https://calendly.com/szmex5/pomoctechniczna?primary_color=ff0000';

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <S.Main>
      <S.StyledDiv>
        <S.Calendar className="calendly-inline-widget" data-url={url} />
        <S.StyledCalendar>
          <LoadingView />
        </S.StyledCalendar>
      </S.StyledDiv>
    </S.Main>
  );
};

export default Calendar;
