import { Typography } from '@mui/material';
import { SignMethod } from 'pages/AgentRemoteSale/steps/Policy/components/PolicyForm/type';
import { useTranslation } from 'react-i18next';

import Announcement from './Announcement';
import { INSTRUCTIONS } from './Announcement/const';
import { ScanDocumentForm as ScanDocument } from './SignMethods';
import { SignForm } from './SignMethods/types';

interface Props {
  signMethod: SignMethod;
  onSubmit: (data: SignForm) => Promise<void>;
  children?: React.ReactNode;
}

function Sign({ signMethod, onSubmit, children }: Props) {
  const { t } = useTranslation();
  const { REMOTELY_IN_SYSTEM, BY_PAYMENT, BY_POST_OFFIE, SIGN_WITH_AGENT } = INSTRUCTIONS;

  const handleSubmit = (data: SignForm) => {
    return onSubmit({ ...data, type: signMethod } as SignForm);
  };

  switch (signMethod) {
    case 'REMOTELY_IN_SYSTEM':
      return <Announcement value={REMOTELY_IN_SYSTEM} />;
    case 'SCAN_DOCUMENT':
      return <ScanDocument onSubmit={handleSubmit}>{children}</ScanDocument>;
    case 'BY_PAYMENT':
      return <Announcement value={BY_PAYMENT} />;
    case 'BY_POST_OFFIE':
      return (
        <>
          <Announcement value={BY_POST_OFFIE} />
          <ScanDocument onSubmit={handleSubmit}>{children}</ScanDocument>
        </>
      );
    case 'SIGN_WITH_AGENT':
      return <Announcement value={SIGN_WITH_AGENT} />;
    case 'POLICY_ALREADY_ACCEPTED':
      return null;
    default:
      return <Typography textAlign="center">{t('insuranceWizard.policy.signMethods.notSupportedYet')}</Typography>;
  }
}

export default Sign;
