import { useMessageMutation, useMessagesQuery } from 'api/client/messages/messagesQuery';
import { Message } from 'api/messages/types';
import { AxiosQueryOptions, PaginatedResponse } from 'api/types';

import { useClientOffer } from './useClientOffer';

export function useClientOfferMessages(options?: AxiosQueryOptions<PaginatedResponse<Message>>) {
  const { offer } = useClientOffer();

  if (!offer) throw new Error('No offer provided');

  return useMessagesQuery(offer.id, options);
}

export function useUpdateMessages() {
  const { offer } = useClientOffer();

  if (!offer) throw new Error('No offer provided');

  return useMessageMutation(offer.id);
}
