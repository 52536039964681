import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #e5f6fd;
  border: 3px solid #79acf5;
  border-radius: 5px;
  height: 150px;
  width: 600px;
  padding: 20px;
  position: relative;
  bottom: 50px;
`;
