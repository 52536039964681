import { Checkbox } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const policyDatesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(3)};
`;

export const CheckboxContainer = styled.div`
  padding-top: ${theme.spacing(1)};
`;

export const Container = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0;
  gap: 15px;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #e5f6fd;
  }
`;

export const Box = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid ${(props) => props.border};
  height: 100px;
  width: 262px;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  @media (max-width: 800px) {
    width: 120px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 18px;
  margin-top: 50px;
`;
