import { Grid } from '@mui/material';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledTextField, FormGroup } from 'components/Form';

import * as S from './styles';

function Address() {
  const { t } = useTranslation('translation', { keyPrefix: 'insuranceWizard.clientData' });
  const { control, getValues } = useFormContext<ClientDataFormState>();

  const isLegalPerson = !!getValues('company');

  return (
    <FormGroup title={isLegalPerson ? t('companyData') : t('address')}>
      <S.Container>
        {isLegalPerson && (
          <>
            <Grid item md={6} xs={12}>
              <ControlledTextField control={control} name="company.taxId" defaultValue="" label={t('nip')} />
            </Grid>

            <Grid item md={6} xs={12}>
              <ControlledTextField
                control={control}
                name="company.name"
                defaultValue=""
                label={t('companyName')}
                inputProps={{ disabled: true }}
              />
            </Grid>
          </>
        )}
        <Grid item md={6} xs={12}>
          <ControlledTextField control={control} name="address" defaultValue="" label={t('street')} />
        </Grid>
        <Grid item md={3} xs={6}>
          <ControlledTextField control={control} name="buildingNumber" defaultValue="" label={t('buildingNumber')} />
        </Grid>
        <Grid item md={3} xs={6}>
          <ControlledTextField control={control} name="apartmentNumber" defaultValue="" label={t('flatNumber')} />
        </Grid>
        <Grid item xs={4}>
          <ControlledTextField control={control} name="zipCode" defaultValue="" label={t('postalCode')} />
        </Grid>
        <Grid item xs={8}>
          <ControlledTextField control={control} name="city" defaultValue="" label={t('city')} />
        </Grid>
      </S.Container>
    </FormGroup>
  );
}

export default Address;
