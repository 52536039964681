import DownloadIcon from '@mui/icons-material/Download';
import { Grid, GridSpacing } from '@mui/material';
import { CSSProperties } from 'react';

import { FormFile } from 'components/Attachments/types';

import * as S from './styles';

interface Props {
  files: FormFile[];
  spacing?: GridSpacing;
  justifyContent?: CSSProperties['justifyContent'];
}

const getFileNameFromFile = (file: FormFile) => {
  return file.filename.replace(/^.*[\\/]/, '');
};

function DownloadList({ files, spacing = 2, justifyContent = 'flex-start' }: Props) {
  return (
    <>
      <Grid container spacing={spacing}>
        {files.map((file) => (
          <Grid item key={file.filename} xs={12} container justifyContent={justifyContent}>
            <S.LinkWrapper download href={file.url}>
              <DownloadIcon fontSize="small" />
              {getFileNameFromFile(file)}
            </S.LinkWrapper>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
export default DownloadList;
