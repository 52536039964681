import { Button, CardHeader, Grid, Modal } from '@mui/material';
import { FileResult } from 'api/files/types';
import useModal from 'library/hooks/useModal';
import EditorInfoBox from 'pages/AgentRemoteSale/components/EditorInfoBox';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import DataForm from 'pages/AgentRemoteSale/steps/ClientData/components/DataForm';
import { useTranslation } from 'react-i18next';
import { ModalActions, ModalContainer, ModalContent } from 'styles';

import { FormFile } from 'components/Attachments/types';
import LabelValue from 'components/LabelValue';

interface Props {
  clientData: ClientDataFormState;
  defaultOpen?: boolean;
  readonly?: boolean;
  onUpdate?: (data: ClientDataFormState) => void;
  onCancelUpdate?: () => void;
  isSubmitting?: boolean;
  onUpload?: (file: File) => Promise<FileResult>;
  onAddFile?: (file: FormFile) => void;
  onDeleteFile?: (file: FormFile) => void;
  type: 'client' | 'agent';
}

function BaseClientDataBox({
  defaultOpen,
  readonly,
  onUpdate,
  clientData,
  isSubmitting,
  onCancelUpdate,
  onUpload,
  onAddFile,
  onDeleteFile,
  type,
}: Props) {
  const { t } = useTranslation();
  const { handleClose: onClose, handleOpen, open: modalOpen } = useModal();

  const handleSubmit = (updatedClient: ClientDataFormState) => {
    onClose();
    if (onUpdate) onUpdate(updatedClient);
  };

  const handleCloseModal = () => {
    onClose();
    onCancelUpdate && onCancelUpdate();
  };

  return (
    <>
      <EditorInfoBox
        onEdit={handleOpen}
        defaultOpen={defaultOpen}
        title={t('insuranceWizard.client')}
        readonly={readonly}
      >
        <Grid container spacing={1}>
          <Grid item container xs={12}>
            <LabelValue label={t('insuranceWizard.clientData.contactData')} multiline>
              <Grid item container xs={12} columnSpacing={1}>
                {clientData.clientUser?.firstName && clientData.clientUser.lastName && (
                  <Grid item xs={12} md={6} style={{ flexShrink: 1 }}>
                    {clientData.clientUser?.firstName} {clientData.clientUser.lastName}
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  {clientData.clientUser?.email}
                </Grid>
                <Grid item xs={12} md={6}>
                  {clientData.phoneNumber}
                </Grid>
              </Grid>
            </LabelValue>
          </Grid>
          {(clientData.address || clientData.city) && (
            <Grid item container xs={12} zeroMinWidth>
              {clientData.address && (
                <LabelValue label={t('insuranceWizard.clientData.address')} multiline>
                  {t('insuranceWizard.clientData.streetShort')} {clientData.address} {clientData.buildingNumber}{' '}
                  {clientData.apartmentNumber && `/ ${clientData.apartmentNumber}`}
                  <br />
                  {clientData.zipCode} {clientData.city}
                </LabelValue>
              )}
            </Grid>
          )}
        </Grid>
      </EditorInfoBox>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <ModalContent>
          <CardHeader title={t('insuranceWizard.clientData.editClient')} />
          <ModalContainer $withActions>
            <DataForm
              type={type}
              initialState={clientData}
              onSubmit={handleSubmit}
              onUpload={onUpload}
              onAddFile={onAddFile}
              onDeleteFile={onDeleteFile}
            >
              <ModalActions>
                <Button onClick={handleCloseModal} disabled={isSubmitting}>
                  {t('common.cancel')}
                </Button>
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  {t('common.save')}
                </Button>
              </ModalActions>
            </DataForm>
          </ModalContainer>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BaseClientDataBox;
