import { Alert, AlertTitle, Button, Grid } from '@mui/material';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import ClientChat from 'pages/ClientRemoteSale/components/ClientChat';
import { useTranslation } from 'react-i18next';

import { LeftContainer, MainContainer, RightContainer } from 'components/Layout/styles';
import WizardLoadingView from 'components/WizardLoadingView';

import { useClientForm } from '../contexts/FormContext/hooks/useClientForm';
import { useClientOffer } from '../contexts/FormContext/hooks/useClientOffer';
import { WizardSteps } from '../types';
import ClientData from './ClientData';
import Insurances from './Insurances';
import Offer from './Offer';
import Payment from './Payment';

function StepsContent() {
  const { actStep } = useWizard();
  const { t } = useTranslation();

  const { state: formState } = useClientForm();
  const handleReloadPage = () => window.location.reload();

  const { loading } = useClientOffer();

  if (loading) return <WizardLoadingView height="200px" />;

  switch (actStep) {
    case WizardSteps.ClientData:
      return <ClientData />;
    case WizardSteps.Insurances:
      return <Insurances />;
    case WizardSteps.Offer:
      return <Offer />;
    case WizardSteps.Payment:
      return <Payment />;
    default:
      return (
        <MainContainer>
          <LeftContainer>
            <Alert
              severity="info"
              action={
                <Grid container alignSelf="center">
                  <Button color="inherit" size="small" onClick={handleReloadPage}>
                    {t('common.refresh')}
                  </Button>
                </Grid>
              }
            >
              <AlertTitle>{t('client.waitingForAgent')}</AlertTitle>
              {t('client.waitingForAgentDescription')}
            </Alert>
            {formState?.agent.logo ? (
              <img
                src={(formState?.agent as unknown as { logo: any })?.logo}
                style={{ width: '200px', height: '200px', marginTop: '20px' }}
              />
            ) : null}
          </LeftContainer>
          <RightContainer>
            <ClientChat setMessagesApk={null} />
          </RightContainer>
        </MainContainer>
      );
  }
}

export default StepsContent;
