import { DateTime } from 'luxon';
import * as yup from 'yup';

export const clientNeedSchema = yup.object().shape({
  startDate: yup
    .date()
    .min(DateTime.now().startOf('day'), 'errors.dateCantBeFromPast')
    .typeError({ key: 'errors.dateMustBeInFormat', options: { format: 'DD.MM.YYYY' } })
    .required(),
  name: yup.string().required().max(50).trim(),
  description: yup.string().required().max(255),
  insuranceType: yup.string().required(),
});
