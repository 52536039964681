import { useClient } from 'contexts/ClientAuthContext/hooks';
import { Navigate, Outlet } from 'react-router';
import { PATHS } from 'routes/paths';

function ClientPrivatePages() {
  const client = useClient();

  if (!client) return <Navigate to={PATHS.client.unauthorized} />;

  return <Outlet />;
}

export default ClientPrivatePages;
