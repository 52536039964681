import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ControlledAttachments } from 'components/Form';

import MethodType from '../components/MethodType';
import { schema } from './schema';
import { ScanDocuments } from './types';

interface Props {
  onSubmit: (data: ScanDocuments) => Promise<void>;
  children: React.ReactNode;
  initialState?: Partial<ScanDocuments>;
}

function ScanDocument({ onSubmit, children, initialState }: Props) {
  const { t } = useTranslation();
  const { watch, handleSubmit, control, reset } = useForm<ScanDocuments>({
    defaultValues: { ...initialState },
    resolver: yupResolver(schema),
  });

  const documents = watch('documents');

  const submit = async (data: ScanDocuments) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      toast(t('errors.cantUploadThisFiles'));
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <MethodType
        description={t('insuranceWizard.policy.signMethods.SCAN_DOCUMENT.description')}
        marginBottom={2}
        marginTop={2}
      />
      <ControlledAttachments files={documents} name={'documents' as never} control={control} />
      {children}
    </form>
  );
}

export default ScanDocument;
