import { Button, Typography } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

import LabelValue from 'components/LabelValue';

export const ImageContent = styled.img`
  width: 100px;
  object-fit: contain;
  display: block;
  overflow: hidden;
  padding-left: ${theme.spacing(5)};
  padding-top: ${theme.spacing(5)};
  height: fit-content;
`;

export const DatesLabelValue = styled(LabelValue)`
  ${theme.breakpoints.down('md')} {
    text-align: left;
  }
`;

export const FilesText = styled(Typography)`
  color: ${theme.palette.grey[400]};
`;

export const ExpandButton = styled(Button)`
  padding-left: 0;
`;
