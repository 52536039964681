/* eslint-disable @typescript-eslint/no-unused-vars */
import Typography from '@mui/material/Typography';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import theme from 'library/theme/theme';
import { useClientForm } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientForm';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PDFFile from '../PDFFile';

const Apk = ({ state, messagesApk, client }: any) => {
  const { t } = useTranslation();

  const { setApk } = useClientForm();

  useEffect(() => {
    const fetchData = async () => {
      const blob = await pdf(<PDFFile state={state} messagesApk={messagesApk} client={client} />).toBlob();
      const formData = new FormData();
      formData.append('apk', blob, 'apk.pdf');
      setApk(formData);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PDFDownloadLink
        style={{ textDecoration: 'none', color: theme.palette.primary.main }}
        document={<PDFFile state={state} messagesApk={messagesApk} client={client} />}
        fileName="apk.pdf"
      >
        {({ loading }: any) =>
          loading ? (
            'Ładowanie...'
          ) : (
            <>
              <Typography>{t('insuranceWizard.offer.downloadAPK')}</Typography>
              <Typography>{t('insuranceWizard.offer.downloadAPKdescription')}</Typography>
            </>
          )
        }
      </PDFDownloadLink>
    </>
  );
};

export default Apk;
