import AgentAuthContextProvider from 'contexts/AgentAuthContext';
import { Outlet } from 'react-router';

import Header from './components/Header';

function AgentPages() {
  return (
    <AgentAuthContextProvider>
      <Header />
      <main>
        <Outlet />
      </main>
    </AgentAuthContextProvider>
  );
}
export default AgentPages;
