import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DescriptionIcon from '@mui/icons-material/Description';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PeopleIcon from '@mui/icons-material/People';
import RuleIcon from '@mui/icons-material/Rule';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TuneIcon from '@mui/icons-material/Tune';

import { StepConfig } from 'components/WizardStepper/types';

import { WizardSteps } from './types';

export const STEPS: StepConfig<WizardSteps>[] = [
  {
    icon: <PeopleIcon />,
    name: 'client',
    step: WizardSteps.ClientSearch,
    label: 'insuranceWizard.client',
    title: 'insuranceWizard.clientSearch.searchClient',
  },
  {
    icon: <FavoriteIcon />,
    name: 'clientNeeds',
    step: WizardSteps.ClientNeeds,
    label: 'insuranceWizard.needs',
    title: 'insuranceWizard.clientNeeds.clientNeeds',
  },
  {
    icon: <AssignmentTurnedInIcon />,
    name: 'data_agreements',
    step: WizardSteps.ClientData,
    label: 'insuranceWizard.dataAgreements',
    title: 'insuranceWizard.clientData.clientData',
  },
  {
    icon: <RuleIcon />,
    name: 'insurances_suggestions',
    step: WizardSteps.Insurances,
    label: 'insuranceWizard.insurancesSuggestions',
    title: 'insuranceWizard.propositionsOfInsurances.propositionsOfInsurances',
  },
  {
    icon: <TuneIcon />,
    name: 'offer_adjustment',
    step: WizardSteps.InsuranceAdjustment,
    label: 'insuranceWizard.offerAdjustment',
    title: 'insuranceWizard.insuranceAdjustments.offerAdjustment',
  },
  {
    icon: <ShoppingCartIcon />,
    name: 'share_offer',
    step: WizardSteps.InsuranceAcceptance,
    label: 'insuranceWizard.shareOffer',
    title: 'insuranceWizard.clientOffer.offerSummary',
  },
  {
    icon: <DescriptionIcon />,
    name: 'policy_issuing',
    step: WizardSteps.Policy,
    label: 'insuranceWizard.policyIssuing',
    title: 'insuranceWizard.policy.policy',
  },
  {
    icon: <AccountBalanceWalletIcon />,
    name: 'payment',
    step: WizardSteps.Payment,
    label: 'insuranceWizard.payment',
    title: 'insuranceWizard.paymentStep.policyPayment',
  },
];
