import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { changePassword } from 'api/auth/authApi';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import ControlledPassword from 'components/Form/Controls/ControlledPassword';

import Information from '../Information/Information';
import { schema } from './schema';
import * as S from './styles';

interface PasswordFormValues {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

interface PasswordFormValues {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

function PasswordChange() {
  const { mutate } = useMutation(changePassword);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { control, handleSubmit, register, setValue } = useForm<PasswordFormValues>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<PasswordFormValues> = (data: any) => {
    mutate(data, {
      onSuccess: () => {
        setIsOpenModal(true);
        setValue('old_password', '');
        setValue('new_password1', '');
        setValue('new_password2', '');
      },
    });
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const text = 'Twoje hasło zostało zmienione';

  return (
    <S.Main>
      <Information {...{ isOpenModal, closeModal, text }} />
      <Typography variant="body1" fontWeight="bolder" fontSize={16}>
        Podaj stare i nowe hasło
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.Box gap={50}>
          <ControlledPassword
            {...register('old_password')}
            label="dotychczasowe hasło"
            control={control}
            useVisibilitySwitch
          />
        </S.Box>
        <S.Box gap={50}>
          <ControlledPassword {...register('new_password1')} label="nowe hasło" control={control} useVisibilitySwitch />
        </S.Box>
        <S.Box gap={50}>
          <ControlledPassword
            {...register('new_password2')}
            label="powtórz nowe hasło"
            control={control}
            useVisibilitySwitch
          />
        </S.Box>
        <S.WrapperButton>
          <Button variant="contained" type="submit">
            Zapisz
          </Button>
        </S.WrapperButton>
      </form>
    </S.Main>
  );
}

export default PasswordChange;
