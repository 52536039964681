import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, FilterOptionsState, InputAdornment, TextField } from '@mui/material';
import { Client } from 'api/client/types';
import React, { SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ClientSearchOption, ClientTypes } from '../../types';
import { isAddOption } from '../../utils';
import * as S from './styles';

const SEARCH_LABELS: Record<ClientTypes, string> = {
  legalPerson: 'insuranceWizard.clientSearch.legalPersonSearch',
  individualPerson: 'insuranceWizard.clientSearch.individualPersonSearch',
};

interface Props {
  data: Client[];
  onSelect: (option: ClientSearchOption) => void;
  selectedClientType: ClientTypes;
  value: ClientSearchOption | null;
  size: 'small' | 'medium';
  loading?: boolean;
  error?: boolean;
  label?: string;
}

function ClientAutocomplete({ onSelect, data, error, size, selectedClientType, value, loading, label }: Props) {
  const { t } = useTranslation();

  const handleSelectAutocompleteOption = (_: SyntheticEvent<Element, Event>, selected: ClientSearchOption | string) => {
    if (typeof selected !== 'string' && onSelect) {
      onSelect({ ...selected });
    }
  };

  const autocompleteOptions = useMemo<ClientSearchOption[]>(() => [{ type: 'add', value: '' }, ...data], [data]);

  const handleGetOptionLabel = (option: ClientSearchOption | string) => {
    if (typeof option === 'string') return option;
    if ('type' in option) return t('insuranceWizard.clientSearch.newClient');
    if (option.company?.name) return option.company.name;
    if (option.clientUser?.email) return option.clientUser.email;
    return t('insuranceWizard.clientSearch.newClient');
  };

  const handleFilterOptions = (
    options: ClientSearchOption[],
    { inputValue }: FilterOptionsState<ClientSearchOption>,
  ) => {
    return options
      .filter((opt) => {
        // always display 'specific' records like "add" record
        if ('type' in opt) return true;

        if (opt.company?.name) return opt.company.name.includes(inputValue);
        if (opt.clientUser?.email) return opt.clientUser.email?.includes(inputValue);
        else return true;
      })
      .map((opt) => {
        if (isAddOption(opt)) return { ...opt, value: inputValue };
        return opt;
      });
  };

  return (
    <Autocomplete
      options={autocompleteOptions}
      filterOptions={handleFilterOptions}
      loadingText={t('common.loading')}
      getOptionLabel={handleGetOptionLabel}
      disableClearable
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      loading={loading}
      size={size}
      // invalid type does not allow nulls
      value={value as any}
      onChange={handleSelectAutocompleteOption}
      renderOption={(props, option) => {
        if (isAddOption(option))
          return (
            <React.Fragment key="addOption">
              <li {...props}>
                <AddIcon />
                {t(`insuranceWizard.clientSearch.addNewClient`)}
              </li>
              {error && (
                <li>
                  <S.ListAlert severity="error">{t('insuranceWizard.clientSearch.failedLoadingClients')}</S.ListAlert>
                </li>
              )}
            </React.Fragment>
          );

        if (option.company?.name)
          return (
            <li key={option.company.id} {...props}>
              {option.company.name}
            </li>
          );
        if (option.clientUser?.email)
          return (
            <li key={option.clientUser.email} {...props}>
              {option.clientUser.email}
            </li>
          );
        return <li {...props}>{t('insuranceWizard.clientSearch.newClient')}</li>;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || t(SEARCH_LABELS[selectedClientType] as never)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
export default ClientAutocomplete;
