import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';

import ApkMessages from '../ApkMessages';
import customFontAnton from '../Font/Anton-Regular.ttf';
import customFontRoboto from '../Font/Roboto-Regular.ttf';
import Footer from '../Footer';
import Header from '../Header/Header';

const PDFFile = ({ state, messagesApk }: any) => {
  const check = (variant: any) => {
    if (!variant) {
      return '-';
    } else {
      return variant;
    }
  };

  const checkInsurance = (insurance: any) => {
    if (state.clientNeeds.insuranceType === insurance) {
      return true;
    } else {
      return false;
    }
  };

  Font.register({
    family: 'Roboto',
    src: customFontRoboto,
  });

  Font.register({
    family: 'Anton',
    src: customFontAnton,
  });

  const logo = state.agent.logo ? state.agent.logo : '/images/4agent-logo.png';
  const chosenInsurance = state?.insurances.find((insurance: any) => insurance.chosen === true);

  return (
    <Document style={{ fontFamily: 'Roboto' }}>
      <Page size="A3" style={{ padding: 50 }}>
        <Header logo={logo} />
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={{ fontSize: '13px', padding: '20px 0' }}>
              Niniejszy dokument jest potwierdzeniem przeprowadzenia Analizy Potrzeb Klienta przez:
            </Text>
          </View>
          <View style={{ border: '1px solid black' }}>
            <View
              style={{
                borderBottom: '1px solid black',
                backgroundColor: '#E6E6E5',
              }}
            >
              <Text
                style={{
                  fontSize: '13px',
                  padding: '5px',
                }}
              >
                Doradca
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View style={{ width: '20%', height: '100%', display: 'flex', justifyContent: 'center', padding: '5px' }}>
                <Text style={{ fontSize: '13px' }}>Imię i nazwisko:</Text>
              </View>
              <View style={{ width: '80%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  {check(state.agent.firstName)} {check(state.agent.lastName)}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View style={{ width: '20%', padding: '5px', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <Text style={{ fontSize: '13px' }}>Email:</Text>
              </View>
              <View
                style={{
                  width: '40%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  height: '100%',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.agent.email)}
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Telefon:
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  height: '100%',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.agent.dataAdministrator.phoneNumber)}
                </Text>
              </View>
            </View>
            <View
              style={{
                borderBottom: '1px solid black',
                backgroundColor: '#E6E6E5',
                padding: '5px',
              }}
            >
              <Text
                style={{
                  fontSize: '13px',
                }}
              >
                Administrator danych
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View
                style={{
                  width: '20%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  justifyContent: 'center',
                }}
              >
                <Text style={{ fontSize: '13px' }}>Nazwa:</Text>
              </View>
              <View
                style={{
                  width: '40%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.agent.dataAdministrator.name)}
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  NIP:
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.agent.dataAdministrator.taxId)}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View
                style={{
                  width: '20%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  justifyContent: 'center',
                }}
              >
                <Text style={{ fontSize: '13px' }}>Ulica:</Text>
              </View>
              <View
                style={{
                  width: '40%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.agent.dataAdministrator.street)}
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Numer:
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.agent.dataAdministrator.apartmentNumber)}/
                  {check(state.agent.dataAdministrator.houseNumber)}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
              }}
            >
              <View
                style={{
                  width: '20%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  justifyContent: 'center',
                }}
              >
                <Text style={{ fontSize: '13px' }}>Kod pocztowy:</Text>
              </View>
              <View
                style={{
                  width: '40%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.agent.dataAdministrator.postalCode)}
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Miejscowość:
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.agent.dataAdministrator.city)}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ padding: '20px 0' }}>
            <Text
              style={{
                fontSize: '13px',
              }}
            >
              Niniejsza Analiza Potrzeb Klienta zostala przeprowadzona dla:
            </Text>
          </View>
          <View style={{ border: '1px solid black' }}>
            <View
              style={{
                borderBottom: '1px solid black',
                backgroundColor: '#E6E6E5',
              }}
            >
              <Text
                style={{
                  fontSize: '13px',
                  padding: '5px',
                }}
              >
                Klient
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View
                style={{
                  width: '20%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  justifyContent: 'center',
                }}
              >
                <Text style={{ fontSize: '13px' }}>Imię i nazwisko:</Text>
              </View>
              <View
                style={{
                  width: '40%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.clientData.clientUser.firstName)} {check(state.clientData.clientUser.lastName)}
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  PESEL:
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.clientData.clientUser.pesel)}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View
                style={{
                  width: '20%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  justifyContent: 'center',
                }}
              >
                <Text style={{ fontSize: '13px' }}>Email:</Text>
              </View>
              <View
                style={{
                  width: '40%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.clientData.clientUser.email)}
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Telefon:
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.clientData.phoneNumber)}
                </Text>
              </View>
            </View>
            <View
              style={{
                borderBottom: '1px solid black',
                backgroundColor: '#E6E6E5',
                padding: '5px',
              }}
            >
              <Text
                style={{
                  fontSize: '13px',
                }}
              >
                Adres
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View
                style={{
                  width: '20%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  justifyContent: 'center',
                }}
              >
                <Text style={{ fontSize: '13px' }}>Ulica:</Text>
              </View>
              <View
                style={{
                  width: '40%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.clientData.address)}
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Numer:
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.clientData.buildingNumber)}/{check(state.clientData.apartmentNumber)}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View
                style={{
                  width: '20%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  justifyContent: 'center',
                }}
              >
                <Text style={{ fontSize: '13px' }}>Kod pocztowy:</Text>
              </View>
              <View
                style={{
                  width: '40%',
                  display: 'flex',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.clientData.zipCode)}
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Miejscowość:
                </Text>
              </View>
              <View
                style={{
                  width: '20%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.clientData.city)}
                </Text>
              </View>
            </View>
            <View
              style={{
                borderBottom: '1px solid black',
                backgroundColor: '#E6E6E5',
              }}
            >
              <Text
                style={{
                  fontSize: '13px',
                  padding: '5px',
                }}
              >
                Dodatkowe informacje
              </Text>
            </View>
            <View
              style={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <View style={{ padding: '20px 5px' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    padding: '0px 5px 20px 5px',
                    fontFamily: 'Anton',
                  }}
                >
                  {check(state.clientData.notes)}
                </Text>
                <Text
                  style={{
                    fontSize: '13px',
                    padding: '0 5px 5px 5px',
                  }}
                >
                  Przekazane załączniki:
                </Text>

                {state?.clientData?.attachments.map((itemFour: any, indexFour: any) => (
                  <Text
                    key={indexFour}
                    style={{
                      fontSize: '13px',
                      padding: '2px 5px',
                      fontFamily: 'Anton',
                    }}
                  >
                    {itemFour?.name?.replace(/^[a-f0-9]+-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+\//, '')}
                  </Text>
                ))}
              </View>
            </View>
          </View>
          <View style={{ padding: '20px 0' }}>
            <Text style={{ fontSize: '13px' }}>
              Decyzja o wygenerowaniu niniejszego dokumentu została podjęta osobiście przez Klienta:{' '}
              {check(state.clientData.clientUser.firstName)} {check(state.clientData.clientUser.lastName)}, który został
              poinformowany, że wypełnienie niniejszej Analizy jest dobrowolne oraz, że w przypadku odmowy jej
              wypełnienia, Doradca, ma ograniczoną możliwość dokonania oceny, czy zawierana przez Klienta umowa będzie
              dopasowana do potrzeb Klienta.
            </Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text style={{ fontSize: '13px' }}>Oświadczenia Klienta:</Text>
            <View style={{ margin: '0 20px', fontSize: '13px' }}>
              <Text style={{ textIndent: -10, fontSize: '13px' }}>
                • Oświadczam, że zapoznałem się z polityką informacyjną administratora danych *
              </Text>
              <Text style={{ textIndent: -10, fontSize: '13px' }}>
                • Zgoda na otrzymywanie informacji handlowych drogą elektroniczną
              </Text>
              <Text style={{ textIndent: -10, fontSize: '13px' }}>
                • Zgadzam się na otrzymywanie informacji handlowej od Agenta {check(state.agent.firstName)}{' '}
                {check(state.agent.lastName)}, z wykorzystaniem środków porozumiewania się na odległość (telefon,
                e-mail, indywidualne konto internetowe udostępnione przez Doradcę) przy użyciu podanych przeze mnie
                danych kontaktowych.
              </Text>
              <Text style={{ textIndent: -10, fontSize: '13px' }}>
                • Wyrażam zgodę na zawarcie umowy ubezpieczenia na warunkach wskazanych w ofercie przekazanej mi z
                wykorzystaniem środków porozumiewania się na odległość. *
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <View style={{ marginTop: '50px', border: '1px solid black' }} wrap={false}>
            <View style={{ backgroundColor: '#E6E6E5' }}>
              <Text
                style={{
                  fontSize: '13px',
                  borderBottom: '1px solid black',
                  padding: '5px',
                }}
              >
                Potrzeby Klienta
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View style={{ width: '25%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <Text style={{ fontSize: '13px', padding: '5px' }}>Usługa:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  Ubezpieczenie
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Nowa/kontynuacja:
                </Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  Nowa polisa
                </Text>
              </View>
            </View>
            <View style={{ backgroundColor: '#E6E6E5' }}>
              <Text
                style={{
                  fontSize: '13px',
                  borderBottom: '1px solid black',
                  padding: '5px',
                }}
              >
                Co Klient chce ubezpieczyć?
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View style={{ width: '25%', padding: '5px', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <Text style={{ fontSize: '13px' }}>Zdrowie i życie:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  {checkInsurance('Zdrowie i życie') ? 'tak' : 'nie'}
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Dom i mieszkanie:
                </Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  {checkInsurance('Dom i mieszkanie') ? 'tak' : 'nie'}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View style={{ width: '25%', padding: '5px', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <Text style={{ fontSize: '13px' }}>Podróż:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  {checkInsurance('Podróż') ? 'tak' : 'nie'}
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Pojazd:
                </Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  {checkInsurance('Pojazd') ? 'tak' : 'nie'}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View style={{ width: '25%', padding: '5px', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <Text style={{ fontSize: '13px' }}>Firma:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  {checkInsurance('Firma') ? 'tak' : 'nie'}
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Gospodarstwo rolne:
                </Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  {checkInsurance('Gospodarstwo rolne') ? 'tak' : 'nie'}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View style={{ width: '25%', padding: '5px', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <Text style={{ fontSize: '13px' }}>Panele fotowoltaiczne:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  {checkInsurance('Panele fotowoltaiczne') ? 'tak' : 'nie'}
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  height: '100%',
                  padding: '5px',
                  borderLeft: '1px solid black',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Inne:
                </Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Anton',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  {checkInsurance('Inne') ? 'tak' : 'nie'}
                </Text>
              </View>
            </View>
            <View style={{ backgroundColor: '#E6E6E5' }}>
              <Text
                style={{
                  fontSize: '13px',
                  padding: '5px',
                  borderBottom: '1px solid black',
                }}
              >
                Informacje o potrzebach Klienta
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View style={{ width: '25%', height: '100%', display: 'flex', justifyContent: 'center', padding: '5px' }}>
                <Text style={{ fontSize: '13px' }}>Start ochrony:</Text>
              </View>
              <View style={{ width: '75%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    padding: '5px',
                    borderLeft: '1px solid black',
                    fontFamily: 'Anton',
                  }}
                >
                  {moment(state.clientNeeds.startDate).format('DD-MM-YYYY')}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid black',
              }}
            >
              <View style={{ width: '25%', height: '100%', display: 'flex', justifyContent: 'center', padding: '5px' }}>
                <Text style={{ fontSize: '13px' }}>Nazwa potrzeby:</Text>
              </View>
              <View style={{ width: '75%' }}>
                <Text
                  style={{
                    fontSize: '13px',
                    padding: '5px',
                    borderLeft: '1px solid black',
                    fontFamily: 'Anton',
                  }}
                >
                  {state.clientNeeds.name}
                </Text>
              </View>
            </View>
            <View style={{ backgroundColor: '#E6E6E5', borderBottom: '1px solid black' }}>
              <Text
                style={{
                  fontSize: '13px',
                  padding: '5px',
                }}
              >
                Opis potrzeby Klienta
              </Text>
            </View>
            <View style={{ backgroundColor: 'white', height: '100px' }}>
              <Text
                style={{
                  fontSize: '13px',
                  padding: '5px',
                  fontFamily: 'Anton',
                }}
              >
                {state.clientNeeds.description}
              </Text>
            </View>
            <View style={{ backgroundColor: '#E6E6E5' }}>
              <Text
                style={{
                  fontSize: '13px',
                  padding: '5px',
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                }}
              >
                Uzgodnienia z czatu
              </Text>
            </View>
            <View style={{ backgroundColor: 'white' }}>
              <View
                style={{
                  padding: '20px 5px',
                }}
              >
                <ApkMessages messages={messagesApk} />
              </View>
            </View>
          </View>
          {state.insurances.map((item: any, index: any) => (
            <View key={index} style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
              <View
                style={{
                  backgroundColor: '#E6E6E5',
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid black',
                }}
              >
                <View style={{ width: '40%', padding: '5px', borderRight: '1px solid black' }}>
                  <Text
                    style={{
                      fontSize: '13px',
                    }}
                  >
                    Przedstawiona propozycja nr {index + 1}
                  </Text>
                </View>
                <View style={{ width: '40%', padding: '5px', borderRight: '1px solid black' }}>
                  <Text
                    style={{
                      fontSize: '13px',
                    }}
                  >
                    Zaakceptowana przez Klienta?
                  </Text>
                </View>
                <View style={{ width: '20%', padding: '5px' }}>
                  <Text
                    style={{
                      fontSize: '13px',

                      fontFamily: 'Anton',
                    }}
                  >
                    {item.chosen ? 'tak' : 'nie'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: 'white',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '100%',
                  borderBottom: '1px solid black',
                }}
              >
                <View
                  style={{ width: '25%', height: '100%', display: 'flex', justifyContent: 'center', padding: '5px' }}
                >
                  <Text style={{ fontSize: '13px' }}>Identyfikator:</Text>
                </View>
                <View style={{ width: '25%' }}>
                  <Text
                    style={{
                      fontSize: '13px',
                      fontFamily: 'Anton',
                      padding: '5px',
                      borderLeft: '1px solid black',
                    }}
                  >
                    {item.identification}
                  </Text>
                </View>
                <View
                  style={{
                    width: '25%',
                    padding: '5px',
                    borderLeft: '1px solid black',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '13px',
                    }}
                  >
                    Nazwa:
                  </Text>
                </View>
                <View
                  style={{
                    width: '25%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    borderLeft: '1px solid black',
                    padding: '5px',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '13px',
                      fontFamily: 'Anton',
                    }}
                  >
                    {item.name}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: 'white',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '100%',
                  borderBottom: '1px solid black',
                }}
              >
                <View
                  style={{ width: '25%', height: '100%', display: 'flex', justifyContent: 'center', padding: '5px' }}
                >
                  <Text style={{ fontSize: '13px' }}>Towarzystwo Ubezpieczeń:</Text>
                </View>
                <View
                  style={{
                    width: '25%',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '13px',
                      fontFamily: 'Anton',
                    }}
                  >
                    {item.companyBrand}
                  </Text>
                </View>
                <View
                  style={{
                    width: '25%',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '13px',
                    }}
                  >
                    Data ważności:
                  </Text>
                </View>
                <View
                  style={{
                    width: '25%',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    padding: '5px',
                    borderLeft: '1px solid black',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '13px',
                      fontFamily: 'Anton',
                    }}
                  >
                    {moment(item.expirationDate).format('DD-MM-YYYY')}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                {item.cost.map((itemTwo: any, indexTwo: any) => (
                  <View
                    key={indexTwo}
                    style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid black' }}
                  >
                    <View style={{ width: '25%', justifyContent: 'center', display: 'flex', padding: '5px' }}>
                      <Text style={{ fontSize: '13px' }}>Składka</Text>
                    </View>
                    <View
                      style={{
                        width: '25%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        padding: '5px',
                        borderLeft: '1px solid black',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: '13px',
                          fontFamily: 'Anton',
                        }}
                      >
                        {itemTwo.cost} zł
                      </Text>
                    </View>
                    <View
                      style={{
                        width: '25%',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%',
                        padding: '5px',
                        borderLeft: '1px solid black',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: '13px',
                        }}
                      >
                        Dopisek (liczba rat):
                      </Text>
                    </View>
                    <View
                      style={{
                        width: '25%',
                        display: 'flex',
                        height: '100%',
                        padding: '5px',
                        borderLeft: '1px solid black',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: '13px',
                          fontFamily: 'Anton',
                        }}
                      >
                        {itemTwo.installments}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>

              <View
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <View style={{ padding: '20px 5px', borderBottom: '1px solid black' }}>
                  <Text
                    style={{
                      fontSize: '13px',
                      padding: '0px 5px 20px 5px',
                      fontFamily: 'Anton',
                    }}
                  >
                    {item.description}
                  </Text>
                  <Text
                    style={{
                      fontSize: '13px',
                      padding: '0 5px 5px 5px',
                    }}
                  >
                    Dodane załączniki:
                  </Text>
                  {item.attachments.map((itemThree: any) => (
                    <Text
                      key={index}
                      style={{
                        fontSize: '13px',
                        padding: '2px 5px',
                        fontFamily: 'Anton',
                      }}
                    >
                      {itemThree?.name?.replace(/^[a-f0-9]+-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+\//, '')}
                    </Text>
                  ))}
                </View>
              </View>
            </View>
          ))}
        </View>
        <View style={{ marginTop: '30px' }}>
          <Text style={{ fontSize: '13px' }}>
            Oświadczam, że: zostało mi okazane i zapoznałem się z treścią pełnomocnictwa do zawarcia umowy ubezpieczenia
            w imieniu <Text style={{ fontFamily: 'Anton' }}>{chosenInsurance.companyBrand}</Text> przed zawarciem umowy
            otrzymałem Ogólne Warunki Ubezpieczeń wraz z załącznikami, na podstawie których umowę zawarto oraz
            zapoznałem się z nimi i zaakceptowałem ich treść; dane we wniosku elektronicznym są zgodne z prawdą i podane
            zostały według mojej najlepszej wiedzy. *
          </Text>
        </View>
        <View style={{ marginTop: '30px' }}>
          <Text style={{ fontSize: '13px', fontFamily: 'Anton' }}>
            Oświadczam, że w systemie bezpośredniej sprzedaży na odległość, przed zawarciem umowy ubezpieczenia,
            nastąpiło:
          </Text>
        </View>
        <View style={{ marginTop: '30px' }}>
          <View style={{ margin: '0 20px', fontSize: '13px' }}>
            <Text style={{ textIndent: -10, fontSize: '13px' }}>
              • Oswiadczam, ze zapoznalem sie z polityka informacyjna administratora danych
            </Text>
            <Text style={{ textIndent: -10, fontSize: '13px' }}>
              • Zgoda na otrzymywanie informacji handlowych droga elektroniczna *
            </Text>
            <Text style={{ textIndent: -10, fontSize: '13px' }}>
              • Zgadzam sie na otrzymywanie informacji handlowej od Agenta {check(state.agent.firstName)}{' '}
              {check(state.agent.lastName)}, z wykorzystaniem srodkow porozumiewania sie na odleglosc (telefon, e-mail,
              indywidualne konto internetowe udostępnione przez Doradce) przy uzyciu podanych przeze mnie danych
              kontaktowych.
            </Text>
            <Text style={{ textIndent: -10, fontSize: '13px' }}>
              • Wyrazam zgodę na zawarcie umowy ubezpieczenia na warunkach wskazanych w ofercie przekazanej mi z
              wykorzystaniem srodkow porozumiewania sie na odleglosc. *
            </Text>
          </View>
        </View>
        <View style={{ backgroundColor: '#E6E6E5', marginTop: '30px' }}>
          <Text
            style={{
              fontSize: '13px',
              padding: '5px',
              border: '1px solid black',
            }}
          >
            Elektroniczne potwierdzenie ustaleń zawartych w niniejszej Analizie Potrzeb Klienta
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              borderLeft: '1px solid black',
              borderBottom: '1px solid black',
              borderRight: '1px solid black',
              backgroundColor: 'white',
            }}
          >
            <View
              style={{
                width: '25%',
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                borderRight: '1px solid black',
              }}
            >
              <Text style={{ fontSize: '13px' }}>Data i godzina:</Text>
            </View>
            <View
              style={{
                width: '25%',
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                borderRight: '1px solid black',
              }}
            >
              <Text style={{ fontSize: '13px', fontFamily: 'Anton' }}>17/02/2024, 23:30:00</Text>
            </View>
            <View
              style={{
                width: '25%',
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                borderRight: '1px solid black',
              }}
            >
              <Text style={{ fontSize: '13px' }}>Potwierdzający:</Text>
            </View>
            <View
              style={{
                width: '25%',
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Text style={{ fontSize: '13px', fontFamily: 'Anton' }}>
                {check(state.clientData.clientUser.firstName)} {check(state.clientData.clientUser.lastName)}
              </Text>
              <Text style={{ fontSize: '13px', fontFamily: 'Anton' }}>{check(state.clientData.clientUser.pesel)}</Text>
            </View>
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

export default PDFFile;
