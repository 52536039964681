import { useMutation } from '@tanstack/react-query';
import { acceptOffer } from 'api/client/offer/offerApi';
import { useContext } from 'react';

import { OfferContext } from '../FormContext';

export function useClientOffer() {
  return useContext(OfferContext);
}

export function useAcceptClientOffer() {
  const { offer } = useClientOffer();
  return useMutation(() => {
    if (!offer?.id) throw new Error('No offer provided');
    return acceptOffer(offer.id);
  });
}
