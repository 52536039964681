import { Button, CardHeader, Modal } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useInsuranceMutate } from 'api/agent/insurances/insurancesQuery';
import { uploadFile } from 'api/files/filesApi';
import { FileBody } from 'api/files/types';
import { useFormFiles } from 'library/hooks/useFormFiles';
import { InsuranceFormState } from 'pages/AgentRemoteSale/components/InsuranceBox/types';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { useOffer } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import {
  useDeleteOfferFiles,
  useUploadOfferFile,
} from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOfferFiles';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ModalActions, ModalContainer, ModalContent } from 'styles';
import { fileResultAsFormFile } from 'utils/formFile';
import { isNotNullish } from 'utils/typeHelpers';

import { FormFile } from 'components/Attachments/types';

import { insuranceFormAsInsurance } from '../../utils';
import InsuranceForm from '../InsuranceForm';

interface Props {
  showModal: boolean;
  onClose: () => void;
  insurance?: InsuranceFormState;
}

function InsuranceFormModal({ showModal, onClose, insurance }: Props) {
  const { offer } = useOffer();
  const { onUpdate, state } = useAgentForm();
  const { t } = useTranslation();
  const { mutate, isLoading } = useInsuranceMutate(insurance?.id);

  const formFilesState = useFormFiles({ tempStored: !insurance?.id });
  const { mutate: deleteFormFiles } = useDeleteOfferFiles();
  const { mutateAsync: uploadFiles, isLoading: isUploading } = useUploadOfferFile({ insuranceId: insurance?.id });
  const { mutateAsync: uploadFilesForNewInsurance, isLoading: isUploadingTempFiles } = useMutation((body: FileBody) =>
    uploadFile(body),
  );

  const handleSubmit = (formState: InsuranceFormState) => {
    if (!offer) return;

    const body = insuranceFormAsInsurance(formState, offer.id);

    const updatedData = {
      ...body,
      offer: offer.id,
      cost: body.cost.map((costItem: any) => ({
        ...costItem,
        cost: typeof costItem.cost === 'string' ? Number(costItem.cost.replace(/[^\d]/g, '')) : costItem.cost,
        installments: costItem.installments,
      })),
    };

    const checkFields = formState?.cost.find((item: any) => item.const === 0 || item.installments === '');

    if (checkFields !== undefined) {
      return toast.error('pola składek nie mogą być puste');
    }

    function areValuesUnique(array: any, key: any) {
      const seen = new Set();
      return !array.some((item: any) => {
        const value = item[key];
        return seen.has(value) || !seen.add(value);
      });
    }

    if (areValuesUnique(formState.cost, 'cost') && areValuesUnique(formState.cost, 'installments')) {
      mutate(updatedData, {
        onSuccess: async (result) => {
          const newState = { ...formState, id: result.id };
          try {
            if (insurance?.id) {
              const filteredInsurances = state.insurances?.filter((insurance) => insurance.id !== result.id);

              onUpdate({
                insurances: filteredInsurances ? [...filteredInsurances, newState] : [newState],
              });
              deleteFormFiles(formFilesState.filesToDelete);
            } else {
              let attachments: FormFile[] = [];
              if (formState.attachments) {
                const filesResults = await Promise.all(
                  formState.attachments?.map((tempFile) => {
                    if (tempFile.file)
                      return uploadFilesForNewInsurance({ file: tempFile.file, insuranceId: result.id });
                  }),
                );

                attachments = filesResults.filter(isNotNullish).map(fileResultAsFormFile);
              }

              onUpdate({
                insurances: state.insurances
                  ? [...state.insurances, { ...newState, attachments }]
                  : [{ ...newState, attachments }],
              });
            }
            handleClose();
          } catch (error) {
            toast(t('errors.cantUploadThisFiles'));
          }
        },
      });
    } else {
      toast.error('Propozycja składki lub liczba rat nie może być taka sama');
    }
  };

  const handleCancelUpdate = () => {
    if (insurance?.id) {
      deleteFormFiles(formFilesState.addedFiles);
    }
    handleClose();
  };

  const handleClose = () => {
    formFilesState.onClear();
    onClose();
  };

  return (
    <Modal open={showModal} onClose={handleClose}>
      <ModalContent>
        <CardHeader
          title={
            insurance
              ? t('insuranceWizard.propositionsOfInsurances.editProposition')
              : t('insuranceWizard.propositionsOfInsurances.addNewProposition')
          }
        />
        <ModalContainer $withActions>
          <InsuranceForm
            onSubmit={handleSubmit}
            initialState={insurance}
            onUpload={insurance?.id ? uploadFiles : undefined}
            onAddFile={insurance?.id ? formFilesState.onAddFile : undefined}
            onDeleteFile={insurance?.id ? formFilesState.onDeleteFile : undefined}
          >
            <ModalActions>
              <Button disabled={isLoading || isUploading || isUploadingTempFiles} onClick={handleCancelUpdate}>
                {t('common.cancel')}
              </Button>
              <Button variant="contained" type="submit" disabled={isLoading || isUploading || isUploadingTempFiles}>
                {t('common.save')}
              </Button>
            </ModalActions>
          </InsuranceForm>
        </ModalContainer>
      </ModalContent>
    </Modal>
  );
}

export default InsuranceFormModal;
