import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { createOffer } from 'api/agent/offer/offerApi';
import { Client } from 'api/client/types';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import ClientSearch from 'pages/AgentRemoteSale/components/ClientSearch';
import { ClientTypes } from 'pages/AgentRemoteSale/components/ClientSearch/types';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { SelectedClient } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import { FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import * as S from './styles';

function ClientSearchStep() {
  const { t } = useTranslation();
  const { goTo } = useWizard();
  const { setClientForId, clientForId } = useAgentForm();
  const [client, setClient] = useState<Client | undefined>();
  const [clientType, setClientType] = useState<ClientTypes | undefined>();

  const createOfferMutation = useMutation(createOffer, {
    onSuccess: (offer) => {
      localStorage.setItem('selected_client_id', offer?.id);
      goTo(1, 1);
    },
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await createOfferMutation.mutateAsync({ client: clientForId });
    } catch (error) {
      toast(t('errors.api.serverError'));
    }
  };

  const handleSelectSearchOption = (client: Client | undefined, type: ClientTypes) => {
    setClientForId(client?.id);
    setClient(client);
    setClientType(type);
  };

  const selected = useMemo<SelectedClient | undefined>(
    () =>
      client && clientType
        ? {
            id: client.id,
            type: clientType,
          }
        : undefined,
    [client, clientType],
  );

  return (
    <S.Content variant="outlined">
      <form onSubmit={handleSubmit}>
        <S.SearchContent>
          <ClientSearch onSelect={handleSelectSearchOption} selected={selected} />
        </S.SearchContent>
        <S.Submit>
          <Button type="submit" variant="contained" disabled={!client || !clientType}>
            {t('common.next')}
          </Button>
        </S.Submit>
      </form>
    </S.Content>
  );
}

export default ClientSearchStep;
