import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import 'library/language/i18n';
import 'library/language/validation/yupTranslations';
import theme from 'library/theme/theme';
import { Settings } from 'luxon';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from 'routes';

import Layout from 'components/Layout';

import './external/mui-toastify.css';
import './library/axios/apiProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const errorHandler = (error: unknown) => {
  if (process.env.NODE_ENV !== 'development') return;
  // catch non-axios errors
  if (error instanceof Error && !axios.isAxiosError(error)) {
    console.error(error);
  }
};

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
  queryCache: new QueryCache({
    onError: errorHandler,
  }),
  mutationCache: new MutationCache({
    onError: errorHandler,
  }),
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={Settings.defaultLocale}>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
          <ToastContainer
            position="bottom-right"
            autoClose={4000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            limit={3}
          />
          <CssBaseline />
          <Layout>
            <Router />
          </Layout>
        </LocalizationProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
