import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Card, CardActions, CardHeader, Collapse, Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

interface Props {
  children: React.ReactNode;
  title: string;
  onEdit: () => void;
  defaultOpen?: boolean;
  readonly?: boolean;
}

function EditorInfoBox({ children, onEdit, title, defaultOpen = false, readonly }: Props) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(defaultOpen);

  const handleExpandClick = () => setExpanded((prev) => !prev);

  return (
    <Card variant="outlined" style={{ width: '100%' }}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'body1', fontWeight: 'bold', textTransform: 'capitalize' }}
        action={
          <S.ExpandMore expand={expanded} onClick={handleExpandClick}>
            <ExpandMoreIcon />
          </S.ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <S.Content>{children}</S.Content>
        {!readonly && (
          <CardActions>
            <Grid container justifyContent="flex-end">
              <Button startIcon={<EditIcon />} onClick={onEdit} size="small">
                {t('common.edit')}
              </Button>
            </Grid>
          </CardActions>
        )}
      </Collapse>
    </Card>
  );
}
export default EditorInfoBox;
