import { Box, Modal, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Client } from 'api/client/types';
import { useAgent } from 'contexts/AgentAuthContext/hooks';
import useModal from 'library/hooks/useModal';
import { SelectedClient } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ClientAutocomplete from './components/ClientAutocomplete';
import ClientCreation from './components/ClientCreation';
import { ClientSearchOption, ClientTypes } from './types';
import { filterClients, isAddOption } from './utils';

interface Props {
  onSelect: (option: Client | undefined, type: ClientTypes) => void;
  selected?: SelectedClient;
  size?: 'small' | 'medium';
  searchLabel?: string;
}

function ClientSearch({ onSelect, selected, size = 'small', searchLabel }: Props) {
  const { t } = useTranslation();
  const agent = useAgent();

  const [selectedClientType, setSelectedClientType] = useState<ClientTypes>(
    selected?.type || ClientTypes.individualPerson,
  );
  const { open: creationModal, handleOpen: handleOpenCreation, handleClose: handleCloseCreation } = useModal();
  const [initialCreationValue, setInitialCreationValue] = useState('');

  const handleSelectClientType = (_: React.MouseEvent<HTMLElement, MouseEvent>, value: ClientTypes | null) => {
    if (value) {
      setSelectedClientType(value);
    }
  };

  const handleSelectAutocompleteOption = (selected: ClientSearchOption) => {
    if (typeof selected !== 'string') {
      if (!isAddOption(selected)) return onSelect(selected, selectedClientType);

      setInitialCreationValue(selected.value);
      handleOpenCreation();
    }
  };

  const handleCancelClientCreation = () => {
    handleCloseCreation();
    setInitialCreationValue('');
  };

  const filteredOptions = useMemo(
    () => filterClients(agent?.clients || [], selectedClientType),
    [agent?.clients, selectedClientType],
  );
  const selectedOption = useMemo<ClientSearchOption | null>(() => {
    if (!selected) return null;
    return filteredOptions?.find((c) => c.id === selected.id) || null;
  }, [filteredOptions, selected]);

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={selectedClientType}
        exclusive
        fullWidth
        size={size}
        onChange={handleSelectClientType}
      >
        <ToggleButton value={ClientTypes.individualPerson} aria-label="individual person">
          {t('insuranceWizard.clientSearch.individualPerson')}
        </ToggleButton>
        <ToggleButton value={ClientTypes.legalPerson} aria-label="client person">
          {t('insuranceWizard.clientSearch.legalPerson')}
        </ToggleButton>
      </ToggleButtonGroup>
      <ClientAutocomplete
        onSelect={handleSelectAutocompleteOption}
        size={size}
        data={filteredOptions || []}
        value={selectedOption}
        selectedClientType={selectedClientType}
        label={searchLabel}
      />
      <Modal open={creationModal} onClose={handleCancelClientCreation}>
        <Box>
          <ClientCreation
            type={selectedClientType}
            onCancel={handleCancelClientCreation}
            initialValue={initialCreationValue}
            filteredOptions={filteredOptions}
          />
        </Box>
      </Modal>
    </>
  );
}

export default ClientSearch;
