import theme from 'library/theme/theme';
import styled from 'styled-components';

export const TilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: 300px;
  width: 600px;
  color: ${theme.palette.primary.main};
  @media (max-width: 600px) {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }
  svg {
    font-size: 62px;
    @media (max-width: 600px) {
      widht: 30px;
      height: 30px;
    }
  }
`;
