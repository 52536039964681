import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Summary = styled.div`
  width: 45%;
  min-width: 200px;
  border-top: 1px solid ${theme.palette.grey[900]};
  padding-top: ${theme.spacing(1)};
  margin: ${theme.spacing(2)} 0;
  margin-left: auto;
`;

export const RefreshContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
