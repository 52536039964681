import { AxiosError, AxiosInstance } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { t } from 'i18next';
import { toast } from 'react-toastify';

import { getErrorMessage } from './helpers/apiHelpers';

const DEFAULT_BASE_URL = 'https://' + window.location.host;

// class to setup the axios instance with all must-have configuration
// the configuration should contain the minimal code required to work with api
export class InstanceService {
  constructor(protected readonly _axios: AxiosInstance) {
    if (process.env.NODE_ENV !== 'development') {
      _axios.defaults.baseURL = DEFAULT_BASE_URL;
    }
    _axios.defaults.withCredentials = false;
    _axios.defaults.headers.post['Content-Type'] = 'application/json';

    // each request should be camelized
    _axios.interceptors.response.use((response) => {
      if (response.data && response.headers['content-type'] === 'application/json') {
        response.data = camelizeKeys(response.data);
      }
      return response;
    });
    _axios.interceptors.request.use((config) => {
      const newConfig = { ...config };

      if (newConfig.headers?.['Content-Type'] === 'multipart/form-data') return newConfig;
      if (config.params) {
        newConfig.params = decamelizeKeys(config.params);
      }
      if (config.data) {
        newConfig.data = decamelizeKeys(config.data);
      }
      return newConfig;
    });

    // each request should have error handler
    _axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        const status = error.response?.status;

        if (error.config && error.config.url === '/auth/token/refresh/' && status === 401) {
          localStorage.removeItem('refresh');
          toast(t('errors.api.sessionExpired') as string, {
            toastId: 'api-response',
            type: 'warning',
          });
        }

        const msg = getErrorMessage(status);
        if (!msg.length) throw error;
        toast(msg, {
          toastId: 'api-response',
          type: 'error',
        });

        throw error;
      },
    );
  }

  public get axios() {
    return this._axios;
  }
}
