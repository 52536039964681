import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import config from 'config';
import useBrand from 'library/hooks/useBrand';
import { DateTime } from 'luxon';
import { InsuranceFormState } from 'pages/AgentRemoteSale/components/InsuranceBox/types';
import { useTranslation } from 'react-i18next';

import DownloadList from 'components/DownloadList';

import * as S from './styles';

interface Props {
  insurance: InsuranceFormState;
  sentAt?: Date;
  seenAt?: Date | null;
  total?: any;
}

function InsuranceSummary({ insurance, sentAt, seenAt, total }: Props) {
  const { t } = useTranslation();
  const insuranceListItem = useBrand(insurance.companyBrand);

  const isValidDate = (date: any) => {
    return DateTime.fromJSDate(date).isValid;
  };

  return (
    <Paper variant="outlined">
      <Box padding={2}>
        <Grid container paddingBottom={5} spacing={1}>
          <Grid item xs={12} md={4}>
            {sentAt && (
              <S.DatesLabelValue label={t('insuranceWizard.clientOffer.sentByAgent')}>
                {DateTime.fromJSDate(sentAt).toFormat(config.DATE_TIME_FORMAT_LONG)}
              </S.DatesLabelValue>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <S.DatesLabelValue label={t('insuranceWizard.clientOffer.expirationDate')} align="center">
              {isValidDate(insurance.expirationDate)
                ? DateTime.fromJSDate(insurance.expirationDate).toFormat(config.DATE_FORMAT_SHORT)
                : insurance.expirationDate}
            </S.DatesLabelValue>
          </Grid>
          <Grid item xs={12} md={4}>
            {seenAt && (
              <S.DatesLabelValue label={t('insuranceWizard.clientOffer.seenByClient')} align="right">
                {seenAt ? DateTime.fromJSDate(seenAt).toFormat(config.DATE_TIME_FORMAT_LONG) : '-'}
              </S.DatesLabelValue>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <S.ImageContent src={insuranceListItem?.img} alt="insurance brand" />
          <Grid item md xs marginTop={2}>
            <Typography variant="subtitle1" fontWeight="bold">
              {insurance.identification}
            </Typography>
            <Typography>{insurance.name}</Typography>
            <Box paddingTop={1}>
              <Typography variant="body2">{insurance.description}</Typography>
            </Box>
            <Box paddingTop={3}>
              {insurance?.attachments && insurance?.attachments.length ? (
                <DownloadList files={insurance.attachments} />
              ) : (
                <S.FilesText>({t('insuranceWizard.clientOffer.noAttachments')})</S.FilesText>
              )}
            </Box>
          </Grid>
          <Grid item md={3} xs={12} marginTop={2}>
            <Typography variant="h6" align="right">
              {total.cost} PLN
            </Typography>
            <Typography align="right">{insurance.costNotes}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
export default InsuranceSummary;
