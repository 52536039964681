import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { errorMessage } from 'library/language/validation/errorMessage';
import { InputHTMLAttributes, useState } from 'react';
import { FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  useVisibilitySwitch?: boolean;
  inputProps?: Omit<TextFieldProps, 'type'>;
}

function ControlledPassword<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ label, inputProps, useVisibilitySwitch, ...controllerProps }: Props<TFieldValues, TName>) {
  const { field, fieldState } = useController(controllerProps);
  const [type, setType] = useState<InputHTMLAttributes<unknown>['type']>('password');
  const { t } = useTranslation();

  const handleSwitchType = () =>
    setType((prev) => {
      if (prev === 'password') return 'text';
      return 'password';
    });

  return (
    <TextField
      {...field}
      helperText={errorMessage(t, fieldState.error?.message)}
      error={!!fieldState.error}
      fullWidth
      type={type}
      label={label}
      {...inputProps}
      InputProps={
        !useVisibilitySwitch
          ? inputProps?.InputProps || {}
          : {
              ...(inputProps?.InputProps || {}),
              endAdornment: (
                <IconButton onClick={handleSwitchType} tabIndex={-1}>
                  {type === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }
      }
    />
  );
}

export default ControlledPassword;
