import Button from '@mui/material/Button';
import theme from 'library/theme/theme';
import styled from 'styled-components';
import { FormContent } from 'styles/FormContent';

export const FormContentWithSpacing = styled(FormContent)`
  display: flex;
  flex-direction: column;
`;

export const SignButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-top: ${theme.spacing(2)};
`;

export const Box = styled.div`
  margin: 40px 0 10px 0;
  font-weight: 1000;
`;
