/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-empty-function */
import { useOfferQuery } from 'api/agent/offer/offerQuery';
import { Offer } from 'api/agent/offer/types';
import { usePolicyQuery } from 'api/agent/policy/policyQuery';
import { useAgent } from 'contexts/AgentAuthContext/hooks';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import { policyAsPolicyForm } from 'pages/AgentRemoteSale/steps/Policy/components/PolicyForm/utils';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { useParams } from 'react-router-dom';

import { FormState } from './types';

interface FormContextProps {
  state: FormState;
  setState: any;
  onUpdate: (newState: Partial<FormState>) => void;
  clientForId: any;
  setClientForId: any;
  reloadOfferQuery: any;
  setReloadOfferQuery: any;
  emailClient: any;
  setEmailClient: any;
  apk: any;
  setApk: any;
}

export const FormContext = createContext<FormContextProps>({
  state: {},
  setState: () => {},
  onUpdate: () => {},
  clientForId: '',
  setClientForId: () => {},
  reloadOfferQuery: false,
  setReloadOfferQuery: () => {},
  emailClient: '',
  setEmailClient: () => {},
  apk: null,
  setApk: () => {},
});

interface OfferContextProps {
  loading: boolean;
  offer: Offer | undefined;
  onUpdateInvitationSent: (newValue: boolean) => void;
  invitationSent: boolean;
  onUpdate: (offerState: Offer) => void;
}

export const OfferContext = createContext<OfferContextProps>({
  offer: undefined,
  loading: false,
  onUpdateInvitationSent: () => {},
  invitationSent: false,
  onUpdate: () => {},
});

interface Props {
  children: React.ReactNode;
}

function FormContextProvider({ children }: Props) {
  const clientId = localStorage.getItem('selected_client_id');

  const [state, setState] = useState<FormState>({});
  const wizard = useWizard();
  const [currentOffer, setCurrentOffer] = useState<any>();
  const [invitationSent, setInvitationSent] = useState(false);
  const [clientForId, setClientForId] = useState<any>();
  const [reloadOfferQuery, setReloadOfferQuery] = useState(false);
  const [emailClient, setEmailClient] = useState<any>('');
  const [apk, setApk] = useState<any>(null);

  const agent = useAgent();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      localStorage.setItem('selected_client_id', id);
    }
  }, [id]);

  const { isFetching: isOfferLoading } = useOfferQuery(clientId || '', {
    onSuccess: (offerData) => {
      flushSync(() => {
        const matchingId = offerData.results.find((item: any) => item.id === clientId);

        setEmailClient(matchingId?.email);
        if (matchingId) {
          const clientData = agent?.clients.find((item) => item.id === matchingId.client);

          const nowaTablica1: any = {
            clientData: {
              ...clientData,
              attachments: matchingId?.files,
              apk: matchingId.apk,
              agreements: {
                privacyPolicy: clientData?.agreements ?? false,
                marketingInformationThroughElectronic: clientData?.agreements ?? false,
                marketingInformationThroughOFWCA: clientData?.agreements ?? false,
                contractConclusion: clientData?.agreements ?? false,
              },
            },
            selectedClient: { id: clientData?.id, type: '' },
            clientNeeds: {
              description: matchingId?.needsDescription,
              insuranceType: matchingId?.insuranceType,
              name: matchingId?.needsShort,
              startDate: matchingId?.startDate,
            },
            offerId: matchingId?.id,
            insurances: matchingId.insurance.map((insurances: any) => ({
              ...insurances,
              attachments: insurances.files,
            })),
          };

          console.log(offerData, ' offerData 555');

          setState(nowaTablica1);
          setCurrentOffer({ ...clientData, id: matchingId?.id, policy: matchingId?.policy });
          wizard.goTo(matchingId.stage + 1, matchingId.stage + 1);
        }
      });
    },
  });

  const { isFetching: isPolicyLoading } = usePolicyQuery(currentOffer?.policy || '', {
    onSuccess(policy) {
      flushSync(() => {
        handleUpdate({ policy: policyAsPolicyForm(policy) });
      });
    },
  });

  const handleUpdate = useCallback((newState: Partial<FormState>) => {
    setState((prev) => ({ ...prev, ...newState }));
  }, []);

  return (
    <FormContext.Provider
      value={useMemo(
        () => ({
          state,
          setState,
          onUpdate: handleUpdate,
          clientForId: clientForId,
          setClientForId: setClientForId,
          setReloadOfferQuery: setReloadOfferQuery,
          reloadOfferQuery: reloadOfferQuery,
          emailClient: emailClient,
          setEmailClient: setEmailClient,
          apk: apk,
          setApk: setApk,
        }),
        [
          handleUpdate,
          state,
          setState,
          clientForId,
          setClientForId,
          reloadOfferQuery,
          setReloadOfferQuery,
          emailClient,
          setEmailClient,
          apk,
          setApk,
        ],
      )}
    >
      <OfferContext.Provider
        value={useMemo(
          () => ({
            offer: currentOffer,
            loading: isOfferLoading || isPolicyLoading,
            invitationSent,
            onUpdateInvitationSent: setInvitationSent,
            onUpdate: setCurrentOffer,
          }),
          [currentOffer, invitationSent, isOfferLoading, isPolicyLoading],
        )}
      >
        {children}
      </OfferContext.Provider>
    </FormContext.Provider>
  );
}
export default FormContextProvider;
