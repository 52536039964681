import { refreshToken } from 'api/auth/authApi';
import { AgentAuthResponse } from 'api/auth/types';
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

export type Tokens = Pick<AgentAuthResponse, 'accessToken' | 'refreshToken'>;

export function withRefreshToken(deAuthorize: () => void, storedRefreshToken: string, axios: AxiosInstance) {
  let _isErrorOnRefresh = false;
  let _queryQueue: { resolve: (value: unknown) => void; reject: (reason?: any) => void }[] = [];
  const processQueue = (error: any, token: string | null = null) => {
    _queryQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    _queryQueue = [];
  };

  const refreshTokenWithQueue = async (originalRequest: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    try {
      const refreshResult = await refreshToken(storedRefreshToken);
      // currently headers not working
      // if (originalRequest.headers) originalRequest.headers['Authorization'] = `Bearer ${refreshResult.access}`;
      processQueue(null, refreshResult.access);
      return originalRequest;
    } catch (err) {
      processQueue(err, null);
      deAuthorize();
      throw err;
    }
  };

  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && originalRequest && originalRequest.url !== '/auth/token/refresh/') {
        if (_isErrorOnRefresh) {
          return new Promise((resolve, reject) => {
            _queryQueue.push({ resolve, reject });
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          }).then((token) => {
            // currently headers not working
            // if (originalRequest.headers) originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return originalRequest ? axios(originalRequest) : null;
          });
        }

        _isErrorOnRefresh = true;

        return new Promise((resolve, reject) => {
          refreshTokenWithQueue(originalRequest)
            .then((reqConfig) => {
              resolve(axios(reqConfig));
            })
            .catch((err) => {
              reject(err);
            })
            .finally(() => {
              _isErrorOnRefresh = false;
            });
        });
      }

      return Promise.reject(error);
    },
  );
}
