import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosQueryOptions } from 'api/types';

import { AxiosMutationOptions } from './../../types';
import { getClientOffer, updateOfferStage } from './offerApi';
import { ClientOffer, NextStage } from './types';

const OFFER_QUERY = ['client-offer'];
export function useClientOfferQuery(options?: AxiosQueryOptions<ClientOffer>) {
  return useQuery(OFFER_QUERY, getClientOffer, options);
}

export function useStepMutation(options?: AxiosMutationOptions<NextStage, undefined>) {
  return useMutation(() => updateOfferStage(), options);
}
