import * as S from './styles';

interface Props {
  children: React.ReactNode;
}

function Layout({ children }: Props) {
  return <S.Content>{children}</S.Content>;
}

export default Layout;
