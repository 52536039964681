import { useMessageMutation, useMessagesQuery } from 'api/agent/messages/messagesQuery';
import { Message } from 'api/messages/types';
import { AxiosQueryOptions, PaginatedResponse } from 'api/types';
import { useOffer } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';

export function useOfferMessages(options?: AxiosQueryOptions<PaginatedResponse<Message>>) {
  const { offer } = useOffer();

  if (!offer) throw new Error('No offer provided');

  return useMessagesQuery(offer.id, options);
}

export function useUpdateMessages() {
  const { offer } = useOffer();

  if (!offer) throw new Error('No offer provided');

  return useMessageMutation(offer.id);
}
