/* eslint-disable react-hooks/exhaustive-deps */
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import InsuranceBox from 'pages/AgentRemoteSale/components/InsuranceBox';
import { InsuranceFormState } from 'pages/AgentRemoteSale/components/InsuranceBox/types';
import { ChangeEvent, useEffect, useState } from 'react';

import * as S from './styles';

interface Props {
  insurances: InsuranceFormState[];
  onSelect: (insurance: InsuranceFormState) => void;
  insurancesState: any;
  setInsurancesState: any;
  setFoundObject: any;
}

function InsuranceList({ insurances, onSelect, insurancesState, setInsurancesState, setFoundObject }: Props) {
  const [selectedInsuranceId, setSelectedInsurance] = useState(insurances.find((insurance) => insurance.chosen)?.id);

  const handleSelect = (_: ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedInsurance = insurances.find((insurance) => insurance.id === id);

    if (!selectedInsurance) return;

    setSelectedInsurance(id);
    onSelect(selectedInsurance);
  };

  useEffect(() => {
    const foundObject = insurancesState.find((item: any) => item.id === selectedInsuranceId);
    setFoundObject(foundObject);
  }, [insurancesState, selectedInsuranceId]);

  //const reversedInsurances = [...insurances].reverse();
  return (
    <S.Control>
      <RadioGroup name="insurance-type-group" onChange={handleSelect}>
        {insurancesState.map((insurance: any, index: any) => (
          <FormControlLabel
            key={insurance.id}
            value={insurance.id}
            control={<Radio />}
            checked={selectedInsuranceId === insurance.id}
            label={
              <InsuranceBox
                insurance={insurance}
                index={index}
                insurancesState={insurancesState}
                setInsurancesState={setInsurancesState}
              />
            }
          />
        ))}
      </RadioGroup>
    </S.Control>
  );
}

export default InsuranceList;
