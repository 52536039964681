import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

function ClientUnauthorized() {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '120px', width: '100%' }}>
      <Alert severity="info" style={{ width: '100%', paddingLeft: '40px' }}>
        <Typography variant="h6">{t('errors.youHaveBeenSignedOut')}</Typography>
        <Typography>{t('errors.signInAgain')}</Typography>
      </Alert>
    </div>
  );
}
export default ClientUnauthorized;
