import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import theme from 'library/theme/theme';

import WizardLoadingView from 'components/WizardLoadingView';

import * as S from './styles';

function Confirmation({ handleSubmit, isConfirmationModalOpen, closeConfirmationModal, isLoading }: any) {
  return (
    <S.Wrapper>
      <Dialog
        open={isConfirmationModalOpen}
        onClose={closeConfirmationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Czy na pewno chcesz dodać nową polisę ?</DialogTitle>
        {!isLoading ? (
          <DialogActions>
            <S.ButtonWrapper>
              <S.ConfirmationButton color={theme.palette.primary.main} onClick={closeConfirmationModal}>
                Nie
              </S.ConfirmationButton>
              <S.ConfirmationButton color="firebrick" onClick={handleSubmit} autoFocus>
                Tak
              </S.ConfirmationButton>
            </S.ButtonWrapper>
          </DialogActions>
        ) : (
          <div style={{ position: 'relative', height: '62px' }}>
            <WizardLoadingView height="45px" />
          </div>
        )}
      </Dialog>
    </S.Wrapper>
  );
}

export default Confirmation;
