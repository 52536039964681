import theme from 'library/theme/theme';
import styled from 'styled-components';

interface WrapProps {
  flex: string;
  align?: string;
  justify?: string;
}

interface BoxWrapProps {
  responsive: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 5px;
  @media (max-width: 1400px) {
    width: 100%;
    padding: 10px;
    margin: 0 auto;
  }
  @media (max-width: 1000px) {
    height: 770px;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  overflow: auto;
  padding: 0 15px;
  @media (max-width: 1400px) {
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
    justify-content: space-between;
    border-left: 1px solid ${theme.palette.grey[300]};
    border-top: 1px solid ${theme.palette.grey[300]};
    border-right: 1px solid ${theme.palette.grey[300]};
  }
`;

export const ContainerTwo = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  overflow: auto;
  padding: 0 15px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #e5f6fd;
  }
  @media (max-width: 1400px) {
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
    justify-content: space-between;
    border-left: 1px solid ${theme.palette.grey[300]};
    border-top: 1px solid ${theme.palette.grey[300]};
    border-right: 1px solid ${theme.palette.grey[300]};
  }
`;

export const CheckBox = styled.div`
  @media (max-width: 1400px) {
    position: absolute;
    left: -9px;
    top: -45px;
  }
`;

export const MiniContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  @media (max-width: 1400px) {
    padding: 5px;
    align-items: initial;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  @media (max-width: 1400px) {
    margin-bottom: 30px;
  }
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  box-shadow: 1px 1px 3px 1px #888888;
  padding: 20px;
  font-weight: 700;
  color: grey;
  margin: 0;
  width: 100%;
`;

export const Name = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  color: black;
  margin-left: 15px;
  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const Views = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  width: 100%;
  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  font-size: 20px;
  padding: 20px 0;
  margin-top: 25px;
  width: 100%;
  @media (max-width: 1400px) {
    padding: 0;
    justify-content: center;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const BoxWrap = styled.div<BoxWrapProps>`
  display: flex;
  align-items: center;
  flex: wrap;
  width: 50%;
  @media (max-width: 1400px) {
    flex-direction: ${(props) => (props.responsive ? 'column' : 'row')};
    align-items: ${(props) => (props.responsive ? 'flex-end' : 'flex-start')};
    width: initial;
    margin: 0;
  }
`;

export const Wrap = styled.div<WrapProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify};
  color: ${(props) => props.color};
  font-weight: ${(props) => (props.color === 'grey' ? '700' : '500')};
  font-family: 'Lato', sans-serif;
  flex: ${(props) => props.flex};
  margin: 0 5px;
  height: 50px;
  @media (max-width: 1400px) {
    flex: initial;
    height: initial;
  }
`;

export const Paragraph = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0 2px;
  color: ${(props) => props.color};
  @media (max-width: 1000px) {
    font-size: 11px;
  }
`;

export const ParagraphIcon = styled.p`
  font-size: 14px;
  margin: 0;
  margin-left: 10px;
  padding: 0 2px;
  color: ${(props) => props.color};
  @media (max-width: 1000px) {
    font-size: 11px;
  }
`;

export const Status = styled.div`
  @media (max-width: 1400px) {
    display: flex, 
    flexDirection: column, 
    align-items: flex-end
  }
`;

export const Image = styled.img`
  width: 50px;
`;
