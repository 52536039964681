import { Button, Switch, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getPolicies } from 'api/agent/policy/policyApi';
import { useState } from 'react';

import LoadingView from 'components/LoadingView';

import * as S from './styles';

function Payment({ agentData, subscriptionData }: { agentData: any; subscriptionData: any }) {
  const { isLoading } = useQuery<any>(['policies'], getPolicies);

  const [copyFromAdmin, setCopyFromAdmin] = useState<boolean>(false);
  const [dataAdministrator, setDataAdministrator] = useState<any>({
    apartmentNumber: '',
    city: '',
    houseNumber: '',
    name: '',
    postalCode: '',
    street: '',
    taxId: '',
  });

  if (isLoading) {
    return <LoadingView />;
  }

  const handleSwitchChange = () => {
    setCopyFromAdmin((prevValue: any) => !prevValue);

    if (!copyFromAdmin) {
      setDataAdministrator({
        apartmentNumber: agentData?.dataAdministrator?.apartmentNumber || '',
        city: agentData?.dataAdministrator?.city || '',
        houseNumber: agentData?.dataAdministrator?.houseNumber || '',
        name: agentData?.dataAdministrator?.name || '',
        postalCode: agentData?.dataAdministrator?.postalCode || '',
        street: agentData?.dataAdministrator?.street || '',
        taxId: agentData?.dataAdministrator?.taxId || '',
      });
    } else {
      setDataAdministrator({
        apartmentNumber: '',
        city: '',
        houseNumber: '',
        name: '',
        postalCode: '',
        street: '',
        taxId: '',
      });
    }
  };

  const handleTextFieldChange =
    (fieldName: keyof typeof dataAdministrator) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setDataAdministrator((prevData: any) => ({
        ...prevData,
        [fieldName]: event.target.value,
      }));
    };

  return (
    <S.Main>
      <Typography variant="body1" fontWeight="bolder" fontSize={16}>
        Dane do faktury
      </Typography>
      <S.Box gap={10}>
        <Switch checked={copyFromAdmin} onChange={handleSwitchChange} />
        <Typography variant="body1" fontWeight="bold" fontSize={16}>
          skopiuj z danych Administratora
        </Typography>
      </S.Box>
      <S.Box gap={50}>
        <TextField
          variant="outlined"
          fullWidth
          value={dataAdministrator?.name}
          placeholder="nazwa"
          onChange={handleTextFieldChange('name')}
        />
        <TextField
          variant="outlined"
          fullWidth
          value={dataAdministrator?.taxId}
          placeholder="nip"
          onChange={handleTextFieldChange('taxId')}
        />
      </S.Box>
      <S.Box gap={50}>
        <TextField
          variant="outlined"
          fullWidth
          value={dataAdministrator?.postalCode}
          placeholder="kod pocztowy"
          onChange={handleTextFieldChange('postalCode')}
        />
        <TextField
          variant="outlined"
          fullWidth
          value={dataAdministrator?.city}
          placeholder="miasto"
          onChange={handleTextFieldChange('city')}
        />
      </S.Box>
      <S.Box gap={50}>
        <S.Box gap={50}>
          <TextField
            variant="outlined"
            fullWidth
            value={dataAdministrator?.street}
            placeholder="ulica"
            onChange={handleTextFieldChange('street')}
          />
        </S.Box>
        <S.Box gap={50}>
          <TextField
            variant="outlined"
            fullWidth
            value={dataAdministrator?.apartmentNumber}
            placeholder="numer apartamentu"
            onChange={handleTextFieldChange('apartmentNumber')}
          />
          <TextField
            variant="outlined"
            fullWidth
            value={dataAdministrator?.houseNumber}
            placeholder="numer budynku"
            onChange={handleTextFieldChange('houseNumber')}
          />
        </S.Box>
      </S.Box>
      {subscriptionData ? (
        <div>
          <S.Box>
            <Typography variant="body1" fontWeight="bolder" fontSize={16}>
              Aplikacja opłacona do
            </Typography>
            &nbsp;&nbsp;
            <S.Text>{subscriptionData?.expirationDate}</S.Text>
          </S.Box>
          <S.Box>
            <Typography variant="body1" fontWeight="bolder" fontSize={16}>
              Polis: {subscriptionData?.policiesCount} z limitu {subscriptionData.plan.policiesLimit}
            </Typography>
          </S.Box>
        </div>
      ) : null}
      <S.Wrapper>
        <S.Box gap={20}>
          <Button variant="contained">KUP 4agenta</Button>
        </S.Box>
      </S.Wrapper>
    </S.Main>
  );
}

export default Payment;
