import { Client } from 'api/client/types';
import { isIndividualClient, isLegalClient } from 'utils/clientTypes';

import { AddOption, ClientSearchOption, ClientTypes } from './types';

export function isAddOption(option: ClientSearchOption): option is AddOption {
  return 'type' in option && option.type === 'add';
}

export function filterClients(clients: Client[], type: ClientTypes) {
  if (type === 'legalPerson') return clients.filter(isLegalClient);
  if (type === 'individualPerson') return clients.filter(isIndividualClient);
}
