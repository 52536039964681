import Typography from '@mui/material/Typography';
import { To } from 'react-router-dom';

import Button from '../../../../components/Button';
import * as S from './styles';

interface Props {
  icon: React.ReactNode;
  label: string;
  link: To;
  disabled?: boolean;
}

function Tile({ icon, label, link }: Props) {
  return (
    <S.Box>
      <Button>
        <S.LinkElement to={link}>
          {icon}
          <Typography fontWeight="bold">{label}</Typography>
        </S.LinkElement>
      </Button>
    </S.Box>
  );
}

export default Tile;
