import theme from 'library/theme/theme';
import styled from 'styled-components';

export const EmptyState = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
  gap: ${theme.spacing(2)};

  svg {
    font-size: 48px;
  }
`;
