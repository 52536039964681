import { InsuranceListItem } from 'pages/AgentRemoteSale/components/InsuranceBox/types';

export const INSURANCES_LIST: InsuranceListItem[] = [
  {
    brand: 'Aegon',
    img: '/images/insurances/aegon_logo.svg',
  },
  {
    brand: 'AGRO Ubezpieczenia',
    img: '/images/insurances/agro_ubezpieczenia.svg',
  },
  {
    brand: 'Allianz',
    img: '/images/insurances/allianz_logo.svg',
  },
  {
    brand: 'Balcia',
    img: '/images/insurances/balcia.svg',
  },
  {
    brand: 'Compensa',
    img: '/images/insurances/compensa_logo.svg',
  },
  {
    brand: 'Defend Insurance',
    img: '/images/insurances/defend.svg',
  },
  {
    brand: 'Ergo Hestia',
    img: '/images/insurances/ergohestia_logo.svg',
  },
  {
    brand: 'Euroins',
    img: '/images/insurances/euroins.svg',
  },
  {
    brand: 'Europa',
    img: '/images/insurances/europa.svg',
  },
  {
    brand: 'Generali',
    img: '/images/insurances/generali_logo.svg',
  },
  {
    brand: 'HDI',
    img: '/images/insurances/hdi_logo.svg',
  },
  {
    brand: 'Helvetia',
    img: '/images/insurances/helvetia.svg',
  },
  {
    brand: 'Inter',
    img: '/images/insurances/inter.svg',
  },
  {
    brand: 'InterRisk',
    img: '/images/insurances/interrisk_logo.svg',
  },
  {
    brand: 'Laven',
    img: '/images/insurances/laven.svg',
  },
  {
    brand: 'Leadenhall',
    img: '/images/insurances/leadenhall.svg',
  },
  {
    brand: 'Link4',
    img: '/images/insurances/link4_logo.svg',
  },
  {
    brand: 'MTU',
    img: '/images/insurances/mtu_logo.svg',
  },
  {
    brand: 'MTU 24',
    img: '/images/insurances/mtu24.svg',
  },
  {
    brand: 'Nationale Nederlanden',
    img: '/images/insurances/nationale.svg',
  },
  {
    brand: 'PZU',
    img: '/images/insurances/pzu_logo.svg',
  },
  {
    brand: 'Saltus',
    img: '/images/insurances/saltus.svg',
  },
  {
    brand: 'Signal',
    img: '/images/insurances/signal_iduna_logo.svg',
  },
  {
    brand: 'Trasti',
    img: '/images/insurances/trasti_logo.svg',
  },
  {
    brand: 'TUW',
    img: '/images/insurances/tuw.svg',
  },
  {
    brand: 'TUZ',
    img: '/images/insurances/tuz_logo.svg',
  },
  {
    brand: 'Uniqa',
    img: '/images/insurances/uniqa.svg',
  },
  {
    brand: 'Unum',
    img: '/images/insurances/unum_logo.svg',
  },
  {
    brand: 'VH Polska',
    img: '/images/insurances/vh_polska.svg',
  },
  {
    brand: 'Warta',
    img: '/images/insurances/warta_logo.svg',
  },
  {
    brand: 'Wefox',
    img: '/images/insurances/wefox.svg',
  },
  {
    brand: 'Wiener',
    img: '/images/insurances/wiener_logo.svg',
  },
  {
    brand: 'YCD',
    img: '/images/insurances/ycd_logo.svg',
  },
];
