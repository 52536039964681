import { Button } from '@mui/material';
import styled from 'styled-components';

export const DayButton = styled(Button).attrs((props) => ({
  ...props,
  size: 'small',
}))`
  flex-grow: 0;
  height: 40px;
`;
