import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { createPolicyExternal } from 'api/agent/policy/policyApi';
import { useAgent } from 'contexts/AgentAuthContext/hooks';
import dayjs from 'dayjs';
import theme from 'library/theme/theme';
import { NEEDS } from 'pages/AgentRemoteSale/steps/ClientNeeds/components/NeedsForm/const';
import BrandPicker from 'pages/AgentRemoteSale/steps/InsurancesSuggestions/components/InsuranceForm/BrandPicker';
import BankAccountNumberInput from 'pages/AgentRemoteSale/steps/Policy/components/PolicyForm/BankAccountNumberInput';
import SignMethods from 'pages/AgentRemoteSale/steps/Policy/components/PolicyForm/SignMethods';
import { isDateField } from 'pages/AgentRemoteSale/steps/Policy/components/PolicyForm/utils';
import { ChangeEvent, ReactNode, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledDatePicker, ControlledTextField } from 'components/Form';

import Confirmation from '../Confirmation';
import Success from '../Success';
import { createSchema } from './schema';
import * as S from './styles';

function Policy() {
  const { t } = useTranslation();
  const agent = useAgent();

  const [isRemotelyInSystem, setIsRemotelyInSystem] = useState(false);
  const [isIndividual, setIsIndividual] = useState<boolean>(true);

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    mode: 'onBlur',
    resolver: yupResolver(createSchema(isRemotelyInSystem, isIndividual)),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleDateChange = (date: any, name: any) => {
    if (!isDateField(name)) return;

    if (!date) {
      setValue(name, null, { shouldValidate: true });
      return;
    }

    setValue(name, date.toJSDate(), { shouldValidate: true });

    if (name === 'startProtection') {
      const endDate = date.plus({ years: 1 }).minus({ days: 1 });
      setValue('endProtection', endDate.toJSDate(), { shouldValidate: true });
    }
  };

  const handleEndlessCheckbox = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setValue('endless', checked);
    clearErrors('endProtection');
  };

  const handleSignMethodChange = ({ target: { value } }: any) => {
    setIsRemotelyInSystem(value === 'REMOTELY_IN_SYSTEM');
    setValue('signMethod', value as any, { shouldValidate: true });
  };

  const mutationPolicy = useMutation((body: any) => createPolicyExternal(body), {
    onSuccess: () => {
      reset();
      setIsConfirmationModalOpen(false);
      setIsSuccess(true);
      setIsLoading(false);
    },
    onError: () => {
      setIsConfirmationModalOpen(false);
    },
  });

  const signMethod = watch('signMethod');
  const endlessPolicy = watch('endless');

  const onSubmit: SubmitHandler<any> = (data) => {
    setIsLoading(true);

    const formattedStartDate = dayjs(data.start_protection).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(data.end_protection).format('YYYY-MM-DD');

    const commonFields = {
      client: {
        client_user: {
          pesel: data.pesel,
          email: data.email,
          first_name: data.name,
          last_name: data.lastName,
        },
        referral_email: data.emailReferal,
        referral_phone: data.phoneReferal,
        address: data.address,
        building_number: data.buildingNumber,
        apartment_number: data.apartmentNumber,
        city: data.city,
        zip_code: data.zipCode,
        phone_number: data.phoneNumber,
      },
      agent: agent?.id,
      tu_number: data.tuNumber,
      name: data.name_policy,
      sign_type: data.signMethod,
      start_protection: formattedStartDate,
      end_protection: formattedEndDate,
      account_number: data.accountNumber,
      cost: data.cost,
      installments: data.installment,
      company_brand: data.companyBrand,
      insurance_type: data.insuranceType,
      confirmed: false,
      policy_paid: false,
      commission_paid: false,
      source: 'ex',
    };

    const bodyPolicy = isIndividual
      ? {
          ...commonFields,
        }
      : {
          ...commonFields,
          client: {
            ...commonFields.client,
            company: {
              name: data.business,
              tax_id: data.nip,
            },
          },
        };

    mutationPolicy.mutate(bodyPolicy, {
      onSuccess: () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    });
  };

  const renderCheckbox = (icon: React.ReactNode, name: string, index: number) => (
    <FormControlLabel
      key={name}
      style={{ margin: 0 }}
      control={
        <S.StyledCheckbox
          onChange={() => handleSelected(index, name)}
          icon={generateIconBox(icon, name, index)}
          checkedIcon={generateIconBox(icon, name, index)}
          style={{ padding: 0, borderRadius: 0 }}
        />
      }
      label={null}
    />
  );

  const handleSelected = (index: number, name: string) => {
    setSelectedCategoryIndex(index);
    setValue('insuranceType', name, { shouldValidate: true });
  };

  const generateIconBox = (icon: React.ReactNode, name: string, index: number) => {
    const isError = errors.insuranceType !== undefined;
    const boxColor = selectedCategoryIndex === index ? '#E5F6FD' : 'transparent';
    const color = isError ? 'firebrick' : theme.palette.primary.main;

    return (
      <S.Box color={boxColor} border={color}>
        <IconButton style={{ color: color }}>{icon}</IconButton>
        <Typography color={color} variant="body1">
          {name}
        </Typography>
      </S.Box>
    );
  };

  const openConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const individual = () => {
    return isIndividual
      ? {
          width: '170px',
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        }
      : {
          width: '170px',
          backgroundColor: 'transparent',
          color: theme.palette.primary.main,
        };
  };

  const legal = () => {
    return !isIndividual
      ? {
          width: '170px',
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        }
      : {
          width: '170px',
          backgroundColor: 'transparent',
          color: theme.palette.primary.main,
        };
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setIsLoading(false);
  };

  const handleKeyPress = (event: any) => {
    const charCode = event.charCode;
    if (!(charCode >= 48 && charCode <= 57) && charCode !== 8) {
      event.preventDefault();
    }
  };

  const getDefaultEndDate = (startDate: any) => {
    const endDate = dayjs(startDate).add(1, 'year').subtract(1, 'day');
    return endDate.toDate();
  };

  const changeVariant = (bol: any) => {
    setIsIndividual(bol);
  };

  return (
    <S.Main>
      {isSuccess ? <Success /> : null}
      <Typography variant="h1" fontWeight="bold" fontSize={25}>
        Nowa polisa
      </Typography>
      <S.Wrap>
        <Button style={individual()} variant="contained" onClick={() => changeVariant(true)}>
          Osoba fizyczna
        </Button>
        <Button style={legal()} variant="contained" onClick={() => changeVariant(false)}>
          Osoba prawna
        </Button>
      </S.Wrap>
      <form style={{ marginTop: '30px' }} onSubmit={handleSubmit(openConfirmationModal)}>
        {!isIndividual ? (
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                control={control}
                name="business"
                defaultValue=""
                label="nazwa firmy"
                inputProps={{ InputLabelProps: { required: true } }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                control={control}
                name="nip"
                defaultValue=""
                label="nip"
                inputProps={{ InputLabelProps: { required: true } }}
              />
            </Grid>
          </Grid>
        ) : null}
        <S.Container>
          {NEEDS.map(({ icon, name }: { icon: React.ReactNode; name: string }, index: number) =>
            renderCheckbox(icon, name, index),
          )}
        </S.Container>
        <Grid item xs={12} marginBottom={2}>
          <Controller
            control={control}
            name="companyBrand"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <BrandPicker onChange={onChange} value={value} error={error} />
            )}
          />
        </Grid>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="cost"
              defaultValue=""
              label="składka w PLN"
              inputProps={{ InputLabelProps: { required: true }, onKeyPress: handleKeyPress }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="installment"
              defaultValue=""
              label="liczba rat"
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="name"
              defaultValue=""
              label="imię"
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="lastName"
              defaultValue=""
              label="nazwisko"
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="email"
              defaultValue=""
              label="email"
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="pesel"
              defaultValue=""
              label="pesel"
              inputProps={{ InputLabelProps: { required: isIndividual } }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="emailReferal"
              defaultValue=""
              label="email osoby polecającej"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="phoneReferal"
              defaultValue=""
              label="telefon osoby polecającej"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="address"
              defaultValue=""
              label="nazwa ulicy"
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="buildingNumber"
              defaultValue=""
              label="numer budynku"
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField control={control} name="apartmentNumber" defaultValue="" label="numer apartamentu" />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="city"
              defaultValue=""
              label="miasto"
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="zipCode"
              defaultValue=""
              label="kod pocztowy"
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="phoneNumber"
              defaultValue=""
              label="numer telefonu"
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} marginBottom={2}>
          <Controller
            control={control}
            name="signMethod"
            render={({ field, fieldState }) => (
              <SignMethods onChange={handleSignMethodChange} value={field.value || ''} fieldState={fieldState} />
            )}
          />
        </Grid>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="tuNumber"
              defaultValue=""
              label={t('insuranceWizard.policy.tuNumber')}
              inputProps={{ InputLabelProps: { required: signMethod !== 'REMOTELY_IN_SYSTEM' } }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="name_policy"
              defaultValue=""
              label={t('insuranceWizard.policy.name')}
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
          <Grid item xs={5}>
            <ControlledDatePicker
              control={control}
              name="startProtection"
              defaultValue={new Date()}
              onChange={handleDateChange}
              label={t('insuranceWizard.policy.startProtection')}
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
          <Grid item xs={5}>
            <ControlledDatePicker
              control={control}
              name="endProtection"
              defaultValue={getDefaultEndDate(new Date())}
              onChange={handleDateChange}
              label={t('insuranceWizard.policy.endProtection')}
              datePickerProps={{
                disabled: endlessPolicy,
              }}
              inputProps={{ InputLabelProps: { required: !endlessPolicy } }}
            />
          </Grid>
          <Grid item>
            <S.CheckboxContainer>
              <Controller
                control={control}
                name="endless"
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={field.value} onChange={handleEndlessCheckbox} />}
                    label={t('insuranceWizard.policy.endless') as ReactNode}
                  />
                )}
              />
            </S.CheckboxContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="accountNumber"
              defaultValue=""
              render={({ field, fieldState }) => (
                <BankAccountNumberInput value={field.value} onChange={field.onChange} fieldState={fieldState} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledDatePicker
              control={control}
              name="paymentDate"
              onChange={handleDateChange}
              label={t('insuranceWizard.policy.paymentDate')}
              inputProps={{ InputLabelProps: { required: false } }}
            />
          </Grid>
        </Grid>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" style={{ marginTop: '20px' }}>
            {t('common.save')}
          </Button>
        </div>
      </form>
      <Confirmation
        handleSubmit={handleSubmit(onSubmit)}
        isConfirmationModalOpen={isConfirmationModalOpen}
        closeConfirmationModal={closeConfirmationModal}
        isLoading={isLoading}
      />
    </S.Main>
  );
}
export default Policy;
