import { Grid, Typography } from '@mui/material';
import theme from 'library/theme/theme';
import styled, { CSSProperties } from 'styled-components';

export const MultilineTextContent = styled.div`
  word-break: break-all;
  width: 100%;
`;

export const SingleLineTextContent = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const Label = styled(SingleLineTextContent)`
  color: ${theme.palette.grey[500]};
  line-height: 1.2;
`;

interface ContentProps {
  align: CSSProperties['textAlign'];
}

export const Content = styled(Grid)<ContentProps>`
  text-align: ${(props) => props.align};
`;
