/* eslint-disable react-hooks/exhaustive-deps */
import EditIcon from '@mui/icons-material/Edit';
import { FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import InsuranceBox from 'pages/AgentRemoteSale/components/InsuranceBox';
import { InsuranceFormState } from 'pages/AgentRemoteSale/components/InsuranceBox/types';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { ChangeEvent, useState } from 'react';

import InsuranceFormModal from '../../InsurancesSuggestions/components/InsuranceFormModal';
import * as S from './styles';

interface Props {
  insurances: InsuranceFormState[];
  selectedInsuranceId: string | undefined;
  onSelectInsurance: (selectedId: string) => void;
  sortOrder: any;
}

function InsurancesList({ selectedInsuranceId, onSelectInsurance }: Props) {
  const [insuranceForModification, setInsuranceForModification] = useState<InsuranceFormState | undefined>(undefined);

  const { state } = useAgentForm();

  const [insurancesState, setInsurancesState] = useState<any>(state?.insurances);

  const handleSelect = (_: ChangeEvent<HTMLInputElement>, id: string) => {
    onSelectInsurance(id);
  };

  const handleEdit = (insurance: InsuranceFormState) => (_: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setInsuranceForModification(insurance);
  };

  const handleModalClose = () => setInsuranceForModification(undefined);

  insurancesState?.forEach((item: any) => {
    const chosenCost = item.cost.find((cost: any) => cost.chosen === true);

    if (chosenCost) {
      item.total = chosenCost.cost;
    } else {
      item.total = 'Brak wybranej stawki';
    }
  });

  return (
    <>
      <S.Control>
        <RadioGroup name="insurance-type-group" onChange={handleSelect}>
          {state?.insurances?.map((insurance: any, index: number) => (
            <FormControlLabel
              key={insurance.id}
              value={insurance.id}
              checked={selectedInsuranceId === insurance.id}
              control={<Radio />}
              label={
                <InsuranceBox
                  index={index}
                  insurance={insurance}
                  insurancesState={insurancesState}
                  setInsurancesState={setInsurancesState}
                >
                  <IconButton onClick={handleEdit(insurance)} color="primary">
                    <EditIcon />
                  </IconButton>
                </InsuranceBox>
              }
            />
          ))}
        </RadioGroup>
      </S.Control>

      <InsuranceFormModal
        insurance={insuranceForModification}
        onClose={handleModalClose}
        showModal={!!insuranceForModification}
      />
    </>
  );
}

export default InsurancesList;
