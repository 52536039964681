import { Button } from '@mui/material';
import styled from 'styled-components';

export const Submit = styled(Button).attrs((props) => ({
  type: 'submit',
  variant: 'contained',
  ...props,
}))`
  display: block;
  margin-left: auto !important;
`;
