import { t } from 'i18next';

export const getErrorMessage = (status: number | undefined = 0) => {
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  let msg: string = '';
  switch (true) {
    case status === 400:
      msg = t('errors.api.badRequest');
      break;
    case status >= 500:
      msg = t('errors.api.serverError');
      break;
    default:
      break;
  }

  return msg;
};
