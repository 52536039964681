import AddBoxIcon from '@mui/icons-material/AddBox';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';

import { Offers } from './types';

export const OFFERS: Record<Offers, { label: string; icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>> }> = {
  insurance: {
    label: 'insuranceWizard.clientNeeds.insurance',
    icon: HealthAndSafetyIcon,
  },
  healthAndLife: {
    label: 'insuranceWizard.clientNeeds.healthAndLife',
    icon: FavoriteIcon,
  },
  newPolicy: {
    label: 'insuranceWizard.clientNeeds.newPolicy',
    icon: AddBoxIcon,
  },
};
