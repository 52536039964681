/* eslint-disable @typescript-eslint/no-unused-vars */
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Modal, SelectChangeEvent, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { getSingleOffer, updateOfferStage } from 'api/agent/offer/offerApi';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import useModal from 'library/hooks/useModal';
import { InsuranceSortOrder, useSortedInsurances } from 'library/hooks/useSortedInsurances';
import AgentChat from 'pages/AgentRemoteSale/components/AgentChat';
import ClientDataInfoBox from 'pages/AgentRemoteSale/components/ClientDataInfoBox/ClientDataInfoBox';
import ClientNeeds from 'pages/AgentRemoteSale/components/ClientNeedsInfoBox';
import InsuranceSortSelect from 'pages/AgentRemoteSale/components/InsuranceSortSelect';
import InvitationSenderBox from 'pages/AgentRemoteSale/components/InvitationSenderBox';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { useChoseInsurance } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useInsurance';
import { useUpdateOffer } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import { useOffer } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ModalContent } from 'styles';
import { FormContent } from 'styles/FormContent';

import { LeftContainer, MainContainer, RightContainer } from 'components/Layout/styles';

import InsuranceFormModal from '../InsurancesSuggestions/components/InsuranceFormModal';
import InsurancesList from './InsuranceList/InsuranceList';
import * as S from './styles';

function InsuranceAdjustment() {
  const { t } = useTranslation();
  const { goTo } = useWizard();
  const { open, handleClose, handleOpen } = useModal();
  const { offer } = useOffer();
  const [sortOrder, setSortOrder] = useState<any>(offer?.insurancesOrder);
  const { insurances } = useSortedInsurances();
  const [selectedInsuranceId, setSelectedInsuranceId] = useState(insurances?.find((insurance) => insurance.chosen)?.id);
  const [initiallySelectedInsuranceId] = useState(selectedInsuranceId);
  const [isSort, setIsSort] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const offerMutation = useUpdateOffer();
  const { state, setState } = useAgentForm();
  const insuranceMutation = useChoseInsurance(selectedInsuranceId, initiallySelectedInsuranceId);
  const [notSort, setNotSort] = useState(true);

  const sortByTotal = (order: string) => {
    switch (order) {
      case 'cost_desc':
        return insurances.sort((a: any, b: any) => b.total - a.total);
      case 'cost_asc':
        return insurances.sort((a: any, b: any) => a.total - b.total);
      //case 'added':
      //return insurances;
      //default:
      //return null;
    }
  };

  useEffect(() => {
    if (!loading) {
      sortByTotal(sortOrder);
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleSortOrderChange = (event: SelectChangeEvent<InsuranceSortOrder>) => {
    setNotSort(false);
    const updatedInsurances = [...(state?.insurances as any)];

    if (event.target.value === 'cost_asc') {
      updatedInsurances.sort((a: any, b: any) => a.total - b.total);
      setState((prevState: any) => ({
        ...prevState,
        insurances: updatedInsurances,
      }));
    } else if (event.target.value === 'cost_desc') {
      updatedInsurances.sort((a: any, b: any) => b.total - a.total);
      setState((prevState: any) => ({
        ...prevState,
        insurances: updatedInsurances,
      }));
    }
    setSortOrder(event.target.value as InsuranceSortOrder);
    sortByTotal(event.target.value as InsuranceSortOrder);
  };

  const selectedInsurance = insurances?.find((insurance) => insurance.id === selectedInsuranceId);

  const useUpdateOfferStageMutation = () => {
    return useMutation(updateOfferStage);
  };

  const updateOfferStageMutation = useUpdateOfferStageMutation();

  const handleNextStep = async () => {
    if (!selectedInsurance || !offer) return;

    try {
      const stage = await getSingleOffer(offer.id);

      if (stage.stage !== 4) {
        try {
          await Promise.all([insuranceMutation.mutateAsync(), updateOfferStageMutation.mutateAsync(offer.id)]);

          goTo(5, 5);
        } catch (error) {
          toast(t('errors.api.serverError'));
        }
      } else {
        try {
          await insuranceMutation.mutateAsync();
          goTo(5, 5);
        } catch {
          toast.error('Coś poszło nie tak');
        }
      }
    } catch (error) {
      toast.error('Coś poszło nie tak');
    }
  };

  const handleReloadPage = () => window.location.reload();

  const saveOrder = () => {
    let sort = '';

    if (sortOrder === 'added') {
      sort = 'added';
    }
    if (sortOrder === 'cost_desc') {
      sort = 'cost_desc';
    }
    if (sortOrder === 'cost_asc') {
      sort = 'cost_asc';
    }

    offerMutation.mutate(
      { client: state.selectedClient?.id, insurancesOrder: sort },
      {
        onSuccess: () => {
          setNotSort(true);
          setIsSort(true);
        },
      },
    );
  };

  const closeModal = () => {
    setIsSort(false);
  };

  insurances.forEach((obj) => {
    const chosenCost = obj.cost.find((costObj: any) => costObj.chosen);
    if (chosenCost) {
      obj.total = chosenCost.cost;
    }
  });

  if (!loading) {
    return null;
  }

  return (
    <MainContainer>
      <Modal open={isSort} onClose={closeModal}>
        <ModalContent>
          <Grid container justifyContent="center">
            <Typography variant="h6">{t('common.orderSaved')}</Typography>
          </Grid>
        </ModalContent>
      </Modal>
      <LeftContainer>
        <FormContent>
          <S.TopActions>
            <InsuranceSortSelect value={sortOrder} onChange={handleSortOrderChange} />
            <Button endIcon={<AddIcon />} variant="outlined" onClick={handleOpen}>
              {t('common.addMore')}
            </Button>
          </S.TopActions>
          <InsurancesList
            sortOrder={sortOrder}
            insurances={insurances}
            onSelectInsurance={setSelectedInsuranceId}
            selectedInsuranceId={selectedInsuranceId}
          />
          <InsuranceFormModal showModal={open} onClose={handleClose} />
          <S.Actions>
            <Button variant="contained" onClick={saveOrder} disabled={notSort}>
              {t('common.saveSort')}
            </Button>
            <Button variant="outlined" onClick={handleReloadPage}>
              {t('common.refresh')}
            </Button>
            <Button
              variant="contained"
              onClick={handleNextStep}
              disabled={!selectedInsuranceId || insuranceMutation.isLoading}
            >
              {t('common.next')}
            </Button>
          </S.Actions>
        </FormContent>
      </LeftContainer>
      <RightContainer>
        <Grid item container direction="column" gap={3}>
          <InvitationSenderBox />
          <AgentChat />
          <ClientDataInfoBox />
          <ClientNeeds />
        </Grid>
      </RightContainer>
    </MainContainer>
  );
}

export default InsuranceAdjustment;
