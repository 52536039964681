const LOCAL_STORAGE_KEY = 'client_token';

export function getStoredClientToken() {
  return sessionStorage.getItem(LOCAL_STORAGE_KEY) || '';
}

export function setStoredClientToken(refreshToken: string) {
  return sessionStorage.setItem(LOCAL_STORAGE_KEY, refreshToken);
}

export function deleteStoredClientToken() {
  return sessionStorage.removeItem(LOCAL_STORAGE_KEY);
}
