import { Alert, Box, FormGroup, Grid } from '@mui/material';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledSwitch from 'components/Form/Controls/ControlledSwitch';

import { AGREEMENTS } from './const';
import { AgentData } from './types';

interface Props {
  disabled?: boolean;
  agent?: AgentData;
}

function Agreements({ disabled, agent }: Props) {
  const { control } = useFormContext<ClientDataFormState>();
  const { t } = useTranslation();

  if (!agent) return <Alert severity="error">{t('errors.cantDisplay')}</Alert>;

  return (
    <Box marginTop={2}>
      <FormGroup>
        <Grid container gap={1}>
          {AGREEMENTS(agent).map(({ id, content, required }) => (
            <ControlledSwitch
              control={control}
              key={id}
              name={`agreements.${id}`}
              required={required}
              labelProps={{ disabled }}
              content={content()}
            />
          ))}
        </Grid>
      </FormGroup>
    </Box>
  );
}

export default Agreements;
