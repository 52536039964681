import { useMutation } from '@tanstack/react-query';
import { AxiosMutationOptions } from 'api/types';

import { createInsurance, patchInsurance } from './insurancesApi';
import { Insurance, UpdateInsuranceBody } from './types';

export function useInsuranceMutate(id?: string, options?: AxiosMutationOptions<Insurance, UpdateInsuranceBody>) {
  return useMutation((body: Partial<UpdateInsuranceBody>) => {
    if (!id) {
      return createInsurance(body);
    } else {
      return patchInsurance(id, body);
    }
  }, options);
}
