import { useClient } from 'contexts/ClientAuthContext/hooks';
import { Navigate, Outlet } from 'react-router';
import { PATHS } from 'routes/paths';

function ClientPublicPages() {
  const client = useClient();

  if (client) return <Navigate to={PATHS.client.remoteSale} />;

  return <Outlet />;
}

export default ClientPublicPages;
