import { ToggleButton, ToggleButtonGroup, toggleButtonClasses } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Group = styled(ToggleButtonGroup)`
  .${toggleButtonClasses.selected} {
    background-color: unset;

    svg {
      background-color: ${theme.palette.success.light};
      fill: ${theme.palette.success.contrastText};
    }
  }
`;

export const ListButton = styled(ToggleButton)`
  justify-content: flex-start;
  gap: ${theme.spacing(2)};
  padding: 0;
  padding-right: ${theme.spacing(1)};

  p {
    padding: ${theme.spacing(1)};
    color: ${theme.palette.text.primary};
  }

  svg {
    padding: 10px;
    width: 48px;
    height: 100%;
    background-color: ${theme.palette.info.main};
    fill: ${theme.palette.info.contrastText};
  }

  &:disabled&:not(.Mui-selected) {
    svg {
      background-color: ${theme.palette.grey[500]};
    }
  }
`;
