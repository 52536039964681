import Links from './components/Links';
import * as S from './styles';

function AgentDashboard() {
  return (
    <S.Container>
      <S.Wrapper>
        <Links />
      </S.Wrapper>
    </S.Container>
  );
}

export default AgentDashboard;
