import config from 'config';
import i18n from 'i18next';
import { DateTime, Settings } from 'luxon';
import { initReactI18next } from 'react-i18next';

import pl from './translations/pl.json';

export const resources = {
  pl: {
    translation: pl,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: config.DEFAULT_LANG,
  resources,
  debug: process.env.NODE_ENV === 'development',
});

Settings.defaultLocale = DateTime.now().resolvedLocaleOptions().locale;

export default i18n;
