import { useOfferMutate, useOfferMutateInvitation, useOfferMutateStep } from 'api/agent/offer/offerQuery';
import { useContext } from 'react';

import { OfferContext } from '../FormContext';

export function useOffer() {
  return useContext(OfferContext);
}

export function useUpdateOffer() {
  const { offer, onUpdate } = useOffer();
  return useOfferMutate(offer?.id, {
    onSuccess: (offer) => {
      onUpdate(offer);
    },
  });
}

export function useUpdateOfferStep() {
  const { offer, onUpdate } = useOffer();

  return useOfferMutateStep(offer?.id, {
    onSuccess(data) {
      onUpdate({ ...data });
    },
  });
}

export function useSendInvitation() {
  const { offer, onUpdateInvitationSent } = useOffer();
  return useOfferMutateInvitation(offer?.id, offer, { onSuccess: () => onUpdateInvitationSent(true) });
}
