import { useMutation } from '@tanstack/react-query';
import { Client, UpdateClientBody } from 'api/client/types';
import { AxiosMutationOptions } from 'api/types';

import { updateClient } from './clientApi';

export function useClientMutate(options?: AxiosMutationOptions<Client, Partial<UpdateClientBody>>) {
  return useMutation((body: UpdateClientBody) => {
    return updateClient(body);
  }, options);
}
