export const PATHS = {
  client: {
    index: '/client',
    signIn: 'sign-in',
    remoteSale: 'remote-sale',
    unauthorized: 'unauthorized',
  },
  agent: {
    index: '/agent',
    service: 'service',
    tasks: 'tasks',
    clientsAndPolicies: 'clients-and-policies',
    signIn: 'sign-in',
    singUp: 'sign-up',
    remoteSale: 'remote-sale',
    remoteSaleClient: '/remote-sale/client',
    remindPassword: 'remind-password',
    settlements: 'settlements',
    newPassword: 'new-password',
    support: 'support',
    profile: 'profile',
    addPolicy: 'add-policy',
    externalPolicy: 'external-policy',
  },
};
