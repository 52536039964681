import { Button, Grid } from '@mui/material';
import { useUpdateClient } from 'contexts/AgentAuthContext/hooks';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import { useFormFiles } from 'library/hooks/useFormFiles';
import AgentChat from 'pages/AgentRemoteSale/components/AgentChat';
import ClientNeedsInfoBox from 'pages/AgentRemoteSale/components/ClientNeedsInfoBox';
import InvitationSenderBox from 'pages/AgentRemoteSale/components/InvitationSenderBox';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { useUpdateOfferStep } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import {
  useDeleteOfferFiles,
  useUploadOfferFile,
} from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOfferFiles';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import { useTranslation } from 'react-i18next';
import { FormContent } from 'styles/FormContent';

import { LeftContainer, MainContainer, RightContainer } from 'components/Layout/styles';

import ActionButtons from './components/ActionButtons';
import DataForm from './components/DataForm';
import clientFormAsClient from './components/DataForm/utils';
import Agreements from './components/ReadonlyAgreements';

function ClientData() {
  const { t } = useTranslation();
  const {
    onUpdate: onFormUpdate,
    state: { selectedClient, clientData },
  } = useAgentForm();

  const { goTo } = useWizard();
  const { mutate } = useUpdateClient(selectedClient?.id);

  const stepMutation = useUpdateOfferStep();

  const { mutateAsync: uploadFiles } = useUploadOfferFile();
  const { mutate: deleteFormFiles } = useDeleteOfferFiles();
  const formFilesState = useFormFiles();

  const handleFormSubmit = (data: ClientDataFormState) => {
    mutate(clientFormAsClient(data), {
      onSuccess: () => {
        onFormUpdate({ clientData: data });
        deleteFormFiles(formFilesState.filesToDelete);
      },
    });
  };

  const handleGoNext = () => {
    goTo(3, 3);
    stepMutation.mutate(void 0);
  };

  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <MainContainer>
      <LeftContainer>
        <FormContent>
          <DataForm
            onSubmit={handleFormSubmit}
            initialState={clientData}
            onUpload={uploadFiles}
            onAddFile={formFilesState.onAddFile}
            onDeleteFile={formFilesState.onDeleteFile}
            type="agent"
          >
            <Agreements />
            <ActionButtons clientData={clientData} onNextStep={handleGoNext} />
          </DataForm>
        </FormContent>
        <Grid item container justifyContent="flex-end" marginTop={2}>
          <Button variant="outlined" onClick={handleRefreshClick} style={{ marginBottom: '15px' }}>
            {t('common.refresh')}
          </Button>
        </Grid>
      </LeftContainer>
      <RightContainer>
        <Grid item container direction="column" gap={3}>
          <InvitationSenderBox />
          <ClientNeedsInfoBox defaultOpen />
          <AgentChat />
        </Grid>
      </RightContainer>
    </MainContainer>
  );
}

export default ClientData;
