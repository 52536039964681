import { authorizedAxios } from 'library/axios/apiProvider';

import { ClientOffer, NextStage } from './types';

export async function getClientOffer() {
  const { data } = await authorizedAxios().get<ClientOffer>('/api/v1/offer-client/');
  return data;
}

export async function updateOfferStage(): Promise<NextStage> {
  const { data } = await authorizedAxios().post(`/api/v1/offer-client/next-stage/`);
  return data;
}

export async function nextStage(body: string): Promise<NextStage> {
  const { data } = await authorizedAxios().post(`/api/v1/offer-client/next-stage/`, body);
  return data;
}

export async function acceptOffer(offerId: string): Promise<void> {
  await authorizedAxios().post('/api/v1/offer-client/accept/', { id: offerId });
  return;
}
