import { useClientMutate } from 'api/agent/client/clientQuery';
import { useContext } from 'react';

import { AgentAuthContext } from './AgentAuthContext';

export function useAgent() {
  return useContext(AgentAuthContext).agent;
}

export function useAgentActions() {
  const { signIn, signOut, addClient, updateClient, emailState, setEmailState } = useContext(AgentAuthContext);
  return { signIn, signOut, addClient, updateClient, emailState, setEmailState };
}

export function useUpdateClient(clientId: string | undefined) {
  const { addClient, updateClient } = useAgentActions();
  return useClientMutate(clientId, {
    onSuccess: (client) => {
      if (clientId) updateClient(clientId, client);
      else addClient(client);
    },
  });
}
