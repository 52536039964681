import theme from 'library/theme/theme';
import styled from 'styled-components';

export const policyDatesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(3)};
`;

export const CheckboxContainer = styled.div`
  padding-top: ${theme.spacing(1)};
`;
