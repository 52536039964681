import { nanoid } from 'nanoid';

import { FormFile } from 'components/Attachments/types';

export function fileAsFormFile(file: File): FormFile {
  return {
    id: nanoid(),
    name: file.name,
    file: file,
    filename: file.name,
    url: URL.createObjectURL(file),
  };
}
