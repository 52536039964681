import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'errors.fieldRequired',
  },
  string: {
    email: 'errors.invalidEmail',
    max: 'errors.fieldMaxLength',
  },
  date: {
    min: 'errors.dateCantBeFromPast',
  },
});
