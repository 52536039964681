import { Offer } from '../api/agent/offer/types';

export const calculation = (offers: Offer[]): number => {
  return offers.reduce((total: number, item: Offer) => {
    if (typeof item.cost === 'string' && !isNaN(parseFloat(item.cost))) {
      return total + parseFloat(item.cost);
    } else {
      return total;
    }
  }, 0);
};
