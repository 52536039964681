import { GridProps } from '@mui/material';
import { CSSProperties } from 'react';

import * as S from './styles';

interface Props extends GridProps {
  label: string;
  children: React.ReactNode;
  multiline?: boolean;
  align?: CSSProperties['textAlign'];
}

function LabelValue({ label, children, multiline, align = 'left', ...props }: Props) {
  return (
    <S.Content container direction="column" align={align} {...props}>
      <S.Label variant="caption">{label}</S.Label>
      {multiline ? (
        <S.MultilineTextContent>{children}</S.MultilineTextContent>
      ) : (
        <S.SingleLineTextContent>{children}</S.SingleLineTextContent>
      )}
    </S.Content>
  );
}
export default LabelValue;
