import { authorizedAxios } from 'library/axios/apiProvider';

import { AuthAgent } from './types';

export async function getAgentData(): Promise<AuthAgent> {
  const { data } = await authorizedAxios().get(`/api/v1/agents/`);
  return data;
}

export async function patchAgentData(body: any): Promise<AuthAgent> {
  const newBody = {
    id: body.idObject,
    profile: {
      email: body.email,
      first_name: body.firstName,
      last_name: body.lastName,
      phone_number: body.phoneNumber,
      id: body.idProfile,
    },
    data_administrator: {
      name: body.name,
      street: body.street,
      house_number: body.houseNumber,
      apartment_number: body.apartmentNumber,
      postal_code: body.postalCode,
      city: body.city,
      tax_id: body.taxId,
      phone_number: body.phoneNumber,
    },
    terms_accepted: true,
    newsletter: true,
  };

  const { data } = await authorizedAxios().patch(`/api/v1/agents/`, newBody);
  return data;
}

export async function patchAgentImage(body: any): Promise<AuthAgent> {
  const { data } = await authorizedAxios().patch(`/api/v1/agents/`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
}

export async function patchAgentFileInfo(body: any): Promise<AuthAgent> {
  const { data } = await authorizedAxios().patch(`/api/v1/agents/`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
}

export async function postAgentFiles(body: any): Promise<AuthAgent> {
  const { data } = await authorizedAxios().post(`/api/v1/agent-files/`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
}

export async function getAgentFiles(): Promise<any> {
  const { data } = await authorizedAxios().get(`/api/v1/agent-files/`);
  return data;
}

export async function deleteFile(id: string) {
  const { data } = await authorizedAxios().delete(`/api/v1/agent-files/${id}/`);
  return data;
}
