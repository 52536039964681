import { TextField } from '@mui/material';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { ErrorMessage, errorMessage } from 'library/language/validation/errorMessage';
import { DateTime } from 'luxon';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SingleInsuranceForm } from './types';

interface Props {
  onChange: (date: DateTime | null) => void;
  control: Control<SingleInsuranceForm, any>;
}

function DatePicker({ control, onChange }: Props) {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name="expirationDate"
      render={({ field, fieldState }) => (
        <MUIDatePicker
          disablePast
          disableMaskedInput
          label={t('insuranceWizard.propositionsOfInsurances.expirationDate')}
          {...field}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!fieldState.error}
              helperText={errorMessage(t, fieldState?.error?.message as ErrorMessage)}
              fullWidth
              InputLabelProps={{ required: true }}
              autoComplete="off"
            />
          )}
        />
      )}
    />
  );
}

export default DatePicker;
