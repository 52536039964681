/* eslint-disable @typescript-eslint/no-empty-function */
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAgentData } from 'api/agent/agentApi';
import { AuthAgent } from 'api/agent/types';
import { refreshToken, signOut } from 'api/auth/authApi';
import { AgentAuthResponse } from 'api/auth/types';
import { Client } from 'api/client/types';
import { createAuthorizedAxiosForAgent, destroyAuthorizedAxiosForAgent } from 'library/axios/apiProvider';
import {
  deleteStoredRefreshToken,
  getStoredRefreshToken,
  setStoredRefreshToken,
} from 'library/axios/helpers/refreshToken';
import { createContext, useCallback, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes/paths';

import AgentSplash from 'components/AgentSplash/AgentSplash';

interface ContextProps {
  signIn: (agent: AgentAuthResponse) => void;
  signOut: () => void;
  addClient: (client: Client) => void;
  updateClient: (id: string, client: Partial<Client>) => void;
  agent: AuthAgent | null;
  emailState: any;
  setEmailState: any;
}

export const AgentAuthContext = createContext<ContextProps>({
  signIn: () => {},
  signOut: () => {},
  addClient: () => {},
  updateClient: () => {},
  agent: null,
  emailState: '',
  setEmailState: () => {},
});

interface Props {
  children: React.ReactNode;
}

function AgentAuthContextProvider({ children }: Props) {
  const [agent, setAgent] = useState<AuthAgent | null>(null);
  const [emailState, setEmailState] = useState('');

  const nav = useNavigate();
  const [storedRefreshToken] = useState(getStoredRefreshToken());
  const { isFetching: isRefreshingToken, isSuccess: isAuthorized } = useQuery(
    ['refresh-token'],
    () => refreshToken(storedRefreshToken || ''),
    {
      staleTime: 0,
      enabled: !!storedRefreshToken,
      onSuccess: () => {
        createAuthorizedAxiosForAgent(handleClearSession, storedRefreshToken);
      },
      onError: () => {
        handleClearSession();
      },
    },
  );
  const { isFetching: isAgentDataLoading, refetch: refetchUserData } = useQuery(['agent-data'], () => getAgentData(), {
    enabled: isAuthorized,
    onSuccess: (data) => {
      flushSync(() => {
        setAgent(data);
        createAuthorizedAxiosForAgent(handleClearSession, storedRefreshToken);
      });
    },
  });

  const handleClearSession = useCallback(() => {
    setAgent(null);

    deleteStoredRefreshToken();
    destroyAuthorizedAxiosForAgent();
  }, []);

  const { mutate: mutateSignOut } = useMutation(signOut);
  const handleSignOut = useCallback(() => {
    mutateSignOut(void 0, {
      onSuccess: () => handleClearSession(),
    });
  }, [handleClearSession, mutateSignOut]);

  const handleSignIn = useCallback(
    (auth: AgentAuthResponse) => {
      setStoredRefreshToken(auth.refreshToken);
      createAuthorizedAxiosForAgent(handleSignOut, auth.refreshToken);
      refetchUserData();
      nav(PATHS.agent.index);
    },
    [handleSignOut, refetchUserData, nav],
  );

  const handleAddClient = (client: Client) => {
    setAgent((prev) => {
      if (!prev) return prev;
      return { ...prev, clients: [client, ...prev.clients] };
    });
  };

  const handleUpdateClient = (id: string, newData: Partial<Client>) => {
    setAgent((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        clients: prev.clients.map((client) => {
          if (client.id === id) return { ...client, ...newData };
          return client;
        }),
      };
    });
  };

  return (
    <AgentAuthContext.Provider
      value={useMemo(
        () => ({
          signIn: handleSignIn,
          signOut: handleSignOut,
          agent,
          addClient: handleAddClient,
          updateClient: handleUpdateClient,
          emailState: emailState,
          setEmailState: setEmailState,
        }),
        [agent, handleSignIn, handleSignOut, emailState, setEmailState],
      )}
    >
      {isRefreshingToken || isAgentDataLoading ? <AgentSplash /> : children}
    </AgentAuthContext.Provider>
  );
}

export default AgentAuthContextProvider;
