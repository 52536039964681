import { LinkProps } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { LinkBehavior } from './muiLink';

const muiTheme = createTheme({
  typography: {
    fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    body1: {
      fontSize: 14,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          alignItems: 'center',
        },
      },
    },
  },
  palette: {
    infoBox: {
      main: '#E5F6FD',
    },
  },
});

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    infoBox: { main: string };
  }
  interface PaletteOptions {
    infoBox: { main: string };
  }
}

const theme = {
  ...muiTheme,
};

export default theme;
