import { Text, View } from '@react-pdf/renderer';
import moment from 'moment';

import { FormMessage, MessagesApk } from 'components/Chat/types';

import { styles } from './styles';

const ApkMessages: React.FC<MessagesApk> = ({ messages }) => {
  const generateTime = (sentAt: any) => {
    const sentAtMoment = moment(sentAt);
    const formattedDate = sentAtMoment.format('DD/MM/YYYY, HH:mm:ss');
    return formattedDate;
  };

  return (
    <View style={styles.wrapper}>
      {messages.map(({ id, message, variant, sentAt }: FormMessage) => (
        <Text key={id} style={styles.messages}>
          ({generateTime(sentAt)}) {variant === 'me' ? `Ty: ${message}` : `Agent: ${message}`}
        </Text>
      ))}
    </View>
  );
};

export default ApkMessages;
