import { useAgent } from 'contexts/AgentAuthContext/hooks';
import FormContextProvider from 'pages/AgentRemoteSale/contexts/FormContext/FormContext';
import { Navigate, Outlet } from 'react-router';
import { PATHS } from 'routes/paths';

import * as S from './styles';

function AgentGlobalPage() {
  const agent = useAgent();

  if (!agent) return <Navigate to={PATHS.agent.signIn} />;

  return (
    <FormContextProvider>
      <S.GlobalPage>
        <S.Main>
          <Outlet />
        </S.Main>
      </S.GlobalPage>
    </FormContextProvider>
  );
}

export default AgentGlobalPage;
