import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-height: 600px) {
    min-height: initial;
    height: 600px;
  }
`;

export const Wrapper = styled.div`
  height: 300px;
  width: 80%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media (max-width: 800px) {
    height: 500px;
    flex-direction: column;
  }
  @media (max-width: 600px) {
    height: 500px;
  }
`;
