import { Box, Typography } from '@mui/material';
import { useAgent } from 'contexts/AgentAuthContext/hooks';
import Agreements from 'pages/ClientRemoteSale/steps/ClientData/components/Agreements';
import { useTranslation } from 'react-i18next';

function ReadonlyAgreements() {
  const { t } = useTranslation('translation', { keyPrefix: 'insuranceWizard.clientData' });
  const agent = useAgent();

  return (
    <Box marginTop={3}>
      <Typography color="gray" variant="body2">
        {t('filledByClient')}
      </Typography>
      <Agreements disabled agent={agent?.profile} />
    </Box>
  );
}

export default ReadonlyAgreements;
