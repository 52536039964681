import * as S from './styles';

function AgentSplash() {
  return (
    <S.Wrapper>
      <img src="/images/4agent-logo.png" alt="4agent-logo" width={80} height={80} />
    </S.Wrapper>
  );
}

export default AgentSplash;
