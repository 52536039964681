import { Box, Grid } from '@mui/material';

import { MessageState } from 'components/Chat/types';

import SpeechBubble from '../SpeechBubble';

interface Props {
  messages: MessageState[];
}

function MessagesDisplay({ messages }: Props) {
  return (
    <Box paddingY={2}>
      <Grid container flexDirection="column" gap={3}>
        {messages.map((message) => (
          <SpeechBubble key={message.id} message={message} />
        ))}
      </Grid>
    </Box>
  );
}
export default MessagesDisplay;
