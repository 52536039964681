import { List } from '@mui/material';
import theme from 'library/theme/theme';
import { DropzoneRootProps } from 'react-dropzone';
import styled, { css } from 'styled-components';

interface Props extends DropzoneRootProps {
  error: boolean;
}

export const Dropzone = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(3)} ${theme.spacing(2)};
  text-align: center;
  border: 1px solid;
  border-color: ${theme.palette.primary.main};
  border-radius: ${theme.shape.borderRadius}px;
  min-height: 150px;

  svg {
    font-size: 62px;
  }

  ${({ isDragRejected, isDragActive, error }) => {
    if (isDragRejected || error)
      return css`
        border-color: ${theme.palette.error.main};
      `;
    if (isDragActive)
      return css`
        border-color: ${theme.palette.success.main};
      `;
  }}
`;

export const FilesList = styled(List)`
  height: 100px;
  overflow-y: auto;
  scrollbar-gutter: stable;
`;
