import Alert from '@mui/material/Alert';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { useUpdateOffer } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import { clientNeedsAsOfferRequest } from 'pages/AgentRemoteSale/steps/ClientNeeds/components/NeedsForm/utils';
import { ClientNeedsFormState } from 'pages/AgentRemoteSale/steps/ClientNeeds/types';
import { useTranslation } from 'react-i18next';

import BaseClientNeedsBox from 'components/RemoteSale/BaseClientNeedsBox';

interface Props {
  defaultOpen?: boolean;
}

function ClientNeedsInfoBox({ defaultOpen }: Props) {
  const {
    state: { clientNeeds },
    onUpdate,
  } = useAgentForm();
  const { t } = useTranslation();
  const offerMutation = useUpdateOffer();

  const handleSubmit = (data: ClientNeedsFormState) => {
    offerMutation.mutate(clientNeedsAsOfferRequest(data), {
      onSuccess: () => {
        onUpdate({ clientNeeds: data });
      },
    });
  };

  if (!clientNeeds) return <Alert severity="error">{t('errors.cantDisplay')}</Alert>;

  return <BaseClientNeedsBox clientNeeds={clientNeeds} onUpdate={handleSubmit} defaultOpen={defaultOpen} />;
}
export default ClientNeedsInfoBox;
