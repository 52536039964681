import { useAgent } from 'contexts/AgentAuthContext/hooks';
import { Navigate, Outlet } from 'react-router';
import { PATHS } from 'routes/paths';

function AgentPrivatePages() {
  const agent = useAgent();

  if (!agent) return <Navigate to={PATHS.agent.signIn} />;

  return <Outlet />;
}

export default AgentPrivatePages;
