import { UpdateOfferBody } from 'api/agent/offer/types';
import { DateTime } from 'luxon';

import { ClientNeedsFormState } from '../../types';

export function clientNeedsAsOfferRequest(formState: ClientNeedsFormState): UpdateOfferBody {
  return {
    needsShort: formState.name,
    needsDescription: formState.description,
    startDate: DateTime.fromJSDate(formState.startDate).toISODate(),
    insuranceType: formState.insuranceType,
  };
}
