import { Typography } from '@mui/material';
import theme from 'library/theme/theme';
import { Link } from 'react-router-dom';

import * as S from './styles';

function Success() {
  return (
    <S.Main>
      <Typography fontWeight="bold" variant="h6" style={{ marginBottom: '10px', fontSize: '16px' }}>
        Twoja polisa została dodana !
      </Typography>
      <Typography variant="body1" style={{ fontSize: '16px' }}>
        Możesz ją podejrzeć w zakładce{' '}
        <Link
          style={{ color: theme.palette.primary.main, textDecoration: 'none', fontWeight: '1000' }}
          to={'/agent/clients-and-policies'}
        >
          KLIENCI I POLISY
        </Link>
      </Typography>
    </S.Main>
  );
}

export default Success;
