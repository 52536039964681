import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Button = styled.button`
  text-align: center;
  width: 100%;
  height: 100%;
  border: 1px solid primary;
  background-color: white;
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 5px;
  padding: 0;
`;
