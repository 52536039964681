import isValidNip from 'is-valid-nip';
import { peselValidation, phoneValidation, requiredSwitchChecked } from 'library/yup/common';
import * as yup from 'yup';

const agreements = yup.object().shape({
  agreements: yup.object().shape({
    privacyPolicy: requiredSwitchChecked.required(),
    marketingInformationThroughElectronic: requiredSwitchChecked.required(),
    marketingInformationThroughOFWCA: requiredSwitchChecked.required(),
    contractConclusion: requiredSwitchChecked.required(),
  }),
});

export const baseClientDataSchema = yup.object().shape({
  clientUser: yup
    .object()
    .shape({
      email: yup.string().email().required().trim().max(100),
      firstName: yup.string().nullable().trim().max(50),
      lastName: yup.string().nullable().trim().max(50),
      pesel: peselValidation.nullable().trim(),
    })
    .required(),

  company: yup
    .object()
    .shape({
      name: yup.string().required().max(150).trim(),
      taxId: yup
        .number()
        .test('nip', 'errors.invalidNip', (nip) => (nip ? isValidNip(nip.toString() || '') : true))
        .nullable()
        .transform((value) => (value ? value : undefined))
        .required(),
    })
    .nullable(),
  referralEmail: yup.string().email().nullable().trim().max(100),
  referralPhone: phoneValidation.nullable().trim(),
  address: yup.string().nullable().trim().max(255),
  buildingNumber: yup.string().nullable().trim().max(20),
  apartmentNumber: yup.string().nullable().trim().max(20),
  city: yup.string().nullable().trim().max(100),
  zipCode: yup.string().nullable().trim().max(16),
  phoneNumber: phoneValidation.nullable(),
  country: yup.string().nullable().trim().max(50),
});

export const clientDataSchemaWithAgreements = baseClientDataSchema.concat(agreements);
