import { FileResult } from 'api/files/types';

import { FormFile } from 'components/Attachments/types';

export function fileResultAsFormFile(filesResult: FileResult): FormFile {
  return {
    name: filesResult.filename,
    filename: filesResult.filename,
    id: filesResult.id,
    url: filesResult.file,
  };
}
