import Alert from '@mui/material/Alert';
import { useUpdateClient } from 'contexts/AgentAuthContext/hooks';
import { useFormFiles } from 'library/hooks/useFormFiles';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import {
  useDeleteOfferFiles,
  useUploadOfferFile,
} from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOfferFiles';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import clientFormAsClient from 'pages/AgentRemoteSale/steps/ClientData/components/DataForm/utils';
import { useTranslation } from 'react-i18next';

import BaseClientDataBox from 'components/RemoteSale/BaseClientDataBox';

interface Props {
  defaultOpen?: boolean;
}

function ClientDataInfoBox({ defaultOpen }: Props) {
  const {
    state: { clientData, selectedClient },
    onUpdate,
  } = useAgentForm();
  const { t } = useTranslation();
  const { mutate, isLoading: isUpdatingClient } = useUpdateClient(selectedClient?.id);

  const formFilesState = useFormFiles();
  const { mutate: deleteFormFiles } = useDeleteOfferFiles();
  const { mutateAsync: uploadFiles, isLoading: isUploading } = useUploadOfferFile();

  const handleSubmit = (data: ClientDataFormState) => {
    mutate(clientFormAsClient(data), { onSuccess: () => onUpdate({ clientData: data }) });
    deleteFormFiles(formFilesState.filesToDelete);
    formFilesState.onClear();
  };

  const handleCancelUpdate = () => {
    deleteFormFiles(formFilesState.addedFiles);
    formFilesState.onClear();
  };

  if (!clientData || !selectedClient) return <Alert severity="error">{t('errors.cantDisplay')}</Alert>;

  return (
    <BaseClientDataBox
      type="agent"
      onUpload={uploadFiles}
      clientData={clientData}
      onUpdate={handleSubmit}
      defaultOpen={defaultOpen}
      isSubmitting={isUpdatingClient || isUploading}
      onCancelUpdate={handleCancelUpdate}
      onAddFile={formFilesState.onAddFile}
      onDeleteFile={formFilesState.onDeleteFile}
    />
  );
}
export default ClientDataInfoBox;
