import theme from 'library/theme/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Box = styled.div`
  text-decoration: none;
  width: calc(50% - 10px);
  @media (max-width: 800px) {
    width: calc(50% - 5px);
  }
  @media (max-width: 600px) {
    width: 100%;
    height: 80px;
  }
`;

export const LinkElement = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  width: 100%;
  height: 100%;
  transition: background 0.3s ease;
  color: ${theme.palette.primary.main};
  svg {
    font-size: 62px;
  }
  &:hover {
    background-color: #e5f6fd;
  }
`;
