import DownloadIcon from '@mui/icons-material/Download';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { usePolicyMutate } from 'api/agent/policy/policyQuery';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import AgentChat from 'pages/AgentRemoteSale/components/AgentChat';
import InsuranceBox from 'pages/AgentRemoteSale/components/InsuranceBox';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { useOffer, useSendInvitation } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import { WizardSteps } from 'pages/AgentRemoteSale/types';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormContent } from 'styles/FormContent';

import ConfirmationModal from 'components/ConfirmationModal';
import { LeftContainer, MainContainer, RightContainer } from 'components/Layout/styles';

import PolicyBox from './components/PolicyBox';
import StatusList from './components/StatusList';
import { StatusType } from './components/StatusList/types';
import * as S from './styles';
import { PolicyActions } from './types';

function Payment() {
  const { t } = useTranslation();
  const {
    state: { insurances, policy, clientData: client },
    onUpdate,
  } = useAgentForm();
  const { goTo, stage } = useWizard();
  const { offer } = useOffer();
  const sendInvitation = useSendInvitation();
  const policyMutation = usePolicyMutate(policy?.id);

  const [confirmationModal, setConfirmationModal] = useState<PolicyActions | null>(null);

  const handleDownloadClick = async () => {
    try {
      const response = await fetch(client?.apk);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.download = 'apk.pdf';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Błąd pobierania pliku:', error);
    }
  };

  const handleStatusChange = async (updatedStatus: StatusType, value: boolean) => {
    onUpdate({ policy: policy ? { ...policy, [updatedStatus]: value } : undefined });

    policyMutation.mutate({
      [updatedStatus]: value,
    });
  };

  const handleModalOpen = (type: PolicyActions) => (_: React.MouseEvent<HTMLButtonElement>) => {
    setConfirmationModal(type);
  };

  const handleModalClose = () => {
    setConfirmationModal(null);
  };

  const handleResendPolicy = () => {
    sendInvitation.mutate(void 0, {
      onSuccess() {
        handleModalClose();
        toast(t('insuranceWizard.paymentStep.policyHasBeenResent'));
      },
    });
  };

  const handleCancelPolicy = () => {
    goTo(WizardSteps.Policy, stage - 1);
  };

  const selectedInsurance = insurances?.find((insurance) => insurance.chosen);

  if (!policy || !selectedInsurance || !client) return <Alert severity="error">{t('errors.cantDisplay')}</Alert>;

  const newData = { ...selectedInsurance, name: policy.name, identification: policy.tuNumber };

  return (
    <>
      <MainContainer>
        <LeftContainer>
          <FormContent>
            <S.Container>
              <StatusList onChange={handleStatusChange} policy={policy} />
              <Typography variant="h6">{t('insuranceWizard.policy.policy')}</Typography>
              <PolicyBox client={client} policy={policy} insurance={newData} />
              <Typography variant="h6">{t('insuranceWizard.clientNeeds.insurance')}</Typography>
              <InsuranceBox insurance={newData} acceptedDate={offer?.acceptedDate || null} />
              <div>
                <Button variant="text" startIcon={<DownloadIcon fontSize="large" />} onClick={handleDownloadClick}>
                  <S.DownloadAPKButtonContent>POBIERZ APK ANALIZE POTRZEB KLIENTA</S.DownloadAPKButtonContent>
                </Button>
              </div>
              <S.Actions>
                <Button color="error" variant="text" onClick={handleModalOpen('CANCEL')}>
                  {t('insuranceWizard.paymentStep.cancelPolicy')}
                </Button>
                <Button color="primary" variant="outlined" onClick={handleModalOpen('RESEND')}>
                  {t('insuranceWizard.paymentStep.resend')}
                </Button>
              </S.Actions>
            </S.Container>
          </FormContent>
        </LeftContainer>
        <RightContainer>
          <AgentChat />
        </RightContainer>
        <ConfirmationModal
          open={!!confirmationModal}
          onReject={handleModalClose}
          onAccept={confirmationModal === 'CANCEL' ? handleCancelPolicy : handleResendPolicy}
        >
          {confirmationModal === 'CANCEL' ? (
            <Typography>
              <Trans
                components={{ bold: <strong /> }}
                i18nKey="insuranceWizard.paymentStep.areYouSureYouWantToCancelPolicyAndReturnToNewPolicy"
              />
            </Typography>
          ) : (
            <Typography>
              <Trans
                components={{ bold: <strong /> }}
                i18nKey="insuranceWizard.paymentStep.areYouSureYouWantToResendPolicyToClient"
              />
            </Typography>
          )}
        </ConfirmationModal>
      </MainContainer>
    </>
  );
}

export default Payment;
