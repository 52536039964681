import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle } from '@mui/material';

import * as S from './styles';

function Information({ isOpenModal, closeModal, text }: any) {
  return (
    <S.Wrapper>
      <Dialog
        open={isOpenModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ position: 'relative' }}>
          <CloseIcon
            style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}
            onClick={() => closeModal()}
          />
          <DialogTitle id="alert-dialog-title" style={{ margin: '10px' }}>
            {text}
          </DialogTitle>
        </div>
      </Dialog>
    </S.Wrapper>
  );
}

export default Information;
