import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getPolicy, patchPolicy } from 'api/agent/policy/policyApi';
import useBrand from 'library/hooks/useBrand';
import StatusList from 'pages/AgentRemoteSale/steps/Payment/components/StatusList';
import { StatusType } from 'pages/AgentRemoteSale/steps/Payment/components/StatusList/types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import WizardLoadingView from 'components/WizardLoadingView';

import * as S from './styles';

function AgentExternalPolicy() {
  const policyId = localStorage.getItem('policy_id');
  const location = useLocation();
  const { t } = useTranslation();

  const {
    data: policy,
    isFetching: isLoadingPolicy,
    isError: isErrorPolicy,
  } = useQuery([policyId, location.pathname], () => getPolicy(policyId), {
    enabled: true,
  });

  const mutation = useMutation((dataToSave: any) => patchPolicy(dataToSave, policy?.id), {
    onError: () => {
      toast.error('Coś poszło nie tak');
    },
  });

  const brand = useBrand(policy?.companyBrand);

  let signMethodLabel: string | undefined = '';

  const way = (signType: any): string => {
    switch (signType) {
      case 'REMOTELY_IN_SYSTEM':
        return t('insuranceWizard.policy.signMethods.REMOTELY_IN_SYSTEM.label');
      case 'SCAN_DOCUMENT':
        return t('insuranceWizard.policy.signMethods.SCAN_DOCUMENT.label');
      case 'BY_PAYMENT':
        return t('insuranceWizard.policy.signMethods.BY_PAYMENT.label');
      case 'BY_POST_OFFIE':
        return t('insuranceWizard.policy.signMethods.BY_POST_OFFIE.label');
      case 'SIGN_WITH_AGENT':
        return t('insuranceWizard.policy.signMethods.SIGN_WITH_AGENT.label');
      case 'POLICY_ALREADY_ACCEPTED':
        return t('insuranceWizard.policy.signMethods.POLICY_ALREADY_ACCEPTED.label');
      default:
        return '';
    }
  };

  if (isLoadingPolicy) {
    return <WizardLoadingView height="100vh" />;
  }

  if (isErrorPolicy) {
    return <div>Coś poszło nie tak</div>;
  }

  signMethodLabel = way(policy?.signType);

  const handleStatusChange = async (updatedStatus: StatusType, value: boolean) => {
    if (policy) {
      const body: any = { [updatedStatus]: value };
      policy[updatedStatus] = value;
      mutation.mutate(body);
    } else {
      console.error('Policy is undefined. Cannot proceed with the mutation.');
    }
  };

  return (
    <S.Main>
      <S.Container>
        <Typography variant="h6">Statusy</Typography>
        <StatusList onChange={handleStatusChange} policy={policy} />
        <Typography variant="h6">Polisa</Typography>
        <S.Policy>
          <S.Wrapper>
            <Grid
              item
              xs={2}
              container
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              gap={5}
            >
              <img
                src={brand?.img}
                style={{ height: '100px', width: '100px', objectFit: 'contain' }}
                alt="insurance logo"
              />
            </Grid>
            <Grid item xs={6} paddingX={5} container flexDirection="column" justifyContent="space-between">
              <Grid item>
                <Typography variant="subtitle1" fontWeight="bold">
                  {policy?.cost} PLN
                </Typography>
                <Typography>{policy?.name}</Typography>
              </Grid>
              <Grid item>
                <Typography>{t('insuranceWizard.policy.signBy')}</Typography>
                <Typography fontWeight="bold">{signMethodLabel}</Typography>
              </Grid>
            </Grid>

            <Grid item xs container flexDirection="column" justifyContent="space-between">
              <Grid item>
                <Typography variant="subtitle1" fontWeight="bold" textAlign="right">
                  {policy?.client?.clientUser?.firstName} {policy?.client?.clientUser?.lastName ?? '-'}
                </Typography>
                <Typography textAlign="right">
                  {t('common.from')}: <b>{policy?.startProtection}</b>
                </Typography>
                <Typography textAlign="right">
                  {t('common.to')}: <b>{policy?.endProtection}</b>
                </Typography>
              </Grid>
            </Grid>
          </S.Wrapper>
        </S.Policy>
      </S.Container>
    </S.Main>
  );
}

export default AgentExternalPolicy;
