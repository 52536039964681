/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { installmentSelection } from 'api/client/installment/installmentApi';
import { useInsuranceMutate } from 'api/client/insurances/insurancesQuery';
import { getClientOffer, nextStage } from 'api/client/offer/offerApi';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import { InsuranceFormState } from 'pages/AgentRemoteSale/components/InsuranceBox/types';
import ClientChat from 'pages/ClientRemoteSale/components/ClientChat';
import ClientDataInfoBox from 'pages/ClientRemoteSale/components/ClientDataInfoBox';
import ClientNeedsInfoBox from 'pages/ClientRemoteSale/components/ClientNeedsInfoBox';
import { useClientForm } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientForm';
import { useClientOffer } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientOffer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormContent } from 'styles/FormContent';

import { LeftContainer, MainContainer, RightContainer } from 'components/Layout/styles';

import InsuranceList from './components/InsuranceList';
import * as S from './styles';

function Insurances() {
  const { t } = useTranslation();
  const {
    onUpdate,
    state: { selectedInsurance, insurances },
  } = useClientForm();
  const insuranceMutation = useInsuranceMutate();

  const [insurancesState, setInsurancesState] = useState(insurances);
  const [foundObject, setFoundObject] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chosenInsurance, setChosenInsurance] = useState<InsuranceFormState | undefined>(
    insurancesState?.find((insurance) => insurance.chosen),
  );
  const { offer } = useClientOffer();
  const [stage, setStage] = useState<number | undefined>(offer?.stage ?? undefined);

  const { goTo } = useWizard();

  useEffect(() => {
    setStage(offer?.stage);
  }, []);

  function sortDataByCriteria() {
    return insurancesState?.slice().sort((a: any, b: any) => {
      if (offer?.insurancesOrder === 'cost_asc') {
        const costA = a.cost.find((cost: any) => cost.chosen).cost;
        const costB = b.cost.find((cost: any) => cost.chosen).cost;
        return parseInt(costA) - parseInt(costB);
      } else if (offer?.insurancesOrder === 'cost_desc') {
        const costA = a.cost.find((cost: any) => cost.chosen).cost;
        const costB = b.cost.find((cost: any) => cost.chosen).cost;
        return parseInt(costB) - parseInt(costA);
      } else if (offer?.insurancesOrder === 'added') {
        return a.added - b.added;
      }

      return 0;
    });
  }

  useEffect(() => {
    if (!isLoading) {
      const sortedData = sortDataByCriteria();
      setInsurancesState(sortedData);
      setIsLoading(true);
    }
  }, [offer]);

  const handleInsuranceSelect = (selectedInsurance: InsuranceFormState) => {
    setChosenInsurance(selectedInsurance);
  };

  const reload = () => {
    window.location.reload();
  };

  const save = async () => {
    if (!chosenInsurance) return;

    const offer = await getClientOffer();

    if (offer.stage === stage) {
      installmentSelection(foundObject.id, { cost: foundObject.cost });

      insuranceMutation.mutate({ ...chosenInsurance, chosen: true });
      onUpdate({ selectedInsurance: foundObject.id });
    } else {
      reload();
    }
  };

  insurancesState?.forEach((item) => {
    const chosenCost = item.cost.find((cost: any) => cost.chosen === true);

    if (chosenCost) {
      item.total = chosenCost.cost;
    } else {
      item.total = 'Brak wybranej stawki';
    }
  });

  const handleReloadPage = () => window.location.reload();

  const next = async () => {
    const offer = await getClientOffer();

    if (offer.stage === 4) {
      window.location.reload();
    } else {
      try {
        await nextStage(offer.id);
        goTo(5, 5);
      } catch {
        toast.error('Coś poszło nie tak');
      }
    }
  };

  if (!isLoading) return null;

  if (!insurances) return <Alert severity="error">{t('errors.cantDisplay')}</Alert>;

  return (
    <>
      <MainContainer>
        <LeftContainer>
          <FormContent>
            <Typography variant="subtitle2" marginBottom={2}>
              {t('insuranceWizard.insuranceAdjustments.selectTheBestOffer')}
            </Typography>
            <InsuranceList
              setFoundObject={setFoundObject}
              insurances={insurances}
              onSelect={handleInsuranceSelect}
              insurancesState={insurancesState}
              setInsurancesState={setInsurancesState}
            />
            <Grid container justifyContent="space-between" alignItems="center">
              {selectedInsurance && (
                <S.SavedText marginTop={3} color="GrayText">
                  {t('insuranceWizard.insuranceAdjustments.offerSavedWaitingForAgent')}
                </S.SavedText>
              )}
              <S.Actions>
                {selectedInsurance && (
                  <>
                    <Button variant="outlined" onClick={next}>
                      {t('common.next')}
                    </Button>
                    <Button variant="outlined" onClick={handleReloadPage}>
                      {t('common.refresh')}
                    </Button>
                  </>
                )}
                <Button variant="contained" onClick={save} disabled={!chosenInsurance}>
                  {t('common.save')}
                </Button>
              </S.Actions>
            </Grid>
          </FormContent>
        </LeftContainer>
        <RightContainer>
          <Grid item container flexDirection="column" gap={3}>
            <ClientChat setMessagesApk={null} />
            <ClientDataInfoBox defaultOpen />
            <ClientNeedsInfoBox />
          </Grid>
        </RightContainer>
      </MainContainer>
    </>
  );
}

export default Insurances;
