import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel, Grid, SelectChangeEvent } from '@mui/material';
import { FileResult } from 'api/files/types';
import dayjs from 'dayjs';
import { useManageFormFiles } from 'library/hooks/useManageFormFiles';
import { ChangeEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFile } from 'components/Attachments/types';
import { ControlledAttachments, ControlledDatePicker, ControlledTextField, FormGroup } from 'components/Form';

import BankAccountNumberInput from './BankAccountNumberInput';
import SignMethods from './SignMethods';
import { createSchema } from './schema';
import * as S from './styles';
import { PolicyForm, SignMethod } from './type';
import { isDateField } from './utils';

interface Props {
  onSubmit: (data: PolicyForm) => void;
  children: React.ReactNode;
  initialValues?: PolicyForm;
  onUpload?: (file: File) => Promise<FileResult>;
  onAddFile?: (file: FormFile) => void;
  onDeleteFile?: (file: FormFile) => void;
  date: any;
}

function PolicyFormComponent({ onSubmit, children, onAddFile, onDeleteFile, onUpload }: Props) {
  const { t } = useTranslation();

  const [isRemotelyInSystem, setIsRemotelyInSystem] = useState(false);

  const { handleSubmit, control, setValue, watch, clearErrors } = useForm<PolicyForm>({
    mode: 'onBlur',
    resolver: yupResolver(createSchema(isRemotelyInSystem)),
  });
  const manageFiles = useManageFormFiles({ onAddFile, onDeleteFile, onUpload });

  const handleDateChange = (date: any, name: any) => {
    if (!isDateField(name)) return;

    if (!date) {
      setValue(name, null, { shouldValidate: true });
      return;
    }

    setValue(name, date.toJSDate(), { shouldValidate: true });

    if (name === 'startProtection') {
      const endDate = date.plus({ years: 1 }).minus({ days: 1 });
      setValue('endProtection', endDate.toJSDate(), { shouldValidate: true });
    }
  };

  const handleEndlessCheckbox = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setValue('endless', checked);
    clearErrors('endProtection');
  };

  const handleSignMethodChange = ({ target: { value } }: SelectChangeEvent<SignMethod>) => {
    setIsRemotelyInSystem(value === 'REMOTELY_IN_SYSTEM');
    setValue('signMethod', value as any, { shouldValidate: true });
  };

  const getDefaultEndDate = (startDate: any) => {
    const endDate = dayjs(startDate).add(1, 'year').subtract(1, 'day');
    return endDate.toDate();
  };

  const endlessPolicy = watch('endless');
  const attachments = watch('attachments');
  const signMethod = watch('signMethod');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup title={t('insuranceWizard.policy.addPolicyApplicationWithAttachments')}>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="signMethod"
              render={({ field, fieldState }) => (
                <SignMethods onChange={handleSignMethodChange} value={field.value || ''} fieldState={fieldState} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="tuNumber"
              defaultValue=""
              label={t('insuranceWizard.policy.tuNumber')}
              inputProps={{ InputLabelProps: { required: signMethod !== 'REMOTELY_IN_SYSTEM' } }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="name"
              defaultValue=""
              label={t('insuranceWizard.policy.name')}
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
          <Grid item xs={4.5}>
            <ControlledDatePicker
              control={control}
              name="startProtection"
              defaultValue={new Date()}
              onChange={handleDateChange}
              label={t('insuranceWizard.policy.startProtection')}
              inputProps={{ InputLabelProps: { required: true } }}
            />
          </Grid>
          <Grid item xs={4.5}>
            <ControlledDatePicker
              control={control}
              name="endProtection"
              defaultValue={getDefaultEndDate(new Date())}
              onChange={handleDateChange}
              label={t('insuranceWizard.policy.endProtection')}
              datePickerProps={{
                disabled: endlessPolicy,
              }}
              inputProps={{ InputLabelProps: { required: !endlessPolicy } }}
            />
          </Grid>
          <Grid item>
            <S.CheckboxContainer>
              <Controller
                control={control}
                name="endless"
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={field.value} onChange={handleEndlessCheckbox} />}
                    label={t('insuranceWizard.policy.endless')}
                  />
                )}
              />
            </S.CheckboxContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="accountNumber"
              defaultValue=""
              render={({ field, fieldState }) => (
                <BankAccountNumberInput value={field.value} onChange={field.onChange} fieldState={fieldState} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledDatePicker
              control={control}
              name="paymentDate"
              onChange={handleDateChange}
              label={t('insuranceWizard.policy.paymentDate')}
              inputProps={{ InputLabelProps: { required: false } }}
            />
          </Grid>
        </Grid>
      </FormGroup>
      <FormGroup title={t('insuranceWizard.policy.attachments')}>
        <ControlledAttachments
          control={control}
          name={'attachments' as never}
          files={attachments}
          onAttach={manageFiles.onAttach}
          onRemove={manageFiles.onRemove}
        />
      </FormGroup>
      {children}
    </form>
  );
}

export default PolicyFormComponent;
