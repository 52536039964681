import { Card, CardContent } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';
import { Expandable } from 'styles/Expandable';

export const InfoCard = styled(Card)`
  border: 1px solid ${theme.palette.info.main};
  background-color: ${theme.palette.infoBox.main};
  color: ${theme.palette.info.main};
`;

export const Content = styled(CardContent)`
  padding-top: 0;
  width: 100%;
  padding-bottom: ${theme.spacing(1)};
`;

export const ExpandMore = styled(Expandable)`
  margin-left: auto;
`;
