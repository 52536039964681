import { Client } from 'api/client/types';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';

function clientFormAsClient(formData: ClientDataFormState): Client {
  const { attachments: _, agreements, ...client } = formData;

  const allAccepted = Object.values(agreements).every((agreement) => agreement);

  return {
    ...client,
    agreements: allAccepted,
  };
}

export default clientFormAsClient;
