import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { patchPolicies } from 'api/agent/policy/policyApi';
import theme from 'library/theme/theme';
import { useState } from 'react';

import * as S from './styles';

function RemoveFromSettlement({ selectedCheckboxes, setData, data }: any) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const customStyle = { fontSize: '25px' };

  const mutation = useMutation(() => patchPolicies({ commissionPaid: false }, selectedCheckboxes), {
    onSuccess: () => {
      const updatedFilterData = [...data];

      const updatedTab = updatedFilterData.map((item) => {
        if (selectedCheckboxes.includes(item.id)) {
          return { ...item, commissionPaid: false };
        }
        return item;
      });

      setData(updatedTab);
      setIsOpenModal(false);
    },
  });

  const handleFormSubmit = async () => {
    setIsOpenModal(true);
  };

  const handleCancelRemove = () => {
    setIsOpenModal(false);
  };

  const handleConfirmRemove = () => {
    mutation.mutate();
  };

  return (
    <S.Wrapper>
      <Dialog
        open={isOpenModal}
        onClose={handleCancelRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Czy na pewno chcesz usunąć z <S.SpanColor>rozliczeń</S.SpanColor> ?
        </DialogTitle>
        <DialogActions>
          <S.ButtonWrapper>
            <S.ConfirmationButton color={theme.palette.primary.main} onClick={handleCancelRemove}>
              Nie
            </S.ConfirmationButton>
            <S.ConfirmationButton color="firebrick" onClick={handleConfirmRemove} autoFocus>
              Tak
            </S.ConfirmationButton>
          </S.ButtonWrapper>
        </DialogActions>
      </Dialog>
      <S.Button color="firebrick" onClick={handleFormSubmit} disabled={selectedCheckboxes.length === 0}>
        <CreditCardOffIcon style={customStyle} />
        <S.Text>
          usuń <S.Span>z rozliczenia</S.Span>
        </S.Text>
      </S.Button>
    </S.Wrapper>
  );
}

export default RemoveFromSettlement;
