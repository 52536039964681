import isValidNip from 'is-valid-nip';
import * as yup from 'yup';

export const individualClientSchema = yup.object({
  clientUser: yup.object({
    email: yup.string().email().required().trim().max(100),
  }),
});

export const legalClientSchema = yup.object({
  clientUser: yup.object({
    email: yup.string().email().required().trim().max(100),
  }),
  company: yup.object({
    name: yup.string().required().trim().max(150),
    taxId: yup
      .number()
      .test('nip', 'errors.invalidNip', (nip) => (nip ? isValidNip(nip.toString() || '') : true))
      .nullable()
      .transform((value) => (value ? value : undefined))
      .required(),
  }),
});
