import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

export const Box = styled.div<any>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap}px;
  width: 50%;
`;

export const Span = styled.span`
  color: ${theme.palette.primary.main};
  cursor: pointer;
  margin-left: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`;

export const Text = styled.h2`
  color: ${theme.palette.primary.main};
`;
