import { useQuery } from '@tanstack/react-query';
import { getAgentData } from 'api/agent/agentApi';
import { getSubscription } from 'api/agent/subscription/subscriptionApi';
import { useState } from 'react';
import { toast } from 'react-toastify';

import WizardLoadingView from 'components/WizardLoadingView';

import PasswordChange from './components/PasswordChange';
import Payment from './components/Payment';
import YourData from './components/YourData';
import * as S from './styles';

const AgentProfile = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const { data: agentData, isLoading: loadingProfile, isError: errorProfile } = useQuery(['agentData'], getAgentData);
  const { data: subscriptionData, isLoading: loadingSubscription } = useQuery(['subscriptionData'], getSubscription);

  const state = () => {
    switch (value) {
      case 0:
        return <YourData agentData={agentData} />;
      case 1:
        return <Payment agentData={agentData} subscriptionData={subscriptionData} />;
      case 2:
        return <PasswordChange />;
      default:
        return null;
    }
  };

  if (loadingProfile || loadingSubscription) {
    return <WizardLoadingView height="100vh" />;
  }

  if (errorProfile) {
    toast.error('Coś poszło nie tak');
    return null;
  }

  return (
    <S.Main>
      <S.Line />
      <S.Wrapper>
        <S.Box>
          <S.StyledTabs value={value} onChange={handleChange}>
            <S.StyledTab label="Twoje dane" />
            <S.StyledTab label="Płatności za aplikację" />
            <S.StyledTab label="Zmiana hasła" />
          </S.StyledTabs>
        </S.Box>
        {state()}
      </S.Wrapper>
    </S.Main>
  );
};

export default AgentProfile;
