import { TFunction } from 'i18next';

export interface ValidationError {
  key: string;
  options?: { [key: string]: any };
}

export type ErrorMessage = ValidationError | string;

export const errorMessage = (t: TFunction, error?: ErrorMessage): string => {
  if (!error) return '';

  if (typeof error === 'string') return t(error as never);

  if ('key' in error && 'options' in error) {
    const { key, options } = error as ValidationError;
    return t(key as never, options);
  }

  return '';
};
