import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import { useAgent, useUpdateClient } from 'contexts/AgentAuthContext/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalActions, ModalContainer } from 'styles';
import { ModalContent } from 'styles/ModalContent';

import { ClientTypes } from '../../types';
import IndividualClient from './components/IndividualClient';
import LegalClient from './components/LegalClient';
import { individualClientSchema, legalClientSchema } from './schema';
import { ClientCreationForms } from './types';

interface Props {
  type: ClientTypes;
  onCancel: () => void;
  initialValue: string;
  filteredOptions: any;
}

function ClientCreation({ type, onCancel, initialValue, filteredOptions }: Props) {
  const formMethods = useForm<ClientCreationForms>({
    defaultValues:
      type === ClientTypes.individualPerson
        ? { clientUser: { email: initialValue } }
        : { company: { name: initialValue } },
    resolver:
      type === ClientTypes.individualPerson ? yupResolver(individualClientSchema) : yupResolver(legalClientSchema),
  });
  const { mutate, isLoading } = useUpdateClient(undefined);
  const agent = useAgent();
  const { t } = useTranslation();

  const onSubmit = () => {
    const formData = formMethods.getValues();

    const emailExists = filteredOptions.find((client: any) => client.clientUser.email === formData.clientUser.email);

    if (emailExists) {
      onCancel();
      toast.error('Ten email już istnieje w danych klienta.');
      return;
    }

    mutate(
      { ...formData, agent: agent?.id || '' },
      {
        onSuccess: () => {
          onCancel();
        },
      },
    );
  };

  return (
    <ModalContent>
      <CardHeader title={t('insuranceWizard.clientSearch.addNewClient')} />
      <ModalContainer $withActions>
        <FormProvider {...formMethods}>
          {type === ClientTypes.individualPerson && <IndividualClient />}
          {type === ClientTypes.legalPerson && <LegalClient />}
        </FormProvider>
        <ModalActions>
          <Button onClick={onCancel}>{t('common.cancel')}</Button>
          <Button variant="contained" onClick={onSubmit} type="submit" disabled={isLoading}>
            {t('common.save')}
          </Button>
        </ModalActions>
      </ModalContainer>
    </ModalContent>
  );
}

export default ClientCreation;
