import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import theme from 'library/theme/theme';

import * as S from './styles';

const Tasks = ({ isTaskDialogOpen, handleCancelTask, handleConfirmTask, name }: any) => {
  return (
    <Dialog
      open={isTaskDialogOpen}
      onClose={handleCancelTask}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Czy na pewno chcesz {name} te zadania ?</DialogTitle>
      <DialogActions>
        <S.ButtonWrapper>
          <S.Button color={theme.palette.primary.main} onClick={handleCancelTask}>
            Nie
          </S.Button>
          <S.Button color="firebrick" onClick={handleConfirmTask} autoFocus>
            Tak
          </S.Button>
        </S.ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default Tasks;
