import { Paper } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
`;

export const SignUpWrapper = styled(Paper).attrs({ variant: 'outlined' })`
  width: 600px;
  padding: ${theme.spacing(3)};
`;
