import { CardContent, CardHeader } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';
import { Expandable } from 'styles/Expandable';

export const Header = styled(CardHeader)`
  padding-bottom: 0;
`;

export const Content = styled(CardContent)`
  padding-top: 0;
  width: 100%;
  padding-bottom: ${theme.spacing(1)};
`;

export const ExpandMore = styled(Expandable)`
  margin-left: auto;
`;
