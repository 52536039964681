import { Button } from '@mui/material';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

interface Props {
  clientData: any;
  onNextStep: () => void;
}

function ActionButtons({ onNextStep }: Props) {
  const { t } = useTranslation();
  const { watch } = useFormContext<ClientDataFormState>();

  const agreements = watch('agreements');
  const agreementsAccepted =
    agreements === undefined ? false : Object.values(agreements).every((agreement) => agreement);

  return (
    <S.Actions>
      <Button type="submit">{t('common.save')}</Button>
      <Button disabled={!agreementsAccepted} variant="contained" onClick={onNextStep}>
        {t('common.next')}
      </Button>
    </S.Actions>
  );
}

export default ActionButtons;
