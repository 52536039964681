import { autocompleteClasses, inputBaseClasses } from '@mui/material';
import theme from 'library/theme/theme';
import styled, { css } from 'styled-components';

export const AutoCompleteWrapper = styled.div<{ hasImg: boolean; isText: boolean }>`
  position: relative;
  .${autocompleteClasses.inputRoot} {
    padding-right: ${theme.spacing(1)} !important;
  }

  ${({ hasImg, isText }) => {
    if (hasImg)
      return css`
        .${inputBaseClasses.root} {
          padding-left: ${isText ? '45px' : '7px'};
        }
      `;
  }}
`;

export const ImgWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-left: ${theme.spacing(1)};
  width: 50px;
  img {
    vertical-align: middle;
    width: 90%;
    height: 90%;
  }
`;

export const ImgInList = styled.img`
  margin-right: ${theme.spacing(2)};
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  object-fit: contain;
`;
