import theme from 'library/theme/theme';
import styled, { css } from 'styled-components';

interface StepIconRootProps {
  $active?: boolean;
  $completed?: boolean;
  $page?: boolean;
  $stateClick: boolean;
}

export const StepIconRoot = styled.button<StepIconRootProps>`
  .info-content {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    z-index: 1;
    top: 60px;
    width: 115px;
    height: 40px;
    left: -35px;
    color: white;
    background-color: grey;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 1px #888888;
    pointer-events: none;
    font-weight: 700;
  }
  border: 1px solid ${theme.palette.primary.main};
  color: ${theme.palette.primary.main};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: white;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  @media (max-width: 800px) {
    width: 40px;
    height: 40px;
  }
  svg {
    font-size: 30px;
    @media (max-width: 800px) {
      width: 25px;
      height: 25px;
    }
  }
  ${({ $active, $completed, $page, $stateClick }) =>
    ($active || $completed) &&
    css`
      border: none;
      background-color: ${$stateClick ? '#AC242C' : theme.palette.primary.main};
      color: ${theme.palette.common.white};
      cursor: ${$page ? `pointer` : `initial`};
      &:hover {
        background-color: ${$page ? '#e5f6fd' : theme.palette.primary.main};
        color: ${$page ? theme.palette.primary.main : theme.palette.common.white};
        /* Pokaż tekst przy najechaniu kursorem */
        .info-content {
          opacity: 1;
        }
      }
    `};
`;

export const InfoContent = styled.span`
  background-color: #f0f0f0;
  border: 1px solid #ccc;
`;
