import { Typography } from '@mui/material';

import * as S from './styles';

function FirstRegistrationInformation() {
  return (
    <S.Main>
      <Typography fontWeight="bold" variant="h6" style={{ marginBottom: '10px', fontSize: '16px' }}>
        Rejestracja przebiegła pomyślnie !
      </Typography>
      <Typography fontWeight="bold" variant="h6" style={{ marginBottom: '10px', fontSize: '16px' }}>
        Potwierdź swój e-mail:
      </Typography>
      <Typography variant="body1" style={{ fontSize: '16px' }}>
        1. Sprawdź swoją skrzynkę e-mail
      </Typography>
      <Typography variant="body1" style={{ fontSize: '16px' }}>
        2. Poszukaj wiadomości od nas (być może wpadła do SPAMu)
      </Typography>
      <Typography variant="body1" style={{ fontSize: '16px' }}>
        3. Postępuj zgodnie z instrukcjami w wiadomości
      </Typography>
    </S.Main>
  );
}

export default FirstRegistrationInformation;
