import { FormControl, formControlLabelClasses } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Control = styled(FormControl)`
  width: 100%;
  .${formControlLabelClasses.root} {
    margin-right: 0;
    margin-bottom: ${theme.spacing(2)};
  }

  .${formControlLabelClasses.label} {
    width: 100%;
  }
`;
