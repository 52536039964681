import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { ClientNeedsFormState } from 'pages/AgentRemoteSale/steps/ClientNeeds/types';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledDatePicker } from 'components/Form';

import * as S from './styles';

interface Props {
  onChange: (date: DateTime | null) => void;
  control: Control<ClientNeedsFormState, any>;
}

function DatePicker({ control, onChange }: Props) {
  const { t } = useTranslation();

  const handleDateChange = (days: number) => (_: React.MouseEvent<HTMLButtonElement>) => {
    onChange(DateTime.now().plus({ days }));
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <ControlledDatePicker
        control={control}
        name="startDate"
        onChange={onChange}
        label={t('insuranceWizard.clientNeeds.policyStart')}
        inputProps={{ InputLabelProps: { required: true }, fullWidth: false }}
      />
      <S.DayButton onClick={handleDateChange(0)}>{t('common.today')}</S.DayButton>
      <S.DayButton onClick={handleDateChange(1)}>{t('common.tomorrow')}</S.DayButton>
      <S.DayButton onClick={handleDateChange(2)}>{t('common.dayAfterTomorrow')}</S.DayButton>
    </Grid>
  );
}

export default DatePicker;
