import { useCallback, useMemo, useState } from 'react';

import { MessageState } from '../types';

export function useMessages(defaultState: MessageState[] = []) {
  const [messages, setMessages] = useState<MessageState[]>(defaultState);

  const resetMessages = useCallback((newMessages: MessageState[]) => {
    setMessages(newMessages);
  }, []);

  const handleUpdateMessage = useCallback(
    (id: string, message: Partial<MessageState>) =>
      setMessages((prev) =>
        prev.map((current) => {
          if (current.id === id) return { ...current, ...message };
          return current;
        }),
      ),
    [],
  );

  const handleAddMessage = useCallback((newMessage: MessageState) => {
    setMessages((prev) => [...prev, newMessage]);
  }, []);

  return useMemo(
    () => ({ messages, onAdd: handleAddMessage, onUpdate: handleUpdateMessage, setMessages: resetMessages }),
    [handleAddMessage, handleUpdateMessage, messages, resetMessages],
  );
}
