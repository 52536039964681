import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Link, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { login } from 'api/auth/authApi';
import { LoginRequestBody } from 'api/auth/types';
import { useAgentActions } from 'contexts/AgentAuthContext/hooks';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'routes/paths';

import { ControlledTextField } from 'components/Form';
import ControlledPassword from 'components/Form/Controls/ControlledPassword';
import SpinnerMini from 'components/SpinnerMini';

import FirstLoginInformation from './components/FirstLoginInformation';
import { loginSchema } from './schema';
import * as S from './styles';

function AgentSignIn() {
  const { mutate, isLoading } = useMutation(login);
  const { t } = useTranslation();
  const { signIn, setEmailState } = useAgentActions();
  const { control, handleSubmit, reset } = useForm<LoginRequestBody>({ resolver: yupResolver(loginSchema) });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isMailVerified = searchParams.get('mail_verified') === 'true';

  const onSubmit = (data: LoginRequestBody) => {
    const { email } = data;

    mutate(data, {
      onSuccess: (res) => {
        signIn(res);
        reset({
          email: '',
          password: '',
        });
      },
      onError: () => {
        setEmailState(email);
      },
    });
  };

  return (
    <S.Main>
      {isMailVerified ? <FirstLoginInformation /> : null}
      <S.SignInWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5">{t('agent.signInTitle')}</Typography>
            </Grid>
            <Grid item container gap={2}>
              <ControlledTextField control={control} name="email" label={t('agent.email')} />
              <ControlledPassword
                control={control}
                name="password"
                label={t('common.password')}
                inputProps={{ autoComplete: 'new-password' }}
                useVisibilitySwitch
              />
            </Grid>
            <Grid container justifyContent="flex-end" marginTop={2}>
              <Trans
                i18nKey="agent.remindPassword"
                components={{ a: <Link href={`${PATHS.agent.index}/${PATHS.agent.remindPassword}`} /> }}
              />
            </Grid>
            <Grid item container justifyContent="space-between" alignItems="center" marginTop={2}>
              <Grid item>
                <Trans
                  i18nKey="agent.dontHaveAccount"
                  components={{ a: <Link href={`${PATHS.agent.index}/${PATHS.agent.singUp}`} /> }}
                />
              </Grid>
              <Button variant="contained" style={{ width: '120px', height: '35px' }} type="submit" disabled={isLoading}>
                {isLoading ? <SpinnerMini /> : t('common.signIn')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </S.SignInWrapper>
    </S.Main>
  );
}

export default AgentSignIn;
