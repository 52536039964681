import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LanguageIcon from '@mui/icons-material/Language';
import PeopleIcon from '@mui/icons-material/People';
import RuleIcon from '@mui/icons-material/Rule';
import { useTranslation } from 'react-i18next';
import { PATHS } from 'routes/paths';

import Tile from '../Tile';
import * as S from './styles';

function Links() {
  const { t } = useTranslation();

  return (
    <S.TilesContainer>
      <Tile
        link={`${PATHS.agent.index}/${PATHS.agent.remoteSale}`}
        icon={<LanguageIcon />}
        label={t('agentDashboard.remoteSales')}
      />
      <Tile
        link={`${PATHS.agent.index}/${PATHS.agent.service}`}
        icon={<RuleIcon />}
        label={t('agentDashboard.service')}
      />
      <Tile
        link={`${PATHS.agent.index}/${PATHS.agent.clientsAndPolicies}`}
        icon={<PeopleIcon />}
        label={t('agentDashboard.clientsAndPolicies')}
      />
      <Tile
        link={`${PATHS.agent.index}/${PATHS.agent.settlements}`}
        icon={<AccountBalanceWalletIcon />}
        label={t('agentDashboard.settlements')}
      />
    </S.TilesContainer>
  );
}

export default Links;
