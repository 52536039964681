import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from 'library/theme/theme';

import * as S from './styles';

const DeletingProcesses = ({ isDeleteDialogOpen, handleCancelDelete, handleConfirmDelete }: any) => {
  return (
    <Dialog
      open={isDeleteDialogOpen}
      onClose={handleCancelDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Czy na pewno chcesz usunąć oferty?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Tej operacji nie można cofnąć. Czy na pewno chcesz kontynuować?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <S.Button color={theme.palette.primary.main} onClick={handleCancelDelete}>
          Anuluj
        </S.Button>
        <S.Button color="firebrick" onClick={handleConfirmDelete} autoFocus>
          Usuń
        </S.Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletingProcesses;
