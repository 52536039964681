import { Accept } from 'react-dropzone';

const ALLOWED_FILE_TYPES: Accept = {};

const config = {
  DEFAULT_LANG: 'pl',
  DATE_FORMAT_SHORT: 'dd.MM.yyyy',
  DATE_TIME_FORMAT_SHORT: 'dd.MM.yyyy HH:mm',
  DATE_TIME_FORMAT_LONG: 'ccc dd.MM.yyyy HH:mm',
  BANK_ACCOUNT_NUMBER_MASK: '99 9999 9999 9999 9999 9999 9999',
  MAX_FILE_SIZE: 10_000_000, // 10MB in bytes,
  ALLOWED_FILE_TYPES,
  MESSAGE_REFETCH_INTERVAL: 10_000,
};

export default config;
