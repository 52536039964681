import { Grid, Typography } from '@mui/material';
import { useClientForm } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientForm';

import * as S from './styles';

function Announcement({ value }: any) {
  const {
    state: { selectedInsurance, policy },
  } = useClientForm();

  return (
    <S.Wrapper>
      <Grid marginBottom={2} marginTop={2}>
        <Typography variant="body2" fontWeight="bold">
          Instrukcja postępowania:
        </Typography>
      </Grid>
      <Grid paddingLeft={2}>
        {value.descriptions.map((item: string, index: number) => (
          <Typography key={index} variant="body2">
            {index === 1 && policy?.signMethod === 'REMOTELY_IN_SYSTEM' ? item + selectedInsurance?.companyBrand : item}
          </Typography>
        ))}
      </Grid>
      <Grid marginBottom={2} marginTop={2}>
        <Typography variant="body2">W razie potrzeby skontaktuj się z Doradcą.</Typography>
      </Grid>
      <Grid marginBottom={2}>
        <Typography variant="body2" fontWeight="bold" color="firebrick">
          UWAGA
        </Typography>
        <Typography variant="body2">
          Jeśli w tej chwili nie zrobisz nic, będzie to równoznaczne z rezygnacją z zakupu polisy.
        </Typography>
      </Grid>
    </S.Wrapper>
  );
}

export default Announcement;
