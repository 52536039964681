import { Policy as ApiPolicy, CreateUpdatePolicy } from 'api/types/policy';
import { DateTime } from 'luxon';
import { FieldPath } from 'react-hook-form';
import { fileResultAsFormFile } from 'utils/formFile';

import { PolicyForm, SignMethod } from './type';

export const isDateField = (name: FieldPath<PolicyForm>) =>
  ['startProtection', 'paymentDate', 'endProtection'].includes(name);

export const policyFormAsCreateUpdatePolicy = (policy: PolicyForm): CreateUpdatePolicy => ({
  offer: policy.offer,
  tuNumber: policy.tuNumber,
  name: policy.name,
  startProtection: DateTime.fromJSDate(policy.startProtection).toISODate(),
  endProtection: policy.endProtection ? DateTime.fromJSDate(policy.endProtection).toISODate() : null,
  endless: policy.endless,
  accountNumber: policy.accountNumber,
  paymentDate: DateTime.fromJSDate(policy.paymentDate).toISODate(),
  signType: policy.signMethod,
});

export const policyAsPolicyForm = (policy: ApiPolicy): PolicyForm => ({
  id: policy.id,
  tuNumber: policy.tuNumber,
  name: policy.name,
  startProtection: DateTime.fromISO(policy.startProtection).toJSDate(),
  endProtection: policy.endProtection ? DateTime.fromISO(policy.endProtection).toJSDate() : null,
  endless: policy.endless,
  accountNumber: policy.accountNumber,
  paymentDate: DateTime.fromISO(policy.paymentDate).toJSDate(),
  receivedDate: policy.receivedDate ? DateTime.fromISO(policy.receivedDate).toJSDate() : null,
  signMethod: policy.signType as SignMethod,
  attachments: policy.files?.map(fileResultAsFormFile) ?? [],
  received: policy.received || false,
  confirmed: policy.confirmed || false,
  confirmedDate: policy.confirmedDate ? DateTime.fromISO(policy.confirmedDate).toJSDate() : null,
  commissionPaid: policy.commissionPaid || false,
  policyPaid: policy.policyPaid || false,
  offer: policy.offer,
});
