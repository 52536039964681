import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { register } from 'api/auth/authApi';
import { useAgentActions } from 'contexts/AgentAuthContext/hooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { PATHS } from 'routes/paths';

import { ControlledTextField } from 'components/Form';
import ControlledPassword from 'components/Form/Controls/ControlledPassword';
import ControlledSwitch from 'components/Form/Controls/ControlledSwitch';
import SpinnerMini from 'components/SpinnerMini';

import FirstRegistrationInformation from './components/FirstRegistrationInformation';
import { registrationSchema } from './schema';
import * as S from './styles';
import { RegistrationForm } from './types';

function AgentSignUp() {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm<RegistrationForm>({ resolver: yupResolver(registrationSchema) });
  const { mutate, isLoading } = useMutation(register);
  const { emailState, setEmailState } = useAgentActions();
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = ({ privacyPolicy: _, ...data }: RegistrationForm) =>
    mutate(data, {
      onSuccess: () => {
        setIsSuccess(true);
        setEmailState('');
        reset({
          email: '',
          password1: '',
          password2: '',
          privacyPolicy: false,
        });
      },
    });

  return (
    <S.Main>
      {isSuccess ? <FirstRegistrationInformation /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.SignUpWrapper>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h5">{t('agent.signUpTitle')}</Typography>
            </Grid>
            <Grid item container gap={2}>
              <ControlledTextField control={control} name="email" label={t('agent.email')} defaultValue={emailState} />
              <ControlledPassword
                control={control}
                name="password1"
                label={t('common.password')}
                inputProps={{ autoComplete: 'new-password' }}
                useVisibilitySwitch
              />
              <ControlledPassword
                control={control}
                name="password2"
                label={t('agent.confirmPassword')}
                useVisibilitySwitch
              />
              <Box marginTop={1}>
                <ControlledSwitch
                  control={control}
                  name="privacyPolicy"
                  content={<Trans i18nKey="agent.privacyPolicy" components={{ a: <Link href="" /> }} />}
                />
              </Box>
            </Grid>
            <Grid item container justifyContent="space-between" alignItems="center" marginTop={1}>
              <Grid item>
                <Trans
                  i18nKey="agent.alreadyHaveAccount"
                  components={{ a: <Link href={`${PATHS.agent.index}/${PATHS.agent.signIn}`} /> }}
                />
              </Grid>
              <Button style={{ width: '150px', height: '35px' }} variant="contained" type="submit" disabled={isLoading}>
                {isLoading ? <SpinnerMini /> : t('common.signUp')}
              </Button>
            </Grid>
          </Grid>
        </S.SignUpWrapper>
      </form>
    </S.Main>
  );
}
export default AgentSignUp;
