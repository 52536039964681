import theme from 'library/theme/theme';
import styled, { css } from 'styled-components';

import { MessageVariant } from 'components/Chat/types';

interface SpeechBubbleProps {
  variant: MessageVariant;
}

export const SpeechBubble = styled.div<SpeechBubbleProps>`
  width: fit-content;
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  border-radius: 8px;
  position: relative;
  margin: 0 ${theme.spacing(2)};
  white-space: pre-line;
  min-width: 150px;
  max-width: 80%;
  text-align: left;

  > div {
    position: relative;
  }

  .sent-time {
    color: ${theme.palette.grey[400]};
    font-size: 11px;
    position: absolute;
    top: -15px;
  }

  .status-icon {
    position: absolute;
    bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  ${({ variant }) => {
    if (variant === 'me') {
      return css`
        &:before {
          right: -8px;
          bottom: 0px;
          border: 16px solid transparent;
          border-left-color: ${theme.palette.primary.main};
          border-right: 0;
          border-bottom: 0;
        }

        .status-icon {
          left: -20px;
        }

        .sent-time {
          right: 0;
        }

        background: ${theme.palette.primary.main};
        color: ${theme.palette.common.white};
        margin-left: auto;
      `;
    } else {
      return css`
        &:before {
          left: -8px;
          bottom: 0px;
          border: 16px solid transparent;
          border-right-color: ${theme.palette.grey[200]};
          border-left: 0;
          border-bottom: 0;
        }

        .status-icon {
          right: -20px;
        }

        .sent-time {
          left: 0;
        }

        background: ${theme.palette.grey[200]};
        margin-right: auto;
      `;
    }
  }}
`;
