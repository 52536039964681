import Box, { BoxProps } from '@mui/material/Box';

import * as S from './styles';

interface Props extends BoxProps {
  description: string;
}

function MethodType({ description, ...rest }: Props) {
  return (
    <Box {...rest}>
      <S.AcceptanceDescription>{description}</S.AcceptanceDescription>
    </Box>
  );
}

export default MethodType;
