import { FormHelperText, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { errorMessage } from 'library/language/validation/errorMessage';
import { useMemo } from 'react';
import { FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  content?: React.ReactNode;
  required?: boolean;
  labelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
  switchProps?: SwitchProps;
}

function ControlledSwitch<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ content, required, switchProps, labelProps, ...controllerProps }: Props<TFieldValues, TName>) {
  const { t } = useTranslation();
  const { field, fieldState } = useController(controllerProps);

  const displayLabel = useMemo(() => {
    if (!required) return content;
    if (typeof content !== 'object') return `${content} *`;
    else return <>{content} *</>;
  }, [content, required]);

  return (
    <FormControl fullWidth>
      <FormControlLabel
        {...labelProps}
        control={<Switch {...switchProps} checked={field.value || false} onChange={field.onChange} />}
        label={<Typography style={{ fontSize: 12 }}>{displayLabel}</Typography>}
      />
      {!!fieldState.error && <FormHelperText error>{errorMessage(t, fieldState.error.message)}</FormHelperText>}
    </FormControl>
  );
}
export default ControlledSwitch;
