import styled from 'styled-components';

export const Container = styled.div`
  background-image: linear-gradient(102deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.5) 0%);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
`;
