import { Button, CardHeader, Grid, Modal, Tooltip } from '@mui/material';
import config from 'config';
import useModal from 'library/hooks/useModal';
import { DateTime } from 'luxon';
import EditorInfoBox from 'pages/AgentRemoteSale/components/EditorInfoBox';
import NeedsForm from 'pages/AgentRemoteSale/steps/ClientNeeds/components/NeedsForm/NeedsForm';
import { NEEDS } from 'pages/AgentRemoteSale/steps/ClientNeeds/components/NeedsForm/const';
import { ClientNeedsFormState } from 'pages/AgentRemoteSale/steps/ClientNeeds/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalActions, ModalContainer, ModalContent } from 'styles';

import LabelValue from 'components/LabelValue';
import OfferBox from 'components/OfferBox';

import * as S from './styles';

interface Props {
  defaultOpen?: boolean;
  clientNeeds: ClientNeedsFormState;
  onUpdate?: (formState: ClientNeedsFormState) => void;
  readonly?: boolean;
}

function BaseClientNeedsBox({ defaultOpen, clientNeeds, onUpdate, readonly }: Props) {
  const { t } = useTranslation();
  const { open: showEditModal, handleOpen: handleOpenEditModal, handleClose: handleCloseModal } = useModal();

  const handleSubmit = (data: ClientNeedsFormState) => {
    handleCloseModal();
    onUpdate && onUpdate(data);
  };

  const foundItem = NEEDS.find((item) => {
    return item.name.includes(clientNeeds.insuranceType);
  });

  const formattedStartDate = DateTime.fromJSDate(clientNeeds.startDate).isValid
    ? DateTime.fromJSDate(clientNeeds.startDate).toFormat(config.DATE_FORMAT_SHORT)
    : clientNeeds.startDate;

  return (
    <>
      <EditorInfoBox
        onEdit={handleOpenEditModal}
        title={t('insuranceWizard.needs')}
        defaultOpen={defaultOpen}
        readonly={readonly}
      >
        <Grid container spacing={1}>
          <Grid item container xs={12} gap={1}>
            <OfferBox offer="insurance" iconOnly />
            <OfferBox offer="newPolicy" iconOnly />
            <Tooltip title={foundItem?.name}>
              <S.Container>
                {foundItem?.icon && React.cloneElement(foundItem?.icon, { fontSize: 'medium' })}
              </S.Container>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelValue label={t('insuranceWizard.clientNeeds.needName')}>{clientNeeds.name}</LabelValue>
          </Grid>
          {clientNeeds.description && (
            <Grid item xs={12} md={6}>
              <LabelValue label={t('insuranceWizard.clientNeeds.descriptionOfNeed')} multiline>
                {clientNeeds.description}
              </LabelValue>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <LabelValue label={t('insuranceWizard.clientNeeds.policyStart')}>{formattedStartDate}</LabelValue>
          </Grid>
        </Grid>
      </EditorInfoBox>
      <Modal open={showEditModal} onClose={handleCloseModal}>
        <ModalContent>
          <CardHeader title={t('insuranceWizard.clientNeeds.editNeeds')} />
          <ModalContainer $withActions>
            <NeedsForm onSubmit={handleSubmit} initialState={clientNeeds}>
              <ModalActions>
                <Button onClick={handleCloseModal}>{t('common.cancel')}</Button>
                <Button variant="contained" type="submit">
                  {t('common.save')}
                </Button>
              </ModalActions>
            </NeedsForm>
          </ModalContainer>
        </ModalContent>
      </Modal>
    </>
  );
}
export default BaseClientNeedsBox;
