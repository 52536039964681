import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { ErrorMessage, errorMessage } from 'library/language/validation/errorMessage';
import { DateTime } from 'luxon';
import { FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  onChange: (date: DateTime | null, name: TName) => void;
  label?: string;
  inputProps?: TextFieldProps;
  datePickerProps?: Omit<DatePickerProps<TextFieldProps, DateTime>, 'onChange' | 'value' | 'renderInput'>;
}

function ControlledDatePicker<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ onChange, label, inputProps, datePickerProps, ...controllerProps }: Props<TFieldValues, TName>) {
  const { t } = useTranslation();
  const { field, fieldState } = useController(controllerProps);

  const handleChange = (date: DateTime | null) => {
    onChange(date, controllerProps.name);
  };

  return (
    <DatePicker
      disablePast
      disableMaskedInput
      label={label}
      {...field}
      {...datePickerProps}
      value={field.value || null}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!fieldState.error}
          helperText={errorMessage(t, fieldState?.error?.message as ErrorMessage)}
          autoComplete="off"
          fullWidth
          {...inputProps}
        />
      )}
    />
  );
}

export default ControlledDatePicker;
