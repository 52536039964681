import { Image, Text, View } from '@react-pdf/renderer';
import moment from 'moment';

function Header({ logo }: any) {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Text style={{ textAlign: 'left', fontSize: '20px', fontFamily: 'Anton' }}>
          Analiza Potrzeb Klienta nr 34343443
        </Text>
        <Text style={{ fontSize: '13px' }}>
          Ostatnia aktualizacja danych {moment(new Date()).format('DD-MM-YYYY HH:mm')}
        </Text>
      </View>
      <Image
        src={logo}
        style={{
          width: 65,
          height: 65,
          backgroundColor: '#E6E6E5',
        }}
      />
    </View>
  );
}

export default Header;
