import { Paper } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const RemindPasswordWrapper = styled(Paper).attrs({ variant: 'outlined' })`
  max-width: 600px;
  margin: 0 auto;
  padding: ${theme.spacing(3)};
  margin-top: ${theme.spacing(20)};
`;
