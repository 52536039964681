import { DateTime } from 'luxon';
import * as yup from 'yup';

const dateValidator = yup
  .date()
  .min(DateTime.now().startOf('day'), 'errors.dateCantBeFromPast')
  .typeError({ key: 'errors.dateMustBeInFormat', options: { format: 'DD.MM.YYYY' } })
  .required();

const dateValidator2 = yup
  .date()
  .min(DateTime.now().startOf('day'), 'errors.dateCantBeFromPast')
  .typeError({ key: 'errors.dateMustBeInFormat', options: { format: 'DD.MM.YYYY' } })
  .required();

export const createSchema = (isRemotelyInSystem: boolean, isIndividual: boolean) => {
  const baseSchema = {
    tuNumber: isRemotelyInSystem ? yup.string() : yup.string().required(),
    companyBrand: yup.string().required(),
    cost: yup.string().required(),
    installment: yup.string().required(),
    name: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    pesel: isIndividual ? yup.string().required() : yup.string(),
    address: yup.string().required(),
    city: yup.string().required(),
    zipCode: yup.string().required(),
    phoneNumber: yup.string().required(),
    buildingNumber: yup.string().required(),
    signMethod: yup.string().required(),
    name_policy: yup.string().required(),
    insuranceType: yup.string().required(),
    business: !isIndividual ? yup.string().required() : yup.string(),
    nip: !isIndividual ? yup.string().required() : yup.string(),
    startProtection: dateValidator,
    endProtection: dateValidator2,
  };

  return yup.object().shape(baseSchema);
};
