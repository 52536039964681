import * as yup from 'yup';

export const newPasswordSchema = yup.object().shape({
  new_password1: yup
    .string()
    .required('Nowe hasło jest wymagane')
    .min(8, 'Nowe hasło musi mieć co najmniej 8 znaków')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      'Nowe hasło musi zawierać co najmniej jedną małą literę, jedną dużą literę, jedną cyfrę i jeden znak specjalny (@$!%*?&)',
    ),
  new_password2: yup
    .string()
    .oneOf([yup.ref('new_password1'), null], 'Hasła muszą się zgadzać')
    .required('Potwierdź nowe hasło'),
  token: yup.string().required(),
  uid: yup.string().required(),
});
