import { Offer } from 'api/agent/offer/types';
import { Client } from 'api/client/types';
import { ClientTypes } from 'pages/AgentRemoteSale/components/ClientSearch/types';
import { insuranceAsInsuranceForm } from 'pages/AgentRemoteSale/steps/InsurancesSuggestions/utils';
import { fileResultAsFormFile } from 'utils/formFile';

import { ClientDataFormState, FormState } from './types';

export function offerAsFormState(offer: Offer, currentFormState: FormState): FormState {
  return {
    offerId: offer.id,
    clientNeeds: {
      name: offer.needsShort,
      description: offer.needsDescription,
      startDate: new Date(offer.startDate),
      insuranceType: offer.insuranceType,
    },
    clientData: currentFormState.clientData
      ? {
          ...currentFormState.clientData,
          attachments: offer.files.map(fileResultAsFormFile),
        }
      : undefined,
    insurances: offer.insurance.map(insuranceAsInsuranceForm),
  };
}

export function clientAsClientDataFormState(client: Client): ClientDataFormState {
  return {
    ...client,
    company: client.company
      ? {
          ...client.company,
          taxId: client.company.taxId.toString(),
        }
      : null,
    // attachments are part of offer which might not be fetched yet
    attachments: [],
    agreements: {
      privacyPolicy: client.agreements ?? false,
      marketingInformationThroughElectronic: client.agreements ?? false,
      marketingInformationThroughOFWCA: client.agreements ?? false,
      contractConclusion: client.agreements ?? false,
    },
  };
}

export function selectedClientAsFormState(client: Client): FormState {
  return {
    selectedClient: {
      id: client.id,
      type: !client.company ? ClientTypes.individualPerson : ClientTypes.legalPerson,
    },
    clientData: {
      ...client,
      company: client.company
        ? {
            ...client.company,
            taxId: client.company.taxId.toString(),
          }
        : null,
      // attachments are part of offer which might not be fetched yet
      attachments: [],
      agreements: {
        privacyPolicy: client.agreements ?? false,
        marketingInformationThroughElectronic: client.agreements ?? false,
        marketingInformationThroughOFWCA: client.agreements ?? false,
        contractConclusion: client.agreements ?? false,
      },
    },
  };
}
