import RefreshIcon from '@mui/icons-material/Refresh';
import { Alert, Button, Grid, Typography } from '@mui/material';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import AgentChat from 'pages/AgentRemoteSale/components/AgentChat';
import InsuranceSummary from 'pages/AgentRemoteSale/components/InsuranceSummary';
import InvitationSenderBox from 'pages/AgentRemoteSale/components/InvitationSenderBox';
import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { useUpdateOffer } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import { WizardSteps } from 'pages/AgentRemoteSale/types';
import { useTranslation } from 'react-i18next';
import { FormContent } from 'styles/FormContent';

import { LeftContainer, MainContainer, RightContainer } from 'components/Layout/styles';

import * as S from './styles';

function ClientOffer() {
  const { t } = useTranslation();
  const { state } = useAgentForm();
  const { goTo } = useWizard();

  const offerMutation = useUpdateOffer();
  const selectedInsurance = state.insurances?.find((insurance) => insurance.chosen);

  const refetchOffer = () => {
    window.location.reload();
  };

  if (!selectedInsurance) return <Alert severity="error">{t('errors.cantDisplay')}</Alert>;

  const handleCancelOffer = () => {
    goTo(WizardSteps.InsuranceAdjustment, 0);
  };

  const total = selectedInsurance?.cost.find((insurance: any) => insurance.chosen);

  return (
    <MainContainer>
      <LeftContainer>
        <FormContent>
          <InsuranceSummary insurance={selectedInsurance} total={total} />
          <S.Summary>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="body2">{t('insuranceWizard.clientOffer.contributionSum')}</Typography>
              <Typography variant="subtitle1" fontWeight="bold">
                {total.cost} PLN
              </Typography>
            </Grid>
          </S.Summary>
          <Grid container justifyContent="space-between" alignItems="flex-start" marginTop={5}>
            <Button variant="text" onClick={handleCancelOffer} color="error" disabled={offerMutation.isLoading}>
              {t('insuranceWizard.clientOffer.cancelOffer')}
            </Button>
            <S.RefreshContainer>
              <Button onClick={refetchOffer} endIcon={<RefreshIcon />} variant="outlined">
                {t('insuranceWizard.clientOffer.waitingForClientAcceptance')}
              </Button>
            </S.RefreshContainer>
          </Grid>
        </FormContent>
      </LeftContainer>
      <RightContainer>
        <Grid item container direction="column" gap={3}>
          <InvitationSenderBox />
          <AgentChat />
        </Grid>
      </RightContainer>
    </MainContainer>
  );
}
export default ClientOffer;
