import WizardProvider from 'contexts/WizardContext';

import WizardStepper from 'components/WizardStepper';

import FormContextProvider from './contexts/FormContext/FormContext';
import StepsContent from './steps';
import { STEPS } from './stepsConfig';
import * as S from './styles';

function AgentRemoteSale() {
  return (
    <S.Main>
      <WizardProvider>
        <FormContextProvider>
          <WizardStepper steps={STEPS} />
          <S.Content>
            <StepsContent />
          </S.Content>
        </FormContextProvider>
      </WizardProvider>
    </S.Main>
  );
}

export default AgentRemoteSale;
