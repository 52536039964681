import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledTextField } from 'components/Form';

import { IndividualClientForm } from '../types';

function IndividualClient() {
  const { control } = useFormContext<IndividualClientForm>();
  const { t } = useTranslation();
  return (
    <ControlledTextField
      defaultValue=""
      name="clientUser.email"
      control={control}
      label={t('insuranceWizard.clientData.email')}
    />
  );
}
export default IndividualClient;
