import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Box,
  FilterOptionsState,
  InputAdornment,
  TextField,
} from '@mui/material';
import useBrand from 'library/hooks/useBrand';
import { INSURANCES_LIST } from 'library/insurances';
import { errorMessage } from 'library/language/validation/errorMessage';
import { InsuranceListItem } from 'pages/AgentRemoteSale/components/InsuranceBox/types';
import { HTMLAttributes, SyntheticEvent, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

interface Props {
  onChange: (selectedBrand: string) => void;
  value?: string;
  error?: FieldError;
}

function BrandPicker({ onChange, value, error }: Props) {
  const brand = useBrand(value || '');
  const { t } = useTranslation();
  const [selectedBrand, setSelectedBrand] = useState<InsuranceListItem | string>(brand || '');
  const [isText, setIsText] = useState(false);

  const handleSelectAutocompleteOption = (_: SyntheticEvent<Element, Event>, value: InsuranceListItem | string) => {
    if (typeof value !== 'string') {
      onChange(value.brand);
      setSelectedBrand(value);
      setIsText(true);
    }
  };
  const renderOptions = (
    props: HTMLAttributes<HTMLLIElement>,
    option: InsuranceListItem,
    _: AutocompleteRenderOptionState,
  ) => {
    return (
      <Box component="li" {...props}>
        <S.ImgInList loading="lazy" width="20" src={option.img} alt={option.brand} />
        {option.brand}
      </Box>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        label={t('insuranceWizard.propositionsOfInsurances.brand')}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'off',
        }}
        InputLabelProps={{
          required: true,
        }}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        error={!!error}
        helperText={errorMessage(t, error?.message)}
        onChange={(e) => {
          const inputValue = e.target.value;
          if (!inputValue) {
            setIsText(false);
          }
        }}
      />
    );
  };

  const filter = (_: InsuranceListItem[], state: FilterOptionsState<InsuranceListItem>) => {
    const filteredOptions = INSURANCES_LIST.filter((insurance) =>
      insurance.brand.toLowerCase().includes(state.inputValue.toLowerCase()),
    );

    return filteredOptions;
  };

  return (
    <S.AutoCompleteWrapper hasImg={!!selectedBrand} isText={isText}>
      {isText && (
        <S.ImgWrapper>
          <img src={(selectedBrand as InsuranceListItem).img} alt={(selectedBrand as InsuranceListItem).brand} />
        </S.ImgWrapper>
      )}
      <Autocomplete
        options={INSURANCES_LIST}
        value={selectedBrand}
        autoHighlight
        disableClearable
        freeSolo
        // isOptionEqualToValue={(option) => option.brand === selectedBrand?.brand}
        getOptionLabel={(option) => (option as InsuranceListItem)?.brand || ''}
        onChange={handleSelectAutocompleteOption}
        renderOption={renderOptions}
        renderInput={renderInput}
        filterOptions={filter}
        noOptionsText={t('common.noResults')}
      />
    </S.AutoCompleteWrapper>
  );
}

export default BrandPicker;
