import { authorizedAxios } from 'library/axios/apiProvider';

import { Client, CreateClientBody, UpdateClientBody } from '../../client/types';

export async function updateClient(id: string, body: UpdateClientBody): Promise<Client> {
  const { data } = await authorizedAxios().put(`/api/v1/clients/${id}/`, body);
  return data;
}

export async function createClient(body: CreateClientBody): Promise<Client> {
  const { data } = await authorizedAxios().post(`/api/v1/clients/`, body);
  return data;
}

export async function getClients(): Promise<any> {
  const { data } = await authorizedAxios().get<any>(`/api/v1/clients/`);
  return data;
}

export async function getClient(id: any): Promise<any> {
  const { data } = await authorizedAxios().get<any>(`/api/v1/clients/${id}/`);
  return data;
}
