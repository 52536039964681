import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Attachments = styled.div`
  width: 50%;
  margin-top: 20px;
`;

export const List = styled.ul`
  width: 100%;
`;

export const ListItem = styled.li`
  font-size: 16px;
`;

export const WrapperButton = styled.div`
  display: flex;
  margin: 50px 0 60px 0;
  width: 50%;
  justify-content: flex-end;
`;
export const DropzoneContainer = styled.div`
  border: 2px solid ${theme.palette.primary.main};
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  height: 150px;

  svg {
    font-size: 62px;
  }
`;

export const DropzoneText = styled.p`
  margin-top: 10px;
  color: #555;
`;

export const SpanText = styled.span`
  color: ${theme.palette.primary.main};
`;
