import { Font, StyleSheet } from '@react-pdf/renderer';

import customFontAnton from '../Font/Anton-Regular.ttf';
import customFontRoboto from '../Font/Roboto-Regular.ttf';

Font.register({
  family: 'Roboto',
  src: customFontRoboto,
});

Font.register({
  family: 'Anton',
  src: customFontAnton,
});

export const styles = StyleSheet.create({
  wrapper: {
    margin: 0,
  },
  messages: {
    fontSize: '13px',
  },
});
