export enum WizardSteps {
  ClientSearch,
  ClientNeeds,
  ClientData,
  Insurances,
  InsuranceAdjustment,
  InsuranceAcceptance,
  Policy,
  Payment,
}
