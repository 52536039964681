import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, CardActions, CircularProgress, Collapse, Grid, Typography } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import { useOffer, useSendInvitation } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

function InvitationSenderBox() {
  const { t } = useTranslation();
  const { mutate, isLoading } = useSendInvitation();
  const { invitationSent } = useOffer();

  const [expanded, setExpanded] = useState(!invitationSent);
  const handleExpandClick = () => setExpanded((prev) => !prev);

  const handleSendInvitation = () => mutate();

  return (
    <S.InfoCard variant="outlined" style={{ width: '100%' }}>
      <CardHeader
        title={t('insuranceWizard.invitation.title')}
        titleTypographyProps={{ variant: 'body1', fontWeight: 'bold', textTransform: 'capitalize' }}
        action={
          <S.ExpandMore expand={expanded} onClick={handleExpandClick}>
            <ExpandMoreIcon />
          </S.ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <S.Content>
          <Typography>{t('insuranceWizard.invitation.description')}</Typography>
          {invitationSent && !isLoading && (
            <Grid container gap={1} marginTop={1}>
              <CheckIcon fontSize="small" />
              <Typography>
                <b>{t('insuranceWizard.invitation.sendSuccess')}</b>
              </Typography>
            </Grid>
          )}
        </S.Content>
        <CardActions>
          <Grid container justifyContent="flex-end" gap={2} alignItems="center">
            {isLoading && <CircularProgress size={20} />}
            <Button color="info" variant="outlined" onClick={handleSendInvitation} disabled={isLoading} size="small">
              {invitationSent ? t('insuranceWizard.invitation.sendAgain') : t('insuranceWizard.invitation.send')}
            </Button>
          </Grid>
        </CardActions>
      </Collapse>
    </S.InfoCard>
  );
}
export default InvitationSenderBox;
