import Checkbox from '@mui/material/Checkbox';
import theme from 'library/theme/theme';
import styled from 'styled-components';

import { BoxProps } from './types';

export const Container = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0;
  gap: 5px;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  width: 100%;
`;

export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid ${(props) => props.border};
  height: 100px;
  width: 280px;
  background-color: ${(props) => props.color};
  @media (max-width: 800px) {
    width: 120px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #e5f6fd;
  }
`;
