import { authorizedAxios } from 'library/axios/apiProvider';

import { CreateUpdatePolicy, Policy } from '../../types/policy';

export async function createPolicy(policy: CreateUpdatePolicy): Promise<Policy> {
  const { data } = await authorizedAxios().post('/api/v1/policies/', policy);
  return data;
}

export async function createPolicyExternal(policy: CreateUpdatePolicy): Promise<Policy> {
  const { data } = await authorizedAxios().post('/api/v1/policies/external/', policy);
  return data;
}

export async function patchPolicy(policy: CreateUpdatePolicy, id: string): Promise<Policy> {
  const { data } = await authorizedAxios().patch(`/api/v1/policies/${id}/`, policy);
  return data;
}

export const patchPolicies = async (policy: CreateUpdatePolicy, idsToPatch: any) => {
  const patchPolicies = idsToPatch.map(async (id: any) => {
    const { data } = await authorizedAxios().patch(`/api/v1/policies/${id}/`, policy);
    return data;
  });

  await Promise.all(patchPolicies);
};

export async function getPolicy(id: any): Promise<Policy> {
  const { data } = await authorizedAxios().get<Policy>(`/api/v1/policies/${id}/`);
  return data;
}

export async function getPolicies(): Promise<Policy> {
  const { data } = await authorizedAxios().get<Policy>(`/api/v1/policies/`);
  return data;
}
