import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Collapse, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { getErrorMessage } from './utils';

interface Props {
  fileRejections: FileRejection[];
}

const AttachmentsErrors = ({ fileRejections }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(fileRejections.length > 0);

  const handleClose = () => setOpen(false);

  return (
    <Collapse in={open}>
      <Alert
        severity="error"
        variant="filled"
        action={
          <IconButton onClick={handleClose}>
            <CloseIcon htmlColor="white" />
          </IconButton>
        }
      >
        <AlertTitle>{t('errors.cantUploadThisFiles')}</AlertTitle>
        {fileRejections.map(({ file, errors }) => (
          <React.Fragment key={`${file.name}-error`}>
            <Typography variant="body1">{file.name}</Typography>
            {errors.map((error) => (
              <Typography variant="body2" paddingLeft={1} key={`${file.name}-${error.code}`}>
                {getErrorMessage(error.code)}
              </Typography>
            ))}
          </React.Fragment>
        ))}
      </Alert>
    </Collapse>
  );
};

export default AttachmentsErrors;
