/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] | string }
>((props, ref) => {
  const { href, download, ...other } = props;

  // Map href (MUI) -> to (html <a />)
  if (download) {
    // eslint-disable-next-line react/jsx-no-target-blank
    return <a href={String(href)} {...other} download target="_blank" />;
  }
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});
