/* eslint-disable @typescript-eslint/no-unused-vars */
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import Close from '@mui/icons-material/Close';
import DeleteForever from '@mui/icons-material/DeleteForever';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { useMutation } from '@tanstack/react-query';
import { deleteOffers, patchOffers } from 'api/agent/offer/offerApi';
import theme from 'library/theme/theme';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { getCustomOffers } from '../../api/agent/offer/offerApi';
import { Offer } from '../../api/agent/offer/types';
import { useOffer } from '../AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import CancellationOfProcesses from './components/CancellationOfProcesses';
import DeletingProcesses from './components/DeletingProcesses/DeletingProcesses';
import Filter from './components/Filter/Filter';
import { OfferFilterForm } from './components/Filter/type';
import Process from './components/Process';
import Tasks from './components/Tasks';
import * as S from './styles';

function AgentTasksAndService({ text }: any) {
  const [isFilterVisible, setFilterVisible] = useState(false);
  const [offers, setOffers] = useState<Offer[]>();
  const [total, setTotal] = useState<number>(0);
  const [nextUrl, setNextUrl] = useState<string>();
  const [queryParams, setQueryParams] = useState<string[]>([]);
  const [ordering, setOrdering] = useState<string>();
  const { control, register, getValues } = useForm<any>({
    defaultValues: {
      last_modified_before: null,
      last_modified_after: null,
    },
  });

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isTaskDialogOpen, setTaskDialogOpen] = useState(false);
  const [isCancellationDialogOpen, setCancellationDialogOpen] = useState(false);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<any[]>([]);

  const { loading } = useOffer();

  const mutationDelete = useMutation((idsToDelete: any) => deleteOffers(idsToDelete), {
    onSuccess: () => {
      if (offers) {
        setOffers((prevOffers: any) => prevOffers.filter((item: any) => !selectedCheckboxes.includes(item.id)));
        setDeleteDialogOpen(false);
      }
    },
  });

  const mutationTasks = useMutation(
    (idsToTask: any) => patchOffers(idsToTask, { task: text !== 'odznacz' ? true : false }),
    {
      onSuccess: () => {
        if (offers) {
          const updatedOffers = offers.map((offer) => {
            if (selectedCheckboxes.includes(offer.id)) {
              return { ...offer, task: text !== 'odznacz' ? true : false };
            }
            return offer;
          });

          setOffers(updatedOffers);
          setTaskDialogOpen(false);
          setSelectedCheckboxes([]);
        }
      },
    },
  );

  const mutationCancellation = useMutation(
    (idsToTask: any) => patchOffers(idsToTask, { stage: -1, cancellation_reason: '-1' }),
    {
      onSuccess: () => {
        if (offers) {
          const updatedOffers = offers.map((offer) => {
            if (selectedCheckboxes.includes(offer.id)) {
              return { ...offer, stage: -1, cancellation_reason: '-1' };
            }
            return offer;
          });
          setOffers(updatedOffers);
          setCancellationDialogOpen(false);
          setSelectedCheckboxes([]);
        }
      },
    },
  );

  async function refreshState(queryStrings: string[]) {
    try {
      const data = await getCustomOffers(queryStrings.join('&'));
      setNextUrl(data.next);
      setOffers(data.results);
      setTotal(data.count);
    } catch (error) {
      toast.error('Coś poszło nie tak');
    }
  }

  useEffect(() => {
    refreshState(queryParams);
  }, [queryParams]);

  const toggleFilter = () => {
    const values = getValues();
    const params: string[] = [];

    // Budowanie tablicy z parametrami filtrowania
    Object.keys(values).forEach((key) => {
      const value = values[key as keyof OfferFilterForm];
      if (value) {
        let formattedValue = '';
        switch (typeof value) {
          case 'string':
            formattedValue = `${value}`;
            break;
          case 'number':
            formattedValue = `${value}`;
            break;
          case 'object':
            formattedValue = `${value.toISOString()}`;
            break;
        }
        params.push(`${key}=${formattedValue}`);
      }
    });

    setQueryParams(params);
    refreshState(params);

    setFilterVisible(!isFilterVisible);
  };

  const style = {
    transition: 'transform 0.3s',
    transform: isFilterVisible ? 'rotate(180deg)' : 'rotate(0deg)',
  };

  const customStyle = { fontSize: '25px' };
  const iconStyle = { position: 'relative' as const, left: '11px' };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  const handleTask = () => {
    setTaskDialogOpen(true);
  };

  const handleCancelTask = () => {
    setTaskDialogOpen(false);
  };

  const handleCancellation = () => {
    setCancellationDialogOpen(true);
  };

  const handleCancelCancellation = () => {
    setCancellationDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    mutationDelete.mutate(selectedCheckboxes);
  };

  const handleConfirmTask = () => {
    mutationTasks.mutate(selectedCheckboxes);
  };

  const handleConfirmCancellation = () => {
    mutationCancellation.mutate(selectedCheckboxes);
  };

  const name = text !== 'odznacz' ? 'zaznaczyć' : 'odznaczyć';

  return (
    <S.Main>
      <S.Wrapper>
        <S.Container>
          <S.Box>
            <S.Button
              color={theme.palette.primary.main}
              onClick={handleDelete}
              disabled={selectedCheckboxes.length === 0}
            >
              <DeleteForever style={customStyle} />
              <S.Text>usuń</S.Text>
            </S.Button>
            <S.Button
              color={theme.palette.primary.main}
              onClick={handleTask}
              disabled={selectedCheckboxes.length === 0}
            >
              {text !== 'odznacz' ? (
                <NotificationsActiveIcon style={customStyle} />
              ) : (
                <NotificationsOffIcon style={customStyle} />
              )}
              <S.Text>{text}</S.Text>
            </S.Button>
            <S.Button color="firebrick" onClick={handleCancellation} disabled={selectedCheckboxes.length === 0}>
              <Close style={customStyle} />
              <S.Text>anuluj</S.Text>
            </S.Button>
          </S.Box>
          <S.BoxTwo>
            <S.ButtonArrow style={style} onClick={toggleFilter} color={theme.palette.primary.main}>
              <ArrowBackIos style={iconStyle} />
            </S.ButtonArrow>
          </S.BoxTwo>
        </S.Container>
        <Process
          offers={offers}
          total={total}
          ordering={ordering}
          setOrdering={setOrdering}
          loading={loading}
          refreshState={refreshState}
          queryParams={queryParams}
          selectedCheckboxes={selectedCheckboxes}
          setSelectedCheckboxes={setSelectedCheckboxes}
          name={name}
        />
      </S.Wrapper>
      <S.Filter width={isFilterVisible}>
        <Filter control={control} register={register} />
      </S.Filter>
      <DeletingProcesses {...{ isDeleteDialogOpen, handleCancelDelete, handleConfirmDelete }} />
      <CancellationOfProcesses {...{ isCancellationDialogOpen, handleCancelCancellation, handleConfirmCancellation }} />
      <Tasks {...{ isTaskDialogOpen, handleCancelTask, handleConfirmTask, name }} />
    </S.Main>
  );
}

export default AgentTasksAndService;
