import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  color: ${(props) => props.color};
  @media (max-width: 1000px) {
    color: white;
    background-color: ${(props) => props.color};
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 5px;
  }
  @media (max-width: 600px) {
    font-size: 11px;
    width: 70px;
    height: 70px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 13px 18px 18px;
  gap: 10px;
`;
