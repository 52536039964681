import { useMutation } from '@tanstack/react-query';
import { patchInsurance } from 'api/agent/insurances/insurancesApi';

import { useAgentForm } from './useAgentForm';

export function useChoseInsurance(selectedInsuranceId?: string, initiallySelectedInsuranceId?: string) {
  const { state, onUpdate } = useAgentForm();

  return useMutation(
    async () => {
      if (!selectedInsuranceId) return;

      if (initiallySelectedInsuranceId) {
        await patchInsurance(initiallySelectedInsuranceId, { chosen: false });
      }
      return await patchInsurance(selectedInsuranceId, { chosen: true });
    },
    {
      onSuccess: () => {
        if (!selectedInsuranceId) return;
        onUpdate({
          insurances: state.insurances?.map((insurance) => {
            if (insurance.id === selectedInsuranceId) return { ...insurance, chosen: true };
            if (initiallySelectedInsuranceId && insurance.id === initiallySelectedInsuranceId)
              return { ...insurance, chosen: false };
            return insurance;
          }),
        });
      },
    },
  );
}

export function useCancelInsurance(selectedInsuranceId?: string) {
  const { state, onUpdate } = useAgentForm();
  return useMutation(
    async () => {
      if (!selectedInsuranceId) return;

      return await patchInsurance(selectedInsuranceId, { chosen: false });
    },
    {
      onSuccess: () => {
        if (!selectedInsuranceId) return;
        onUpdate({
          insurances: state.insurances?.map((insurance) => {
            if (insurance.id === selectedInsuranceId) return { ...insurance, chosen: false };
            return insurance;
          }),
        });
      },
    },
  );
}
