import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import useModal from 'library/hooks/useModal';
import { useSortedInsurances } from 'library/hooks/useSortedInsurances';
import AgentChat from 'pages/AgentRemoteSale/components/AgentChat';
import ClientDataInfoBox from 'pages/AgentRemoteSale/components/ClientDataInfoBox';
import ClientNeedsInfoBox from 'pages/AgentRemoteSale/components/ClientNeedsInfoBox';
import InvitationSenderBox from 'pages/AgentRemoteSale/components/InvitationSenderBox';
import { useUpdateOfferStep } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useOffer';
import { useTranslation } from 'react-i18next';
import { FormContent } from 'styles/FormContent';

import { LeftContainer, MainContainer, RightContainer } from 'components/Layout/styles';

import EmptyState from './components/EmptyState';
import InsuranceFormModal from './components/InsuranceFormModal/InsuranceFormModal';
import InsurancesList from './components/InsurancesList/InsurancesList';
import * as S from './styles';

function InsurancesSuggestions() {
  const { goTo } = useWizard();
  const { open, handleClose, handleOpen } = useModal();
  const { t } = useTranslation();
  const { insurances } = useSortedInsurances();

  const stepMutation = useUpdateOfferStep();

  const handleClickNext = () => {
    goTo(4, 4);
    stepMutation.mutate(void 0);
  };

  const hasNoPropositions = insurances.length === 0;

  console.log(insurances, ' insurances');

  return (
    <MainContainer>
      <LeftContainer>
        <FormContent>
          {hasNoPropositions ? (
            <EmptyState />
          ) : (
            <>
              <S.Actions>
                <Button endIcon={<AddIcon />} variant="outlined" onClick={handleOpen}>
                  {t('common.addMore')}
                </Button>
              </S.Actions>
              <InsurancesList insurances={insurances} />
              <InsuranceFormModal showModal={open} onClose={handleClose} />
            </>
          )}
          <S.Actions>
            <Button variant="contained" onClick={handleClickNext} disabled={hasNoPropositions}>
              {t('common.next')}
            </Button>
          </S.Actions>
        </FormContent>
      </LeftContainer>
      <RightContainer>
        <Grid item container direction="column" gap={3}>
          <InvitationSenderBox />
          <ClientDataInfoBox defaultOpen />
          <ClientNeedsInfoBox />
          <AgentChat />
        </Grid>
      </RightContainer>
    </MainContainer>
  );
}

export default InsurancesSuggestions;
