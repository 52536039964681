import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${theme.palette.common.white};
  width: 100vw;
  height: 100vh;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;
