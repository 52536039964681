import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { FileResult } from 'api/files/types';
import { useManageFormFiles } from 'library/hooks/useManageFormFiles';
import { InsuranceFormState } from 'pages/AgentRemoteSale/components/InsuranceBox/types';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFile } from 'components/Attachments/types';
import { ControlledAttachments, ControlledTextField, FormGroup } from 'components/Form';

import BrandPicker from './BrandPicker';
import NewCollection from './newCollection';
import { schema } from './schema';
import { SingleInsuranceForm } from './types';

interface Props {
  onSubmit: (data: InsuranceFormState) => void;
  children: React.ReactNode;
  initialState?: InsuranceFormState;
  onUpload?: (file: File) => Promise<FileResult>;
  onAddFile?: (file: FormFile) => void;
  onDeleteFile?: (file: FormFile) => void;
}

function InsuranceForm({ onSubmit, children, initialState, onUpload, onAddFile, onDeleteFile }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'insuranceWizard.propositionsOfInsurances' });
  const manageFiles = useManageFormFiles({ onAddFile, onDeleteFile, onUpload });
  const {
    control,
    handleSubmit: onFormSubmit,
    setValue,
    watch,
  } = useForm<SingleInsuranceForm>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      expirationDate: null,
      ...initialState,
    },
  });

  const attachments = watch('attachments') || [];
  const requiredInputProp = {
    InputLabelProps: {
      required: true,
    },
  };

  const lowestCost = (list: any) => {
    if (list.length > 0) {
      const minCostIndex = list.reduce((minIndex: any, currentCost: any, currentIndex: any) => {
        const currentCostValue = parseFloat(currentCost.cost) || 0;
        const minCostValue = parseFloat(list[minIndex].cost) || 0;

        return currentCostValue < minCostValue ? currentIndex : minIndex;
      }, 0);

      const updatedList = list.map((item: any, index: any) => ({
        ...item,
        chosen: index === minCostIndex,
      }));

      return updatedList;
    } else {
      console.error('List is empty');
      return [];
    }
  };

  const handleSubmit = (formDate: SingleInsuranceForm) => {
    onSubmit({
      ...formDate,
      chosen: false,
      expirationDate: formDate.expirationDate || new Date(),
      cost: lowestCost(list),
    });
  };

  const [list, setList] = useState(initialState ? initialState.cost : [{ cost: '', chosen: false, installments: '' }]);

  return (
    <form onSubmit={onFormSubmit(handleSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            control={control}
            name="identification"
            defaultValue=""
            label={t('tuNumber')}
            inputProps={requiredInputProp}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            control={control}
            name="name"
            defaultValue=""
            label={t('name')}
            inputProps={requiredInputProp}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="companyBrand"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <BrandPicker onChange={onChange} value={value} error={error} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="description"
            defaultValue=""
            label={t('description')}
            inputProps={{ multiline: true, rows: 3, ...requiredInputProp }}
          />
        </Grid>
      </Grid>
      <NewCollection {...{ control, setValue, list, setList }} />
      <div style={{ marginTop: '12px' }}>
        <Box>
          <FormGroup title={t('addAttachments')}>
            <ControlledAttachments
              control={control}
              name={'attachments' as never}
              files={attachments}
              onAttach={manageFiles.onAttach}
              onRemove={manageFiles.onRemove}
            />
          </FormGroup>
        </Box>
      </div>
      {children}
    </form>
  );
}

export default InsuranceForm;
