/* eslint-disable @typescript-eslint/no-empty-function */
import { useQuery } from '@tanstack/react-query';
import { ClientAuthResponse } from 'api/auth/types';
import { getClientUser } from 'api/client/clientApi';
import { Client } from 'api/client/types';
import { createAuthorizedAxiosForClient, destroyAuthorizedAxiosForClient } from 'library/axios/apiProvider';
import { deleteStoredClientToken, getStoredClientToken, setStoredClientToken } from 'library/axios/helpers/clientToken';
import { createContext, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';

import AgentSplash from 'components/AgentSplash/AgentSplash';

interface ContextProps {
  signIn: (client: ClientAuthResponse) => void;
  signOut: () => void;
  client: Client | null;
}

export const ClientAuthContext = createContext<ContextProps>({
  signIn: () => {},
  signOut: () => {},
  client: null,
});

interface Props {
  children: React.ReactNode;
}

function ClientAuthContextProvider({ children }: Props) {
  const [storedClientToken] = useState(getStoredClientToken());
  const [clientAuth, setClientAuth] = useState<ClientAuthResponse | null>(
    storedClientToken ? { clientToken: storedClientToken } : null,
  );

  useMemo(() => {
    if (storedClientToken) createAuthorizedAxiosForClient(storedClientToken);
  }, [storedClientToken]);

  const [client, setClient] = useState<Client | null>(null);

  const { isFetching: isRefreshingUser } = useQuery(['client-user'], () => getClientUser(), {
    staleTime: 0,
    enabled: !!clientAuth,
    onSuccess: (result) => {
      flushSync(() => {
        setClient(result);
      });
    },
    onError: () => {
      handleClearSession();
    },
  });

  const handleSignIn = (client: ClientAuthResponse) => {
    setClientAuth(client);
    setStoredClientToken(client.clientToken);
    createAuthorizedAxiosForClient(client.clientToken);
  };
  const handleClearSession = () => {
    setClient(null);

    deleteStoredClientToken();
    destroyAuthorizedAxiosForClient();
  };

  return (
    <ClientAuthContext.Provider
      value={useMemo(() => ({ signIn: handleSignIn, signOut: handleClearSession, client }), [client])}
    >
      {isRefreshingUser ? <AgentSplash /> : children}
    </ClientAuthContext.Provider>
  );
}

export default ClientAuthContextProvider;
