import CheckIcon from '@mui/icons-material/Check';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useQuery } from '@tanstack/react-query';
import { getPolicies } from 'api/agent/policy/policyApi';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import WizardLoadingView from 'components/WizardLoadingView';

import Filter from '../AgentTasksAndService/components/Filter/Filter';
import Process from './components/Process';
import RemoveFromSettlement from './components/Process/RemoveFromSettlement/RemoveFromSettlement';
import * as S from './styles';

function AgentSettlements() {
  const { data: policiesData, isFetching: isLoadingPolicies } = useQuery<any>(['policies'], getPolicies);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<any[]>([]);
  const [data, setData] = useState([]);

  const { control, register } = useForm<any>({
    defaultValues: {
      last_modified_before: null,
      last_modified_after: null,
    },
  });

  useEffect(() => {
    if (policiesData) {
      const newPolicyStatusArray = policiesData.results.map((item: any) => ({
        ...item,
        policyStatus: [
          {
            key: 'issued',
            value: true,
            name: 'wystawiona',
            icon: DescriptionIcon,
            index: 0,
          },
          {
            key: 'received',
            value: item.received,
            name: 'odebrana',
            icon: CheckIcon,
            index: 1,
          },
          {
            key: 'confirmed',
            value: item.confirmed,
            name: 'potwierdzona',
            icon: EditIcon,
            index: 2,
          },
          {
            key: 'policyPaid',
            value: item.policyPaid,
            name: 'opłacona',
            icon: PaymentsIcon,
            index: 3,
          },
          {
            key: 'commissionPaid',
            value: item.commissionPaid,
            name: 'rozliczona',
            icon: CreditScoreIcon,
            index: 4,
          },
        ],
      }));

      setData(newPolicyStatusArray);
    }
  }, [policiesData]);

  if (isLoadingPolicies) return <WizardLoadingView height="100vh" />;

  return (
    <S.Main>
      <S.Wrapper isFilterVisible={false}>
        <S.Container>
          <S.Box>
            <RemoveFromSettlement {...{ selectedCheckboxes, setData, data }} />
          </S.Box>
          {/*<S.BoxTwo>
              <S.ButtonArrow style={style} onClick={toggleFilter} color={theme.palette.primary.main}>
                <ArrowBackIos style={iconStyle} />
              </S.ButtonArrow>
            </S.BoxTwo>*/}
        </S.Container>
        <Process {...{ data, selectedCheckboxes, setSelectedCheckboxes }} />
      </S.Wrapper>
      <S.Filter width={false}>
        <Filter control={control} register={register} />
      </S.Filter>
    </S.Main>
  );
}

export default AgentSettlements;
