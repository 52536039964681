import { CaretRightOutlined } from '@ant-design/icons';
import Search from '@mui/icons-material/Search';
import { useQuery } from '@tanstack/react-query';
import type { CollapseProps } from 'antd';
import { Collapse, theme } from 'antd';
import { getFaq } from 'api/agent/faq/faqApi';
import he from 'he';
import parse from 'html-react-parser';
import React, { useState } from 'react';

import WizardLoadingView from 'components/WizardLoadingView';

import Calendar from './components/Calendar/Calendar';
import * as S from './styles';

const AgentSupport: React.FC = () => {
  const { token } = theme.useToken();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTexts, setFilteredTexts] = useState([]);
  const [originalTexts, setOriginalTexts] = useState([]);

  const { isLoading: queryIsLoading } = useQuery(['faq'], getFaq, {
    onSuccess: (data) => {
      setOriginalTexts(data?.results);
      setFilteredTexts(data?.results);
    },
  });

  const highlightSearchTerm = (text: string, searchTerm: string) => {
    if (!searchTerm) {
      return text;
    }

    const decodedText = he.decode(text);

    const regex = new RegExp(`(${searchTerm}|\\s${searchTerm})`, 'gi');
    return decodedText.replace(regex, (match: any, group: string) => ` <mark>${group.trim()}</mark>`);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (!term) {
      setFilteredTexts(originalTexts);
    } else {
      const filtered = originalTexts.filter((item: any) => item?.question?.toLowerCase().includes(term));
      setFilteredTexts(filtered);
    }
  };

  const getItems: () => CollapseProps['items'] = () =>
    filteredTexts.map((item: any, index) => ({
      key: `${index + 1}`,
      label: (
        <S.Paragraph
          weight="700"
          margin="0"
          dangerouslySetInnerHTML={{ __html: highlightSearchTerm(item?.question, searchTerm) }}
        />
      ),
      children: (
        <S.Paragraph weight="500" margin="37px">
          {parse(item.answer)}
        </S.Paragraph>
      ),
      style: {
        marginBottom: 10,
        background: '#EBEAEA',
        borderRadius: 0,
        border: 'none',
      },
    }));

  if (queryIsLoading) {
    return <WizardLoadingView height="100vh" />;
  }

  return (
    <S.Main>
      <S.Views>
        <S.Wrapper>
          <S.Paragraph weight="700">FAQ</S.Paragraph>
        </S.Wrapper>
        <S.Wrapper>
          <S.Box>
            <S.Input onChange={handleInputChange} value={searchTerm} placeholder="Szukaj" />
            <S.Loupe>
              <Search />
            </S.Loupe>
          </S.Box>
        </S.Wrapper>
      </S.Views>
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined style={{ color: '#6B6B6B', fontSize: '25px' }} rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
        items={getItems()}
      />
      <Calendar />
    </S.Main>
  );
};

export default AgentSupport;
