import { yupResolver } from '@hookform/resolvers/yup';
import DownloadIcon from '@mui/icons-material/Download';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import { createAPK } from 'api/client/apk/apkApi';
import { useStepMutation } from 'api/client/offer/offerQuery';
import { useClient } from 'contexts/ClientAuthContext/hooks';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import InsuranceSummary from 'pages/AgentRemoteSale/components/InsuranceSummary';
import Apk from 'pages/ClientRemoteSale/components/Apk/Apk';
import ClientChat from 'pages/ClientRemoteSale/components/ClientChat';
import { useClientForm } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientForm';
import { useAcceptClientOffer } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientOffer';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormContent } from 'styles/FormContent';

import { LeftContainer, MainContainer, RightContainer } from 'components/Layout/styles';

import Agreements from './components/Agreements';
import { offerSchema } from './schema';
import * as S from './styles';
import { OfferData } from './types';

function Offer() {
  const [messagesApk, setMessagesApk] = useState([]);
  const { t } = useTranslation();
  const { state, apk } = useClientForm();
  const { onNext } = useWizard();
  const stepMutation = useStepMutation();
  const { mutateAsync: acceptOffer, isLoading: isAcceptingOffer } = useAcceptClientOffer();
  const mutationFile = useMutation((item: any) => createAPK(item));

  const client = useClient();

  const formMethods = useForm<OfferData>({
    resolver: yupResolver(offerSchema),
  });

  if (!state.selectedInsurance) return <Alert severity="error">{t('errors.cantDisplay')}</Alert>;

  const handleOfferAccepted = async () => {
    try {
      await Promise.all([acceptOffer(), stepMutation.mutateAsync(void 0), mutationFile.mutateAsync(apk as any)]);
      onNext();
    } catch (error) {
      toast(t('errors.api.serverError'));
    }
  };

  const total = state.selectedInsurance.cost.find((insurance: any) => insurance.chosen);

  return (
    <MainContainer>
      <LeftContainer>
        <FormContent>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleOfferAccepted)}>
              <InsuranceSummary insurance={state.selectedInsurance} total={total} />
              <Grid container justifyContent="space-between" marginY={2}>
                <Button variant="text" startIcon={<DownloadIcon fontSize="large" />}>
                  <S.DownloadAPKButtonContent>
                    <Apk state={state} messagesApk={messagesApk} client={client} />
                  </S.DownloadAPKButtonContent>
                </Button>
                <S.PricingSummary>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="body2">{t('insuranceWizard.clientOffer.contributionSum')}</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {total.cost} PLN
                    </Typography>
                  </Grid>
                </S.PricingSummary>
              </Grid>
              <Box marginTop={6} marginBottom={3}>
                <Agreements brand={state.selectedInsurance.companyBrand} />
              </Box>
              <Grid container justifyContent="flex-end" marginTop={5}>
                <Button variant="contained" type="submit" disabled={isAcceptingOffer || stepMutation.isLoading || !apk}>
                  {t('insuranceWizard.offer.acceptOffer')}
                </Button>
              </Grid>
            </form>
          </FormProvider>
        </FormContent>
      </LeftContainer>
      <RightContainer>
        <ClientChat setMessagesApk={setMessagesApk} />
      </RightContainer>
    </MainContainer>
  );
}
export default Offer;
