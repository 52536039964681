import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Button, Grid, Typography } from '@mui/material';
import { useClient, useClientActions } from 'contexts/ClientAuthContext/hooks';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();
  const client = useClient();
  const { signOut } = useClientActions();

  const handleSignOut = () => signOut();

  return (
    <nav>
      <Grid container alignItems="center" justifyContent="space-between">
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '3px' }}>
          {client?.agent?.logo ? (
            <img
              src={client.agent.logo}
              alt="logo"
              style={{ height: '100px', width: '100px', objectFit: 'cover' }}
            ></img>
          ) : null}
          <Typography variant="h4">{t('client.yourInsurance')}</Typography>
        </div>
        {client && (
          <Grid item>
            <Button onClick={handleSignOut} startIcon={<PowerSettingsNewIcon />}>
              <span style={{ lineHeight: 'normal' }}>{t('common.signOut')}</span>
            </Button>
          </Grid>
        )}
      </Grid>
    </nav>
  );
}

export default Header;
