import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing(5)};
`;

export const DownloadAPKButtonContent = styled.span`
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
