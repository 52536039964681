import CreateIcon from '@mui/icons-material/Create';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import DoneIcon from '@mui/icons-material/Done';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as S from './styles';
import { StatusType } from './types';

function StatusList({ policy, onChange }: any) {
  const { t } = useTranslation();

  const handleChange = (_: React.MouseEvent<HTMLElement>, [newStatus]: StatusType[]) => {
    onChange(newStatus, !policy[newStatus]);
  };

  return (
    <S.Group orientation="vertical" onChange={handleChange}>
      <S.ListButton value={'received'} selected={policy.source === 'ex' ? true : policy.received} disabled>
        <DoneIcon />
        <Typography>{t('insuranceWizard.paymentStep.policyStatus.received')}</Typography>
      </S.ListButton>
      <S.ListButton value={'confirmed'} selected={policy.confirmed}>
        <CreateIcon />
        <Typography>{t('insuranceWizard.paymentStep.policyStatus.confirmed')}</Typography>
      </S.ListButton>
      <S.ListButton value={'policyPaid'} selected={policy.policyPaid}>
        <PaymentsIcon />
        <Typography>{t('insuranceWizard.paymentStep.policyStatus.policyPaid')}</Typography>
      </S.ListButton>
      <S.ListButton value={'commissionPaid'} selected={policy.commissionPaid}>
        <CreditScoreIcon />
        <Typography>{t('insuranceWizard.paymentStep.policyStatus.commissionPaid')}</Typography>
      </S.ListButton>
    </S.Group>
  );
}

export default StatusList;
