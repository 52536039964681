import CircularProgress from '@mui/material/CircularProgress';

import * as S from './styles';

function LoadingView(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <S.Container {...props}>
      <CircularProgress />
    </S.Container>
  );
}

export default LoadingView;
