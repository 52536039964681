import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Info from '@mui/icons-material/Info';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PeopleIcon from '@mui/icons-material/People';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RuleIcon from '@mui/icons-material/Rule';
import { useAgent, useAgentActions } from 'contexts/AgentAuthContext/hooks';
import theme from 'library/theme/theme';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { PATHS } from 'routes/paths';

import * as S from './styles';

function Header() {
  const { signOut } = useAgentActions();
  const agent = useAgent();
  const { t } = useTranslation();
  const location = useLocation();

  const currentPath = location.pathname;

  const [isMenu, setIsMenu] = useState(false);

  const handleSignOut = () => {
    signOut();
  };

  const customStyle = { fontSize: '25px' };

  const menu = () => {
    setIsMenu(!isMenu);
  };

  const removeClientId = () => {
    localStorage.setItem('selected_client_id', '');
  };

  return (
    agent && (
      <S.Header>
        <S.Nav>
          <div onClick={removeClientId}>
            <Link to={PATHS.agent.index}>
              <S.LogoIcon src="/images/4agent-logo.png" alt="4agent-logo" width="50px" />
            </Link>
          </div>
          <S.Wrapper right={isMenu ? '17px' : '-320px'}>
            <S.List border={theme.palette.primary.main}>
              <S.ListItem onClick={removeClientId}>
                <Link style={{ textDecoration: 'none' }} to={PATHS.agent.remoteSale}>
                  <S.Box isCurrentPath={currentPath === `${PATHS.agent.index}/${PATHS.agent.remoteSale}`}>
                    <LanguageIcon style={customStyle} />
                    <S.Item>{t('agentDashboard.remoteSales').toUpperCase()}</S.Item>
                  </S.Box>
                </Link>
              </S.ListItem>
              <S.ListItem>
                <Link to={PATHS.agent.tasks} style={{ textDecoration: 'none' }}>
                  <S.Box isCurrentPath={currentPath === `${PATHS.agent.index}/${PATHS.agent.tasks}`}>
                    <NotificationsActiveIcon style={customStyle} />
                    <S.Item>{t('agentDashboard.tasks').toUpperCase()}</S.Item>
                  </S.Box>
                </Link>
              </S.ListItem>
              <S.ListItem>
                <Link to={PATHS.agent.service} style={{ textDecoration: 'none' }}>
                  <S.Box isCurrentPath={currentPath === `${PATHS.agent.index}/${PATHS.agent.service}`}>
                    <RuleIcon style={customStyle} />
                    <S.Item>{t('agentDashboard.service').toUpperCase()}</S.Item>
                  </S.Box>
                </Link>
              </S.ListItem>
              <S.ListItem>
                <Link to={PATHS.agent.clientsAndPolicies} style={{ textDecoration: 'none' }}>
                  <S.Box isCurrentPath={currentPath === `${PATHS.agent.index}/${PATHS.agent.clientsAndPolicies}`}>
                    <PeopleIcon style={customStyle} />
                    <S.Item>{t('agentDashboard.clientsAndPolicies').toUpperCase()}</S.Item>
                  </S.Box>
                </Link>
              </S.ListItem>
              <S.ListItem>
                <Link to={PATHS.agent.settlements} style={{ textDecoration: 'none' }}>
                  <S.Box isCurrentPath={currentPath === `${PATHS.agent.index}/${PATHS.agent.settlements}`}>
                    <AccountBalanceWalletIcon style={customStyle} />
                    <S.Item>{t('agentDashboard.settlements').toUpperCase()}</S.Item>
                  </S.Box>
                </Link>
              </S.ListItem>
            </S.List>

            <S.List border="none">
              <S.ListItem>
                <Link to={PATHS.agent.support} style={{ textDecoration: 'none' }}>
                  <S.Box isCurrentPath={currentPath === `${PATHS.agent.index}/${PATHS.agent.support}`}>
                    <Info style={customStyle} />
                    <S.Item>{t('common.support').toUpperCase()}</S.Item>
                  </S.Box>
                </Link>
              </S.ListItem>
              <S.ListItem>
                <Link to={PATHS.agent.profile} style={{ textDecoration: 'none' }}>
                  <S.Box isCurrentPath={currentPath === `${PATHS.agent.index}/${PATHS.agent.profile}`}>
                    <AccountCircle style={customStyle} />
                    <S.Item>{t('common.profile').toUpperCase()}</S.Item>
                  </S.Box>
                </Link>
              </S.ListItem>
              <S.ListItem onClick={handleSignOut}>
                <S.Box>
                  <PowerSettingsNewIcon style={customStyle} />
                  <S.Item>{t('common.signOut').toUpperCase()}</S.Item>
                </S.Box>
              </S.ListItem>
            </S.List>
          </S.Wrapper>
          <S.BoxTwo>
            <S.Hamburger onClick={menu}>
              <S.Box>
                <MenuIcon style={{ fontSize: '35px' }} />
              </S.Box>
            </S.Hamburger>
          </S.BoxTwo>
        </S.Nav>
      </S.Header>
    )
  );
}

export default Header;
