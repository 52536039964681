import { requiredSwitchChecked } from 'library/yup/common';
import * as yup from 'yup';

export const offerSchema = yup.object({
  agreements: yup.object({
    general: requiredSwitchChecked.required(),
    apk: requiredSwitchChecked.required(),
    distribution: requiredSwitchChecked.required(),
    informed: requiredSwitchChecked.required(),
  }),
});
