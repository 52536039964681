import theme from 'library/theme/theme';
import styled from 'styled-components';

export const ModalActions = styled.div`
  position: absolute;
  right: ${theme.spacing(4)};
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(2)};
  background-color: white;
`;
