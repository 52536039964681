import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Button, Switch, TextField, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchAgentData, patchAgentImage } from 'api/agent/agentApi';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import FileInfoAdministrator from '../FileInfoAdministrator';
import Information from '../Information/Information';
import * as S from './styles';

const YourData = (agent: any) => {
  const queryClient = useQueryClient();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDataChange, setIsDataChange] = useState(false);
  const [logo, setLogo] = useState<any>(agent.agentData.logo);

  const [contactData, setContactData] = useState<any>({
    firstName: agent.agentData.profile.firstName || '',
    lastName: agent.agentData.profile.lastName || '',
    email: agent.agentData.profile.email || '',
    phoneNumber: agent.agentData.profile.phoneNumber || null,
    idProfile: agent.agentData.profile.id || null,
    idObject: agent.agentData.id || null,
    apartmentNumber: agent.agentData.dataAdministrator?.apartmentNumber || '',
    city: agent.agentData.dataAdministrator?.city || '',
    houseNumber: agent.agentData.dataAdministrator?.houseNumber || '',
    name: agent.agentData.dataAdministrator?.name || '',
    postalCode: agent.agentData.dataAdministrator?.postalCode || '',
    street: agent.agentData.dataAdministrator?.street || '',
    taxId: agent.agentData.dataAdministrator?.taxId || '',
    termsAccepted: agent.agentData.termsAccepted,
    newsletter: agent.agentData.newsletter,
  });

  const handleSwitchChange = (fieldName: keyof typeof contactData) => () => {
    setContactData((prevData: any) => ({
      ...prevData,
      [fieldName]: !prevData[fieldName],
    }));
  };

  const handleTextFieldChange =
    (fieldName: keyof typeof contactData) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setContactData((prevData: any) => ({
        ...prevData,
        [fieldName]: event.target.value,
      }));
      setIsDataChange(true);
    };

  const mutationData = useMutation((dataToSave) => patchAgentData(dataToSave), {
    onSuccess: () => {
      queryClient.invalidateQueries(['agentData']);
      setIsOpenModal(true);
    },
    onError: () => {
      toast.error('Coś poszło nie tak');
    },
  });

  const mutationLogo = useMutation((dataToSave) => patchAgentImage(dataToSave), {
    onError: () => {
      toast.error('Coś poszło nie tak');
    },
  });

  const save = () => {
    if (contactData.idProfile || contactData.idObject) {
      mutationData.mutate(contactData);
      return;
    }
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const formData = new FormData();

    const fileInfo = URL.createObjectURL(file);

    formData.append('logo', file);
    formData.append('id', agent.agentData.id);

    setLogo(fileInfo);
    setIsDataChange(true);

    await mutationLogo.mutateAsync(formData as any);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'image/webp': [],
    },
  });

  const text = 'Twoje dane zostały zapisane';

  return (
    <S.Main>
      <Information {...{ isOpenModal, closeModal, text }} />
      <Typography variant="body1" fontWeight="bolder" fontSize={16} style={{ marginBottom: '20px' }}>
        Dane kontaktowe
      </Typography>
      <S.Box gap={50}>
        <TextField
          variant="outlined"
          fullWidth
          value={contactData.firstName}
          onChange={handleTextFieldChange('firstName')}
          placeholder="imię"
        />
        <TextField
          variant="outlined"
          fullWidth
          value={contactData.lastName}
          onChange={handleTextFieldChange('lastName')}
          placeholder="nazwisko"
        />
      </S.Box>
      <S.Box gap={50}>
        <TextField
          variant="outlined"
          fullWidth
          value={contactData.email}
          onChange={handleTextFieldChange('email')}
          placeholder="email"
        />
        <TextField
          variant="outlined"
          fullWidth
          value={contactData.phoneNumber}
          onChange={handleTextFieldChange('phoneNumber')}
          placeholder="telefon"
        />
      </S.Box>
      <Typography variant="body1" fontWeight="bolder" fontSize={16} style={{ marginBottom: '20px' }}>
        Administrator danych
      </Typography>
      <S.Box gap={50}>
        <TextField
          variant="outlined"
          fullWidth
          onChange={handleTextFieldChange('name')}
          placeholder="nazwa"
          value={contactData.name}
        />
        <TextField
          variant="outlined"
          fullWidth
          onChange={handleTextFieldChange('taxId')}
          placeholder="nip"
          value={contactData.taxId}
        />
      </S.Box>
      <S.Box gap={50}>
        <TextField
          variant="outlined"
          fullWidth
          onChange={handleTextFieldChange('postalCode')}
          placeholder="kod pocztowy"
          value={contactData.postalCode}
        />
        <TextField
          variant="outlined"
          fullWidth
          onChange={handleTextFieldChange('city')}
          placeholder="miasto"
          value={contactData.city}
        />
      </S.Box>
      <S.Box gap={50}>
        <S.Box gap={50}>
          <TextField
            variant="outlined"
            fullWidth
            onChange={handleTextFieldChange('street')}
            placeholder="ulica"
            value={contactData.street}
          />
        </S.Box>
        <S.Box gap={50}>
          <TextField
            variant="outlined"
            fullWidth
            onChange={handleTextFieldChange('apartmentNumber')}
            placeholder="numer apartamentu"
            value={contactData.apartmentNumber}
          />
          <TextField
            variant="outlined"
            fullWidth
            onChange={handleTextFieldChange('houseNumber')}
            placeholder="numer budynku"
            value={contactData.houseNumber}
          />
        </S.Box>
      </S.Box>
      <S.Box gap={0}>
        <Switch checked={contactData.termsAccepted} onChange={handleSwitchChange('termsAccepted')} color="primary" />
        <Typography variant="body1" fontSize={16}>
          Oświadczam, że zapoznałem się z treścią
          <S.Span> regulaminu systemu iBooster - zdalna sprzedaż ubezpieczeń</S.Span>i akceptuje jego treści
        </Typography>
      </S.Box>
      <S.Box gap={0}>
        <Switch checked={contactData.newsletter} onChange={handleSwitchChange('newsletter')} color="primary" />
        <Typography variant="body1" fontSize={16}>
          Wyrażam zgodę na przetwarzanie moich danych osobowych przez Michał Dworakowski ncmedia w celu otrzymywania
          Newsletter’a 4agent zawierającego informacje: marketingowe, o działalności, konkursach i usługach Michał
          Dworakowski ncmedia z wykorzystaniem środków porozumiewania się na odległość, przy użyciu podanych przeze mnie
          danych kontaktowych.
        </Typography>
      </S.Box>
      <S.Attachments>
        <Typography variant="body1" fontWeight="bolder" fontSize={16}>
          Twoje logo (pojawi się np. na podsumowaniu komunikacji z Klientem)
        </Typography>
        <S.DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <CloudUploadOutlinedIcon color="primary" />
          <S.DropzoneText>Upuść pliki tutaj, lub kliknij, aby wybrać z dysku (max 10MB)</S.DropzoneText>
        </S.DropzoneContainer>
        {logo ? (
          <div style={{ marginTop: '20px' }}>
            <img src={logo} alt="logo" style={{ height: '400px', width: '400px', objectFit: 'cover' }} />
          </div>
        ) : null}
      </S.Attachments>
      <FileInfoAdministrator agent={agent} />
      {/*
        <FilesAgent agent={agent}/>
      */}
      <S.WrapperButton>
        <Button variant="contained" onClick={save} disabled={!isDataChange}>
          Zapisz
        </Button>
      </S.WrapperButton>
    </S.Main>
  );
};

export default YourData;
