import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Message, NewMessage } from 'api/messages/types';
import { AxiosQueryOptions, PaginatedResponse } from 'api/types';

import { getMessages, sendMessage } from './messagesApi';

export const MESSAGES_QUERY = (offerId: string) => ['messages-client', offerId];

export function useMessagesQuery(offerId: string | undefined, options?: AxiosQueryOptions<PaginatedResponse<Message>>) {
  return useQuery(MESSAGES_QUERY(offerId ?? ''), () => getMessages(offerId ?? ''), options);
}

export function useMessageMutation(offerId: string) {
  const queryClient = useQueryClient();

  return useMutation((message: NewMessage) => sendMessage(offerId, message), {
    onSuccess() {
      queryClient.invalidateQueries(MESSAGES_QUERY(offerId));
    },
  });
}
