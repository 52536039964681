import theme from 'library/theme/theme';
import styled from 'styled-components';
import { FormContent } from 'styles/FormContent';

export const Content = styled(FormContent)`
  margin: 0 auto;
  width: 420px;
  text-align: center;
  @media (max-width: 800px) {
    width: 300px;
  }
`;

export const SearchContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`;

export const Submit = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing(3)};
`;
