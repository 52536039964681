import { passwordValidation, requiredSwitchChecked } from 'library/yup/common';
import * as yup from 'yup';

export const registrationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password1: passwordValidation.required().matches(/[\W_]/, 'errors.passwordSpecialChar'),
  password2: yup
    .string()
    .required()
    .oneOf([yup.ref('password1'), null], 'errors.passwordsNotMatch'),
  privacyPolicy: requiredSwitchChecked.required(),
});
