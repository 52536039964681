import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 25px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const Main = styled.div`
  display: flex;
  position: relative;
  margin-top: 100px;
  flex: 100%;
`;

export const Filter = styled.div<any>`
  position: relative;
  width: ${(props) => (!props.width ? '0' : '320px')};

  min-height: 100vh;
  background-color: rgb(238, 238, 238);
  transition: width 0.3s;
  @media (max-width: 600px) {
    top: 190px;
  }
`;

export const Box = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    justify-content: space-between;
  }
`;

export const BoxTwo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 75px;
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 30px;
  }
`;

export const Text = styled.p`
  color: black;
  margin: 0;
  margin-top: 10px;
  @media (max-width: 1000px) {
    color: white;
  }
`;

export const Span = styled.span`
  display: block;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  color: ${(props) => props.color};
  @media (max-width: 1000px) {
    color: white;
    background-color: ${(props) => props.color};
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 5px;
  }
  @media (max-width: 600px) {
    font-size: 11px;
    width: 70px;
    height: 70px;
  }
`;

export const ButtonArrow = styled.button`
  display: flex;
  width: 22px;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
  margin-right: 5px;
`;

export const Wrapper = styled.div<any>`
  transition: width 0.3s;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 45px;
  width: 100%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
