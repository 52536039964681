import theme from 'library/theme/theme';
import styled from 'styled-components';

interface PropsList {
  border: string;
}

interface PropsWrapper {
  right: string;
}

export const LogoIcon = styled.img`
  display: block;
  margin: auto;
`;

export const Main = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div<PropsWrapper>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  @media (max-width: 1200px) {
    transition: right 0.5s;
    position: absolute;
    z-index: 100;
    flex-direction: column;
    justify-content: center;
    width: 200px;
    height: 320px;
    top: 120px;
    right: ${(props) => props.right};
    background-color: white;
  }
`;

export const Header = styled.header`
  display: flex;
  position: fixed;
  z-index: 2;
  background-color: white;
  align-items: center;
  width: 100%;
  height: 120px;
  background-color: ${theme.palette.common.white};
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const List = styled.ul<PropsList>`
  display: flex;
  align-items: center;
  padding: 0;
  color: ${theme.palette.primary.main};
  @media (max-width: 1200px) {
    flex-direction: column;
    height: 500px;
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.border};
  }
`;

export const Item = styled.span`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 1000;
  padding-left: 8px;
  font-family: 'Lato', sans-serif;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-left: 15px;
  color: ${theme.palette.primary.main};
  height: 40px;
  cursor: pointer;
  @media (max-width: 1200px) {
    margin: 5px;
  }
`;

export const Box = styled.span<any>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.isCurrentPath ? '#AD252A' : theme.palette.primary.main)};
  ${(props) => (!props.isCurrentPath ? `&:hover { color: #79ACF5; }` : null)}
  transition: color 0.3s ease;
`;

export const Hamburger = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  align-items: center;
  margin: 10px 0 10px 10px;
  color: ${theme.palette.primary.main};
`;

export const BoxTwo = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  right: 70px;
  top: 0;
  @media (max-width: 1200px) {
    display: flex;
  }
`;
