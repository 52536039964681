import AddIcon from '@mui/icons-material/Add';
import RuleIcon from '@mui/icons-material/Rule';
import { Button, Typography } from '@mui/material';
import useModal from 'library/hooks/useModal';
import { useTranslation } from 'react-i18next';

import InsuranceFormModal from '../InsuranceFormModal/InsuranceFormModal';
import * as S from './styles';

function EmptyState() {
  const { open, handleClose, handleOpen } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <S.EmptyState>
        <RuleIcon color="disabled" />
        <Typography variant="body1">{t('insuranceWizard.propositionsOfInsurances.noPropositions')}</Typography>
        <Button variant="contained" size="small" endIcon={<AddIcon />} onClick={handleOpen}>
          {t('insuranceWizard.propositionsOfInsurances.addNewProposition')}
        </Button>
      </S.EmptyState>
      <InsuranceFormModal onClose={handleClose} showModal={open} />
    </>
  );
}

export default EmptyState;
