import * as S from './styles';

interface Props {
  children: React.ReactNode;
}

function Heading({ children }: Props) {
  return <S.Title>{children}</S.Title>;
}

export default Heading;
