import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import ControlledSwitch from 'components/Form/Controls/ControlledSwitch';

import { OfferData } from '../../types';
import { ADDITIONAL_AGREEMENTS } from './const';

interface Props {
  brand: string;
}

function Agreements({ brand }: Props) {
  const { t } = useTranslation();
  const { control } = useFormContext<OfferData>();

  return (
    <Grid container flexDirection="column" spacing={3}>
      <Grid item>
        <ControlledSwitch
          control={control}
          name={'agreements.general'}
          required
          content={
            <Trans
              components={{ bold: <strong /> }}
              i18nKey={'insuranceWizard.offer.agreements.generalInsuranceConditions'}
              values={{ brand: brand.toUpperCase() }}
            />
          }
        />
      </Grid>
      <Grid item>
        <Box marginBottom={1}>
          <Typography>
            <b>{t('insuranceWizard.offer.agreementBeforeContractConclusion')}</b>
          </Typography>
        </Box>
        <Grid container gap={1}>
          {ADDITIONAL_AGREEMENTS.map(({ id, content, required }) => (
            <ControlledSwitch
              key={id}
              control={control}
              name={`agreements.${id}`}
              required={required}
              content={content()}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Agreements;
