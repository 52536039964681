import * as S from './styles';

function Bar(props: any) {
  const { stage, color } = props;

  const step = () => {
    switch (stage) {
      case 1:
        return <S.Bar width="10%" color={color} />;
      case 2:
        return <S.Bar width="20%" color={color} />;
      case 3:
        return <S.Bar width="40%" color={color} />;
      case 4:
        return <S.Bar width="60%" color={color} />;
      case 5:
        return <S.Bar width="80%" color={color} />;
      case 6:
        return <S.Bar width="100%" color={color} />;
      default:
        return null;
    }
  };

  return <S.Main>{step()}</S.Main>;
}

export default Bar;
