import { Button } from '@mui/material';
import styled from 'styled-components';
import { FormContent } from 'styles/FormContent';

export const Container = styled(FormContent)`
  width: 618px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 800px) {
    width: 300px;
  }
`;

export const Submit = styled(Button).attrs((props) => ({
  ...props,
  type: 'submit',
  variant: 'contained',
  size: 'large',
}))`
  margin-left: auto;
`;
