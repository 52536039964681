import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { ErrorMessage, errorMessage } from 'library/language/validation/errorMessage';
import { useTranslation } from 'react-i18next';

import { signMethods } from './type';

function SignMethods({ fieldState, ...selectProps }: any) {
  const { t } = useTranslation();

  const label = selectProps.label ? selectProps.label : t('insuranceWizard.policy.signType');

  return (
    <FormControl error={!!fieldState?.error} fullWidth>
      <InputLabel required id="signMethod-options">
        {label}
      </InputLabel>
      <Select
        labelId="signMethod-options"
        input={<OutlinedInput label={label} />}
        placeholder={t('insuranceWizard.policy.chooseSignMethod')}
        {...selectProps}
      >
        {signMethods.map((method) => (
          <MenuItem key={method} value={method}>
            {t(`insuranceWizard.policy.signMethods.${method}.label`)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMessage(t, fieldState?.error?.message as ErrorMessage)}</FormHelperText>
    </FormControl>
  );
}

export default SignMethods;
