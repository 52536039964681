import { Alert, AlertTitle, Button, Grid } from '@mui/material';
import { useClientMutate } from 'api/client/client/clientQuery';
import { useWizard } from 'contexts/WizardContext/hooks/useWizard';
import { useFormFiles } from 'library/hooks/useFormFiles';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import DataForm from 'pages/AgentRemoteSale/steps/ClientData/components/DataForm';
import clientFormAsClient from 'pages/AgentRemoteSale/steps/ClientData/components/DataForm/utils';
import ClientChat from 'pages/ClientRemoteSale/components/ClientChat';
import ClientNeedsInfoBox from 'pages/ClientRemoteSale/components/ClientNeedsInfoBox';
import { useClientForm } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientForm';
import { useClientOffer } from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientOffer';
import {
  useDeleteClientOfferFiles,
  useUploadClientOfferFile,
} from 'pages/ClientRemoteSale/contexts/FormContext/hooks/useClientOfferFiles';
import { useTranslation } from 'react-i18next';
import { FormContent } from 'styles/FormContent';

import { LeftContainer, MainContainer, RightContainer } from 'components/Layout/styles';

import Agreements from './components/Agreements';
import * as S from './styles';

function ClientData() {
  const { onUpdate: onFormUpdate, state: formState } = useClientForm();
  const { offer } = useClientOffer();
  const clientMutate = useClientMutate();
  const { onNext } = useWizard();
  const { t } = useTranslation();

  const { mutateAsync: uploadFiles } = useUploadClientOfferFile();
  const { mutate: deleteFormFiles } = useDeleteClientOfferFiles();
  const formFilesState = useFormFiles();

  const onClientSubmit = (data: ClientDataFormState) => {
    clientMutate.mutateAsync(clientFormAsClient(data), {
      onSuccess() {
        onNext();
        deleteFormFiles(formFilesState.filesToDelete);
        onFormUpdate({ clientData: data });
      },
    });
  };

  const handleReloadPage = () => window.location.reload();

  if (formState?.clientData?.agreements.privacyPolicy) {
    return (
      <MainContainer>
        <LeftContainer>
          <Alert
            severity="info"
            action={
              <Grid container alignSelf="center">
                <Button color="inherit" size="small" onClick={handleReloadPage}>
                  {t('common.refresh')}
                </Button>
              </Grid>
            }
          >
            <AlertTitle>{t('client.waitingForAgent')}</AlertTitle>
            {t('client.waitingForAgentDescription')}
          </Alert>
        </LeftContainer>
        <RightContainer>
          <ClientChat setMessagesApk={null} />
        </RightContainer>
      </MainContainer>
    );
  }

  console.log(offer?.agent, ' offer?.agent');

  return (
    <MainContainer>
      <LeftContainer>
        <FormContent>
          <DataForm
            onSubmit={onClientSubmit}
            initialState={formState.clientData}
            type="client"
            onUpload={uploadFiles}
            onAddFile={formFilesState.onAddFile}
            onDeleteFile={formFilesState.onDeleteFile}
          >
            <Agreements agent={offer?.agent} />
            <Grid mt={5}>
              <S.Submit>{t('insuranceWizard.clientData.sendToAgent')}</S.Submit>
            </Grid>
          </DataForm>
        </FormContent>
      </LeftContainer>
      <RightContainer>
        <Grid item container flexDirection="column" gap={3}>
          <ClientChat setMessagesApk={null} />
          <ClientNeedsInfoBox defaultOpen />
        </Grid>
      </RightContainer>
    </MainContainer>
  );
}

export default ClientData;
