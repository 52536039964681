import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledTextField } from 'components/Form';

import { LegalClientForm } from '../types';

function LegalClient() {
  const { control } = useFormContext<LegalClientForm>();
  const { t } = useTranslation();
  return (
    <Grid container gap={2}>
      <Grid item container gap={2}>
        <Grid item xs>
          <ControlledTextField
            control={control}
            name="company.name"
            defaultValue=""
            label={t('insuranceWizard.clientData.companyName')}
          />
        </Grid>
        <Grid item xs>
          <ControlledTextField
            control={control}
            name="clientUser.email"
            defaultValue=""
            label={t('insuranceWizard.clientData.email')}
          />
        </Grid>
      </Grid>
      <ControlledTextField
        control={control}
        name="company.taxId"
        defaultValue=""
        label={t('insuranceWizard.clientData.nip')}
      />
    </Grid>
  );
}
export default LegalClient;
