import { Card } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const ModalContent = styled(Card)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 650px;
  max-height: 90%;
  position: absolute;
  inset: 0;
  margin: auto;
  padding: ${theme.spacing(2)};
`;
