/* eslint-disable @typescript-eslint/no-unused-vars */
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LanguageIcon from '@mui/icons-material/Language';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Checkbox } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import theme from 'library/theme/theme';
import numeral from 'numeral';
import { NEEDS } from 'pages/AgentRemoteSale/steps/ClientNeeds/components/NeedsForm/const';
import { STEPS } from 'pages/AgentRemoteSale/stepsConfig';
import Bar from 'pages/AgentTasksAndService/components/Bar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WizardLoadingView from 'components/WizardLoadingView';

import { Offer } from '../../../../api/agent/offer/types';
import * as S from './styles';

interface ProcessProps {
  offers: Offer[] | undefined;
  total: number;
  ordering: string | undefined;
  setOrdering: React.Dispatch<React.SetStateAction<string | undefined>>;
  loading: boolean;
  refreshState: (queryParams: string[]) => void;
  queryParams: string[];
  selectedCheckboxes: any;
  setSelectedCheckboxes: any;
  name: string;
}

function Process({
  offers,
  ordering,
  setOrdering,
  loading,
  refreshState,
  queryParams,
  selectedCheckboxes,
  setSelectedCheckboxes,
  name,
}: ProcessProps) {
  const { t } = useTranslation();
  const nav = useNavigate();

  const [data, setData] = useState([]);

  const handleOrdering = (columnId: string) => {
    if (ordering === columnId || ordering === `-${columnId}`) {
      setOrdering(ordering === columnId ? `-${columnId}` : columnId);
    } else {
      setOrdering(columnId);
    }
  };

  useEffect(() => {
    const temp = queryParams;
    if (ordering) {
      temp.push(`ordering=${ordering}`);
    }
    refreshState(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  if (loading) return <WizardLoadingView height="100vh" />;

  const handleCheckboxChange = (id: any) => {
    const newSelectedCheckboxes = [...selectedCheckboxes];
    const isChecked = newSelectedCheckboxes.includes(id);
    if (isChecked) {
      newSelectedCheckboxes.splice(newSelectedCheckboxes.indexOf(id), 1);
    } else {
      newSelectedCheckboxes.push(id);
    }

    setSelectedCheckboxes(newSelectedCheckboxes);
  };

  const showProces = (item: any) => {
    localStorage.setItem('selected_client_id', item.id);
    nav('/agent/remote-sale');
  };

  let filterData = offers?.filter((item: any) => item.stage !== 6);

  const state =
    name === 'odznaczyć'
      ? filterData?.filter((item: any) => item.task === true && item.cancellationReason !== '-1')
      : filterData?.filter((item) => item.cancellationReason !== '-1');
  filterData = state;

  const handleCheckboxChangeAll = () => {
    const allCheckboxIds = offers?.map((item: any) => item.id);

    if (allCheckboxIds) {
      const allSelected = allCheckboxIds.every((id: any) => selectedCheckboxes.includes(id));

      if (allSelected) {
        setSelectedCheckboxes([]);
      } else {
        setSelectedCheckboxes(allCheckboxIds);
      }
    }
  };

  const sourceIcon = (source: string, task: boolean) => {
    switch (source) {
      case 'rs':
        return (
          <>
            {task ? <NotificationsActiveIcon /> : null}
            <LanguageIcon />
          </>
        );
      case 'rn':
        return <AutorenewIcon />;
      case 'out':
        return <AddIcon />;
      default:
        return null;
    }
  };

  return (
    <S.Main>
      <S.Box>
        <S.Price>
          kwoty łącznie
          <S.Name>
            {filterData
              ? numeral(filterData.reduce((acc, curr) => acc + curr.cost, 0))
                  .format('0,0')
                  .replace(/,/g, ' ')
              : 0}
          </S.Name>
        </S.Price>
      </S.Box>
      <S.Views>
        <S.Wrapper>
          <S.Text>
            liczba wyświetlonych elementów {filterData ? filterData.length : 0} z {filterData?.length}
          </S.Text>
        </S.Wrapper>
      </S.Views>
      <S.Container>
        <S.Wrap flex="0">
          <Checkbox
            style={{ padding: 0, marginRight: '15px' }}
            checked={selectedCheckboxes.length !== data.length}
            onChange={handleCheckboxChangeAll}
          />
        </S.Wrap>
        <S.MiniContainer>
          <S.BoxWrap responsive={false}>
            <S.Wrap onClick={() => handleOrdering('source')} flex="0px">
              źródło
            </S.Wrap>
            <S.Wrap onClick={() => handleOrdering('start_date')} flex="100px">
              start ochrony
            </S.Wrap>
          </S.BoxWrap>
          <S.Wrap onClick={() => handleOrdering('client')} flex="200px">
            Klient
          </S.Wrap>
        </S.MiniContainer>
        <S.MiniContainer>
          <S.Wrap onClick={() => handleOrdering('insurance_type')} flex="300px">
            potrzeba
          </S.Wrap>
          <S.BoxWrap responsive={true}>
            <S.Wrap onClick={() => handleOrdering('stage')} flex="300px">
              status
            </S.Wrap>
            <S.Wrap onClick={() => handleOrdering('cost')} flex="100px" justify="flex-end">
              składka
            </S.Wrap>
          </S.BoxWrap>
        </S.MiniContainer>
        <Bar stage={0} color={theme.palette.primary.main} />
      </S.Container>
      {filterData &&
        filterData.map((item: Offer, index: number) => {
          const matchingNeed = NEEDS.find((needItem) => needItem.name === item.insuranceType);
          const searchSteps = STEPS.find((stepItem) => stepItem.step === item.stage + 1);

          return (
            <S.ContainerTwo key={index}>
              <S.CheckBox>
                <S.Wrap flex="0">
                  <Checkbox
                    key={index}
                    style={{ padding: 0, marginRight: '15px', borderRadius: 0 }}
                    checked={selectedCheckboxes.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                    value={index}
                    name="checkboxGroup"
                  />
                </S.Wrap>
              </S.CheckBox>
              <S.MainWrapper onClick={() => showProces(item)}>
                <S.MiniContainer>
                  <S.BoxWrap responsive={false}>
                    <S.Wrap flex="0px" color={theme.palette.primary.main}>
                      {sourceIcon(item.source, item.task)}
                    </S.Wrap>
                    <S.Wrap flex="100px">
                      <S.Paragraph>{item.startDate}</S.Paragraph>
                    </S.Wrap>
                  </S.BoxWrap>
                  <S.Wrap flex="200px" color={theme.palette.primary.main}>
                    <Tooltip title={item.email.length > 30 ? item.email : undefined}>
                      <S.Paragraph>{item.email.length > 30 ? `${item.email.slice(0, 27)}...` : item.email}</S.Paragraph>
                    </Tooltip>
                  </S.Wrap>
                </S.MiniContainer>
                <S.MiniContainer>
                  <S.Wrap flex="300px" color={theme.palette.primary.main} align="flex-end">
                    {matchingNeed && matchingNeed.icon && React.cloneElement(matchingNeed.icon, { fontSize: 'medium' })}
                    <Tooltip title={item.needsShort && item.needsShort.length > 30 ? item.needsShort : undefined}>
                      <S.ParagraphIcon>
                        {item.needsShort && item.needsShort.length > 30
                          ? `${item.needsShort.slice(0, 27)}...`
                          : item.needsShort}
                      </S.ParagraphIcon>
                    </Tooltip>
                  </S.Wrap>
                  <S.BoxWrap responsive={true}>
                    <S.Wrap flex="300px" color={theme.palette.primary.main}>
                      {searchSteps && searchSteps.icon}
                      <S.ParagraphIcon color="black">{t(searchSteps?.title as any).toLowerCase()}</S.ParagraphIcon>
                    </S.Wrap>
                    <S.Wrap flex="100px" justify="flex-end">
                      {item.cost && (
                        <S.Paragraph>
                          {Math.floor(item.cost)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </S.Paragraph>
                      )}
                    </S.Wrap>
                  </S.BoxWrap>
                </S.MiniContainer>
              </S.MainWrapper>
              <Bar stage={item.stage + 1} color={theme.palette.primary.main} />
            </S.ContainerTwo>
          );
        })}
    </S.Main>
  );
}

export default Process;
