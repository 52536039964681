/* eslint-disable @typescript-eslint/no-unused-vars */
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

import DatePicker from '../DatePicker';
import * as S from './styles';

const NewCollection = ({ control, setValue, list, setList }: any) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(1);

  const handleDateChange = (date: DateTime | null) => {
    if (!date) {
      setValue('expirationDate', null);
      return;
    }

    setValue('expirationDate', date.toJSDate(), { shouldValidate: true });
  };

  const addCollection = () => {
    if (list.length < 5) {
      setCount((prev: any) => prev + 1);
      setList((prevArray: any) => [...prevArray, { cost: '', installments: '', chosen: false }]);
    }
  };

  const removeCollection = () => {
    if (list.length > 1) {
      setCount((prev: any) => prev - 1);
      const newArray = [...list];
      newArray.pop();
      setList(newArray);
    }
  };

  const handleInputChange = (index: any, fieldName: any, value: any) => {
    const updatedInputValues = [...list];
    if (!updatedInputValues[index]) {
      updatedInputValues[index] = { cost: '', installments: '' };
    }
    updatedInputValues[index][fieldName] = value;
    setList(updatedInputValues);
  };

  return (
    <Grid container spacing={2} mt={0}>
      <Grid container alignItems="center" spacing={2} ml={0} mt={0}>
        <Grid item xs={12} md={6}>
          <DatePicker control={control} onChange={handleDateChange} />
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="flex-end">
          <S.Button onClick={removeCollection} disabled={list.length === 1}>
            <RemoveIcon fontSize="large" />
          </S.Button>
          <S.Button onClick={addCollection} disabled={list.length === 5}>
            <AddIcon fontSize="large" />
          </S.Button>
        </Grid>
      </Grid>
      {list.map((item: any, index: any) => (
        <Grid container spacing={2} ml={0} mt={0} key={index}>
          <Grid item xs={12} md={6} style={{ position: 'relative' }}>
            <NumberFormat
              required={index === 0}
              thousandSeparator={' '}
              allowedDecimalSeparators={[',']}
              name={`cost_${index}`}
              style={{ width: '100%', height: '100%', padding: '0 10px' }}
              value={list[index]?.cost || ''}
              placeholder={t('insuranceWizard.propositionsOfInsurances.price')}
              onChange={(e: any) => {
                handleInputChange(index, 'cost', e.target.value);
              }}
            />
            <S.Number>PLN</S.Number>
          </Grid>
          <Grid item xs={12} md={6}>
            <S.Input
              onChange={(e: any) => {
                handleInputChange(index, 'installments', e.target.value);
              }}
              value={list[index]?.installments || ''}
              placeholder="dopisek (liczba rat)"
              required={index === 0}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default NewCollection;
