import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled(Grid)`
  height: 100%;
`;

export const ChatContainer = styled(Grid)`
  overflow-y: scroll;
`;
