import styled from 'styled-components';

export const Title = styled.h2`
  text-align: center;
  font-size: 25px;
  widht: 100%;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;
