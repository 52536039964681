import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Main = styled.div`
  display: flex;

  flex-direction: column;
`;

export const Box = styled.div<any>`
  display: flex;
  gap: ${(props) => props.gap}px;
  width: 50%;
  margin-bottom: 20px;
`;

export const Span = styled.a`
  color: ${theme.palette.primary.main};
  margin: 0 5px;
  text-decoration: underline;
`;

export const SpanText = styled.span`
  color: ${theme.palette.primary.main};
`;

export const Attachments = styled.div`
  width: 50%;
  margin-top: 20px;
`;

export const List = styled.ul`
  width: 100%;
`;

export const ListItem = styled.li`
  font-size: 16px;
`;

export const WrapperButton = styled.div`
  display: flex;
  margin: 50px 0 50px 0;
  width: 50%;
  justify-content: flex-end;
`;
export const DropzoneContainer = styled.div`
  border: 2px solid ${theme.palette.primary.main};
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  height: 150px;

  svg {
    font-size: 62px;
  }
`;

export const DropzoneText = styled.p`
  margin-top: 10px;
  color: #555;
`;
