import ForumIcon from '@mui/icons-material/Forum';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

interface Props {
  type: 'agent' | 'client';
}

function EmptyChat({ type }: Props) {
  const { t } = useTranslation();
  return (
    <S.Wrapper>
      <ForumIcon fontSize={'large'} />
      <Typography variant="subtitle1">
        {type === 'agent'
          ? t('insuranceWizard.chat.startConversationWithClient')
          : t('insuranceWizard.chat.startConversationWithAgent')}
      </Typography>
    </S.Wrapper>
  );
}
export default EmptyChat;
