import { useMutation } from '@tanstack/react-query';
import { AxiosMutationOptions } from 'api/types';

import { Client, UpdateClientBody } from '../../client/types';
import { CreateClientBody } from './../../client/types';
import { createClient, updateClient } from './clientApi';

export function useClientMutate(
  clientId: string | undefined,
  options?: AxiosMutationOptions<Client, Partial<UpdateClientBody | CreateClientBody>>,
) {
  return useMutation((body: CreateClientBody | UpdateClientBody) => {
    if (clientId) return updateClient(clientId, body);

    if (!('agent' in body)) throw new Error('Agent id is required');

    return createClient(body);
  }, options);
}
