import { authorizedAxios } from 'library/axios/apiProvider';

import { FileBody, FileResult } from './types';

export async function uploadFile(body: FileBody): Promise<FileResult> {
  const formData = new FormData();
  formData.append('file', body.file);
  body.offer && formData.append('offer', body.offer);
  body.insuranceId && formData.append('insurance', body.insuranceId);
  body.policy && formData.append('policy', body.policy);

  const { data } = await authorizedAxios().post(`/api/v1/files/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
}

export async function deleteOfferFile(fileId: string): Promise<void> {
  await authorizedAxios().delete(`/api/v1/files/${fileId}/`);
}
