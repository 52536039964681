import { FileResult } from 'api/files/types';
import { fileResultAsFormFile } from 'utils/formFile';

import { FormFile } from 'components/Attachments/types';

interface ManageProps {
  onUpload?: (file: File) => Promise<FileResult>;
  onAddFile?: (file: FormFile) => void;
  onDeleteFile?: (file: FormFile) => void;
}

export function useManageFormFiles({ onUpload, onAddFile, onDeleteFile }: ManageProps) {
  const handleAttachFiles = async (files: File[]) => {
    if (!onUpload || !onAddFile) return;
    return Promise.all(
      files.map(async (file) => {
        const result = await onUpload(file);
        const formFile = fileResultAsFormFile(result);
        onAddFile(formFile);
        return formFile;
      }),
    );
  };

  const handleRemoveFile = (file: FormFile) => {
    onDeleteFile && onDeleteFile(file);
  };

  return {
    onAttach: onUpload && onAddFile ? handleAttachFiles : undefined,
    onRemove: onDeleteFile ? handleRemoveFile : undefined,
  };
}
