import { Image, Text, View } from '@react-pdf/renderer';

function Footer() {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '50px',
      }}
    >
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Image
          src={'/images/4agent-logo.png'}
          style={{
            width: 65,
            height: 65,
            backgroundColor: '#E6E6E5',
          }}
        />
        <View style={{ paddingLeft: '20px' }}>
          <Text style={{ fontSize: '13px' }}>
            Dane i informacje zawarte w niniejszej{' '}
            <Text style={{ fontSize: '13px', fontFamily: 'Anton' }}>Analizie Potrzeb Klienta</Text>
          </Text>
          <Text style={{ fontSize: '13px' }}>zostały wygenerowane poprzez system elektroniczny 4agent.pl,</Text>
          <Text style={{ fontSize: '13px' }}>w związku z tym nie wymagają podpisu.</Text>
        </View>
      </View>
    </View>
  );
}

export default Footer;
