import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Content = styled.div`
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-areas:
    'head'
    'main';
  grid-template-rows: 70px 1fr;
  header {
    display: flex;
    position: fixed;
    z-index: 2;
    background-color: white;
    align-items: center;
    width: 100%;
    height: 120px;
    background-color: ${theme.palette.common.white};
    nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 ${theme.spacing(5)};
      margin: 0 auto;
      width: 100%;
      height: 100%;
    }
    border-bottom: 1px solid ${theme.palette.grey[300]};
  }

  main {
    min-height: 100vh;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 70%;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 30%;
  margin-left: 25px;
  @media (max-width: 800px) {
    margin-top: 25px;
    margin-left: 0px;
  }
`;
