import { yupResolver } from '@hookform/resolvers/yup';
import { FileResult } from 'api/files/types';
import { useManageFormFiles } from 'library/hooks/useManageFormFiles';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFile } from 'components/Attachments/types';
import { ControlledAttachments, FormGroup } from 'components/Form';

import { AdditionalInfo, Address, ContactInfo, Recommender } from './Controls';
import { baseClientDataSchema, clientDataSchemaWithAgreements } from './schema';

interface Props {
  onSubmit: (data: ClientDataFormState) => void;
  children: React.ReactNode;
  initialState?: Partial<ClientDataFormState>;
  onUpload?: (file: File) => Promise<FileResult>;
  onAddFile?: (file: FormFile) => void;
  onDeleteFile?: (file: FormFile) => void;
  type: 'client' | 'agent';
}

function DataForm({ onSubmit, initialState, children, onAddFile, onDeleteFile, onUpload, type }: Props) {
  const { t } = useTranslation();
  const manageFiles = useManageFormFiles({ onAddFile, onDeleteFile, onUpload });
  const formMethods = useForm<ClientDataFormState>({
    defaultValues: { ...initialState },
    resolver: yupResolver(type === 'agent' ? baseClientDataSchema : clientDataSchemaWithAgreements),
    mode: 'onBlur',
  });

  const { handleSubmit, control, watch } = formMethods;

  const attachments = watch('attachments');

  const submit = (state: ClientDataFormState) => {
    formMethods.reset(state);
    onSubmit(state);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submit)}>
        <Recommender />
        <ContactInfo />
        <Address />
        <AdditionalInfo />
        <FormGroup title={t('insuranceWizard.clientData.attachments')}>
          <ControlledAttachments
            control={control}
            name={'attachments' as never}
            files={attachments ?? []}
            onAttach={manageFiles.onAttach}
            onRemove={manageFiles.onRemove}
          />
        </FormGroup>
        {children}
      </form>
    </FormProvider>
  );
}

export default DataForm;
