import { Grid } from '@mui/material';
import { ClientDataFormState } from 'pages/AgentRemoteSale/contexts/FormContext/types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledTextField, FormGroup } from 'components/Form';

import * as S from './styles';

function AdditionalInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'insuranceWizard.clientData' });
  const { control } = useFormContext<ClientDataFormState>();

  return (
    <FormGroup title={t('additionalInformation')}>
      <S.Container>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="notes"
            defaultValue=""
            label={t('notes')}
            inputProps={{ multiline: true, rows: 5 }}
          />
        </Grid>
      </S.Container>
    </FormGroup>
  );
}

export default AdditionalInfo;
