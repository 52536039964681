import { Grid, Typography } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Container = styled(Grid).attrs((props) => ({ container: true, spacing: 2, ...props }))`
  margin-bottom: ${theme.spacing(2)};
`;

export const GroupTitle = styled(Typography).attrs((props) => ({
  variant: 'body2',
  fontWeight: 'bold',
  ...props,
}))``;
