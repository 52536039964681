import styled from 'styled-components';

interface ButtonProps {
  color: string;
}

export const Wrapper = styled.div`
  display: flex;
`;

export const Text = styled.p`
  color: black;
  margin: 0;
  margin-top: 10px;
  @media (max-width: 1000px) {
    color: white;
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  color: ${(props) => props.color};
  @media (max-width: 1000px) {
    color: white;
    background-color: ${(props) => props.color};
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 5px;
  }
  @media (max-width: 600px) {
    font-size: 11px;
    width: 70px;
    height: 70px;
  }
`;

export const ConfirmationButton = styled.button<ButtonProps>`
  font-size: 18px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: ${(props) => props.color};
`;

export const Span = styled.span`
  display: block;
`;

export const SpanColor = styled.span`
  color: green;
  text-decoration: underline;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  margin: 12px;
  font-size: 20px;
`;
