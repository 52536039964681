import WizardProvider from 'contexts/WizardContext';

import WizardStepper from 'components/WizardStepper';

import FormContextProvider from './contexts/FormContext';
import StepsContent from './steps';
import { STEPS } from './stepsConfig';
import * as S from './styles';

function ClientRemoteSale() {
  return (
    <WizardProvider>
      <S.Main>
        <FormContextProvider>
          <WizardStepper steps={STEPS} />
          <S.Content>
            <StepsContent />
          </S.Content>
        </FormContextProvider>
      </S.Main>
    </WizardProvider>
  );
}

export default ClientRemoteSale;
