import styled from 'styled-components';

export const Main = styled.div`
  height: 900px;
  width: 100%;
`;

export const Calendar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 950px;
  width: 720px;
  position: absolute;
  left: -20px;
  top: 0;
  z-index: 1;
  padding: 0;
  margin: 0 auto;
`;

export const StyledDiv = styled.div`
  position: relative;
  height: 900px;
  width: 720px;
`;

export const StyledCalendar = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
