import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import theme from 'library/theme/theme';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledTextField, FormGroup } from 'components/Form';
import Heading from 'components/Heading';

import { ClientNeedsFormState } from '../../types';
import DatePicker from '../DatePicker';
import OfferDetails from '../OfferDetails';
import { NEEDS } from './const';
import { clientNeedSchema } from './schema';
import * as S from './styles';

interface Props {
  onSubmit: (data: ClientNeedsFormState) => void;
  children: React.ReactNode;
  initialState?: ClientNeedsFormState;
}

function NeedsForm({ onSubmit, children, initialState }: Props) {
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ClientNeedsFormState>({
    resolver: yupResolver(clientNeedSchema),
    defaultValues: {
      startDate: DateTime.now().startOf('day').toJSDate(),
      ...initialState,
    },
    mode: 'onBlur',
  });
  const { t } = useTranslation();

  const handleDateChange = (date: DateTime | null) => {
    if (!date) return;
    setValue('startDate', date.toJSDate(), { shouldValidate: true });
  };

  const renderCheckbox = (icon: React.ReactNode, name: string, index: number) => (
    <FormControlLabel
      key={name}
      style={{ margin: 0 }}
      control={
        <S.StyledCheckbox
          onChange={() => handleSelected(index, name)}
          icon={generateIconBox(icon, name, index)}
          checkedIcon={generateIconBox(icon, name, index)}
          style={{ padding: 0, borderRadius: 0 }}
        />
      }
      label={null}
    />
  );

  const generateIconBox = (icon: React.ReactNode, name: string, index: number) => {
    const isError = errors.insuranceType !== undefined;
    const boxColor = selectedCategoryIndex === index ? '#E5F6FD' : 'transparent';
    const color = isError ? 'firebrick' : theme.palette.primary.main;

    return (
      <S.Box color={boxColor} border={color}>
        <IconButton style={{ color: color }}>{icon}</IconButton>
        <Typography color={color} variant="body1">
          {name}
        </Typography>
      </S.Box>
    );
  };

  const handleSelected = (index: number, name: string) => {
    setSelectedCategoryIndex(index);
    setValue('insuranceType', name, { shouldValidate: true });
  };

  return (
    <>
      <OfferDetails />
      <Heading>{t('agentDashboard.category')}</Heading>
      <S.Container>
        {NEEDS.map(({ icon, name }: { icon: React.ReactNode; name: string }, index: number) =>
          renderCheckbox(icon, name, index),
        )}
      </S.Container>
      <FormGroup title={t('insuranceWizard.clientNeeds.informationAboutClientNeeds')}>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <DatePicker control={control} onChange={handleDateChange} />

          <ControlledTextField
            control={control}
            name="name"
            label={t('insuranceWizard.clientNeeds.shortNameOfNeed')}
            inputProps={{
              autoComplete: 'off',
              InputLabelProps: {
                required: true,
              },
            }}
          />

          <ControlledTextField
            control={control}
            name="description"
            label={t('insuranceWizard.clientNeeds.descriptionOfNeed')}
            defaultValue=""
            inputProps={{
              multiline: true,
              rows: 5,
              autoComplete: 'off',
            }}
          />
          {children}
        </S.Form>
      </FormGroup>
    </>
  );
}
export default NeedsForm;
