import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, CircularProgress, FormHelperText, IconButton, Link, ListItem } from '@mui/material';
import config from 'config';
import React, { useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import AttachmentsErrors from './AttachmentsErrors';
import * as S from './styles';
import { FormFile } from './types';
import { MAX_FILE_SIZE_IN_MB } from './utils';

interface Props {
  files: FormFile[];
  onFileAdd: (file: File | File[]) => void | Promise<void>;
  onFileRemove: (fileName: string) => void;
  error?: boolean;
  errorText?: string;
}

function Attachments({ files, onFileAdd, onFileRemove, errorText, error }: Props) {
  const { t } = useTranslation();
  const [isAttaching, setIsAttaching] = useState(false);
  const [internalError, setInternalError] = useState(false);

  const handleFileUpload = async <T extends File>(acceptedFiles: T[], _: FileRejection[], __: DropEvent) => {
    const filteredFiles = acceptedFiles.filter(
      (file) => !files.find((uploadedFile) => uploadedFile.name === file.name),
    );
    setInternalError(false);

    try {
      if (onFileAdd) {
        setIsAttaching(true);
        await onFileAdd(filteredFiles);
      }
    } catch (error) {
      setInternalError(true);
    }
    setIsAttaching(false);
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    maxSize: config.MAX_FILE_SIZE,
    onDrop: handleFileUpload,
    accept: config.ALLOWED_FILE_TYPES,
  });

  const handleDeleteFile = (fileName: string) => (_: React.MouseEvent<HTMLButtonElement>) => {
    onFileRemove(fileName);
  };

  const getFileNameFromFile = (file: FormFile) => {
    return file.filename.replace(/^.*[\\/]/, '');
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      {fileRejections.length > 0 && (
        <Box marginBottom={1}>
          <AttachmentsErrors fileRejections={fileRejections} />
        </Box>
      )}
      <S.Dropzone {...getRootProps({ isDragReject, isDragActive })} error={error || internalError}>
        {isAttaching ? (
          <CircularProgress />
        ) : (
          <>
            <input {...getInputProps()} />
            <CloudUploadOutlinedIcon color="primary" />
            {isDragActive
              ? t('insuranceWizard.clientData.dropHere')
              : t('insuranceWizard.clientData.dropHereOrClickToUpload', { sizeLimit: `${MAX_FILE_SIZE_IN_MB}MB` })}
          </>
        )}
      </S.Dropzone>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
      {internalError && <FormHelperText error>{t('errors.cantUploadThisFiles')}</FormHelperText>}
      {files.length > 0 && (
        <S.FilesList>
          {files.map((file) => (
            <ListItem
              key={file.name}
              secondaryAction={
                <IconButton onClick={handleDeleteFile(file.name)}>
                  <DeleteForeverIcon color="error" />
                </IconButton>
              }
            >
              <Link download href={file.url}>
                {getFileNameFromFile(file)}
              </Link>
            </ListItem>
          ))}
        </S.FilesList>
      )}
    </div>
  );
}

export default Attachments;
