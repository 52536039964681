import { Typography } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Actions = styled.div`
  margin-top: ${theme.spacing(2)};
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing(2)};
`;

export const SavedText = styled(Typography)`
  color: ${theme.palette.grey[500]};
`;
