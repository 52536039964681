import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid ${theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 40px;
`;
