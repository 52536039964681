import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { createElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OFFERS } from './const';
import * as S from './styles';
import { Offers } from './types';

interface Props {
  offer: Offers;
  iconOnly?: boolean;
  fontSize?: SvgIconTypeMap['props']['fontSize'];
}

function OfferBox({ iconOnly, offer, fontSize = 'medium' }: Props) {
  const { t } = useTranslation();
  const selectedOffer = useMemo(() => OFFERS[offer], [offer]);
  if (!selectedOffer) return <></>;

  const icon = createElement(selectedOffer.icon, { fontSize });
  if (iconOnly)
    return (
      <Tooltip title={t(selectedOffer.label as any)}>
        <S.Container>{icon}</S.Container>
      </Tooltip>
    );

  return (
    <S.Container>
      {icon}
      <Typography variant="body2">{t(selectedOffer.label as any)}</Typography>
    </S.Container>
  );
}
export default OfferBox;
