import { Tab, Tabs } from '@mui/material';
import theme from 'library/theme/theme';
import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
`;

export const Line = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 188px;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${theme.palette.grey[300]};
`;

export const Wrapper = styled.div`
  margin: 120px 70px 0 70px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  margin: 0;
  font-weight: 700;
  @media (max-width: 1000px) {
    font-size: 11px;
  }
`;

export const StyledTabs = styled(Tabs)`
  .MuiTab-root {
    text-transform: none;
  }
`;

export const StyledTab = styled(Tab)`
  text-align: left;
  font-size: 16px;
`;
