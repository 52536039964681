import { Message, NewMessage } from 'api/messages/types';
import { PaginatedResponse } from 'api/types';
import { authorizedAxios } from 'library/axios/apiProvider';

export async function getMessages(offerId: string): Promise<PaginatedResponse<Message>> {
  const { data } = await authorizedAxios().get(`/api/v1/offers/${offerId}/messages-client/`);

  return data;
}

export async function sendMessage(offerId: string, message: NewMessage): Promise<Message> {
  const { data } = await authorizedAxios().post(`/api/v1/offers/${offerId}/messages-client/`, message);

  return data;
}
