import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller, useForm } from 'react-hook-form';

import * as S from './styles';

interface FilterProps {
  control: ReturnType<typeof useForm>['control'];
  register: ReturnType<typeof useForm>['register'];
}

function Filter({ control, register }: FilterProps) {
  const style = { backgroundColor: 'white' };

  return (
    <S.Main>
      <FormControl variant="outlined" size="small">
        <S.Wrapper>
          <S.Box>
            <S.Label>źródło</S.Label>
            <Select style={style} {...register('source')}>
              <MenuItem value="">-wybierz-</MenuItem>
              <MenuItem value="rn">odnowienia</MenuItem>
              <MenuItem value="rs">sprzedaz zdalna</MenuItem>
            </Select>
          </S.Box>

          <S.Box>
            <S.Label>start ochrony</S.Label>
            <Select style={style} {...register('start_date')}>
              <MenuItem value="">-wybierz-</MenuItem>
              <MenuItem value="older">starsze</MenuItem>
              <MenuItem value="7">najbliższe 7 dni</MenuItem>
              <MenuItem value="14">najbliższe 14 dni</MenuItem>
              <MenuItem value="30">najbliższe 30 dni</MenuItem>
              <MenuItem value="60">najbliższe 60 dni</MenuItem>
            </Select>
          </S.Box>

          <S.Box>
            <S.Label>Klient</S.Label>
            <TextField
              error={false}
              style={{ backgroundColor: 'white', borderRadius: '3.5px' }}
              inputProps={{ style: { fontSize: '14px', padding: '8px 5px' } }}
              size="small"
              {...register('client')}
            />
          </S.Box>
          <S.Box>
            <S.Label>potrzeba - kategoria</S.Label>
            <Select style={style} {...register('insurance_type')}>
              <MenuItem value="">-wybierz-</MenuItem>
              <MenuItem value="Zdrowie i życie">Zdrowie i życie</MenuItem>
              <MenuItem value="Dom i mieszkanie">Dom i mieszkanie</MenuItem>
              <MenuItem value="Podróż">Podróż</MenuItem>
              <MenuItem value="Pojazd">Pojazd</MenuItem>
              <MenuItem value="Firma">Firma</MenuItem>
              <MenuItem value="Gospodarstwo rolne">Gospodarstwo rolne</MenuItem>
              <MenuItem value="Panele fotowoltaiczne">Panele fotowoltaiczne</MenuItem>
              <MenuItem value="Inne">Inne</MenuItem>
            </Select>
          </S.Box>
          <S.Box>
            <S.Label>potrzeba - opis</S.Label>
            <TextField
              error={false}
              style={{ backgroundColor: 'white', borderRadius: '3.5px' }}
              inputProps={{ style: { fontSize: '14px', padding: '8px 5px' } }}
              size="small"
              {...register('needs_short')}
            />
          </S.Box>
          <S.Box>
            <S.Label>status</S.Label>
            <Select style={style} {...register('stage')}>
              <MenuItem value={0}>-wybierz-</MenuItem>
              <MenuItem value={1}>Potrzeby klienta</MenuItem>
              <MenuItem value={2}>Dane klienta</MenuItem>
              <MenuItem value={3}>Propozycje ubezpieczeń</MenuItem>
              <MenuItem value={4}>Dopasowanie oferty</MenuItem>
              <MenuItem value={5}>Podsumowanie oferty</MenuItem>
              <MenuItem value={6}>Polisa</MenuItem>
              <MenuItem value={7}>- Spolisowane</MenuItem>
              <MenuItem value={8}>- Anulowane</MenuItem>
            </Select>
          </S.Box>
          <S.MultiBox>
            <S.Box marginRight="10px">
              <S.Label>składka od</S.Label>
              <TextField
                error={false}
                style={{ backgroundColor: 'white', borderRadius: '3.5px' }}
                inputProps={{ style: { fontSize: '14px', padding: '8px 5px' } }}
                size="small"
                {...register('cost_min')}
              />
            </S.Box>
            <S.Box marginLeft="10px">
              <S.Label>składka do</S.Label>
              <TextField
                error={false}
                style={{ backgroundColor: 'white', borderRadius: '3.5px' }}
                inputProps={{ style: { fontSize: '14px', padding: '8px 5px' } }}
                size="small"
                {...register('cost_max')}
              />
            </S.Box>
          </S.MultiBox>
          <S.Box>
            <S.Label>data ostatniej modyfikacji</S.Label>
            <Select style={style} {...register('last_modified')}>
              <MenuItem value="">-wybierz-</MenuItem>
              <MenuItem value="this_week">bieżący tydzień</MenuItem>
              <MenuItem value="previous_week">poprzedni tydzień</MenuItem>
              <MenuItem value="this_month">bieżący miesiąc</MenuItem>
              <MenuItem value="previous_month">poprzedni miesiąc</MenuItem>
              <MenuItem value="this_year">bieżący rok</MenuItem>
              <MenuItem value="previous_year">poprzedni rok</MenuItem>
            </Select>
          </S.Box>
          <div style={{ marginTop: '10px' }}>
            <S.MultiBox>
              <S.Box marginRight="10px">
                <Controller
                  control={control}
                  name="last_modified_before"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      value={field.value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={false}
                          style={{ backgroundColor: 'white', borderRadius: '3.5px' }}
                          size="small"
                        />
                      )}
                    />
                  )}
                />
              </S.Box>
              <S.Box marginLeft="10px">
                <Controller
                  control={control}
                  name="last_modified_after"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      value={field.value}
                      PopperProps={{
                        sx: { '&.MuiPickersPopper-root': { border: '4px solid red' } },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={false}
                          style={{ backgroundColor: 'white', borderRadius: '3.5px' }}
                          size="small"
                        />
                      )}
                    />
                  )}
                />
              </S.Box>
            </S.MultiBox>
          </div>
        </S.Wrapper>
      </FormControl>
    </S.Main>
  );
}

export default Filter;
