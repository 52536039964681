import { PaginatedResponse } from 'api/types';
import { authorizedAxios } from 'library/axios/apiProvider';

import { Offer } from './types';

export async function getOffers() {
  const { data } = await authorizedAxios().get<PaginatedResponse<Offer>>('/api/v1/offers/');
  return data;
}

export async function getCustomOffers(queryString?: string, customUrl?: string) {
  let url = '';
  if (customUrl) {
    url = customUrl;
  } else {
    const baseUrl = '/api/v1/offers/';
    url = queryString ? `${baseUrl}?${queryString}` : baseUrl;
  }
  const { data } = await authorizedAxios().get<PaginatedResponse<Offer>>(url);
  return data;
}

export async function getSingleOffer(offerId: string) {
  const { data } = await authorizedAxios().get<Offer>(`/api/v1/offers/${offerId}/`);
  return data;
}

type CreateUpdateOffer = Partial<Omit<Offer, 'id' | 'stage'>>;

export async function patchOffer(id: string, offer: any): Promise<any> {
  const { data } = await authorizedAxios().patch(`/api/v1/offers/${id}/`, offer);
  return data;
}

export async function createOffer(offer: CreateUpdateOffer): Promise<Offer> {
  const { data } = await authorizedAxios().post('/api/v1/offers/', offer);
  return data;
}

export async function updateOfferStage(id: string): Promise<Offer> {
  const { data } = await authorizedAxios().post(`/api/v1/offers/${id}/next_stage/`);
  return data;
}

export async function sendInvitation(id: string, offer: CreateUpdateOffer) {
  const { data } = await authorizedAxios().post(`/api/v1/offers/${id}/send_invitation/`, offer);
  return data;
}

export const deleteOffers = async (idsToDelete: any) => {
  const deletePromises = idsToDelete.map(async (id: any) => {
    const { data } = await authorizedAxios().delete(`/api/v1/offers/${id}/`);
    return data;
  });

  await Promise.all(deletePromises);
};

export const patchOffers = async (idsToTasks: any, body: any) => {
  const deletePromises = idsToTasks.map(async (id: any) => {
    const { data } = await authorizedAxios().patch(`/api/v1/offers/${id}/`, body);
    return data;
  });

  await Promise.all(deletePromises);
};

export async function patchOfferNew(id: string, offer: any) {
  const { data } = await authorizedAxios().patch(`/api/v1/offers/${id}/`, offer);
  return data;
}
