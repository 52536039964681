import { useAgentForm } from 'pages/AgentRemoteSale/contexts/FormContext/hooks/useAgentForm';
import { useCallback, useMemo, useState } from 'react';

import { InsuranceSortOrder } from './types';
import { getSortOrder, saveSortOrder } from './utils';

function useSortedInsurances() {
  const {
    state: { insurances },
  } = useAgentForm();
  const [sortOrder, setSortOrder] = useState<InsuranceSortOrder>(getSortOrder());

  const sortedInsurances = useMemo(() => {
    if (!insurances) return [];

    if (sortOrder === 'added') return [...insurances].reverse();

    return [...insurances].sort((insurance, nextInsurance) => {
      if (sortOrder === 'cost_asc') return nextInsurance.cost - insurance.cost;

      return insurance.cost - nextInsurance.cost;
    });
  }, [insurances, sortOrder]);

  const onSortOrderChange = useCallback((order: InsuranceSortOrder) => {
    setSortOrder(order);
    saveSortOrder(order);
  }, []);

  return {
    sortOrder,
    setSortOrder: onSortOrderChange,
    insurances: sortedInsurances,
  };
}

export default useSortedInsurances;
