import { DateTime } from 'luxon';
import * as yup from 'yup';

const dateValidator = yup
  .date()
  .min(DateTime.now().startOf('day'), 'errors.dateCantBeFromPast')
  .typeError({ key: 'errors.dateMustBeInFormat', options: { format: 'DD.MM.YYYY' } })
  .required();

export const createSchema = (isRemotelyInSystem: boolean) => {
  const baseSchema = {
    tuNumber: isRemotelyInSystem ? yup.string() : yup.string().required(),
    name: yup.string().required().trim().max(50),
    startProtection: dateValidator,
    endless: yup.boolean(),
    endProtection: dateValidator,
    accountNumber: yup
      .string()
      .test('contains any underscore', 'errors.invalidFormat', (value) => !value?.includes('_')),
    paymentDate: yup.date().nullable(),
    signMethod: yup.string().required(),
    attachments: yup.array(),
  };

  return yup.object().shape(baseSchema);
};
